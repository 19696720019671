export class HomemadeDate {
  year: number;
  month: number;
  dayOfMonth: number;
  monthValue?: number;
  hour: number;
  minute: number;
  second: number;

  constructor(
    year?: number,
    month?: number,
    dayOfMonth?: number,
    hour?: number,
    minute?: number,
    second?: number,
  ) {
    this.year = year || 0;
    this.month = (month || 0) + 1;
    this.dayOfMonth = dayOfMonth || 0;
    this.hour = hour || 0;
    this.minute = minute || 0;
    this.second = second || 0;
  }

  public getString(): string {
    return `${this.dayOfMonth}-${this.month}-${this.year}`;
  }

  public getStringWithTime(): string {
    return `${this.dayOfMonth}-${this.month}-${this.year} ${this.hour}:${this.minute}`;
  }
}
