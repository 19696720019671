export enum UserAuthenticationTypes {
  // User login related
  USER_LOGIN_REQUEST = '@@user/login/REQUEST',
  USER_LOGIN_SUCCESS = '@@user/login/SUCCESS',
  USER_LOGIN_ERROR = '@@user/login/ERROR',
  USER_CHANGE_PASSWORD = '@@user/login/CHANGE_PASSWORD',

  // Re-auth related
  USER_REAUTH_REQUEST = '@@user/reauth/REQUEST',
  USER_REAUTH_SUCCESS = '@@user/reauth/SUCCESS',
  USER_REAUTH_ERROR = '@@user/reauth/ERROR',
  USER_REFRESH_TOKEN_AGE = '@@user/login/REFRESH_TOKEN_AGE',

  // User logout related
  USER_LOGIN_REMOVE_USER_INFO = '@@user/login/REMOVE_USER_INFO',

  USER_LOGOUT_REQUEST = '@@user/logout/REQUEST',
  USER_LOGOUT_SUCCESS = '@@user/logout/SUCCESS',
  USER_LOGOUT_ERROR = '@@user/logout/ERROR',
}

// Response object for POST /login/
export interface UserDTO extends ApiResponse {
  id: number;
  user_type: UserTypes;
  phone_number: string;
  last_name: string;
  email: string;
  username: string;
  user_group_code: string;
  first_name: string;
  user_type_id: number;
  user_group_id: 1;
  super_company_id: number;
}

export interface PasswordChangeRequest {
  password_first: string;
  password_second: string;
  password_old?: string;
}

export interface UserLoginResponse {
  userDTO: UserDTO;
  jwt: string;
}

// This type is basically shorthand for `{ [key: string]: any }`. Feel free to replace `any` with
// the expected return type of your API response.
export type ApiResponse = Record<string, {}>;

export enum UserTypes {
  INSTRUCTOR = 'instructor',
  STUDENT = 'student',
  NOT_LOGGED_IN = 'notLoggedIn',
}

export interface UserInformation {
  readonly id: number;
  readonly userType: UserTypes;
  readonly userFirstName: string;
  readonly userLastName: string;
  readonly userEmail: string;
  readonly userTypeId: number;
  readonly userGroupId: number;
  readonly userGroupCode: string;
  readonly userPhoneNo: string;
  readonly userSuperCompanyId: number;
  readonly jwtToken: string | null;
  readonly jwtTokenAge: string | null;
  readonly loading: boolean;
  readonly errors?: string;
}
