import React, { useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import RequisitionService from '../../../../api/services/RequisitionService';
import { useMutation } from 'react-query';
import { useToast } from '../../../hooks/UseToast';

interface PropsFromParent {
  products: any;
  to_company_id: any;
  from_company_id: any;
  setProducts: () => null;
}

type Totals = { total_amount_without_vax: number; total_vax: number };

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

const Checkout: React.FC<PropsFromParent> = props => {
  const { toast } = useToast();

  const [navisionID, setNavisionID] = useState<string>('');
  const [totals, setTotals] = useState<Totals>({
    total_amount_without_vax: 0,
    total_vax: 0,
  });

  const { mutate: createRequisition, isLoading } = useMutation(
    RequisitionService.createRequisition,
    {
      onSuccess: () => {
        toast('success', 'Rekvisition blev oprettet.', 'Rekvisition');
        props.setProducts();
      },
      onError: err => {
        toast('error', 'Rekvisition blev ikke oprettet. ' + err, 'Rekvisition');
      },
    },
  );

  useEffect(() => {
    getTotals();
  }, [props.products]);

  const getTotals = (): void => {
    let total_amount_without_vax: number = 0;
    let total_vax: number = 0;

    props.products.forEach(product => {
      total_amount_without_vax += product.quantity * product.price_cost;

      if (!product.is_vax_free) {
        total_vax += product.quantity * product.price_cost * 0.25;
      }
    });

    setTotals({ total_amount_without_vax, total_vax });
  };

  const formatDataToRequisition = () => {
    let from_company_id = props.from_company_id;
    let products: any = [];

    props.products.forEach(product => {
      let formattedProduct = [parseInt(product.id), parseInt(product.quantity)];
      products.push(formattedProduct);
    });

    return {
      from_company_id: parseInt(from_company_id),
      to_company_id: parseInt(props.to_company_id),
      navision_id: navisionID,
      products: products,
    };
  };

  const handleCheckout = () => {
    createRequisition(formatDataToRequisition());
  };

  return (
    <div>
      <p className="mr-sm-2">
        Total u. moms: {formatter.format(totals.total_amount_without_vax)}
      </p>
      <p className="mr-sm-2">
        Total m. moms:{' '}
        {formatter.format(totals.total_amount_without_vax + totals.total_vax)}
      </p>

      <Label className="mr-sm-2 font-weight-bold">Navision ordre nr.:</Label>
      <Input
        min={0}
        name="navision_id"
        type="number"
        placeholder="Indtast navision ordre nr"
        onChange={e => setNavisionID(e.target.value)}
      />
      <button
        onClick={() => handleCheckout()}
        className={'btn btn-success w-100 mt-2'}
        disabled={isLoading}
      >
        Bestil
      </button>
    </div>
  );
};

export default Checkout;
