export enum BankAccountTypeString {
  COMPANY = 'company_account',
  STUDENT = 'student_account',
}

export enum BankAccountTypeNumber {
  COMPANY = 1,
  STUDENT = 2,
}

export interface BankAccount extends NewBankAccount {
  id: number;
  isActive: boolean;
}

export interface NewBankAccount {
  type_id: number;
  companyId?: number;
  userId?: number;
  name: string;
  currencyCode: number;
  balance: number;
}

export interface ApiRequestEditedBankAccount {
  name: string;
  currency_code: number;
  balance: number;
}

export enum BankAccountActionTypes {
  BANK_ACCOUNT_GET_ALL = 'bankAccount/GET_ALL',
  BANK_ACCOUNT_GET_SPECIFIC_ACCOUNT = 'bankAccount/GET_ACCOUNT',
  BANK_ACCOUNT_GET_COMPANY_ACCOUNTS = 'bankAccount/GET_COMPANY_ACCOUNTS',
  BANK_ACCOUNT_GET_STUDENT_ACCOUNTS = 'bankAccount/GET_STUDENT_ACCOUNTS',
  BANK_ACCOUNT_CREATE = 'bankAccount/CREATE',
  BANK_ACCOUNT_EDIT = 'bankAccount/EDIT',
  BANK_ACCOUNT_DELETE = 'bankAccount/DELETE',
  BANK_ACCOUNT_SUCCESS = 'bankAccount/SUCCESS',
  BANK_ACCOUNT_COMPANY_ACCOUNTS_SUCCESS = 'bankAccount/COMPANY_ACCOUNTS_SUCCESS',
  BANK_ACCOUNT_STUDENT_ACCOUNTS_SUCCESS = 'bankAccount/STUDENT_ACCOUNTS_SUCCESS',
  BANK_ACCOUNT_SPECIFIC_ACCOUNT_SUCCESS = 'bankAccount/SPECIFIC_ACCOUNT_SUCCESS',
  BANK_ACCOUNT_ERROR = 'bankAccount/ERROR',
}

export interface BankAccountState {
  readonly loading: boolean;
  readonly loadingSpecificAccount: boolean;
  readonly bankAccounts: BankAccount[];
  readonly companyBankAccounts: BankAccount[];
  readonly studentAccounts: BankAccount[];
  readonly specificAccount: BankAccount;
  readonly errors?: string;
}
