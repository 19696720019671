import { Delivery, IDeliveryActionTypes, NewDelivery } from './types';
import { action } from 'typesafe-actions';
import { ArchiveType } from '../../../utils/convertions';
import { Page } from '../../../utils/storeTypes';

export const deliveryGetAllToAndFromCompanyId = (companyId: number) =>
  action(IDeliveryActionTypes.IDELIVERY_GET_ALL, { companyId });
export const deliveryGetAllFromCompanyId = (companyId: number) =>
  action(IDeliveryActionTypes.IDELIVERY_GET_FROM, { companyId });
export const deliveryCreate = (
  newDelivery: NewDelivery,
  fromCompanyId: number,
) =>
  action(IDeliveryActionTypes.IDELIVERY_CREATE, { newDelivery, fromCompanyId });
export const deliveryGetFromCompanyPage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(IDeliveryActionTypes.IDELIVERY_GET_ALL_FROM_COMP_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    searchQuery,
    startDate,
    endDate,
  });
export const deliveryGetToCompanyActivePage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  archiveType: ArchiveType,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  isDelivered: boolean,
) =>
  action(IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ACTIVE_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    archiveType,
    searchQuery,
    startDate,
    endDate,
    isDelivered,
  });
export const deliveryGetToCompanyArchivePage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  archiveType: ArchiveType,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
  isDelivered: boolean,
) =>
  action(IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ARCHIVED_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    archiveType,
    searchQuery,
    startDate,
    endDate,
    isDelivered,
  });
export const deliverySetArchiveType = (
  deliveryId: number,
  newArchiveTypeId: number,
  currentUserCompanyId: number,
) =>
  action(IDeliveryActionTypes.IDELIVERY_SET_ARCHIVE_TYPE, {
    deliveryId,
    newArchiveTypeId,
    currentUserCompanyId,
  });
export const deliverySetAddedToStock = (
  deliveryId: number,
  status: boolean,
  currentUserCompanyId: number,
) =>
  action(IDeliveryActionTypes.IDELIVERY_SET_ADDEDTOSTOCK_STATUS, {
    deliveryId,
    status,
    currentUserCompanyId,
  });
export const deliverySuccess = (data: Delivery[]) =>
  action(IDeliveryActionTypes.IDELIVERY_SUCCESS, data);
export const deliveryFromSuccess = (data: Delivery[]) =>
  action(IDeliveryActionTypes.IDELIVERY_FROM_SUCCESS, data);
export const deliverySuccessFromCompanyPage = (data: Page) =>
  action(IDeliveryActionTypes.IDELIVERY_GET_ALL_FROM_COMP_PAGE_SUCCESS, data);
export const deliverySuccessToCompanyActivePage = (data: Page) =>
  action(
    IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS,
    data,
  );
export const deliverySuccessToCompanyArchivePage = (data: Page) =>
  action(
    IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS,
    data,
  );
export const deliveryError = (message: string) =>
  action(IDeliveryActionTypes.IDELIVERY_ERROR, message);
