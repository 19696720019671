import * as React from 'react';
import { connect } from 'react-redux';
import { InstructorPageLayout } from '../_layout';
import { Helmet } from 'react-helmet-async';
import Dashboard from './dashboard';

const AdminDashboard: React.FC = () => {
  return (
    <InstructorPageLayout>
      <Helmet>
        <title>Forside</title>
      </Helmet>
      <Dashboard />
    </InstructorPageLayout>
  );
};

export default connect()(AdminDashboard);
