import React, { Component } from 'react';
import MaterialTable, { Components } from '@material-table/core';
import { TablePagination } from '@material-ui/core';

/* Docs:
 * https://material-table-core.com/
 */

export type OnChangePageFuncType = (
  targetPageNumber: number,
  pageSize: number,
) => void;
export type OnChangeRowsPerPageFuncType = (newRowsPerPage: number) => void;

interface PropsFromParent {
  data: any[];
  headers: any[];
  actions?: any[];
  selection?: boolean;
  editFunction?: any;
  isLoading?: boolean;
  titleComponent?: React.ReactElement<any>;
  removeBorder?: boolean;

  numberRowPerPage: number;
  totalElementAmount: number;
  onChangePageFunc: OnChangePageFuncType; //Triggered on page change //Should fetch new page data and set props data
  onChangeRowsPerPageFunc: OnChangeRowsPerPageFuncType; //Triggered on rows per page is changed
  onSearchChange?: (string) => void; // Trigger on search field change
  pageDataLoading?: boolean;
  pageNumber: number; //Current page number
  components?: Components;
  searchAble?: boolean;
  detailPanel?: any;
}

/* TODO-Future: Enable editing within
 *   https://material-table.com/#/docs/features/editable */
class CustomTablePagination extends Component<PropsFromParent> {
  handleChangePage(selectedPageNumber: number) {
    this.props.onChangePageFunc(
      selectedPageNumber,
      this.props.numberRowPerPage,
    );
  }

  handleChangeRowPerPage(value) {
    this.props.onChangeRowsPerPageFunc(value);
  }

  render() {
    return (
      <>
        <MaterialTable
          style={{
            border: this.props.removeBorder ? 'none' : '',
          }}
          onSearchChange={query => this.props.onSearchChange!(query)}
          onChangePage={(number, pageSize) =>
            this.props.onChangePageFunc(number, pageSize)
          }
          title={this.props?.titleComponent}
          data={this.props.data}
          actions={this.props.actions}
          columns={this.props.headers}
          isLoading={this.props.isLoading || this.props.pageDataLoading}
          detailPanel={this.props.detailPanel ?? undefined}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                const re = /^0$|^[1-9][0-9]*$/;
                if (re.test(newValue)) {
                  this.props.editFunction(rowData, newValue);
                  resolve();
                } else {
                  reject();
                }
              });
            },
            isCellEditable(rowData, columnDef) {
              return true;
            },
          }}
          options={{
            search: this.props.searchAble ?? true,
            paging: true,
            emptyRowsWhenPaging: false,
            filtering: false,
            actionsColumnIndex: -1,
            showTitle: !!this.props.titleComponent,
            draggable: false,
            selection: this.props.selection ? this.props.selection : false,
            pageSize: this.props.numberRowPerPage,
            debounceInterval: 400,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'Der er intet data at vise',
            },
            toolbar: {
              searchTooltip: 'Søg',
              searchPlaceholder: 'Søg',
            },
            header: {
              actions: '',
            },
            pagination: {
              labelRowsSelect: 'rækker',
              labelDisplayedRows: '({count}) Række {from}-{to}',
              firstTooltip: 'Første side',
              previousTooltip: 'Forrige side',
              nextTooltip: 'Næste side',
              lastTooltip: 'Sidste side',
            },
          }}
          components={{
            ...this.props.components,
            Pagination: props => (
              <TablePagination
                {...props}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowsPerPage={this.props.numberRowPerPage}
                count={this.props.totalElementAmount}
                page={this.props.pageNumber}
                onChangePage={(e, page) => this.handleChangePage(page)}
                onChangeRowsPerPage={event => {
                  props.onChangeRowsPerPage(event);
                  this.handleChangeRowPerPage(event.target.value);
                }}
              />
            ),
          }}
        />
      </>
    );
  }
}

export default CustomTablePagination;
