import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import { Invoice, invoiceGetAll } from '../../../store/instructor/orderInvoice';
import { Company, companyGetAll } from '../../../store/instructor/company';
import CompanyTab from './companyTab';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  invoices: Invoice[];
  allCompanies: Company[];
}

interface ThisCompState {
  selectedCompany: Company;
  currentSelectedCompanyId: number;
}

class OrderInvoicesContent extends React.Component<
  PropsFromState,
  ThisCompState
> {
  state = {
    selectedCompany: {
      name: '',
      id: -1,
      payment_terms: -1,
      address: '',
      delivery_time: -1,
      bank_accounts: [],
      company_type_id: -1,
      company_type: '',
    },
    currentSelectedCompanyId: -1,
  };

  componentDidMount() {
    store.dispatch(invoiceGetAll());
    store.dispatch(companyGetAll());
  }

  retrieveCompanyFromId = (id: number) => {
    return this.props.allCompanies.find(company => company.id === id);
  };

  onChange = e => {
    const selectedCompany: any = this.retrieveCompanyFromId(
      parseInt(e.target.value),
    );

    if (e.target.value !== -1) {
      this.setState({ selectedCompany: selectedCompany });
    }

    this.setState({
      currentSelectedCompanyId: selectedCompany.id,
    });
  };

  renderTab = (company: Company, allInvoices: Invoice[]) => {
    return (
      <Tabs>
        <Tab eventKey={company.name} title={company.name}>
          <CompanyTab
            company={company}
            invoices={allInvoices.filter(
              invoice => invoice.company_from === company.id,
            )}
            allCompanies={this.props.allCompanies}
          />
        </Tab>
      </Tabs>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          <>
            <Form>
              <Row>
                <Form.Label>Vælg virksomhed:</Form.Label>
                <Col sm="auto">
                  <Form.Control
                    as="select"
                    defaultValue="Vælg.."
                    name={'currentSelectedCompanyId'}
                    value={this.state.currentSelectedCompanyId}
                    onChange={this.onChange}
                  >
                    <option value={-1}>Vælg..</option>
                    {this.props.allCompanies
                      .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                      .map((elem, index) => {
                        return (
                          <option key={index} value={elem.id}>
                            {elem.name}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
              </Row>
            </Form>
            {this.state.currentSelectedCompanyId !== -1
              ? this.renderTab(this.state.selectedCompany, this.props.invoices)
              : null}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ iInvoice, iCompany }: ApplicationState) => ({
  loading: iInvoice.loading || iCompany.loading,
  invoices: iInvoice.invoices,
  allCompanies: iCompany.companies,
});

export default connect(mapStateToProps)(OrderInvoicesContent);
