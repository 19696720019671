import { action } from 'typesafe-actions';
import {
  PasswordChangeRequest,
  UserAuthenticationTypes,
  UserDTO,
  UserLoginResponse,
} from './types';

// Login related
export const loginRequest = (
  usernameOrEmail: string,
  usernameIsEmail: boolean,
  password: string,
  remember: boolean,
) =>
  action(UserAuthenticationTypes.USER_LOGIN_REQUEST, {
    usernameOrEmail,
    usernameIsEmail,
    password,
    remember,
  });
export const loginSuccess = (data: UserLoginResponse) =>
  action(UserAuthenticationTypes.USER_LOGIN_SUCCESS, data);
export const loginError = (message: string) =>
  action(UserAuthenticationTypes.USER_LOGIN_ERROR, message);
export const changePassword = (userId: number, data: PasswordChangeRequest) =>
  action(UserAuthenticationTypes.USER_CHANGE_PASSWORD, {
    userId,
    data,
  });

// Re-auth related
export const reAuthRequest = () =>
  action(UserAuthenticationTypes.USER_REAUTH_REQUEST);
export const reAuthSuccess = (data: UserDTO) =>
  action(UserAuthenticationTypes.USER_REAUTH_SUCCESS, data);
export const reAuthError = (message: string) =>
  action(UserAuthenticationTypes.USER_REAUTH_ERROR, message);
export const refreshTokenAge = () =>
  action(UserAuthenticationTypes.USER_REFRESH_TOKEN_AGE);

// Logout Related
export const logoutRequest = () =>
  action(UserAuthenticationTypes.USER_LOGOUT_REQUEST);
export const logoutSuccess = () =>
  action(UserAuthenticationTypes.USER_LOGOUT_SUCCESS);
export const logoutError = (message: string) =>
  action(UserAuthenticationTypes.USER_LOGOUT_ERROR, message);
export const fetchRemoveUserInfo = () =>
  action(UserAuthenticationTypes.USER_LOGIN_REMOVE_USER_INFO);
