import * as React from 'react';
import { store } from '../../../../store/store';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Company } from '../../../../store/instructor/company';
import { BankAccount } from '../../../../store/instructor/bank';
import {
  NewTaxDeclaration,
  TaxAdd,
  TaxDeclarationType,
} from '../../../../store/instructor/tax';
import {
  addThousandDots,
  removeDotsFromString,
} from '../../../../utils/convertions';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  thisUserCompanyId: number;
  taxCompany: Company;
  userCompanyBankAccounts: BankAccount[];
  selectedMonth: number;
  selectedYear: number;
}

interface ThisCompState {
  type: TaxDeclarationType;
  enteredData: {
    salaryReceiverContribution: string;
    employerContribution: string;
  };
  accountFrom: number;
  totalTaxAmountEntered: string;
  localErrors: {
    salaryReceiverContribution: string | null;
    employerContribution: string | null;
    accountFrom: string | null;
    totalAmount: string | null;
  };
}

//ATP
class DeclareATPModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      type: TaxDeclarationType.ATP,
      enteredData: {
        salaryReceiverContribution: '0',
        employerContribution: '0',
      },
      accountFrom: -1,
      totalTaxAmountEntered: '0',
      localErrors: {
        salaryReceiverContribution: null,
        employerContribution: null,
        accountFrom: null,
        totalAmount: null,
      },
    };
  }

  onChange = e => {
    const re = /^(-)?(\d*(\.\d{2,4})*)$/; //allows: space | integer dotted per thousand | integer
    if (!re.test(e.target.value)) {
      return;
    } else {
      if (e.target.name === 'accountFrom') {
        this.setState({
          accountFrom: parseInt(e.target.value),
        });
      } else {
        this.setState(
          {
            enteredData: {
              ...this.state.enteredData,
              [e.target.name]: addThousandDots(
                removeDotsFromString(e.target.value),
              ),
            },
          },
          () => {
            this.recalculateTotalTaxAmount();
          },
        );
      }
    }
  };

  recalculateTotalTaxAmount() {
    const error = this.validate();
    if (
      error.employerContribution === null &&
      error.salaryReceiverContribution === null
    ) {
      //NO ERRORS -> calculate
      this.setState({
        totalTaxAmountEntered: addThousandDots(
          (
            parseInt(
              removeDotsFromString(this.state.enteredData.employerContribution),
            ) +
            parseInt(
              removeDotsFromString(
                this.state.enteredData.salaryReceiverContribution,
              ),
            )
          ).toString(),
        ).toString(),
      });
    } else {
      this.setState({ totalTaxAmountEntered: 'Ej Muligt' });
    }
  }

  validate = () => {
    let errors: {
      employerContribution: string | null;
      salaryReceiverContribution: string | null;
      accountFrom: string | null;
      totalAmount: string | null;
    } = {
      employerContribution: null,
      salaryReceiverContribution: null,
      accountFrom: null,
      totalAmount: null,
    };

    if (this.state.enteredData.salaryReceiverContribution === '') {
      errors.salaryReceiverContribution = 'Lønmodtagerbidrag skal være et tal.';
    }
    if (this.state.enteredData.employerContribution === '') {
      errors.employerContribution = 'Arbejdsgiverbidrag skal være et tal.';
    }

    if (this.state.accountFrom === -1) {
      errors.accountFrom = 'Vælg venligst en konto.';
    }

    if (isNaN(parseInt(this.state.totalTaxAmountEntered))) {
      errors.totalAmount = 'Totalt beløb er ikke gyldigt.';
    }

    return errors;
  };

  onSubmitButtonClick = e => {
    e.preventDefault();
    //validates the entered data (client-side)
    const errors = this.validate();
    this.setState({
      localErrors: {
        employerContribution: errors.employerContribution,
        salaryReceiverContribution: errors.salaryReceiverContribution,
        accountFrom: errors.accountFrom,
        totalAmount: errors.totalAmount,
      },
    });

    if (
      errors.employerContribution === null &&
      errors.salaryReceiverContribution === null &&
      errors.accountFrom === null &&
      errors.totalAmount === null
    ) {
      //At this point, all values are an int
      if (this.props.taxCompany.bank_accounts.length < 1) {
        throw new Error('The tax company has no bank accounts!');
      } else {
        const declaration: NewTaxDeclaration = {
          company_id: this.props.thisUserCompanyId,
          bank_account_id: this.state.accountFrom,
          target_month: this.props.selectedMonth,
          target_year: this.props.selectedYear,
          type: TaxDeclarationType.ATP,
          amount: parseInt(
            removeDotsFromString(this.state.totalTaxAmountEntered),
          ),
          details: {
            salary_receiver_contribution: parseInt(
              removeDotsFromString(
                this.state.enteredData.salaryReceiverContribution,
              ),
            ),
            employer_contribution: parseInt(
              removeDotsFromString(this.state.enteredData.employerContribution),
            ),
          },
        };

        store.dispatch(TaxAdd(declaration));
        this.props.visibilityToggleFunc();
      }
    }
  };

  /** This function is used when a field is un-focused/blur.
   If the value is empty, it is it to 0. */
  onEnteredDataTaxFieldBlur = e => {
    if (e.target.value === '') {
      //The value of the field should be set to 0
      this.setState(
        {
          enteredData: {
            ...this.state.enteredData,
            [e.target.name]: '0',
          },
        },
        () => {
          this.recalculateTotalTaxAmount();
        },
      );
    }
  };

  public render() {
    return <div>{this.renderContent()}</div>;
  }

  //The current from account is not rendered as an option
  renderToAccountOptions = (allAccounts: BankAccount[]) => {
    const fromAccountRemoved: BankAccount[] = [...allAccounts];
    const removeIndex: number = allAccounts.findIndex(
      elem => elem.id === this.state.accountFrom,
    );
    if (removeIndex !== -1) {
      fromAccountRemoved.splice(removeIndex, 1);
    }

    return this.renderAccountOptions(fromAccountRemoved);
  };

  renderAccountOptions = (allAccounts: BankAccount[]) => {
    return (
      <>
        {allAccounts.map((account, index) => {
          return (
            <option key={index} value={account.id}>
              {account.name}
            </option>
          );
        })}
      </>
    );
  };

  renderContent() {
    const activeCompanyAccounts: BankAccount[] = this.props.userCompanyBankAccounts.filter(
      elem => elem.isActive,
    );

    return (
      <>
        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label>Lønmodtagerbidrag:</Form.Label>
            </div>
            <div className="col-sm-3">
              <Form.Control
                placeholder=""
                name={'salaryReceiverContribution'}
                value={this.state.enteredData.salaryReceiverContribution}
                onChange={this.onChange}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1 text-left">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Arbejdsgiverbidrag:</Form.Label>
            </div>
            <div className="col-sm-3">
              <Form.Control
                placeholder=""
                name={'employerContribution'}
                value={this.state.enteredData.employerContribution}
                onChange={this.onChange}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label className={'font-weight-bold'}>
                <i>Afgiftsbeløb i alt</i>:
              </Form.Label>
            </div>
            <div className="col-sm-3">
              <Form.Control
                placeholder=""
                name={'totalTaxAmountEntered'}
                value={this.state.totalTaxAmountEntered}
                disabled={true}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-5">
              <Form.Label>Fra konto</Form.Label>
            </div>
            <div className="col-sm-7">
              <Col sm="auto">
                <Form.Control
                  as="select"
                  defaultValue="Vælg.."
                  name={'accountFrom'}
                  value={this.state.accountFrom}
                  onChange={this.onChange}
                >
                  <option value={-1}>Vælg..</option>
                  {this.renderAccountOptions(activeCompanyAccounts)}
                </Form.Control>
              </Col>
            </div>
          </Row>
        </div>

        <ErrorDiv>
          {this.state.localErrors.salaryReceiverContribution}
          {this.state.localErrors.employerContribution}
          {this.state.localErrors.accountFrom}
          {this.state.localErrors.totalAmount}
        </ErrorDiv>

        <Button variant="success" onClick={e => this.onSubmitButtonClick(e)}>
          Indberet
        </Button>
      </>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  thisUserCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(DeclareATPModalData);
