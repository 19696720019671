import React, { Component } from 'react';
import CustomTable from '../../../components/table/customTable';
import update from 'immutability-helper';
import { store } from '../../../store/store';
import {
  Company,
  companyGetCreditors,
  companyGetProducts,
} from '../../../store/instructor/company';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { Product } from '../../../store/instructor/product';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';

interface PropsFromParent {
  creditorCompanies: Company[];
  userSuperCompanyId: number;
  products: any;
  editProducts: any;
  loading: boolean;
  setToCompany: any;
  setFromCompany: any;
  fromCompany: any;
  companyProducts: Product[];
  isInstructor?: boolean;
}

/* TODO: Make TableData be product[]*/
interface ThisCompState {
  tableHeader: any[];
  tableData: any[];
}

class ProductsTable extends Component<PropsFromParent, ThisCompState> {
  constructor(props) {
    super(props);
    this.state = {
      tableHeader: [
        { title: 'Vare nr.', field: 'navision_id', editable: 'never' },
        { title: 'Varebeskrivelse', field: 'name', editable: 'never' },
        {
          title: 'Pris stk.',
          field: 'price_cost',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
        { title: 'Antal', field: 'quantity', emptyValue: 'Antal' },
      ],
      tableData: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.companyProducts !== this.props.companyProducts) {
      this.setState({
        tableData: this.props.companyProducts,
      });
    }
  }

  componentDidMount() {
    store.dispatch(companyGetCreditors(this.props.userSuperCompanyId));
  }

  onEditSelectionApproved(rowData, newValue) {
    let newData = this.state.tableData;

    let rowIndex = newData.findIndex(({ id }) => id === rowData.id);

    newData = update(newData, {
      [rowIndex]: { quantity: { $set: newValue } },
    });

    this.setState({ tableData: newData });
  }

  onChangeSupplier(e) {
    this.props.setToCompany(e.target.value);
    store.dispatch(companyGetProducts(parseInt(e.target.value)));
  }

  onChangeSender(e) {
    this.props.setFromCompany(e.target.value);
  }

  render() {
    return (
      <div>
        <div className="row">
          {this.props.isInstructor ? (
            <div className="col">
              <label>Vælg afsender</label>
              <select
                className="form-control"
                onChange={e => this.onChangeSender(e)}
                defaultValue={-1}
              >
                <option value={-1}>Vælg ...</option>
                {this.props.creditorCompanies
                  .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                  .map((company, index) => {
                    return (
                      <option key={index} value={company.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          ) : (
            ''
          )}
          <div className="col">
            <label>Vælg Leverandør</label>
            <select
              className="form-control"
              onChange={e => this.onChangeSupplier(e)}
              defaultValue={-1}
            >
              <option value={-1}>Vælg ...</option>
              {this.props.isInstructor
                ? this.props.creditorCompanies
                    .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                    .map((company, index) => {
                      return (
                        <option key={index} value={company.id}>
                          {company.name}
                        </option>
                      );
                    })
                : this.props.creditorCompanies //TODO-future: filter to make sure all sub companies are NORMAL and not SUPER
                    .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                    .map((company, index) => {
                      return (
                        <option key={index} value={company.id}>
                          {company.name}
                        </option>
                      );
                    })}
            </select>
          </div>
        </div>
        <CustomTable
          isLoading={this.props.loading}
          data={this.state.tableData}
          headers={this.state.tableHeader}
          selection={true}
          editFunction={(rowData, newValue) =>
            this.onEditSelectionApproved(rowData, newValue)
          }
          actions={[
            {
              icon: 'add_shopping_cart',
              tooltip: 'Tilføj til Kurv',
              onClick: (event, selectedRows) => {
                this.props.editProducts(selectedRows);
              },
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ iCompany, userInfo }: ApplicationState) => ({
  loading: iCompany.loading,
  creditorCompanies: iCompany.companyCreditors,
  companyProducts: iCompany.companyProducts,
  userSuperCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(ProductsTable);
