import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import styled from 'styled-components';
import InboxContent from './inboxContent';
import { Helmet } from 'react-helmet-async';

const SInboxPage: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Indbakke</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Indbakke</h2>
      </PageHeader>

      <InboxContent />
    </StudentPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(SInboxPage);
