import { ExtendedDetails } from '../contexts/MistakeProvider';

export enum MistakesTypes {
  MISTAKES_SET_MODAL_VISIBILITY = '@@mistakes/set/VISIBILITY',
  MISTAKES_SET_SELECTED_DETAILS = '@@mistakes/set/DETAILS',
  MISTAKES_ERROR_SET_MSG = '@@mistakes/set/ERROR',
}

export enum MistakeActionTypes {
  UNDEFINED,
  MESSAGE,
  RETURN_DELIVERY,
}

type Action =
  | {
      type: MistakesTypes.MISTAKES_SET_MODAL_VISIBILITY;
      modalVisibility: { state; actionType };
    }
  | {
      type: MistakesTypes.MISTAKES_SET_SELECTED_DETAILS;
      selectedDetails: { details; type };
    }
  | {
      type: MistakesTypes.MISTAKES_ERROR_SET_MSG;
      error: string;
    };

type State = {
  modalVisibility: { state: boolean; actionType: MistakeActionTypes };
  mistakeError?: string;
  selectedDetails?: ExtendedDetails;
};

export function mistakesReducer(state: State, action: Action): State {
  switch (action.type) {
    case MistakesTypes.MISTAKES_SET_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: {
          state: action.modalVisibility.state,
          actionType: action.modalVisibility.actionType,
        },
      };
    case MistakesTypes.MISTAKES_SET_SELECTED_DETAILS:
      return {
        ...state,
        selectedDetails: {
          details: action.selectedDetails.details,
          type: action.selectedDetails.type,
        },
      };
    case MistakesTypes.MISTAKES_ERROR_SET_MSG:
      return {
        ...state,
        mistakeError: action.error,
      };
  }
}
