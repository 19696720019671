import { action } from 'typesafe-actions';
import { IProductTypeActionTypes, IProductTypes, ProductType } from './types';

export const productTypeGetAll = () =>
  action(IProductTypeActionTypes.IPRODUCT_TYPE_GET_ALL);
export const productTypeAdd = (name: string) =>
  action(IProductTypeActionTypes.IPRODUCT_TYPE_GET_ADD, { name });
export const productTypeEdit = (editedProductType: ProductType) =>
  action(IProductTypeActionTypes.IPRODUCT_TYPE_GET_EDIT, { editedProductType });
export const productTypeDelete = (productTypeId: number) =>
  action(IProductTypeActionTypes.IPRODUCT_TYPE_GET_DELETE, { productTypeId });
export const productTypesSuccess = (data: IProductTypes) =>
  action(IProductTypeActionTypes.IPRODUCT_TYPE_GET_SUCCESS, data);
export const productTypesError = (message: string) =>
  action(IProductTypeActionTypes.IPRODUCT_TYPE_GET_ERROR, message);
