import React from 'react';
import { InstructorPageLayout } from '../_layout';
import { Helmet } from 'react-helmet-async';
import ExpensesContent from './expensesContent';

const ExpensesPage: React.FC = props => {
  return (
    <InstructorPageLayout>
      <Helmet>
        <title>Faste omkostninger</title>
      </Helmet>
      <h2 className={'pb-3'}>Faste omkostninger</h2>

      <ExpensesContent />
    </InstructorPageLayout>
  );
};

export default ExpensesPage;
