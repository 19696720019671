//https://stackoverflow.com/questions/47747754/how-to-rewrite-the-protected-private-route-using-typescript-and-react-router-4-a

import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { isAuthenticated as isAuthenticatedChecker } from '../utils/isAuthenticated';
import { reAuthRequest, UserTypes } from '../store/user/userAuthentication';
import { store } from '../store/store';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import LoadingComponent from '../components/LoadingComponent';

interface PropsFromStore extends RouteProps {
  loading: boolean;
}

class InstructorRoute extends Route<PropsFromStore> {
  componentDidMount() {
    store.dispatch(reAuthRequest());
  }
  isAuthenticated = isAuthenticatedChecker();

  render() {
    return (
      <>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          <Route
            render={(props: RouteComponentProps) => {
              if (store.getState().userInfo.userType !== UserTypes.INSTRUCTOR) {
                return <Redirect to="/login" />;
              }
              if (this.props.component) {
                return React.createElement(this.props.component, props);
              } else if (this.props.render) {
                return this.props.render(props);
              }
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  loading: userInfo.loading,
});

export default connect(mapStateToProps)(InstructorRoute);
