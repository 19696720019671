import * as React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, Container, Col, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../../../store';
import ShippingInfoTab from './shippingInfoTab';
import { ShippingAreaType } from './data/shippingPrices';
import LoadingComponent from '../../../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
  userCompanyId: number;
}

class AboutShippingCompanyTab extends React.Component<PropsFromParent> {
  renderShippingInfoTabs = () => {
    return (
      <Tabs defaultActiveKey="domestic">
        <Tab eventKey="domestic" title="Indlands forsendelse">
          <ShippingInfoTab
            infoTabType={ShippingAreaType.DOMESTIC}
            thisUserCompanyId={this.props.userCompanyId}
          />
        </Tab>
        <Tab eventKey="foreign" title="Udlands forsendelse" disabled>
          <ShippingInfoTab
            infoTabType={ShippingAreaType.FOREIGN}
            thisUserCompanyId={this.props.userCompanyId}
          />
        </Tab>
      </Tabs>
    );
  };

  renderShippingCompInfo = () => {
    return (
      <Container fluid={'md'}>
        <Row>
          <Col md={'auto'}>
            <img src="https://via.placeholder.com/84" alt={'Placeholder'} />
          </Col>
          <Col md={'auto'}>
            Danish Cargo
            <br />
            Birkemosevej 1
            <br />
            DK-6000 Kolding
          </Col>
        </Row>
      </Container>
    );
  };

  renderContent = () => {
    return (
      <>
        {this.renderShippingCompInfo()}
        {this.renderShippingInfoTabs()}
      </>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  loading: userInfo.loading,
});

export default connect(mapStateToProps)(AboutShippingCompanyTab);
