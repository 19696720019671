import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { IProductActionTypes } from './types';
import { productError, productGetAll, productSuccess } from './actions';
import {
  deleteEndpoint,
  getEndpoint,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';

function* handleGetPost() {
  try {
    const res = yield getEndpoint('product');

    if (res.error) {
      yield put(productError(res.error));
    } else {
      yield put(productSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleProductDeletion(action) {
  try {
    const res = yield deleteEndpoint('product', action.payload.productId);

    if (res.error) {
      yield put(productError(res.error));
    } else {
      yield put(productGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleProductEdit(action) {
  try {
    const res = yield putEndpoint(
      'product',
      action.payload.editedProduct,
      action.payload.editedProduct.id,
    );

    if (res.error) {
      yield put(productError(res.error));
    } else {
      yield put(productGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleProductAdd(action) {
  try {
    const res = yield postEndpoint('product', action.payload.newProduct);

    if (res.error) {
      yield put(productError(res.error));
    } else {
      yield put(productGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchProduct() {
  yield takeEvery(IProductActionTypes.IPRODUCT_GET_ALL, handleGetPost);
  yield takeEvery(IProductActionTypes.IPRODUCT_DELETE, handleProductDeletion);
  yield takeEvery(IProductActionTypes.IPRODUCT_EDIT, handleProductEdit);
  yield takeEvery(IProductActionTypes.IPRODUCT_ADD, handleProductAdd);
}

function* productSaga() {
  yield all([fork(watchProduct)]);
}

export { productSaga };
