import * as React from 'react';
import { connect } from 'react-redux';
import { Company } from '../../../store/instructor/company';
import { Invoice } from '../../../store/instructor/orderInvoice';
import { convertCompanyIdToName } from '../../../utils/convertions';
import { filterBySimilarity } from '../../../utils/filteringAlgorithms';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import CustomTable from '../../../components/table/customTable';

interface PropsFromParentAndState {
  company: Company;
  invoices: Invoice[];
  allCompanies: Company[];
}

interface StateProps {
  tableData: Invoice[];
  tableHeader: any[];
}

class CompanyTab extends React.Component<PropsFromParentAndState, StateProps> {
  state = {
    tableData: [],
    tableHeader: [
      { title: 'Faktura id', field: 'invoice_id', editable: 'never' },
      { title: 'Købsordre id', field: 'requisition_id', editable: 'never' },
      {
        title: 'Faktura dato',
        field: 'invoice_date',
        editable: 'never',
        customFilterAndSearch: (term, rowData) =>
          filterBySimilarity(term, rowData.invoice_date.getString()),
        render: data => <span>{data.invoice_date.getString()}</span>,
      },
      {
        title: 'Modtager',
        field: 'company_to',
        editable: 'never',
        customFilterAndSearch: (term, rowData) =>
          filterBySimilarity(
            term,
            convertCompanyIdToName(this.props.allCompanies, rowData.company_to),
          ),
        customSort: (a, b) =>
          sortStringAlphabetically(
            convertCompanyIdToName(this.props.allCompanies, a.company_to),
            convertCompanyIdToName(this.props.allCompanies, b.company_to),
          ),
        render: rowData => (
          <span>
            {convertCompanyIdToName(
              this.props.allCompanies,
              rowData.company_to,
            )}
          </span>
        ),
      },
      {
        title: 'Beløb uden moms',
        field: 'total_amount_without_vax',
        editable: 'never',
        type: 'currency',
        maximumFractionDigits: 2,
        currencySetting: {
          currencyCode: 'DKK',
          locale: 'da-DK',
        },
      },
    ],
  };

  componentDidMount() {
    this.setState({ tableData: this.props.invoices });
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParentAndState>) {
    if (prevProps.invoices !== this.props.invoices) {
      this.setState({ tableData: this.props.invoices });
    }
  }

  renderContent = () => {
    return (
      <CustomTable
        data={this.state.tableData}
        headers={this.state.tableHeader}
        actions={[]} //TODO add action: ryk for betaling, eller er det lavet et andet sted?
      />
    );
  };

  public render() {
    return <>{this.renderContent()}</>;
  }
}

export default connect()(CompanyTab);
