import {
  Message,
  MessagePaginated,
  MessageStatusType,
  MessageType,
} from '../../../store/instructor/inbox';
import { Company } from '../../../store/instructor/company';
import * as React from 'react';
import { useState } from 'react';
import { HomemadeDate } from '../../../utils/homemadeDate';
import { convertCompanyIdToName } from '../../../utils/convertions';
import InstructorModal from '../../../components/modal/instructorModal';
import ViewMsgModalData from './modal/viewMsgModalData';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { filterBySimilarity } from '../../../utils/filteringAlgorithms';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { fetchMessagesPaginatedOnType, putEndpoint } from '../../../utils/api';
import DatepickerTableTitle from '../../../components/table/title/datepickerTitle';
import DatePicker from 'react-datepicker';
import CustomTablePagination from '../../../components/table/customTablePagination';
import { convertHomemadeDateToString } from '../../../utils/dateUtils';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromParentAndState {
  loading: boolean;
  companyId: number;
  isUserCompany: boolean;
  allCompanies: Company[];
}

const CompanyInboxTab: React.FC<PropsFromParentAndState> = props => {
  const [selectedMsg, setSelectedMsg] = useState<Message>({
    msg_datesent: new HomemadeDate(),
    msg_id: -1,
    msg_to_id: -1,
    msg_dateread: new HomemadeDate(),
    msg_status: MessageStatusType.UNREAD,
    msg_type: MessageType.SOMETHING,
    msg_body: '',
    msg_from_id: -1,
    msg_header: '',
    msg_recipient_id: -1,
  });
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
  });
  const [modalViewMsgVisible, setModalViewMsgVisible] = useState<boolean>(
    false,
  );
  const [tableData, setTableData] = useState<Message[]>([]);
  const [tableHeader] = useState<any[]>([
    { title: 'Besked nr.', field: 'msg_id', editable: 'never' },
    {
      title: 'Fra virksomhed',
      field: 'msg_from_id',
      editable: 'never',
      customFilterAndSearch: (term, rowData) =>
        filterBySimilarity(
          term,
          convertCompanyIdToName(props.allCompanies, rowData.msg_from_id),
        ),
      customSort: (a, b) =>
        sortStringAlphabetically(
          convertCompanyIdToName(props.allCompanies, a.msg_from_id),
          convertCompanyIdToName(props.allCompanies, b.msg_from_id),
        ),
      render: rowData => (
        <span>
          {convertCompanyIdToName(props.allCompanies, rowData.msg_from_id)}
        </span>
      ),
    },
    {
      title: 'Til virksomhed',
      field: 'msg_to_id',
      editable: 'never',
      customFilterAndSearch: (term, rowData) => {
        filterBySimilarity(
          term,
          convertCompanyIdToName(props.allCompanies, rowData.msg_to_id),
        );
      },
      customSort: (a, b) =>
        sortStringAlphabetically(
          convertCompanyIdToName(props.allCompanies, a.msg_to_id),
          convertCompanyIdToName(props.allCompanies, b.msg_to_id),
        ),
      render: rowData => (
        <span>
          {convertCompanyIdToName(props.allCompanies, rowData.msg_to_id)}
        </span>
      ),
    },
    {
      title: 'Modtaget dato',
      field: 'msg_datesent',
      editable: 'never',
      render: ({ msg_datesent }) => (
        <span>{convertHomemadeDateToString(msg_datesent)}</span>
      ),
    },
    { title: 'Titel', field: 'msg_header', editable: 'never' },
    {
      title: 'Læst/ulæst',
      field: 'msg_status',
      editable: 'never',
      render: rowData => (
        <p>
          {rowData.msg_status === MessageStatusType.UNREAD
            ? 'Ulæst'
            : rowData.msg_status === 'READ'
            ? 'Læst'
            : 'Unknown msg_status: ' + rowData.msg_status}
        </p>
      ),
    },
  ]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const { mutate: setMsgToReadCall } = useMutation(
    (message: Message) =>
      putEndpoint(
        'message',
        { ...message, msg_status: MessageStatusType.READ },
        message.msg_recipient_id,
      ),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { isLoading, data, isFetching, refetch } = useQuery<
    MessagePaginated,
    AxiosError
  >(
    ['inbox', props.companyId, { page, searchQuery, selectedDateRange }],
    () =>
      fetchMessagesPaginatedOnType(
        page,
        rowsPerPage,
        { to_company_id: props.companyId === -1 ? null : props.companyId },
        searchQuery,
        selectedDateRange.startDate,
        selectedDateRange.endDate,
      ),
    {
      keepPreviousData: true,
      onSuccess: data => {
        setTableData(data.items);
      },
    },
  );

  const handleModalViewMsgVisibility = () => {
    setModalViewMsgVisible(!modalViewMsgVisible);
  };

  const setMsgToRead = (msg: Message) => {
    if (!props.isUserCompany) {
      if (msg.msg_status === MessageStatusType.UNREAD) {
        setMsgToReadCall(msg);
      }
    }
  };

  const renderContent = () => {
    return (
      <CustomTablePagination
        titleComponent={
          <DatepickerTableTitle
            datepicker={
              <DatePicker
                selectsRange={true}
                startDate={selectedDateRange.startDate}
                endDate={selectedDateRange.endDate}
                onCalendarClose={() => refetch}
                onChange={dates => {
                  if (dates) {
                    if (Array.isArray(dates)) {
                      if (dates.every(ele => ele == null)) {
                        setSelectedDateRange({
                          startDate: undefined,
                          endDate: undefined,
                        });
                      }
                    }
                    setSelectedDateRange({
                      startDate: dates[0],
                      endDate: dates[1],
                    });
                  }
                }}
                isClearable={true}
              />
            }
          />
        }
        data={tableData}
        headers={tableHeader}
        numberRowPerPage={rowsPerPage}
        totalElementAmount={data?.total_items ?? 0}
        onChangePageFunc={page => setPage(page)}
        onChangeRowsPerPageFunc={rows => setRowsPerPage(rows)}
        onSearchChange={query => setSearchQuery(query)}
        pageNumber={page}
        isLoading={props.loading || isLoading || isFetching}
        actions={[
          {
            icon: 'description',
            tooltip: 'Vis detaljer',
            onClick: (event, message) => {
              setSelectedMsg(message);
              handleModalViewMsgVisibility();
            },
          },
        ]}
      />
    );
  };

  return (
    <>
      <div>
        {props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={props.loading} />
          </div>
        ) : (
          renderContent()
        )}
      </div>

      <InstructorModal
        modalState={modalViewMsgVisible}
        showFunc={handleModalViewMsgVisibility}
        titleHeader="Vis mail"
      >
        <ViewMsgModalData
          visibilityToggleFunc={handleModalViewMsgVisibility}
          setMsgToReadFunc={setMsgToRead}
          msg={selectedMsg}
          allCompanies={props.allCompanies}
        />
      </InstructorModal>
    </>
  );
};

const mapStateToProps = ({ iMessages }: ApplicationState) => ({
  loading: iMessages.loading,
  errors: iMessages.errors,
});

export default connect(mapStateToProps)(CompanyInboxTab);
