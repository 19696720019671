import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Company } from '../../../../store/instructor/company';
import { BankAccount } from '../../../../store/instructor/bank';
import { BankTransaction } from '../../../../store/instructor/bank-transactions';
import { User } from '../../../../store/instructor/users';
import AccountTabNoPagination from '../accountTabNoPagination';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  bankAccount: BankAccount;
  allBankAccounts: BankAccount[];
  transactions: BankTransaction[];
  allCompanies: Company[];
  allUsers: User[];
}

class ViewTransactionsModalData extends React.Component<PropsFromParent> {
  public render() {
    return (
      <>
        <AccountTabNoPagination
          bankAccount={this.props.bankAccount}
          allBankAccounts={this.props.allBankAccounts}
          transactions={this.props.transactions}
          allCompanies={this.props.allCompanies}
          allUsers={this.props.allUsers}
        />
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.visibilityToggleFunc}>
            Luk
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default connect()(ViewTransactionsModalData);
