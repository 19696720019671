import React from 'react';
import { Company } from '../../../../store/instructor/company';
import { Col, Row } from 'react-bootstrap';
import { Delivery } from '../../../../store/instructor/delivery';
import { Link } from 'react-router-dom';

interface PropsFromParent {
  to_company: Company;
  from_company: Company;
  delivery: Delivery;
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

const DeliveryDetails: React.FC<PropsFromParent> = ({
  to_company,
  from_company,
  delivery,
}) => {
  return (
    <>
      <Row>
        <Col>
          <h4>Afsender</h4>
          <p className={'mb-0'}>{from_company.name}</p>
          <p>{from_company.address}</p>
        </Col>
        <Col>
          <h4>Modtager</h4>
          <p className={'mb-0'}>{to_company.name}</p>
          <p>{to_company.address}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Leverings detaljer</h4>
          <p>Internt ID: {delivery.delivery_id}</p>
          <p>Navision ID: TBD</p>
          <p>
            Rekvisition internt ID:
            <Link
              to={
                '/instructor/mistakes/requisitions/' + delivery.requisition_id
              }
            >
              {delivery.requisition_id}
            </Link>
          </p>
          <p>
            Dato: {delivery.delivery_date.dayOfMonth}/
            {delivery.delivery_date.monthValue}/{delivery.delivery_date.year}
          </p>
          <p>Leverings kategori: {delivery.delivery_category}</p>
          <p>Beløb: {formatter.format(delivery.cost ?? 0)}</p>
          <p>Tilføjet til lager: {delivery.added_to_stock ? 'Ja' : 'Nej'}</p>
          <p>
            Arkiveret: {delivery.archive_type === 'processed' ? 'Ja' : 'Nej'}
          </p>
          <p>Produkter leveret:</p>
          {delivery.items.map((item, key) => {
            return (
              <p>
                ID: {item.navision_id} | Antal: {item.product_quantity} | Pris:{' '}
                {formatter.format(item.cost)}
              </p>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default DeliveryDetails;
