import React, { useState } from 'react';
import { Company } from '../../../store/instructor/company';
import { MessageNew, MessageType } from '../../../store/instructor/inbox';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import { MistakeContext } from '../../../store/contexts/MistakeProvider';
import { useMutation, useQueryClient } from 'react-query';
import { InboxTabType } from './inboxTab';
import { postEndpoint } from '../../../utils/api';

interface PropsFromParent {
  recipient?: Company;
  customRecipientId?: number;
  setIndicator: any;
  thisUserCompanyId: number;
  customFromId?: number;
  msgReceivers: Company[];
}

const NewMsgTab: React.FC<PropsFromParent> = props => {
  const queryClient = useQueryClient();
  const { setModalVisibility } = React.useContext(MistakeContext);

  const { mutate: createMessage } = useMutation(
    (message: MessageNew) => postEndpoint('message', message),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['inbox', InboxTabType.SENT]);
        props.setIndicator('Beskeden er blevet sendt!');
      },
    },
  );

  const [newMsg, setNewMsg] = useState({
    msg_to_id: '',
    msg_body: '',
    msg_header: '',
    msg_from_id: props.customFromId
      ? props.customFromId
      : props.thisUserCompanyId,
  });

  const [localErrors, setLocalErrors] = useState({
    receiver: '',
    header: '',
    body: '',
  });

  const onChange = e => {
    setNewMsg({
      ...newMsg,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    const errors = {
      receiver: '',
      header: '',
      body: '',
    };

    const enteredData = newMsg;

    if (!props.recipient && !props.customRecipientId) {
      if (enteredData.msg_to_id === 'Vælg..' || enteredData.msg_to_id === '') {
        errors.receiver = 'Vælg venligst en modtager.';
      }
    }
    if (enteredData.msg_header === '') {
      errors.receiver = 'Titel kan ikke være tom.';
    }
    if (enteredData.msg_body === '') {
      errors.receiver = 'Besked kan ikke være tom.';
    }

    return errors;
  };

  const onSendButtonClick = e => {
    e.preventDefault();

    const errors = validate();
    setLocalErrors({
      receiver: errors.receiver,
      header: errors.header,
      body: errors.body,
    });

    if (
      (!props.customRecipientId || Object.keys(errors.receiver).length === 0) &&
      Object.keys(errors.header).length === 0 &&
      Object.keys(errors.body).length === 0
    ) {
      const parsedMsg: MessageNew = {
        ...newMsg,
        msg_to_id: props.customRecipientId
          ? props.customRecipientId
          : props.recipient
          ? props.recipient.id
          : parseInt(newMsg.msg_to_id.toString()),
        msg_type: MessageType.MAIL,
      };

      setModalVisibility({ state: false, actionType: '' });
      createMessage(parsedMsg);
    }
  };

  const renderReceiverOption = (company: Company, index: number) => {
    return (
      <option key={index} value={company.id}>
        {company.name}
      </option>
    );
  };

  return (
    <>
      <p>
        Indtast de ønskede oplysninger nedenfor, og tryk på tilføj for at
        bekræfte
      </p>
      <div className="px-3 pt-0 pb-3">
        <div>
          <Form>
            {!props.customRecipientId && (
              <Row>
                <Form.Label>Modtager</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue="Vælg.."
                  name={'msg_to_id'}
                  value={
                    props.recipient ? props.recipient.id : newMsg.msg_to_id
                  }
                  disabled={!!props.recipient}
                  onChange={onChange}
                >
                  <option>Vælg..</option>
                  {props.msgReceivers
                    .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                    .map(renderReceiverOption)}
                </Form.Control>
              </Row>
            )}
            <Row>
              <Form.Label>Titel</Form.Label>
              <Form.Control
                placeholder="Email titel"
                name={'msg_header'}
                value={newMsg.msg_header}
                onChange={onChange}
              />
            </Row>
            <Row>
              <Form.Label>Besked</Form.Label>
              <Form.Control
                placeholder="Email besked"
                name={'msg_body'}
                value={newMsg.msg_body}
                onChange={onChange}
                as="textarea"
              />
            </Row>
          </Form>
          <ErrorDiv>
            {localErrors.receiver}
            {localErrors.header}
            {localErrors.body}
          </ErrorDiv>
        </div>
      </div>
      <Modal.Footer>
        <Button variant="primary" onClick={onSendButtonClick}>
          Send
        </Button>
      </Modal.Footer>
    </>
  );
};

const ErrorDiv = styled.div`
  color: red;
`;

const mapStateToProps = ({ iMessages, userInfo }: ApplicationState) => ({
  loading: iMessages.loading,
  thisUserCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(NewMsgTab);
