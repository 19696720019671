import React, { createContext, useReducer } from 'react';

const ToastContext = createContext<{
  state: ToastState;
  dispatch: React.Dispatch<any>;
}>({ state: { toasts: [] }, dispatch: () => null });

export interface ToastObject {
  id: string | number;
  type: 'success' | 'error';
  message: string;
  header: string;
}

interface ToastState {
  toasts: ToastObject[];
}

interface ToastAction {
  type: ToastActionTypes;
  toast: ToastObject;
  id: number;
}

export enum ToastActionTypes {
  'ADD_TOAST',
  'DELETE_TOAST',
}

const ToastReducer = (state: ToastState, action: ToastAction) => {
  const { type, toast, id } = action;

  switch (type) {
    case ToastActionTypes.ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, toast],
      };
    case ToastActionTypes.DELETE_TOAST:
      const updatedToasts = state.toasts.filter(e => e.id !== id);
      return {
        ...state,
        toasts: updatedToasts,
      };
    default: {
      throw new Error('unhandled action');
    }
  }
};

function ToastProvider({ children }) {
  const [state, dispatch] = useReducer(ToastReducer, { toasts: [] });

  return (
    <ToastContext.Provider value={{ state, dispatch }}>
      {children}
    </ToastContext.Provider>
  );
}

export { ToastContext, ToastProvider };
