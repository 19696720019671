import { Reducer } from 'redux';
import { IProductState, IProductActionTypes } from './types';

// Type-safe initialState!
export const initialState: IProductState = {
  products: [],
  loading: false,
  errors: undefined,
};

const reducer: Reducer<IProductState> = (state = initialState, action) => {
  switch (action.type) {
    case IProductActionTypes.IPRODUCT_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductActionTypes.IPRODUCT_DELETE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductActionTypes.IPRODUCT_EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductActionTypes.IPRODUCT_SUCCESS: {
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    }
    case IProductActionTypes.IPRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as iProductReducer };
