import React from 'react';
import { InstructorPageLayout } from '../_layout';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { Invoice } from '../../../store/instructor/orderInvoice';
import { Page } from '../../../utils/storeTypes';
import {
  fetchMistakesDeliveries,
  fetchMistakesInvoices,
  fetchMistakesTransactions,
} from '../../../utils/api';
import MistakeRowDetails from '../../../components/mistakes/MistakeRowDetails';
import { Alert, Table } from 'react-bootstrap';
import { MistakesTransaction } from '../../../store/instructor/mistakes';
import MistakeModal from '../../../components/mistakes/MistakeModal';
import { MistakeContext } from '../../../store/contexts/MistakeProvider';

interface InvoicePagination extends Page {
  items: Invoice[];
}

interface TransactionPagination extends Page {
  items: MistakesTransaction[];
}

export const MistakePage: React.FC = () => {
  const [page, setPage] = React.useState(0);
  const [handledFilter, setHandledFilter] = React.useState(false);
  const queryClient = useQueryClient();
  const { mistakeError } = React.useContext(MistakeContext);

  const { isLoading, isError, isFetched, data: invoices } = useQuery<
    InvoicePagination,
    AxiosError
  >(['invoiceMistakes', [page, handledFilter]], () =>
    fetchMistakesInvoices(page, handledFilter),
  );

  const {
    isFetched: deliveryFetched,
    isLoading: deliveryLoading,
    isError: deliveryError,
    data: deliveries,
  } = useQuery<InvoicePagination, AxiosError>(
    ['deliveryMistakes', [page, handledFilter]],
    () => fetchMistakesDeliveries(page, handledFilter),
  );

  const {
    isFetched: transactionsFetched,
    isLoading: transactionsLoading,
    isError: transactionsError,
    data: transactions,
  } = useQuery<TransactionPagination, AxiosError>(
    ['transactionMistakes', [page, handledFilter]],
    () => fetchMistakesTransactions(page, handledFilter),
  );

  const changeTabAction = (state: boolean) => {
    queryClient.invalidateQueries('deliveryMistakes').then(() => {
      queryClient.invalidateQueries('invoiceMistakes').then(() => {
        queryClient.invalidateQueries('transactionMistakes').then(() => {
          setHandledFilter(state);
          setPage(0);
        });
      });
    });
  };

  return (
    <>
      <InstructorPageLayout>
        <div className={'pb-3'}>
          <h2>Fejlregister</h2>
        </div>

        <div className={'nav nav-tabs'}>
          <div
            onClick={() => changeTabAction(false)}
            className={
              'cursor-pointer nav-item nav-link ' +
              (!handledFilter ? 'active' : '')
            }
          >
            Ubehandlede
          </div>
          <div
            onClick={() => changeTabAction(true)}
            className={
              'cursor-pointer nav-item nav-link ' +
              (handledFilter ? 'active' : '')
            }
          >
            Behandlede
          </div>
        </div>

        {(isLoading || deliveryLoading || transactionsLoading) && (
          <p>Loading ...</p>
        )}
        {(isError || deliveryError || transactionsError) && <p>Error!</p>}

        {mistakeError && (
          <>
            <Alert variant={'danger'}>{mistakeError}</Alert>
          </>
        )}

        {isFetched && deliveryFetched && transactionsFetched && (
          <>
            <div className={'d-flex p-2 align-items-center'}>
              <span>
                Side: <b>{page + 1}</b>
              </span>
              <button
                className={'ml-3 btn-sm btn-primary'}
                onClick={() => setPage(old => Math.max(old - 1, 0))}
                disabled={page === 0}
              >
                Forrige side
              </button>
              <button
                className={'ml-3 btn-sm btn-primary'}
                onClick={() => {
                  setPage(old => old + 1);
                }}
              >
                Næste side
              </button>
            </div>

            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Dato</th>
                  <th>Type</th>
                  <th>Bruger</th>
                  <th>Navision ID</th>
                  <th>Faktura ID</th>
                  <th>Rekvisition ID</th>
                  <th>Leverings ID</th>
                  <th>Fejl</th>
                  <th className={'text-right'}>Handlinger</th>
                </tr>
              </thead>
              <tbody>
                {invoices?.items.map((invoice, key) => {
                  return (
                    <MistakeRowDetails
                      key={key}
                      type={'Faktura'}
                      details={invoice}
                    />
                  );
                })}
                {deliveries?.items.map((delivery, key) => {
                  return (
                    <MistakeRowDetails
                      key={key}
                      type={'Levering'}
                      details={delivery}
                    />
                  );
                })}
                {transactions?.items.map((transaction, key) => {
                  return (
                    <MistakeRowDetails
                      key={key}
                      type={'Transaktion'}
                      details={transaction}
                    />
                  );
                })}
              </tbody>
            </Table>
          </>
        )}
      </InstructorPageLayout>

      <MistakeModal />
    </>
  );
};
export default MistakePage;
