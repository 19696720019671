import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import { Company, companyGetAll } from '../../../store/instructor/company';
import InvoiceTab, { InvoiceTabType } from './invoiceTab';

interface PropsFromState {
  allCompanies: Company[];
  userCompanyId: number;
  loading: boolean;
}

class InvoiceOverview extends React.Component<PropsFromState> {
  componentDidMount() {
    store.dispatch(companyGetAll());
  }

  public render() {
    return (
      <Tabs defaultActiveKey={'invoiceReceived'}>
        <Tab eventKey="invoiceReceived" title="Modtagne">
          <Tabs defaultActiveKey="receivedInvoiceActive">
            <Tab eventKey="receivedInvoiceActive" title="Aktive">
              <InvoiceTab
                allCompanies={this.props.allCompanies}
                userCompanyId={this.props.userCompanyId}
                invoiceTabType={InvoiceTabType.RECEIVED_ACTIVE}
              />
            </Tab>
            <Tab eventKey="receivedInvoiceArchived" title="Behandlede">
              <InvoiceTab
                allCompanies={this.props.allCompanies}
                userCompanyId={this.props.userCompanyId}
                invoiceTabType={InvoiceTabType.RECEIVED_ARCHIVE}
              />
            </Tab>
          </Tabs>
        </Tab>
        <Tab eventKey="invoiceSend" title="Afsendte">
          <InvoiceTab
            allCompanies={this.props.allCompanies}
            userCompanyId={this.props.userCompanyId}
            invoiceTabType={InvoiceTabType.SEND}
          />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = ({ iCompany, userInfo }: ApplicationState) => ({
  loading: iCompany.loading,
  errors: iCompany.errors,
  allCompanies: iCompany.companies,
  userCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(InvoiceOverview);
