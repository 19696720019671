import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import styled from 'styled-components';
import BankContent from './bankContent';
import { Helmet } from 'react-helmet-async';

const StudentBank: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Bank</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Bank</h2>
      </PageHeader>

      <BankContent />
    </StudentPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(StudentBank);
