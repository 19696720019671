import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { InstructorPageLayout } from '../_layout';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useQuery } from 'react-query';
import { fetchCompanyById, fetchDeliveryById } from '../../../utils/api';
import { AxiosError } from 'axios';
import { Company } from '../../../store/instructor/company';
import { Delivery } from '../../../store/instructor/delivery';
import DeliveryDetails from './details/DeliveryDetails';
import LoadingComponent from '../../../components/LoadingComponent';

export const DeliveryDetailsPage = () => {
  const { id } = useParams();
  const routes = [
    { path: '/instructor/mistakes', breadcrumb: 'Mistakes' },
    {
      path: '/instructor/mistakes/deliveries/:id',
      breadcrumb: 'Levering ' + id,
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  const { data: delivery, isLoading: deliveryLoading } = useQuery<
    Delivery,
    AxiosError
  >(['delivery', id], () => fetchDeliveryById(id), {
    enabled: !!id,
  });

  const { data: companyFromDetails, isLoading: companyFromLoading } = useQuery<
    Company,
    AxiosError
  >(
    ['company', delivery?.from_company_id],
    () => fetchCompanyById(delivery!.from_company_id),
    {
      enabled: !!delivery?.from_company_id,
    },
  );

  const { data: companyToDetails, isLoading: companyToLoading } = useQuery<
    Company,
    AxiosError
  >(
    ['company', delivery?.to_company_id],
    () => fetchCompanyById(delivery!.to_company_id),
    {
      enabled: !!delivery?.to_company_id,
    },
  );

  const allLoadingFinished =
    !deliveryLoading && !companyFromLoading && !companyToLoading;

  return (
    <>
      <InstructorPageLayout>
        <div className={'pb-3'}>
          <h2>Detaljer</h2>
        </div>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={'/instructor/dashboard'}>Forside</Link>
          </BreadcrumbItem>
          {breadcrumbs.map(({ match, breadcrumb }) => (
            <BreadcrumbItem key={match.url} active>
              <Link to={match.url}>{breadcrumb}</Link>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>

        {/* Show loading while retrieving data */}
        {!allLoadingFinished && (
          <LoadingComponent visible={!allLoadingFinished} />
        )}

        {allLoadingFinished && (
          <DeliveryDetails
            to_company={companyToDetails!}
            from_company={companyFromDetails!}
            delivery={delivery!}
          />
        )}
      </InstructorPageLayout>
    </>
  );
};
