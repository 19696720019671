import React, { useReducer } from 'react';
import {
  MistakeActionTypes,
  mistakesReducer,
  MistakesTypes,
} from '../reducers/MistakesReducer';
import { Details } from '../../components/mistakes/MistakeRowDetails';

export type MistakeProviderTypes =
  | 'Faktura'
  | 'Rekvisition'
  | 'Levering'
  | 'Transaktion';

export enum MistakeHandlingProcedures {
  DELIVERY_RETURN = 'RETURN',
  DELIVERY_KEEP = 'KEEP',
  INVOICE_IGNORE = 'IGNORE',
  INVOICE_KEEP = 'KEEP',
  TRANSACTION_RETURN = 'RETURN',
  TRANSACTION_KEEP = 'KEEP',
}

export interface ExtendedDetails {
  details: Details;
  type: MistakeProviderTypes;
}

interface MistakeContext {
  modalVisibility: { state: boolean; actionType: MistakeActionTypes };
  setModalVisibility: ({ state, actionType }) => void;
  selectedDetails?: ExtendedDetails;
  setSelectedDetails: (doc: ExtendedDetails) => void;
  mistakeError?: string;
  setMistakeError: (err: string) => void;
}

// TODO-future: Find anden løsning
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MistakeContext = React.createContext<MistakeContext>(
  {} as MistakeContext,
);

const MistakeProvider: React.FC = ({ children }) => {
  const [
    { modalVisibility, selectedDetails, mistakeError },
    dispatch,
  ] = useReducer(mistakesReducer, {
    modalVisibility: {
      state: false,
      actionType: MistakeActionTypes.UNDEFINED,
    },
  });

  const value: MistakeContext = {
    modalVisibility: modalVisibility,
    selectedDetails: selectedDetails,
    mistakeError: mistakeError,

    setMistakeError: async error => {
      dispatch({
        type: MistakesTypes.MISTAKES_ERROR_SET_MSG,
        error,
      });
    },

    setModalVisibility: async detail => {
      dispatch({
        type: MistakesTypes.MISTAKES_SET_MODAL_VISIBILITY,
        modalVisibility: detail,
      });
    },

    setSelectedDetails: async doc => {
      dispatch({
        type: MistakesTypes.MISTAKES_SET_SELECTED_DETAILS,
        selectedDetails: doc,
      });
    },
  };

  return (
    <MistakeContext.Provider value={value}>{children}</MistakeContext.Provider>
  );
};

export default MistakeProvider;
