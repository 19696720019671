import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { InstructorPageLayout } from '../_layout';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useQuery } from 'react-query';
import { fetchCompanyById, fetchRequisitionById } from '../../../utils/api';
import { Requisition } from '../../../store/instructor/requisitions';
import { AxiosError } from 'axios';
import { Company } from '../../../store/instructor/company';
import RequisitionDetails from './details/RequisitionDetails';
import { Alert } from 'react-bootstrap';
import LoadingComponent from '../../../components/LoadingComponent';

export const RequisitionDetailsPage = () => {
  const { id } = useParams();
  const routes = [
    { path: '/instructor/mistakes', breadcrumb: 'Mistakes' },
    {
      path: '/instructor/mistakes/requisitions/:id',
      breadcrumb: 'Rekvisition ' + id,
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  const {
    data: requisition,
    isLoading: requisitionLoading,
    isError: errorReq,
    error,
  } = useQuery<Requisition, AxiosError>(
    ['requisition', id],
    () => fetchRequisitionById(id),
    {
      enabled: !!id,
    },
  );

  const { data: companyFromDetails, isLoading: companyFromLoading } = useQuery<
    Company,
    AxiosError
  >(
    ['company', requisition?.from_company_id],
    () => fetchCompanyById(requisition!.from_company_id),
    {
      enabled: !!requisition?.from_company_id,
    },
  );

  const { data: companyToDetails, isLoading: companyToLoading } = useQuery<
    Company,
    AxiosError
  >(
    ['company', requisition?.to_company_id],
    () => fetchCompanyById(requisition!.to_company_id),
    {
      enabled: !!requisition?.to_company_id,
    },
  );

  const allLoadingFinished =
    !requisitionLoading && !companyFromLoading && !companyToLoading;

  return (
    <>
      <InstructorPageLayout>
        <div className={'pb-3'}>
          <h2>Detaljer</h2>
        </div>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={'/instructor/dashboard'}>Forside</Link>
          </BreadcrumbItem>
          {breadcrumbs.map(({ match, breadcrumb }) => (
            <BreadcrumbItem key={match.url} active>
              <Link to={match.url}>{breadcrumb}</Link>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>

        {/* Show loading while retrieving data */}
        {!allLoadingFinished && !errorReq && (
          <LoadingComponent visible={!allLoadingFinished} />
        )}

        {allLoadingFinished && !errorReq && (
          <RequisitionDetails
            to_company={companyToDetails!}
            from_company={companyFromDetails!}
            requisition={requisition!}
          />
        )}

        {errorReq && (
          <Alert variant={'danger'}>
            <Alert.Heading>Åh nej! Der skete en fejl!</Alert.Heading>
            <p>{error?.response?.data.error}</p>
          </Alert>
        )}
      </InstructorPageLayout>
    </>
  );
};
