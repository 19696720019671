import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import {
  Company,
  companyGetSuperCompaniesCompany,
} from '../../../store/instructor/company';
import { store } from '../../../store/store';
import {
  BankAccount,
  bankAccountGetCompanyAccounts,
} from '../../../store/instructor/bank';
import DeclarationOverview from './declarationOverview';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  companyId: number;
  superCompaniesCompany: Map<number, Company[]>;
  userCompanyBankAccounts: BankAccount[];
}

interface ThisCompState {
  taxCompany: Company;
  companyAccounts: BankAccount[];
}

export function getMonthsArray(): string[] {
  let month: string[] = [];
  month.push('januar');
  month.push('februar');
  month.push('marts');
  month.push('april');
  month.push('maj');
  month.push('juni');
  month.push('juli');
  month.push('august');
  month.push('september');
  month.push('oktober');
  month.push('november');
  month.push('december');

  return month;
}

/** Zero indexed! Jan = 0 */
export function getMonthFromIndex(index: number): string {
  return getMonthsArray()[index];
}

class TaxContent extends React.Component<PropsFromState, ThisCompState> {
  constructor(props: Readonly<PropsFromState>) {
    super(props);
    this.state = {
      taxCompany: {
        name: '',
        id: -1,
        payment_terms: -1,
        address: '',
        delivery_time: -1,
        bank_accounts: [],
        company_type_id: -1,
        company_type: '',
      },
      companyAccounts: [],
    };
  }

  componentDidMount() {
    store.dispatch(companyGetSuperCompaniesCompany([this.props.companyId]));
    store.dispatch(bankAccountGetCompanyAccounts(this.props.companyId));
  }

  componentDidUpdate(prevProps: Readonly<PropsFromState>) {
    if (prevProps.superCompaniesCompany !== this.props.superCompaniesCompany) {
      if (
        this.props.superCompaniesCompany.get(this.props.companyId) !== undefined
      ) {
        const subCompanies:
          | Company[]
          | undefined = this.props.superCompaniesCompany.get(
          this.props.companyId,
        );

        if (subCompanies !== undefined) {
          const taxCompany: Company | undefined = subCompanies.find(
            elem => elem.name === 'SKAT',
          );
          if (taxCompany === undefined) {
            throw new Error(
              'TaxContent: could not find tax company for company!',
            );
          } else {
            this.setState({ taxCompany: taxCompany });
          }
        }
      }
    }

    if (
      prevProps.userCompanyBankAccounts !== this.props.userCompanyBankAccounts
    ) {
      this.setState({
        companyAccounts: this.props.userCompanyBankAccounts.filter(
          elem => elem.companyId === this.props.companyId,
        ),
      });
    }
  }

  renderContent = () => {
    return (
      <DeclarationOverview
        taxCompany={this.state.taxCompany}
        companyAccounts={this.state.companyAccounts}
      />
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  userInfo,
  iCompany,
  iBankAccount,
}: ApplicationState) => ({
  loading: iCompany.superCompaniesCompanyLoading || iBankAccount.loading,
  companyId: userInfo.userSuperCompanyId,
  superCompaniesCompany: iCompany.superCompaniesCompany,
  userCompanyBankAccounts: iBankAccount.companyBankAccounts,
});

export default connect(mapStateToProps)(TaxContent);
