import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { BankAccount } from '../../../store/instructor/bank';
import { BankTransaction } from '../../../store/instructor/bank-transactions';
import CustomTable from '../../../components/table/customTable';
import { convertToVisualTransactions } from '../../../utils/transactionUtils';
import { HomemadeDate } from '../../../utils/homemadeDate';
import { Company } from '../../../store/instructor/company';
import { filterBySimilarity } from '../../../utils/filteringAlgorithms';
import {
  convertCompanyIdToName,
  floatToDKFloatString,
} from '../../../utils/convertions';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import { User } from '../../../store/instructor/users';
import LoadingComponent from '../../../components/LoadingComponent';

export interface VisualTransaction {
  date: HomemadeDate;
  dateString: string;
  fromCompanyId: number;
  text: string;
  amount: number; //The balance change of the affected account (-/+amount)
  balanceAfterTransaction: number;
}

interface PropsFromParent {
  loading: boolean;
  bankAccount: BankAccount;
  allBankAccounts: BankAccount[];
  transactions: BankTransaction[];
  allCompanies: Company[];
  allUsers: User[];
}

interface ComponentState {
  tableData: VisualTransaction[];
  tableHeader: any[];
}

class AccountTabNoPagination extends React.Component<
  PropsFromParent,
  ComponentState
> {
  state = {
    tableData: [],
    tableHeader: [
      { title: 'Dato', field: 'dateString', editable: 'never' },
      {
        title: 'Modtaget fra',
        field: 'fromCompanyId',
        editable: 'never',
        customFilterAndSearch: (term, rowData) =>
          filterBySimilarity(
            term,
            convertCompanyIdToName(
              this.props.allCompanies,
              rowData.fromCompanyId,
            ),
          ),
        customSort: (a, b) =>
          sortStringAlphabetically(
            convertCompanyIdToName(this.props.allCompanies, a.fromCompanyId),
            convertCompanyIdToName(this.props.allCompanies, b.fromCompanyId),
          ),
        render: rowData => (
          <span>
            {convertCompanyIdToName(
              this.props.allCompanies,
              rowData.fromCompanyId,
            )}
          </span>
        ),
      },
      { title: 'Tekst', field: 'text', editable: 'never' },
      {
        title: 'Beløb (DKK)',
        field: 'amount',
        editable: 'never',
        render: rowData => (
          <p className={'float-right'}>
            {floatToDKFloatString(rowData.amount)}
          </p>
        ),
      },
      {
        title: 'Saldo (DKK)',
        field: 'balanceAfterTransaction',
        editable: 'never',
        render: rowData => (
          <p className={'float-right'}>
            {floatToDKFloatString(rowData.balanceAfterTransaction)}
          </p>
        ),
      },
    ],
  };

  componentDidMount() {
    this.updateTableData();
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.transactions !== this.props.transactions) {
      this.updateTableData();
    }
  }

  updateTableData() {
    const convertedTransactions: VisualTransaction[] = convertToVisualTransactions(
      this.props.bankAccount.id,
      this.props.transactions,
      this.props.allBankAccounts,
      this.props.allCompanies,
      this.props.allUsers,
    );

    this.setState({
      tableData: convertedTransactions.reverse(),
    });
  }

  renderTable = () => {
    return (
      <CustomTable
        data={this.state.tableData}
        headers={this.state.tableHeader}
        actions={[]}
      />
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderTable()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ iBankAccount }: ApplicationState) => ({
  loading: iBankAccount.loading,
  errors: iBankAccount.errors,
});

export default connect(mapStateToProps)(AccountTabNoPagination);
