import React, { createContext, useContext, useReducer } from 'react';

type RobotMessage = { body: string; id: string };

type RobotAction =
  | {
      type: RobotDispatchTypes.ADD_MESSAGE;
      robotMessage: RobotMessage;
    }
  | {
      type: RobotDispatchTypes.DELETE_MESSAGE;
      id: string;
    };

export enum RobotDispatchTypes {
  ADD_MESSAGE,
  DELETE_MESSAGE,
}

interface RobotState {
  messages: RobotMessage[];
}

const RobotStateContext = createContext<RobotState>({
  messages: [],
});
const RobotDispatchContext = createContext<React.Dispatch<any>>(() => null);

const RobotReducer = (state: RobotState, action: RobotAction) => {
  switch (action.type) {
    case RobotDispatchTypes.ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.robotMessage],
      };
    case RobotDispatchTypes.DELETE_MESSAGE:
      const updatedMessages = state.messages.filter(e => e.id !== action.id);
      return {
        ...state,
        toasts: updatedMessages,
      };
    default: {
      throw new Error('RobotContext: Unhandled action');
    }
  }
};

export function RobotProvider({ children }) {
  const [state, dispatch] = useReducer(RobotReducer, {
    messages: [
      {
        body: 'Beskeder sendt fra robotten vil blive vist her ...',
        id: 'xmkces7l3',
      },
    ],
  });

  return (
    <RobotStateContext.Provider value={state}>
      <RobotDispatchContext.Provider value={dispatch}>
        {children}
      </RobotDispatchContext.Provider>
    </RobotStateContext.Provider>
  );
}

export const useRobotStateContext = () => useContext(RobotStateContext);
export const useRobotDispatchContext = () => useContext(RobotDispatchContext);
