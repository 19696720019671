import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import OverviewTab from './mainTabs/overview';
import PaymentTab, {
  PaymentContextType,
  PaymentType,
} from './mainTabs/payment';
import ApproveTab from './mainTabs/approve';
import { Company, companyGetAll } from '../../../store/instructor/company';
import { NewBankTransaction } from '../../../store/instructor/bank-transactions';
import {
  BankAccount,
  bankAccountGetAll,
  bankAccountGetCompanyAccounts,
} from '../../../store/instructor/bank';
import { User, usersGetAll } from '../../../store/instructor/users';
import LoadingComponent from '../../../components/LoadingComponent';

export interface NewBankTransactionWithId extends NewBankTransaction {
  id: number;
}

interface PropsFromState {
  allCompanies: Company[];
  allBankAccounts: BankAccount[];
  userCompanyId: number;
  userCompanyBankAccounts: BankAccount[];
  allUsers: User[];
  loading: boolean;
}

interface ThisCompState {
  pendingTransactions: NewBankTransactionWithId[];
  activeAccounts: BankAccount[];
  companyAccounts: BankAccount[];
}

class BankContent extends React.Component<PropsFromState, ThisCompState> {
  constructor(props: PropsFromState) {
    super(props);
    this.state = {
      pendingTransactions: [],
      activeAccounts: [],
      companyAccounts: [],
    };
  }

  componentDidMount() {
    store.dispatch(companyGetAll());
    store.dispatch(bankAccountGetAll());
    store.dispatch(bankAccountGetCompanyAccounts(this.props.userCompanyId));
    store.dispatch(usersGetAll());
  }

  componentDidUpdate(prevProps: Readonly<PropsFromState>) {
    if (prevProps.allBankAccounts !== this.props.allBankAccounts) {
      this.setState({
        activeAccounts: this.props.allBankAccounts.filter(
          elem => elem.isActive,
        ),
      });
    }

    if (
      prevProps.userCompanyBankAccounts !== this.props.userCompanyBankAccounts
    ) {
      this.setState({
        companyAccounts: this.props.userCompanyBankAccounts.filter(
          elem => elem.companyId === this.props.userCompanyId,
        ),
      });
    }
  }

  addPendingTransaction = (transaction: NewBankTransactionWithId) => {
    this.setState({
      pendingTransactions: [...this.state.pendingTransactions, transaction],
    });
  };

  removePendingTransaction = (transaction: NewBankTransactionWithId) => {
    const array = this.state.pendingTransactions.filter(
      item => item.id !== transaction.id,
    );
    this.setState({
      pendingTransactions: array,
    });
  };

  public render() {
    if (this.props.loading) {
      return (
        <div className={'d-flex justify-content-center'}>
          <LoadingComponent visible={this.props.loading} />
        </div>
      );
    }

    return (
      <Tabs defaultActiveKey={'accounts'}>
        <Tab eventKey="accounts" title="Konti">
          <OverviewTab userCompanyBankAccounts={this.state.companyAccounts} />
        </Tab>
        <Tab eventKey="paymentCard" title="Betal indbetalingskort">
          <PaymentTab
            type={PaymentType.PAYMENT_CARD}
            context={PaymentContextType.OTHER}
            pendingTransactions={this.state.pendingTransactions}
            addPendingTransactions={this.addPendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            userCompanyBankAccounts={this.state.companyAccounts}
          />
        </Tab>
        <Tab eventKey="paymentIntern" title="Intern overførsel">
          <PaymentTab
            type={PaymentType.TRANSFER_INTERN}
            context={PaymentContextType.OTHER}
            pendingTransactions={this.state.pendingTransactions}
            addPendingTransactions={this.addPendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            userCompanyBankAccounts={this.state.companyAccounts}
          />
        </Tab>
        <Tab eventKey="paymentExtern" title="Ekstern overførsel">
          <PaymentTab
            type={PaymentType.TRANSFER_EXTERN}
            context={PaymentContextType.OTHER}
            pendingTransactions={this.state.pendingTransactions}
            addPendingTransactions={this.addPendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            userCompanyBankAccounts={this.state.companyAccounts}
          />
        </Tab>
        <Tab
          eventKey="approve"
          title={
            'Godkend betaling (' + this.state.pendingTransactions.length + ')'
          }
        >
          <ApproveTab
            allCompanies={this.props.allCompanies}
            pendingTransactions={this.state.pendingTransactions}
            removePendingTransaction={this.removePendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            allBankAccounts={this.props.allBankAccounts}
            allUsers={this.props.allUsers}
          />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = ({
  iBankAccount,
  iCompany,
  userInfo,
  iUsers,
}: ApplicationState) => ({
  loading:
    iBankAccount.loading ||
    iCompany.loading ||
    userInfo.loading ||
    iUsers.loading,
  allBankAccounts: iBankAccount.bankAccounts,
  allCompanies: iCompany.companies,
  userCompanyId: userInfo.userSuperCompanyId,
  userCompanyBankAccounts: iBankAccount.companyBankAccounts,
  allUsers: iUsers.allUsers,
});

export default connect(mapStateToProps)(BankContent);
