import * as React from 'react';
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { store } from '../../../store/store';
import { setAuthorizationHeader } from '../../../utils/authenticationUtil';
import NavigationBar from '../../../components/newnavbar/navigationBar';
import { NavbarDataAdmin } from '../../../data/NavbarDataAdmin';
import ToastContainer from '../../../components/toasts/ToastContainer';

export const InstructorPageLayout = props => {
  const theme = useSelector((state: any) => state.theme);
  useEffect(() => {
    setAuthorizationHeader(store.getState().userInfo.jwtToken);
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />

      <div className={'sidebar-wrapper'}>
        <NavigationBar navbarData={NavbarDataAdmin} />

        <div className={'layout-wrapper p-4 w-100'}>{props.children}</div>
      </div>
    </ThemeProvider>
  );
};
