import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import { Company, companyGetAll } from '../../../store/instructor/company';
import CompanyOverview from './mainTabs/companyOverview';
import KontoudtogTab from './mainTabs/stateOfAccountTab';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  allCompanies: Company[];
}

class CompanyContent extends React.Component<PropsFromState> {
  componentDidMount() {
    store.dispatch(companyGetAll());
  }

  renderContent = () => {
    return (
      <Tabs defaultActiveKey={'companyOverviewTab'}>
        <Tab eventKey="companyOverviewTab" title="Leverandør oversigt">
          <CompanyOverview />
        </Tab>
        <Tab
          eventKey="kontoudtogTab"
          title="Kontoudtog for faste udgifter"
          disabled={true}
        >
          <KontoudtogTab />
        </Tab>
      </Tabs>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ iCompany }: ApplicationState) => ({
  loading: iCompany.loading,
  errors: iCompany.errors,
  allCompanies: iCompany.companies,
});

export default connect(mapStateToProps)(CompanyContent);
