import { Company } from '../../../store/instructor/company';
import { BankAccount, bankAccountDelete } from '../../../store/instructor/bank';
import * as React from 'react';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import InstructorModal from '../../../components/modal/instructorModal';
import AddIcon from '@material-ui/icons/Add';
import CustomTable from '../../../components/table/customTable';
import { store } from '../../../store/store';
import EditBankAccountModalData from './modals/editBankAccountModalDate';
import ViewTransactionsModalData from './modals/viewTransactionsModalData';
import { BankTransaction } from '../../../store/instructor/bank-transactions';
import { confirmAlert } from 'react-confirm-alert';
import AddBankAccountModalData, {
  AddBankAccountModalType,
} from '../../../components/modal/modals/addBankAccountModalData';
import { User } from '../../../store/instructor/users';
import CreateTransactionModalData from './modals/createTransactionModalData';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
  company: Company;
  companyBankAccounts: BankAccount[];
  allCompanies: Company[];
  allBankAccounts: BankAccount[];
  allTransactions: BankTransaction[];
  allUsers: User[];
}

interface ComponentState {
  modalAddVisible: boolean;
  modalAddTransactionVisibility: boolean;
  modalEditVisible: boolean;
  modalViewTransactions: boolean;
  selectedBankAccount: BankAccount;
  tableData: BankAccount[];
  tableHeader: any[];
  localErrors: string;
}

class CompanyTab extends React.Component<PropsFromParent, ComponentState> {
  state = {
    modalAddVisible: false,
    modalEditVisible: false,
    modalAddTransactionVisibility: false,
    modalViewTransactions: false,
    selectedBankAccount: {
      id: -1,
      type_id: -1,
      companyId: undefined,
      userId: undefined,
      name: '',
      currencyCode: -1,
      balance: -1,
      isActive: true,
    },
    tableData: [],
    tableHeader: [
      { title: 'Kontonummer', field: 'id', editable: 'never' },
      { title: 'Navn', field: 'name', editable: 'never' },
      {
        title: 'Saldo',
        field: 'balance',
        editable: 'never',
        type: 'currency',
        maximumFractionDigits: 2,
        currencySetting: {
          currencyCode: 'DKK',
          locale: 'da-DK',
        },
      },
    ],
    localErrors: '',
  };

  componentDidMount() {
    this.setState({ tableData: this.props.companyBankAccounts });
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.companyBankAccounts !== this.props.companyBankAccounts) {
      this.setState({ tableData: this.props.companyBankAccounts });
    }
  }

  handleModalAddVisibility = () => {
    this.setState({ modalAddVisible: !this.state.modalAddVisible });
  };

  handleModalCreateTransactionVisibility = () => {
    this.setState({
      modalAddTransactionVisibility: !this.state.modalAddTransactionVisibility,
    });
  };

  handleModalViewTransactionsVisibility = () => {
    this.setState({ modalViewTransactions: !this.state.modalViewTransactions });
  };

  deleteBankAccount = (bankAccount: BankAccount) => {
    store.dispatch(bankAccountDelete(bankAccount.id));
  };

  validateBeforeDelete = () => {
    if (this.props.companyBankAccounts.length !== 1) {
      return true;
    } else {
      this.setState({
        localErrors: 'Virksomheden skal have mindst én bank konto',
      });
      return false;
    }
  };

  confirmDeleteBankAccount = (bankAccount: BankAccount) => {
    confirmAlert({
      title: 'Bekræft sletning af bank konto: ' + bankAccount.name + '?',
      message: 'Er du sikker?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => this.deleteBankAccount(bankAccount),
        },
        {
          label: 'Nej',
          onClick: () => void 0,
        },
      ],
    });
  };

  handleModalEditVisibility = () => {
    this.setState({ modalEditVisible: !this.state.modalEditVisible });
  };

  setSelectedBankAccount = (bankAccount: BankAccount) => {
    this.setState({ selectedBankAccount: bankAccount });
  };

  renderContent = () => {
    return (
      <div>
        <CustomTable
          data={this.state.tableData}
          headers={this.state.tableHeader}
          actions={[
            {
              icon: 'ballot',
              tooltip: 'Se konto overførsler',
              onClick: (event, bankAccount) => {
                this.setSelectedBankAccount(bankAccount);
                this.handleModalViewTransactionsVisibility();
              },
            },
            {
              icon: 'edit',
              tooltip: 'Rediger data',
              onClick: (event, bankAccount) => {
                this.setSelectedBankAccount(bankAccount);
                this.handleModalEditVisibility();
              },
            },
            {
              icon: 'delete',
              tooltip: 'Slet bank konto',
              onClick: (event, bankAccount) => {
                if (this.validateBeforeDelete()) {
                  this.confirmDeleteBankAccount(bankAccount);
                }
              },
            },
          ]}
        />
        {this.state.localErrors ? (
          <p className={'text-danger'}>{this.state.localErrors}</p>
        ) : (
          ''
        )}
        <div className={'my-2'}>
          <button
            type="button"
            className="btn btn-success rounded-pill"
            onClick={this.handleModalAddVisibility}
          >
            <AddIcon /> Tilføj ny konto
          </button>
          <button
            type="button"
            className="btn btn-success rounded-pill"
            onClick={this.handleModalCreateTransactionVisibility}
          >
            <AddIcon /> Opret ny overførsel
          </button>
        </div>
      </div>
    );
  };

  public render() {
    return (
      <>
        <div>
          {this.props.loading ? (
            <div className={'d-flex justify-content-center'}>
              <LoadingComponent visible={this.props.loading} />
            </div>
          ) : (
            this.renderContent()
          )}
        </div>

        <InstructorModal
          modalState={this.state.modalAddVisible}
          showFunc={this.handleModalAddVisibility}
          titleHeader="Tilføj ny bank konto"
        >
          <AddBankAccountModalData
            visibilityToggleFunc={this.handleModalAddVisibility}
            ownerId={this.props.company.id}
            company={this.props.company}
            componentType={AddBankAccountModalType.COMPANY}
          />
        </InstructorModal>

        <InstructorModal
          modalState={this.state.modalEditVisible}
          showFunc={this.handleModalEditVisibility}
          titleHeader="Rediger bank konto"
        >
          <EditBankAccountModalData
            bankAccount={this.state.selectedBankAccount}
            visibilityToggleFunc={this.handleModalEditVisibility}
          />
        </InstructorModal>

        <InstructorModal
          modalState={this.state.modalAddTransactionVisibility}
          showFunc={this.handleModalCreateTransactionVisibility}
          titleHeader="Opret ny overførsel"
        >
          <CreateTransactionModalData
            selectedCompany={this.props.company}
            thisCompanyBankAccount={this.props.companyBankAccounts}
            allCompanies={this.props.allCompanies}
            allBankAccounts={this.props.allBankAccounts}
          />
        </InstructorModal>

        <InstructorModal
          modalState={this.state.modalViewTransactions}
          showFunc={this.handleModalViewTransactionsVisibility}
          titleHeader="Bank overførsler"
          width={90}
        >
          <ViewTransactionsModalData
            visibilityToggleFunc={this.handleModalViewTransactionsVisibility}
            bankAccount={this.state.selectedBankAccount}
            allCompanies={this.props.allCompanies}
            allBankAccounts={this.props.allBankAccounts}
            transactions={this.props.allTransactions.filter(
              elem =>
                elem.fromBankAccountId === this.state.selectedBankAccount.id ||
                elem.toBankAccountId === this.state.selectedBankAccount.id,
            )}
            allUsers={this.props.allUsers}
          />
        </InstructorModal>
      </>
    );
  }
}

const mapStateToProps = ({ iCompany }: ApplicationState) => ({
  loading: iCompany.loading,
});

export default connect(mapStateToProps)(CompanyTab);
