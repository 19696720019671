import client from '../client';
import { Requisition } from '../../app/store/instructor/requisitions';
import { AxiosResponse } from 'axios';

export interface NewRequisitionRequest {
  from_company_id: number;
  to_company_id: number;
  navision_id: string;
  products: [number, number][]; // TODO: Is this the key,value i think it is?
}

export default {
  getRequisition: async id =>
    await client.get<Requisition>(`/requisition/${id}`),
  createRequisition: async (
    requisition: NewRequisitionRequest,
  ): Promise<AxiosResponse<Requisition>> =>
    await client.post<Requisition>(
      '/requisition/',
      JSON.stringify(requisition),
    ),
};
