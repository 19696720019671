import { HomemadeDate } from './homemadeDate';

export function convertHomemadeDateToString(date: HomemadeDate): string {
  return `${date.dayOfMonth}-${date.monthValue}-${date.year}`;
}

export function convertHomemadeDateToISOFormat(date: HomemadeDate) {
  return new Date(
    `${date.monthValue!}-${date.dayOfMonth}-${date.year}`,
  ).toISOString();
}
