import React, { Component } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';

interface PropsFromParent {
  version: string;
  date: string;
  title?: string;
}

class PatchCard extends Component<PropsFromParent> {
  render() {
    return (
      <Accordion>
        <Card className={'mb-3'}>
          <Card.Header as="h5">
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Version {this.props.version}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {this.props.title ? (
                <Card.Title>{this.props.title}</Card.Title>
              ) : (
                ''
              )}
              <Card.Text>{this.props.children}</Card.Text>
            </Card.Body>
          </Accordion.Collapse>
          <Card.Footer className="text-muted">{this.props.date}</Card.Footer>
        </Card>
      </Accordion>
    );
  }
}

export default PatchCard;
