import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  Company,
  CompanyTypes,
  EditCompany,
} from '../../../store/instructor/company';
import { ModalContext } from '../../../contexts/modalContexts';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { Field, Form as FormikForm, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { updateCompanyDetails } from '../../../utils/api';

interface PropsFromParent {
  company: Company;
  setIndicator: (msg: string) => void;
}

const EditCompanyModalData: React.FC<PropsFromParent> = props => {
  const queryClient = useQueryClient();
  const { showModal } = React.useContext(ModalContext);

  const { mutate: updateCompany } = useMutation(updateCompanyDetails, {
    onSuccess: () => {
      queryClient.refetchQueries('allCompanies');
      props.setIndicator('Ændrede detaljer på ' + props.company.name);
    },
  });

  const onEditButtonClick = (values: EditCompany) => {
    updateCompany({ details: values, company_id: props.company.id });
    showModal();
  };

  const editCompanyRequirementSchema: SchemaOf<EditCompany> = Yup.object().shape(
    {
      name: Yup.string().max(50).defined().min(2),
      payment_terms: Yup.number().defined().integer().min(1),
      delivery_time: Yup.number().defined().integer().min(1),
      address: Yup.string().defined(),
      company_type_id: Yup.number().defined().min(1).max(5),
    },
  );

  return (
    <Formik
      initialValues={{
        name: props.company.name ?? '',
        payment_terms: props.company.payment_terms ?? 14,
        delivery_time: props.company.delivery_time ?? 4,
        address: props.company.address ?? '',
        company_type_id: props.company.company_type_id ?? 2,
      }}
      validationSchema={editCompanyRequirementSchema}
      validateOnChange={false}
      onSubmit={(values: EditCompany) => {
        onEditButtonClick(values);
      }}
    >
      {({ errors, touched }) => (
        <FormikForm>
          <p className="h5">Virksomhedsdetaljer</p>
          <div className="form-group">
            <label>Virksomhedsnavn</label>
            <Field name="name" placeholder="Navn" className="form-control" />
            {errors.name && touched.name && (
              <small className="form-text text-danger">{errors.name}</small>
            )}
          </div>
          <div className="form-group">
            <label>Betalingsbetingelser</label>
            <Field
              name="payment_terms"
              placeholder="Betalingsbetingelser"
              className="form-control"
            />
            {errors.payment_terms && touched.payment_terms && (
              <small className="form-text text-danger">
                {errors.payment_terms}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Leveringstid</label>
            <Field
              name="delivery_time"
              placeholder="Leveringstid"
              className="form-control"
            />
            {errors.delivery_time && touched.delivery_time && (
              <small className="form-text text-danger">
                {errors.delivery_time}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Adresse</label>
            <Field
              name="address"
              placeholder="Adresse"
              className="form-control"
            />
            {errors.address && touched.address && (
              <small className="form-text text-danger">{errors.address}</small>
            )}
          </div>
          <div className="form-group">
            <label>Type</label>
            <Field as="select" name="company_type_id" className="form-control">
              {Object.keys(CompanyTypes)
                .filter(k => isNaN(Number(k)))
                .map(enumKey => (
                  <option
                    disabled={enumKey === 'Super virksomhed'}
                    key={CompanyTypes[enumKey]}
                    label={enumKey}
                    value={CompanyTypes[enumKey]}
                  />
                ))}
            </Field>
            {errors.company_type_id && touched.company_type_id && (
              <small className="form-text text-danger">
                {errors.company_type_id}
              </small>
            )}
          </div>
          <Modal.Footer>
            <Button type={'submit'} variant="success">
              Gem ændringer
            </Button>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default EditCompanyModalData;
