import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Company } from '../../../../store/instructor/company';
import { BankAccount } from '../../../../store/instructor/bank';
import { store } from '../../../../store/store';
import {
  BankTransactionCreate,
  NewBankTransaction,
} from '../../../../store/instructor/bank-transactions';
import { parseDanishDouble } from '../../../../utils/convertions';
import { validateDoubleWithCommaOrInteger } from '../../../../utils/validators';

interface PropsFromParent {
  selectedCompany: Company;
  thisCompanyBankAccount: BankAccount[];
  allCompanies: Company[];
  allBankAccounts: BankAccount[];
}

interface ThisCompState {
  recipient: string;
  bankAccount: string;
  amount: string;
  message: string;
  indicator: null | string;
  error: null | string;
}

class CreateTransactionModalData extends Component<
  PropsFromParent,
  ThisCompState
> {
  state = {
    recipient: '-1',
    bankAccount: '-1',
    amount: '0',
    message: '',
    indicator: null,
    error: null,
  };

  setIndicator = (msg: string) => {
    this.setState({ indicator: msg });
  };

  setError = (msg: string) => {
    this.setState({ error: msg });
  };

  onChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  createTransaction = (transaction: NewBankTransaction) => {
    store.dispatch(BankTransactionCreate(transaction));
    this.setIndicator('Overførsel er blevet oprettet');
  };

  validateTransaction = (transaction: NewBankTransaction): boolean => {
    if (transaction.amount === -1 || transaction.amount < 0) {
      this.setError('Fejl: Beløbet skal være over 0');
      return false;
    }

    if (transaction.fromBankAccountId === -1) {
      this.setError('Fejl: Fra konto mangler');
      return false;
    }

    if (transaction.toBankAccountId === -1) {
      this.setError('Fejl: Modtager konto mangler');
      return false;
    }

    if (transaction.message.trim() === '') {
      this.setError('Fejl: Besked feltet kan ikke være tomt');
      return false;
    }

    return true;
  };

  onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (this.validateAmount(this.state.amount)) {
      const parsedTransaction: NewBankTransaction = {
        amount: parseDanishDouble(String(this.state.amount)),
        bankTransactionTypeId: 2,
        fromBankAccountId: parseInt(String(this.state.bankAccount)),
        toBankAccountId: parseInt(String(this.state.recipient)),
        message: this.state.message,
      };

      if (this.validateTransaction(parsedTransaction)) {
        this.createTransaction(parsedTransaction);
      }
    }
  };

  validateAmount = (amount: string): boolean => {
    if (amount === '' || amount === '0') {
      this.setError('Beløb må ikke være tomt.');
      return false;
    } else {
      const amountErrorMsg: string = validateDoubleWithCommaOrInteger(amount);
      if (amountErrorMsg !== '') {
        this.setError('Beløb: ' + amountErrorMsg + '.');
        return false;
      } else {
        return true;
      }
    }
  };

  generateOptList = (company: Company) => {
    const companyBankAccs = this.props.allBankAccounts.filter(
      account => account.companyId === company.id,
    );

    return (
      <optgroup label={company.name}>
        {companyBankAccs.map(acc => {
          return (
            <option value={acc.id}>
              {acc.id} {acc.name}
            </option>
          );
        })}
      </optgroup>
    );
  };

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Group controlId="newTransaction.Recipient">
          <Form.Label>Modtager konto</Form.Label>
          <Form.Control
            name="recipient"
            onChange={e => this.onChange(e)}
            as="select"
            value={this.state.recipient}
            required
          >
            <option disabled={true} value={-1}>
              Vælg ..
            </option>
            {this.props.allCompanies
              .filter(company => company.company_type_id === 1)
              .map(superCompany => this.generateOptList(superCompany))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="newTransaction.BankAccount">
          <Form.Label>Overfør fra konto</Form.Label>
          <Form.Control
            name="bankAccount"
            onChange={e => this.onChange(e)}
            as="select"
            value={this.state.bankAccount}
            required
          >
            <option disabled={true} value={-1}>
              Vælg ..
            </option>
            {this.props.thisCompanyBankAccount.map(acc => {
              return (
                <option value={acc.id}>
                  ({acc.id}) {acc.name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="newTransaction.Amount">
          <Form.Label>Beløb</Form.Label>
          <Form.Control
            name="amount"
            onChange={e => this.onChange(e)}
            placeholder="Beløb"
            required
          />
        </Form.Group>

        <Form.Group controlId="newTransaction.Message">
          <Form.Label>Besked til modtager</Form.Label>
          <Form.Control
            name="message"
            onChange={e => this.onChange(e)}
            placeholder="Besked"
            required
          />
        </Form.Group>

        {this.state.indicator ? (
          <div className="alert alert-success" role="alert">
            {this.state.indicator}
          </div>
        ) : null}

        {this.state.error ? (
          <div className="alert alert-warning" role="alert">
            {this.state.error}
          </div>
        ) : null}

        <Button variant="success" type="submit">
          Opret
        </Button>
      </Form>
    );
  }
}

export default CreateTransactionModalData;
