import * as React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { ApplicationState } from '../../../../../store';
import LoadingComponent from '../../../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
}

class StateOfAccountCompanyTab extends React.Component<PropsFromParent> {
  renderContent = () => {
    return (
      <Table>
        <thead>
          <tr>
            <th>Dato</th>
            <th>Beskrivelse</th>
            <th>Valuta</th>
            <th>Beløb</th>
            <th>Rest</th>
            <th>Salo</th>
          </tr>
        </thead>
      </Table>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ iCompany }: ApplicationState) => ({
  loading: iCompany.loading,
});

export default connect(mapStateToProps)(StateOfAccountCompanyTab);
