import {
  Delivery,
  deliveryGetFromCompanyPage,
  deliveryGetToCompanyActivePage,
  deliveryGetToCompanyArchivePage,
  deliverySetAddedToStock,
  deliverySetArchiveType,
} from '../../../../../store/instructor/delivery';
import React from 'react';
import { ArchiveType } from '../../../../../utils/convertions';
import { Company } from '../../../../../store/instructor/company';
import InstructorModal from '../../../../../components/modal/instructorModal';
import { HomemadeDate } from '../../../../../utils/homemadeDate';
import ViewDeliveryModalData from '../../featureModal/viewDeliveryModalData';
import { store } from '../../../../../store/store';
import { companyProductAddAmount } from '../../../../../store/instructor/company-products';
import { confirmAlert } from 'react-confirm-alert';
import CustomTablePagination from '../../../../../components/table/customTablePagination';
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { Page } from '../../../../../utils/storeTypes';
import DatePicker from 'react-datepicker';
import DatepickerTableTitle from '../../../../../components/table/title/datepickerTitle';

export enum DeliveryOverviewType {
  INCOMING_ACTIVE,
  OUTGOING,
  INCOMING_ARCHIVED,
}

export enum ComponentSection {
  INVENTORY,
  SHIPPING,
}

interface PropsFromParent {
  loading: boolean;
  componentSection: ComponentSection;
  type: DeliveryOverviewType;
  allCompanies: Company[];
  thisUserCompanyId: number;

  deliveriesFromCompany: Page;
  deliveriesToCompanyActive: Page;
  deliveriesToCompanyArchive: Page;
  loadingFromCompany: boolean;
  loadingToCompanyActive: boolean;
  loadingToCompanyArchive: boolean;
}

interface ComponentState {
  searchQuery: string | undefined;
  startDate: Date | null;
  endDate: Date | null;
  tableData: Delivery[];
  tableHeader: any[];
  modalViewDeliveryVisible: boolean;
  selectedDelivery: Delivery;

  totalElementAmount: number;
  pageSize: number;
  currentPage: number;
  //TODO-future: optimize: above four should be replaced with type Page
}

class DeliveryOverview extends React.Component<
  PropsFromParent,
  ComponentState
> {
  constructor(props: Readonly<PropsFromParent>) {
    super(props);
    this.state = {
      searchQuery: undefined,
      startDate: null,
      endDate: null,
      pageSize: 10,
      totalElementAmount: -1,
      currentPage: 0,
      selectedDelivery: {
        requisition_id: -1,
        delivery_date: new HomemadeDate(),
        items: [],
        archive_type: '',
        archive_type_id: -1,
        delivery_id: -1,
        added_to_stock: false,
        delivery_category: '',
        delivery_payment_requisition_id: -1,
        franko: false,
        from_company_id: -1,
        from_company_name: '',
        requisition_navision_id: '',
        to_company_id: -1,
        to_company_name: '',
      },
      modalViewDeliveryVisible: false,
      tableData: [],
      tableHeader: [
        ...(this.props.componentSection === ComponentSection.SHIPPING
          ? [
              {
                title: 'Købsordre nr.',
                field: 'requisition_id',
                editable: 'never',
              },
            ]
          : []),
        ...(this.props.componentSection === ComponentSection.INVENTORY &&
        (this.props.type === DeliveryOverviewType.INCOMING_ACTIVE ||
          this.props.type === DeliveryOverviewType.INCOMING_ARCHIVED)
          ? [
              {
                title: 'Navision nr. fra købsordre',
                field: 'requisition_navision_id',
                editable: 'never',
              },
            ]
          : []),
        {
          title: 'Leveringstidspunkt',
          field: 'delivery_date',
          editable: 'never',
          render: data => <span>{data.delivery_date.getString()}</span>,
        },
        {
          title:
            this.props.type === DeliveryOverviewType.INCOMING_ACTIVE ||
            this.props.type === DeliveryOverviewType.INCOMING_ARCHIVED
              ? 'Afsender'
              : 'Modtager',
          field:
            this.props.type === DeliveryOverviewType.OUTGOING
              ? 'to_company_name'
              : 'from_company_name',
          editable: 'never',
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchPage();
  }

  getPageMatchingTabType(): Page | undefined {
    switch (this.props.type) {
      case DeliveryOverviewType.INCOMING_ACTIVE:
        return this.props.deliveriesToCompanyActive;
      case DeliveryOverviewType.OUTGOING:
        return this.props.deliveriesFromCompany;
      case DeliveryOverviewType.INCOMING_ARCHIVED:
        return this.props.deliveriesToCompanyArchive;
      default:
        throw new Error(
          'A new tab type has been added in DeliveryOverviewType!',
        );
    }
  }

  confirmReturnOfPackage = (delivery: Delivery) => {
    confirmAlert({
      title:
        'Bekræft returnering af levering med id: ' +
        delivery.requisition_navision_id +
        '?',
      message:
        'Dette er endnu ikke implementeret, så de nedenstående knapper gør intet.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => void 0,
        },
        {
          label: 'No',
          onClick: () => void 0,
        },
      ],
    });
  };

  componentDidUpdate(
    prevProps: Readonly<PropsFromParent>,
    prevState: Readonly<ComponentState>,
  ) {
    let page: Page | undefined = this.getPageMatchingTabType();

    if (page !== undefined) {
      if (page.items !== prevState.tableData) {
        this.setState({
          totalElementAmount: page.total_items,
          tableData: page.items,
        });
      }
    }
  }

  handleModalViewVisibility = () => {
    this.setState({
      modalViewDeliveryVisible: !this.state.modalViewDeliveryVisible,
    });
  };

  setSelectedDelivery = (delivery: Delivery) => {
    this.setState({ selectedDelivery: delivery });
  };

  onActionProcessedClick(delivery: Delivery) {
    store.dispatch(
      deliverySetArchiveType(
        delivery.delivery_id,
        ArchiveType.PROCESSED,
        this.props.thisUserCompanyId,
      ),
    );
  }

  onActionAddDeliverToInventoryClickConfirmation(delivery: Delivery) {
    confirmAlert({
      title:
        'Du er ved at tilføje varer til lageret fra levering: ' +
        delivery.requisition_navision_id +
        '?',
      message:
        'Er du sikker på at du vil tilføje denne leverings produkter til lageret?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => this.onActionAddDeliverToInventoryClick(delivery),
        },
        {
          label: 'Nej',
          onClick: () => void 0,
        },
      ],
    });
  }

  temporaryConfirmAlertAddedToStockIsFalse() {
    confirmAlert({
      title: 'Denne levering er allerede tilføjet til varelageret.',
      message: 'Nedenstående knapper gør intet.',
      buttons: [
        {
          label: '.',
          onClick: () => void 0,
        },
        {
          label: '.',
          onClick: () => void 0,
        },
      ],
    });
  }

  onActionAddDeliverToInventoryClick(delivery: Delivery) {
    delivery.items.forEach(orderItem => {
      store.dispatch(
        companyProductAddAmount(
          this.props.thisUserCompanyId,
          delivery.from_company_id,
          delivery.delivery_id,
          orderItem.product_id,
          orderItem.product_quantity,
          'add',
        ),
      );
      store.dispatch(
        deliverySetAddedToStock(
          delivery.delivery_id,
          true,
          this.props.thisUserCompanyId,
        ),
      );
    });
    this.fetchPage();
  }

  onChangePage = (targetPageNumber: number, pageSize: number): void => {
    this.setState({ currentPage: targetPageNumber }, () => {
      this.fetchPage();
    });
  };

  onChangeRowPerPage = (newRowsPerPage: number) => {
    this.setState({ pageSize: newRowsPerPage }, () => {
      this.fetchPage();
    });
  };

  onSearchChange = (query: string) => {
    this.setState(
      {
        searchQuery: query,
        currentPage: 0,
      },
      () => {
        this.fetchPage();
      },
    );
  };

  fetchPage = () => {
    switch (this.props.type) {
      case DeliveryOverviewType.INCOMING_ACTIVE:
        store.dispatch(
          deliveryGetToCompanyActivePage(
            this.props.thisUserCompanyId,
            this.state.currentPage,
            this.state.pageSize,
            true,
            ArchiveType.DEFAULT,
            this.state.searchQuery,
            this.state.startDate,
            this.state.endDate,
            true,
          ),
        );
        break;
      case DeliveryOverviewType.INCOMING_ARCHIVED:
        store.dispatch(
          deliveryGetToCompanyArchivePage(
            this.props.thisUserCompanyId,
            this.state.currentPage,
            this.state.pageSize,
            true,
            ArchiveType.PROCESSED,
            this.state.searchQuery,
            this.state.startDate,
            this.state.endDate,
            true,
          ),
        );
        break;
      case DeliveryOverviewType.OUTGOING:
        store.dispatch(
          deliveryGetFromCompanyPage(
            this.props.thisUserCompanyId,
            this.state.currentPage,
            this.state.pageSize,
            true,
            this.state.searchQuery,
            this.state.startDate,
            this.state.endDate,
          ),
        );
        break;
      default:
        throw new Error('New type for DeliveryOverviewType has been added!');
    }
  };

  getPageLoadingBoolean(): boolean {
    switch (this.props.type) {
      case DeliveryOverviewType.OUTGOING:
        return this.props.loadingFromCompany;
      case DeliveryOverviewType.INCOMING_ACTIVE:
        return this.props.loadingToCompanyActive;
      case DeliveryOverviewType.INCOMING_ARCHIVED:
        return this.props.loadingToCompanyArchive;
    }
  }

  render() {
    const actions = [
      {
        icon: 'ballot',
        tooltip: 'Se levering',
        onClick: (event, delivery) => {
          this.setSelectedDelivery(delivery);
          this.handleModalViewVisibility();
        },
      },
    ];
    if (this.props.type === DeliveryOverviewType.INCOMING_ACTIVE) {
      actions.push({
        icon: 'redo',
        tooltip: 'Returner pakke',
        onClick: (event, delivery) => {
          /**TODO-future: add return feature*/
          this.confirmReturnOfPackage(delivery);
        },
      });
      actions.push({
        icon: 'done_outline',
        tooltip: 'Flyt til behandlet',
        onClick: (event, delivery) => this.onActionProcessedClick(delivery),
      });
      actions.push({
        //disabled: (rowData): boolean => rowData.added_to_stock === true, //TODO-future: make work
        icon: 'input',
        tooltip: 'Tilføj produkter til varelager',
        onClick: (event, delivery) => {
          if (delivery.added_to_stock === false) {
            this.onActionAddDeliverToInventoryClickConfirmation(delivery);
          } else {
            this.temporaryConfirmAlertAddedToStockIsFalse();
          }
        },
      });
    }

    const meLoading =
      this.props.loading ||
      this.props.loadingToCompanyArchive ||
      this.props.loadingToCompanyActive ||
      this.props.loadingFromCompany;

    return (
      <>
        <CustomTablePagination
          titleComponent={
            <DatepickerTableTitle
              datepicker={
                <DatePicker
                  selectsRange={true}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onCalendarClose={() => this.fetchPage()}
                  onChange={dates => {
                    if (dates) {
                      if (Array.isArray(dates)) {
                        if (dates.every(ele => ele == null)) {
                          this.setState(
                            {
                              startDate: null,
                              endDate: null,
                            },
                            () => {
                              this.fetchPage();
                            },
                          );
                        }
                      }
                      this.setState({
                        startDate: dates[0],
                        endDate: dates[1],
                      });
                    }
                  }}
                  isClearable={true}
                />
              }
            />
          }
          onSearchChange={this.onSearchChange}
          data={this.state.tableData}
          headers={this.state.tableHeader}
          actions={actions}
          isLoading={meLoading}
          onChangePageFunc={this.onChangePage}
          totalElementAmount={this.state.totalElementAmount}
          numberRowPerPage={this.state.pageSize}
          pageDataLoading={this.getPageLoadingBoolean()}
          onChangeRowsPerPageFunc={this.onChangeRowPerPage}
          pageNumber={this.state.currentPage}
        />
        <InstructorModal
          modalState={this.state.modalViewDeliveryVisible}
          showFunc={this.handleModalViewVisibility}
          titleHeader="Se levering"
          width={70}
        >
          <ViewDeliveryModalData
            delivery={this.state.selectedDelivery}
            allCompanies={this.props.allCompanies}
            visibilityToggleFunc={this.handleModalViewVisibility}
          />
        </InstructorModal>
      </>
    );
  }
}

const mapStateToProps = ({ iDelivery }: ApplicationState) => ({
  deliveriesFromCompany: iDelivery.deliveriesFromCompany,
  deliveriesToCompanyActive: iDelivery.deliveriesToCompanyActive,
  deliveriesToCompanyArchive: iDelivery.deliveriesToCompanyArchive,
  loadingFromCompany: iDelivery.loadingFromCompany,
  loadingToCompanyActive: iDelivery.loadingToCompanyActive,
  loadingToCompanyArchive: iDelivery.loadingToCompanyArchive,
  loading: iDelivery.loading,
});

export default connect(mapStateToProps)(DeliveryOverview);
