import { HomemadeDate } from '../../../utils/homemadeDate';
import { ErrorMessage, OrderItem } from '../orderInvoice';
import { Page } from '../../../utils/storeTypes';

export enum FetchTypes {
  newest = 'newest',
  getAll = 'getAll',
}

export interface Requisition {
  requisition_id: number;
  paid: boolean;
  items: OrderItem[];
  navision_id: number;
  from_company_id: number;
  to_company_id: number;
  requisition_date: HomemadeDate;
  requisition_amount: number;
  archive_type_id: number;
  archive_type: string;
  delivery_parent_id: number;
  errorMessages?: ErrorMessage[];
  created_by_id?: number;
  total_amount_without_vax: number;
  total_vax: number;
}

export enum RequisitionActionTypes {
  REQUISITION_GET_ALL_TOANDFROM_COMPANY = '@@public/requisition/GET_ALL_TOANDFROM_COMPANY',
  REQUISITION_GET_ALL_TOANDFROM_COMPANY_NEWEST = '@@public/requisition/GET_ALL_TOANDFROM_COMPANY_NEWEST',
  REQUISITION_GET_ALL_TO_COMP_ACTIVE_PAGE = '@@instructor/requisition/GET_ALL_TO_COMP_ACTIVE_PAGE',
  REQUISITION_GET_ALL_TO_COMP_ARCHIVED_PAGE = '@@instructor/requisition/GET_ALL_TO_COMP_ARCHIVED_PAGE',
  REQUISITION_GET_ALL_FROM_COMP_WITHOUT_SHIP_PAGE = '@@instructor/requisition/GET_ALL_FROM_COMP_PAGE',
  REQUISITION_CREATE = '@@public/requisition/CREATE',
  REQUISITION_SET_ARCHIVE_TYPE = '@@public/requisition/SET_ARCHIVE_TYPE',
  REQUISITION_SUCCESS = '@@public/requisition/SUCCESS',
  REQUISITION_SUCCESS_NEWEST = '@@public/requisition/SUCCESS_NEWEST',
  REQUISITION_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS = '@@instructor/requisition/GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS',
  REQUISITION_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS = '@@instructor/requisition/GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS',
  REQUISITION_GET_ALL_FROM_COMP_WITHOUT_SHIP_PAGE_SUCCESS = '@@instructor/requisition/GET_ALL_FROM_COMP_PAGE_SUCCESS',

  REQUISITION_ERROR = '@@public/requisition/ERROR',
}

export interface RequisitionState {
  readonly requisitionsToAndFromCompany: Requisition[];
  readonly requisitionsToAndFromCompanyNewest: Requisition[];
  readonly loading: boolean;
  readonly errors?: string;

  readonly loadingToCompanyActive: boolean;
  readonly loadingToCompanyArchive: boolean;
  readonly loadingFromCompany: boolean;
  readonly requisitionsToCompanyActive: Page;
  readonly requisitionsToCompanyArchive: Page;
  readonly requisitionsFromCompany: Page;
}
