import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../../store';
import { Invoice } from '../../../../store/instructor/orderInvoice';
import { Company } from '../../../../store/instructor/company';
import { getCompanyNameFromId } from '../../../../utils/convertions';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  invoice: Invoice;
  allCompanies: Company[];
}

class ViewInvoiceModalData extends React.Component<PropsFromParent> {
  renderContent = () => {
    return (
      <Container className="px-2 pt-0 pb-2">
        {/*<Row>
          <Col>Invoice status</Col>
          <Col>Status/PLACEHOLDER</Col>
        </Row>*/}
        <Row>
          <Col>Købsordre id</Col>
          <Col>{this.props.invoice.requisition_id}</Col>
        </Row>
        <Row>
          <Col>Faktura dato</Col>
          <Col>{this.props.invoice.invoice_date.getString()}</Col>
        </Row>
        <Row>
          <Col>Modtager</Col>
          <Col>
            {getCompanyNameFromId(
              this.props.allCompanies,
              this.props.invoice.company_to,
            )}
          </Col>
        </Row>
      </Container>
    );
  };

  public render() {
    return (
      <>
        <>{this.renderContent()}</>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.visibilityToggleFunc}>
            Luk
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ iProduct }: ApplicationState) => ({
  loading: iProduct.loading,
  errors: iProduct.errors,
});

export default connect(mapStateToProps)(ViewInvoiceModalData);
