import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import Catalog from './tabs/catalog';
import {
  ProductType,
  productTypeGetAll,
} from '../../../store/instructor/productType';
import { store } from '../../../store/store';
import CompanyProducts from '../../instructor-pages/products/products/companies/companyProducts';
import { Product, productGetAll } from '../../../store/instructor/product';
import {
  CompanyIdProductsPair,
  companyProductsGetAll,
} from '../../../store/instructor/company-products';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  allProductTypes: ProductType[];
  allProducts: Product[];
  companyId: number;
}

class ProductsContent extends React.Component<PropsFromState> {
  componentDidMount() {
    store.dispatch(productTypeGetAll());
    store.dispatch(productGetAll());
    store.dispatch(companyProductsGetAll(this.props.companyId));
  }

  getCompanyProducts = (companyId: number): Product[] => {
    const companyIdProductsPair: CompanyIdProductsPair[] = store
      .getState()
      .iCompanyProducts.companyProducts.filter(
        elem => elem.companyId === companyId,
      );

    if (companyIdProductsPair.length === 1) {
      //Found the right one
      return companyIdProductsPair[0].products;
    } else if (companyIdProductsPair.length > 1) {
      //Should never happen
      throw Error;
    } else {
      return [];
    }
  };

  companyProducts = (): Product[] => {
    return this.getCompanyProducts(this.props.companyId);
  };

  renderContent = () => {
    return (
      <Tabs>
        <Tab eventKey="inventory" title="Varelager">
          <CompanyProducts
            allProducts={this.props.allProducts}
            companyId={this.props.companyId}
            companyProducts={this.companyProducts()}
          />
        </Tab>
        {/*

        <Tab eventKey="productTypes" title="Varekategorier">
          <ProductTypeOverview allProductTypes={this.props.allProductTypes} />
        </Tab>
        */}
        <Tab eventKey="catalogs" title="Katalog" disabled={true}>
          <Catalog />
        </Tab>
      </Tabs>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iProductType,
  iProduct,
  userInfo,
  iCompanyProducts,
}: ApplicationState) => ({
  loading: iProductType.loading || iProduct.loading || iCompanyProducts.loading,
  allProductTypes: iProductType.productTypes,
  allProducts: iProduct.products,
  companyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(ProductsContent);
