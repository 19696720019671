import axios from 'axios';
import {
  ProductNoIdAndTypeCode,
  ProductNoTypeCode,
} from '../store/instructor/product';
import { ProductQuantityDTO } from '../components/modal/products/editProductQuantityModalContent';
import { EditCompany, NewCompany } from '../store/instructor/company';
import { ExpensePOST } from '../../types/expenses';
import { StockRequest } from '../../api/services/StockService';

axios.defaults.baseURL = 'https://kopra.khs.dk:8080/api';
// axios.defaults.baseURL = 'http://212.237.181.217:8080/api';
// axios.defaults.baseURL = 'http://localhost:8080/api';

export async function userChangePassword(userId: number, theTwoPasswords) {
  return await axios.put(
    '/user/' + userId + '/password',
    JSON.stringify(theTwoPasswords),
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
}

export async function addCompanyProduct(companyId: number, productId: number) {
  return await axios.put('/company/' + companyId + '/product/' + productId, {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function deleteCompanyProduct(
  companyId: number,
  productId: number,
) {
  return await axios.delete('/company/' + companyId + '/product/' + productId, {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function addCompanyProductAmount(
  receiverCompanyId: number,
  senderCompanyId: number,
  deliveryId: number,
  productId: number,
  amount: number,
  operationType: string,
) {
  return await axios.put(
    '/stock/',
    {
      receiverCompanyId,
      senderCompanyId,
      deliveryId,
      productId,
      amount,
      operationType,
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
}

export async function removeCompanyProductAmount(
  receiverCompanyId: number,
  senderCompanyId: number,
  deliveryId: number,
  productId: number,
  amount: number,
  operationType: string,
) {
  return await axios.put(
    '/stock/',
    {
      receiverCompanyId,
      senderCompanyId,
      deliveryId,
      productId,
      amount,
      operationType,
    },
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
}

export async function getTransactionsFromBankAccountId(bankAccountId: number) {
  return await axios.get(
    `/bank-account/` + bankAccountId + `/bank-transaction`,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
}

export async function getEndpoint(path: string): Promise<any> {
  return await axios.get(`/${path}/`, {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function getEndpointWithTwoIds(
  path: string,
  id_1: number,
  id_2: number,
) {
  return await getEndpoint(path + '/' + id_1 + '/' + id_2);
}

export async function getEndpointId(path: string, id: number) {
  return await getEndpoint(path + '/' + id);
}

export async function getEndpointWithFilters(path: string, params) {
  return await axios.get(`/${path}/filter`, {
    headers: {
      'Content-type': 'application/json',
    },
    params: params,
  });
}

export async function postEndpoint(path: string, data) {
  return await axios.post(`/${path}/`, JSON.stringify(data), {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function deleteEndpoint(path: string, id: number) {
  return await axios.delete(`/${path}/` + id, {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function putEndpoint(path: string, bodyData, id: number) {
  return await axios.put(`/${path}/` + id, JSON.stringify(bodyData), {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function fetchMistakesDeliveries(
  page: number,
  isHandled: boolean,
): Promise<any> {
  const resp = await axios.get(`/mistake/delivery`, {
    params: { size: 5, page: page, handled: isHandled },
  });

  return resp.data;
}

export async function fetchMistakesInvoices(
  page: number,
  isHandled: boolean,
): Promise<any> {
  const resp = await axios.get(`/mistake/invoice`, {
    params: { size: 5, page: page, handled: isHandled },
  });

  return resp.data;
}

export async function fetchExpenses(
  page: number,
  size: number,
  company?: string | number,
): Promise<any> {
  const resp = await axios.get(`/expense/page/filter`, {
    params: {
      page,
      size,
      to_company_id: company === 'all' ? null : company,
    },
  });

  return resp.data;
}

export async function fetchMistakesTransactions(
  page: number,
  isHandled: boolean,
): Promise<any> {
  const resp = await axios.get(`/mistake/transaction`, {
    params: { size: 5, page: page, handled: isHandled },
  });

  return resp.data;
}

export async function fetchInvoiceById(id: number): Promise<any> {
  const resp = await axios.get(`/invoice/${id}`);

  return resp.data;
}

export async function fetchRequisitionById(id: number): Promise<any> {
  const resp = await axios.get(`/requisition/${id}`);

  return resp.data;
}

export async function fetchCompanyById(id: number): Promise<any> {
  const resp = await axios.get(`/company/${id}`);

  return resp.data;
}

export async function fetchCompanyByTypeId(
  company_type_id: number,
): Promise<any> {
  const resp = await axios.get(`/company/filter`, {
    params: { company_type_id },
  });

  return resp.data;
}

export async function fetchDeliveryById(id: number): Promise<any> {
  const resp = await axios.get(`/delivery/${id}`);

  return resp.data;
}

export async function fetchUserById(id: number): Promise<any> {
  const resp = await axios.get(`/user/${id}`);

  return resp.data;
}

export async function fetchProductById(id: number): Promise<any> {
  const resp = await axios.get(`/product/${id}`);

  return resp.data;
}

export async function updateMistakeInvoice({ id, ...data }): Promise<any> {
  const resp = await axios.put(`/mistake/invoice/${id}`, data);

  return resp.data;
}

export async function updateMistakeDelivery({ id, ...data }): Promise<any> {
  const resp = await axios.put(`/mistake/delivery/${id}`, data);

  return resp.data;
}

export async function updateMistakeTransaction({ id, ...data }): Promise<any> {
  const resp = await axios.put(`/mistake/transaction/${id}`, data);

  return resp.data;
}

export async function fetchAllCompanies(): Promise<any> {
  const resp = await axios.get(`/company/`);

  return resp.data;
}

export async function createRelationBetweenCompanies(data: {
  super_company_id: number;
  company_id: number;
}): Promise<any> {
  return await axios.post('/company/company', JSON.stringify(data), {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function removeRelationBetweenCompanies(
  super_company_id: number,
  company_id: number,
) {
  return await axios.delete(
    '/company/' + super_company_id + '/company/' + company_id,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
}

export async function removeCompanyBasedOnId(company_id: number) {
  return await axios.delete('/company/' + company_id, {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function fetchProducts(company: 'all' | string): Promise<any> {
  let resp;

  if (company === 'all') {
    resp = await axios.get(`/product/`);
  } else {
    resp = await axios.get('/company/' + company + '/product/');
  }

  return resp.data;
}

export async function updateProductBasedOnId({
  id,
  ...data
}: ProductNoTypeCode): Promise<any> {
  const resp = await axios.put(`/product/${id}`, data);

  return resp.data;
}

export async function updateProductActiveState({ id, active }): Promise<any> {
  const resp = await axios.put(`/product/${id}`, { active });

  return resp.data;
}

export async function removeProductBasedOnCompanyIdAndProductId(data: {
  company_id: number;
  product_id: number;
}) {
  return await axios.delete(
    '/company/' + data.company_id + '/product/' + data.product_id,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  );
}

export async function addProductBasedOnObject(
  data: ProductNoIdAndTypeCode,
): Promise<any> {
  const resp = await axios.post(`/product/`, JSON.stringify(data), {
    headers: {
      'Content-type': 'application/json',
    },
  });

  return resp.data;
}

export async function addExpenseBasedOnObject(data: ExpensePOST): Promise<any> {
  const resp = await axios.post(`/expense`, JSON.stringify(data), {
    headers: {
      'Content-type': 'application/json',
    },
  });

  return resp.data;
}

export async function deleteExpenseBasedOnId(id: number) {
  return await axios.delete(`/expense/${id}`, {
    headers: {
      'Content-type': 'application/json',
    },
  });
}

export async function editExpenseBasedOnObject({ id, ...data }): Promise<any> {
  const resp = await axios.put(`/expense/${id}`, JSON.stringify(data), {
    headers: {
      'Content-type': 'application/json',
    },
  });

  return resp.data;
}

export async function updateQuantityOfProduct(
  data: ProductQuantityDTO,
): Promise<any> {
  const formattedRequest: StockRequest = {
    receiverCompanyId: data.company_id,
    senderCompanyId: data.company_id,
    deliveryId: null,
    productId: data.product_id,
    amount: Math.abs(data.amount),
    operationType: data.amount > 0 ? 'add' : 'remove',
  };

  const resp = await axios.put(`/stock/`, formattedRequest);

  return resp.data;
}

export async function updateCompanyDetails(data: {
  details: EditCompany;
  company_id: number;
}): Promise<any> {
  const resp = await axios.put(`/company/` + data.company_id, data.details);

  return resp.data;
}

export async function addNewCompany(data: NewCompany): Promise<any> {
  const resp = await axios.post('/company', JSON.stringify(data), {
    headers: {
      'Content-type': 'application/json',
    },
  });

  return resp.data;
}

export async function fetchMessagesPaginatedOnType(
  page: number,
  rowsPerPage: number,
  params: any,
  search?: string,
  startDate?: Date,
  endDate?: Date,
): Promise<any> {
  const resp = await axios.get(`/message/page/filter`, {
    params: {
      size: rowsPerPage,
      page,
      search,
      ...params,
      after: startDate,
      before: endDate,
    },
  });

  return resp.data;
}

export async function getAccountStatement(params): Promise<any> {
  const resp = await axios.get(`/statement`, { params: params });

  return resp.data;
}
