import { Reducer } from 'redux';
import { RequisitionActionTypes, RequisitionState } from './types';

export const initialState: RequisitionState = {
  requisitionsToAndFromCompany: [],
  requisitionsToAndFromCompanyNewest: [],
  loading: false,
  errors: undefined,

  loadingFromCompany: false,
  loadingToCompanyActive: false,
  loadingToCompanyArchive: false,
  requisitionsFromCompany: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
  requisitionsToCompanyActive: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
  requisitionsToCompanyArchive: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
};

const reducer: Reducer<RequisitionState> = (state = initialState, action) => {
  switch (action.type) {
    case RequisitionActionTypes.REQUISITION_GET_ALL_TOANDFROM_COMPANY: {
      return {
        ...state,
        loading: true,
      };
    }
    case RequisitionActionTypes.REQUISITION_GET_ALL_TOANDFROM_COMPANY_NEWEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case RequisitionActionTypes.REQUISITION_GET_ALL_FROM_COMP_WITHOUT_SHIP_PAGE: {
      return {
        ...state,
        loadingFromCompany: true,
      };
    }
    case RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ACTIVE_PAGE: {
      return {
        ...state,
        loadingToCompanyActive: true,
      };
    }
    case RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ARCHIVED_PAGE: {
      return {
        ...state,
        loadingToCompanyArchive: true,
      };
    }
    case RequisitionActionTypes.REQUISITION_CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RequisitionActionTypes.REQUISITION_SET_ARCHIVE_TYPE: {
      return {
        ...state,
        loading: true,
      };
    }
    case RequisitionActionTypes.REQUISITION_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    case RequisitionActionTypes.REQUISITION_SUCCESS: {
      return {
        ...state,
        requisitionsToAndFromCompany: action.payload,
        loading: false,
      };
    }
    case RequisitionActionTypes.REQUISITION_SUCCESS_NEWEST: {
      return {
        ...state,
        requisitionsToAndFromCompanyNewest: action.payload,
        loading: false,
      };
    }
    case RequisitionActionTypes.REQUISITION_GET_ALL_FROM_COMP_WITHOUT_SHIP_PAGE_SUCCESS: {
      return {
        ...state,
        requisitionsFromCompany: action.payload,
        loadingFromCompany: false,
      };
    }
    case RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS: {
      return {
        ...state,
        requisitionsToCompanyActive: action.payload,
        loadingToCompanyActive: false,
      };
    }
    case RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS: {
      return {
        ...state,
        requisitionsToCompanyArchive: action.payload,
        loadingToCompanyArchive: false,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as requisitionReducer };
