import React, { Component } from 'react';
import { store } from '../../../store/store';
import { logoutRequest } from '../../../store/user/userAuthentication';
import { Helmet } from 'react-helmet-async';

class Logout extends Component {
  componentDidMount() {
    store.dispatch(logoutRequest());
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Log ud</title>
        </Helmet>
        Log ud
      </div>
    );
  }
}

export default Logout;
