export interface ProductType {
  id: number;
  code: string;
}

export interface IProductTypes {
  productTypes: ProductType[];
}

export enum IProductTypeActionTypes {
  IPRODUCT_TYPE_GET_ALL = '@@instructor/product_type/GET_ALL',
  IPRODUCT_TYPE_GET_ADD = '@@instructor/product_type/ADD',
  IPRODUCT_TYPE_GET_EDIT = '@@instructor/product_type/EDIT',
  IPRODUCT_TYPE_GET_DELETE = '@@instructor/product_type/DELETE',
  IPRODUCT_TYPE_GET_SUCCESS = '@@instructor/product_type/SUCCESS',
  IPRODUCT_TYPE_GET_ERROR = '@@instructor/product_type/ERROR',
}

export interface IProductTypesState {
  readonly loading: boolean;
  readonly productTypes: ProductType[];
  readonly errors?: string;
}
