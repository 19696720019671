import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../../store/store';
import {
  convertBankAccountIdToName,
  convertCompanyIdToName,
  floatToDKFloatString,
} from '../../../../utils/convertions';
import { Company } from '../../../../store/instructor/company';
import { BankTransactionCreate } from '../../../../store/instructor/bank-transactions';
import { NewBankTransactionWithId } from '../bankContent';
import { filterBySimilarity } from '../../../../utils/filteringAlgorithms';
import { sortStringAlphabetically } from '../../../../utils/sortingAlgorithms';
import CustomTable from '../../../../components/table/customTable';
import { confirmAlert } from 'react-confirm-alert';
import { BankAccount } from '../../../../store/instructor/bank';
import { getNameOfBankAccountOwner } from '../../../../utils/transactionUtils';
import { User } from '../../../../store/instructor/users';

interface PropsFromParent {
  pendingTransactions: NewBankTransactionWithId[];
  allCompanies: Company[];
  removePendingTransaction: any;
  activeBankAccounts: BankAccount[];
  allBankAccounts: BankAccount[];
  allUsers: User[];
}

interface ThisCompState {
  tableData: NewBankTransactionWithId[];
  tableHeader: any[];
}

class ApproveTab extends React.Component<PropsFromParent, ThisCompState> {
  state = {
    tableData: [],
    tableHeader: [
      {
        title: 'Fra Konto',
        field: 'fromBankAccountId',
        editable: 'never',
        customFilterAndSearch: (term, rowData) =>
          filterBySimilarity(
            term,
            convertBankAccountIdToName(
              this.props.activeBankAccounts,
              rowData.fromBankAccountId,
            ),
          ),
        customSort: (a, b) =>
          sortStringAlphabetically(
            convertBankAccountIdToName(
              this.props.activeBankAccounts,
              a.fromBankAccountId,
            ),
            convertBankAccountIdToName(
              this.props.activeBankAccounts,
              b.fromBankAccountId,
            ),
          ),
        render: rowData => (
          <span>
            {convertBankAccountIdToName(
              this.props.activeBankAccounts,
              rowData.fromBankAccountId,
            )}
          </span>
        ),
      },
      {
        title: 'Til konto',
        field: 'toBankAccountId',
        editable: 'never',
        render: rowData => {
          if (rowData.identificationNumber !== null) {
            return <span>Ej relevant for indbetalingskort</span>;
          } else {
            return <span>{rowData.toBankAccountId}</span>;
          }
        },
      },
      {
        title: 'Til virksomhed',
        field: 'toBankAccountId',
        editable: 'never',
        customFilterAndSearch: (term, rowData) =>
          filterBySimilarity(
            term,
            rowData.slip_identificationNumber !== null
              ? convertCompanyIdToName(
                  this.props.allCompanies,
                  rowData.slip_creditorNumber,
                )
              : getNameOfBankAccountOwner(
                  rowData.toBankAccountId,
                  this.props.allBankAccounts,
                  this.props.allUsers,
                  this.props.allCompanies,
                ),
          ),
        customSort: (a, b) =>
          sortStringAlphabetically(
            a.slip_paymentTypeCode !== null
              ? convertCompanyIdToName(
                  this.props.allCompanies,
                  a.slip_creditorNumber,
                )
              : getNameOfBankAccountOwner(
                  a.toBankAccountId,
                  this.props.allBankAccounts,
                  this.props.allUsers,
                  this.props.allCompanies,
                ),
            b.slip_paymentTypeCode !== null
              ? convertCompanyIdToName(
                  this.props.allCompanies,
                  b.slip_creditorNumber,
                )
              : getNameOfBankAccountOwner(
                  b.toBankAccountId,
                  this.props.allBankAccounts,
                  this.props.allUsers,
                  this.props.allCompanies,
                ),
          ),
        render: rowData => (
          <span>
            {rowData.slip_paymentTypeCode &&
            rowData.slip_paymentTypeCode !== null
              ? convertCompanyIdToName(
                  this.props.allCompanies,
                  rowData.slip_creditorNumber,
                )
              : getNameOfBankAccountOwner(
                  rowData.toBankAccountId,
                  this.props.allBankAccounts,
                  this.props.allUsers,
                  this.props.allCompanies,
                )}
          </span>
        ),
      },
      {
        title: 'Beløb (DKK)',
        field: 'amount',
        editable: 'never',
        render: rowData => (
          <p className={'float-right'}>
            {floatToDKFloatString(rowData.amount)}
          </p>
        ),
      },
      {
        title: 'Status',
        field: '',
        editable: 'never',
        render: () => <span>Ej godkendt</span>,
      },
    ],
  };

  componentDidMount() {
    this.setStateTableData();
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.pendingTransactions !== this.props.pendingTransactions) {
      this.setStateTableData();
    }
  }

  setStateTableData = () => {
    this.setState({ tableData: this.props.pendingTransactions });
  };

  approveTransaction = (transaction: NewBankTransactionWithId) => {
    store.dispatch(BankTransactionCreate(transaction));
    this.props.removePendingTransaction(transaction);
  };

  cancelTransaction = (transaction: NewBankTransactionWithId) => {
    this.props.removePendingTransaction(transaction);
  };

  confirmCancelTransaction = (transaction: NewBankTransactionWithId) => {
    confirmAlert({
      title: 'Confirm cancel of transaction with id: ' + transaction.id + '?',
      message: 'Are you sure you want to cancel this transaction?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.cancelTransaction(transaction),
        },
        {
          label: 'No',
          onClick: () => void 0,
        },
      ],
    });
  };

  public render() {
    return (
      <div>
        <CustomTable
          data={this.state.tableData}
          headers={this.state.tableHeader}
          isLoading={false}
          actions={[
            {
              icon: 'check_box',
              tooltip: 'Approve transaction',
              onClick: (event, transaction) => {
                this.approveTransaction(transaction);
              },
            },
            {
              icon: 'cancel',
              tooltip: 'Cancel transaction',
              onClick: (event, transaction) => {
                this.confirmCancelTransaction(transaction);
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default connect()(ApproveTab);
