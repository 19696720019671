import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { BankAccount } from '../../../../../store/instructor/bank';
import {
  BankTransaction,
  BankTransactionGetAllToFromAccountPage,
} from '../../../../../store/instructor/bank-transactions';
import { convertBankTransactionToVisualTransactions } from '../../../../../utils/transactionUtils';
import { HomemadeDate } from '../../../../../utils/homemadeDate';
import { Company } from '../../../../../store/instructor/company';
import { filterBySimilarity } from '../../../../../utils/filteringAlgorithms';
import {
  convertCompanyIdToName,
  floatToDKFloatString,
} from '../../../../../utils/convertions';
import { sortStringAlphabetically } from '../../../../../utils/sortingAlgorithms';
import { User } from '../../../../../store/instructor/users';
import CustomTablePagination from '../../../../../components/table/customTablePagination';
import { store } from '../../../../../store/store';
import { Page } from '../../../../../utils/storeTypes';
import DatepickerTableTitle from '../../../../../components/table/title/datepickerTitle';
import DatePicker from 'react-datepicker';
import LoadingComponent from '../../../../../components/LoadingComponent';

export interface VisualTransaction {
  date: HomemadeDate;
  dateString: string;
  fromCompanyId: number;
  text: string;
  amount: number; //The balance change of the affected account (-/+amount)
  balanceAfterTransaction: number;
}

interface PropsFromParent {
  loading: boolean;
  bankAccount: BankAccount;
  allBankAccounts: BankAccount[];
  allCompanies: Company[];
  allUsers: User[];

  bankTransactionsToFromAccountPages: Map<number, Page>;
  loadingToFromAccountPage: boolean;
}

interface ComponentState {
  currentPageTransactions: BankTransaction[];
  totalElementAmount: number;
  pageSize: number;
  currentPage: number;
  //TODO-future: optimize: above four should be replaced with type Page

  startDate: Date | null;
  endDate: Date | null;
  searchQuery: string | undefined;
  tableData: VisualTransaction[];
  tableHeader: any[];
}

class AccountTab extends React.Component<PropsFromParent, ComponentState> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      searchQuery: undefined,
      currentPageTransactions: [],
      pageSize: 10,
      totalElementAmount: -1,
      currentPage: 0,
      tableData: [],
      tableHeader: [
        {
          title: 'Dato',
          field: 'dateString',
          editable: 'never',
          render: rowData => <span>{rowData.dateString.split(' ')[0]}</span>,
        },
        {
          title: 'Tidspunkt',
          field: 'dateString',
          editable: 'never',
          render: rowData => <span>{rowData.dateString.split(' ')[1]}</span>,
        },
        {
          title: 'Modtaget fra',
          field: 'fromCompanyId',
          editable: 'never',
          customFilterAndSearch: (term, rowData) =>
            filterBySimilarity(
              term,
              convertCompanyIdToName(
                this.props.allCompanies,
                rowData.fromCompanyId,
              ),
            ),
          customSort: (a, b) =>
            sortStringAlphabetically(
              convertCompanyIdToName(this.props.allCompanies, a.fromCompanyId),
              convertCompanyIdToName(this.props.allCompanies, b.fromCompanyId),
            ),
          render: rowData => (
            <span>
              {convertCompanyIdToName(
                this.props.allCompanies,
                rowData.fromCompanyId,
              )}
            </span>
          ),
        },
        { title: 'Tekst', field: 'text', editable: 'never' },
        {
          title: 'Beløb (DKK)',
          field: 'amount',
          editable: 'never',
          render: rowData => (
            <p className={'float-right'}>
              {floatToDKFloatString(rowData.amount)}
            </p>
          ),
        },
        {
          title: 'Saldo (DKK)',
          field: 'balanceAfterTransaction',
          editable: 'never',
          render: rowData => (
            <p className={'float-right'}>
              {floatToDKFloatString(rowData.balanceAfterTransaction)}
            </p>
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchPage();
  }

  componentDidUpdate(
    prevProps: Readonly<PropsFromParent>,
    prevState: Readonly<ComponentState>,
  ) {
    const page:
      | Page
      | undefined = this.props.bankTransactionsToFromAccountPages.get(
      this.props.bankAccount.id,
    );
    if (page?.items !== prevState.currentPageTransactions) {
      this.getPageAndSetState();
    }

    if (
      prevState.currentPageTransactions !== this.state.currentPageTransactions
    ) {
      this.updateTableDataCurrentPage();
    }
  }

  getPageAndSetState = () => {
    const page:
      | Page
      | undefined = this.props.bankTransactionsToFromAccountPages.get(
      this.props.bankAccount.id,
    );
    if (page !== undefined) {
      let array: BankTransaction[] = page.items;
      this.setState({ currentPageTransactions: array });
      this.setState({ totalElementAmount: page.total_items });
      this.updateTableDataCurrentPage();
    }
  };

  updateTableDataCurrentPage() {
    const convertedTransactions: VisualTransaction[] = [];
    this.state.currentPageTransactions.forEach(elem => {
      convertedTransactions.push(
        convertBankTransactionToVisualTransactions(
          this.props.bankAccount.id,
          elem,
          this.props.allBankAccounts,
          this.props.allCompanies,
          this.props.allUsers,
        ),
      );
    });

    this.setState({
      tableData: convertedTransactions,
    });
  }

  onChangePage = (targetPageNumber: number, pageSize: number): void => {
    this.setState({ currentPage: targetPageNumber }, () => {
      this.fetchPage();
    });
  };

  onChangeRowPerPage = (newRowsPerPage: number) => {
    this.setState({ pageSize: newRowsPerPage }, () => {
      this.fetchPage();
    });
  };

  fetchPage = () => {
    store.dispatch(
      BankTransactionGetAllToFromAccountPage(
        this.props.bankAccount.id,
        this.state.currentPage,
        this.state.pageSize,
        'createdAt',
        true,
        this.state.searchQuery,
        this.state.startDate,
        this.state.endDate,
      ),
    );
  };

  onSearchChange = (query: string) => {
    this.setState(
      {
        searchQuery: query,
        currentPage: 0,
      },
      () => {
        this.fetchPage();
      },
    );
  };

  renderTable = () => {
    return (
      <CustomTablePagination
        titleComponent={
          <DatepickerTableTitle
            datepicker={
              <DatePicker
                selectsRange={true}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onCalendarClose={() => this.fetchPage()}
                onChange={dates => {
                  if (dates) {
                    if (Array.isArray(dates)) {
                      if (dates.every(ele => ele == null)) {
                        this.setState(
                          {
                            startDate: null,
                            endDate: null,
                          },
                          () => {
                            this.fetchPage();
                          },
                        );
                      }
                    }
                    this.setState({
                      startDate: dates[0],
                      endDate: dates[1],
                    });
                  }
                }}
                isClearable={true}
              />
            }
          />
        }
        onSearchChange={this.onSearchChange}
        data={this.state.tableData}
        headers={this.state.tableHeader}
        actions={[]}
        onChangePageFunc={this.onChangePage}
        totalElementAmount={this.state.totalElementAmount}
        numberRowPerPage={this.state.pageSize}
        pageDataLoading={this.props.loadingToFromAccountPage}
        onChangeRowsPerPageFunc={this.onChangeRowPerPage}
        pageNumber={this.state.currentPage}
      />
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          <>
            {this.state.tableData.length === 0 &&
              (this.state.searchQuery === undefined ||
                this.state.searchQuery === '') && (
                <button
                  className={'btn btn-info mb-1'}
                  onClick={() => {
                    this.updateTableDataCurrentPage();
                  }}
                >
                  Genindlæs tabel data
                </button>
              )}
            {this.renderTable()}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iCompany,
  iUsers,
  iBankAccount,
  iBankTransactions,
}: ApplicationState) => ({
  loading:
    iBankAccount.loading ||
    iBankTransactions.loadingToFromAccount ||
    iCompany.loading ||
    iUsers.loading,
  allCompanies: iCompany.companies,
  allUsers: iUsers.allUsers,
  allBankAccounts: iBankAccount.bankAccounts,
  errors: iBankAccount.errors,
  bankTransactionsToFromAccountPages:
    iBankTransactions.bankTransactionsToFromAccountPages,
  loadingToFromAccountPage: iBankTransactions.loadingToFromAccountPage,
});

export default connect(mapStateToProps)(AccountTab);
