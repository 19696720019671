import React, { Component } from 'react';
import CustomTable from '../../../../../../components/table/customTable';
import update from 'immutability-helper';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { Product } from '../../../../../../store/instructor/product';
import { sortStringAlphabetically } from '../../../../../../utils/sortingAlgorithms';
import { FormGroup, Input, Label } from 'reactstrap';
import { Company } from '../../../../../../store/instructor/company';

interface PropsFromParent {
  debtorCompanies: Company[];
  products: any;
  editProducts: any;
  loading: boolean;
  setToCompany: any;
  setFromCompany: any;
  fromCompany: any;
  requisitionId: any;
  setRequisitionId: any;
  companyProducts: Product[];
  isInstructor?: boolean;
}

/* TODO: Make TableData be product[]*/
interface ThisCompState {
  tableHeader: any[];
  tableData: any[];
}

class ProductsTable extends Component<PropsFromParent, ThisCompState> {
  constructor(props) {
    super(props);
    this.state = {
      tableHeader: [
        { title: 'Vare nr.', field: 'navision_id', editable: 'never' },
        { title: 'Navn', field: 'name', editable: 'never' },
        {
          title: 'Pris',
          field: 'price_unit',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
        { title: 'Antal', field: 'quantity', emptyValue: 'Antal' },
      ],
      tableData: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.companyProducts !== this.props.companyProducts) {
      this.setState({
        tableData: this.props.companyProducts,
      });
    }
  }

  componentDidMount() {
    this.setState({ tableData: this.props.companyProducts });
  }

  onEditSelectionApproved(rowData, newValue) {
    let newData = this.state.tableData;

    let rowIndex = newData.findIndex(({ id }) => id === rowData.id);

    newData = update(newData, {
      [rowIndex]: { quantity: { $set: newValue } },
    });

    this.setState({ tableData: newData });
  }

  onChangeSupplier(e) {
    this.props.setToCompany(e.target.value);
  }

  onChangeSender(e) {
    this.props.setFromCompany(e.target.value);
  }

  onChangeRequisitionId(e) {
    if (e.target.value !== '') {
      //TODO allow comma
      const re = /^[0-9\b]+$/; //No comma allowed. Api defines these as int
      if (re.test(e.target.value)) {
        this.props.setRequisitionId(e.target.value);
      }
    } else {
      this.props.setRequisitionId(e.target.value);
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          {this.props.isInstructor ? (
            <div className="col">
              <label>Vælg afsender</label>
              <select
                className="form-control"
                onChange={e => this.onChangeSender(e)}
                defaultValue={-1}
              >
                <option value={-1}>Vælg ...</option>
                {this.props.debtorCompanies
                  .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                  .map((company, index) => {
                    return (
                      <option key={index} value={company.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          ) : (
            ''
          )}
          <div className="col">
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0" inline>
              <Label className="mr-sm-2">Rekvisition nr.:</Label>
              <Input
                name="shippingCost"
                placeholder="Indtast rekvisition nummer"
                onChange={e => this.onChangeRequisitionId(e)}
              />
            </FormGroup>
            <label>Vælg Kunde</label>
            <select
              className="form-control"
              onChange={e => this.onChangeSupplier(e)}
              defaultValue={-1}
            >
              <option value={-1}>Vælg ...</option>
              {this.props.isInstructor
                ? this.props.debtorCompanies
                    .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                    .map((company, index) => {
                      return (
                        <option key={index} value={company.id}>
                          {company.name}
                        </option>
                      );
                    })
                : this.props.debtorCompanies //TODO-future: filter to make sure all sub companies are NORMAL and not SUPER
                    .sort((a, b) => sortStringAlphabetically(a.name, b.name))
                    .map((company, index) => {
                      return (
                        <option key={index} value={company.id}>
                          {company.name}
                        </option>
                      );
                    })}
            </select>
          </div>
        </div>
        <CustomTable
          isLoading={this.props.loading}
          data={this.state.tableData}
          headers={this.state.tableHeader}
          selection={true}
          editFunction={(rowData, newValue) =>
            this.onEditSelectionApproved(rowData, newValue)
          }
          actions={[
            {
              icon: 'add_shopping_cart',
              tooltip: 'Tilføj til kurv',
              onClick: (event, selectedRows) => {
                this.props.editProducts(selectedRows);
              },
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ iCompany }: ApplicationState) => ({
  loading: iCompany.loading,
});

export default connect(mapStateToProps)(ProductsTable);
