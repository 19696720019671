import React from 'react';
import { Company } from '../../../../store/instructor/company';
import { Requisition } from '../../../../store/instructor/requisitions';
import { Col, Row } from 'react-bootstrap';

interface PropsFromParent {
  to_company: Company;
  from_company: Company;
  requisition: Requisition;
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

const RequisitionDetails: React.FC<PropsFromParent> = ({
  to_company,
  from_company,
  requisition,
}) => {
  return (
    <>
      <Row>
        <Col>
          <h4>Afsender</h4>
          <p className={'mb-0'}>{from_company.name}</p>
          <p>{from_company.address}</p>
        </Col>
        <Col>
          <h4>Modtager</h4>
          <p className={'mb-0'}>{to_company.name}</p>
          <p>{to_company.address}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Rekvisitions detaljer</h4>
          <p>Internt ID: {requisition.requisition_id}</p>
          <p>Navision ID: {requisition.navision_id}</p>
          <p>
            Dato: {requisition.requisition_date.dayOfMonth}/
            {requisition.requisition_date.monthValue}/
            {requisition.requisition_date.year}
          </p>
          <p>Beløb: {formatter.format(requisition.requisition_amount)}</p>
          <p>Betalt: {requisition.paid ? 'Ja' : 'Nej'}</p>
          <p>
            Arkiveret: {requisition.archive_type === 'processed' ? 'Ja' : 'Nej'}
          </p>
          <p>Produkter:</p>
          {requisition.items.map((item, key) => {
            return (
              <p>
                ID: {item.navision_id} | Antal: {item.product_quantity} | Pris:{' '}
                {formatter.format(item.cost)}
              </p>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

export default RequisitionDetails;
