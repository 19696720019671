import {
  RobotDispatchTypes,
  useRobotDispatchContext,
} from '../contexts/RobotContexts';

export function useRobot() {
  const dispatch = useRobotDispatchContext();

  function push(message) {
    const id = Math.random().toString(36).substr(2, 9);

    dispatch({
      type: RobotDispatchTypes.ADD_MESSAGE,
      robotMessage: {
        body: message,
        id,
      },
    });
  }

  return push;
}
