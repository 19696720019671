import { Reducer } from 'redux';
import { IOrderGenActionTypes, IOrderGenState } from './types';

// Type-safe initialState!
export const initialState: IOrderGenState = {
  orderGens: {
    orderGens: [],
  },
  loading: false,
  errors: undefined,
};

const reducer: Reducer<IOrderGenState> = (state = initialState, action) => {
  switch (action.type) {
    case IOrderGenActionTypes.IORDERGEN_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IOrderGenActionTypes.IORDERGEN_EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case IOrderGenActionTypes.IORDERGEN_SET_IS_ACTIVE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IOrderGenActionTypes.IORDERGEN_SUCCESS: {
      return {
        ...state,
        orderGens: action.payload,
        loading: false,
      };
    }
    case IOrderGenActionTypes.IORDERGEN_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iOrderGenReducer };
