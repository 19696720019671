import React from 'react';
import NewMsgTab from '../../../pages/student-pages/inbox/newMsgTab';
import { MistakeContext } from '../../../store/contexts/MistakeProvider';

export const SendMessage: React.FC = () => {
  const { selectedDetails } = React.useContext(MistakeContext);
  const [messageStatus, setMessageStatus] = React.useState('');

  const retrieveProperId = () => {
    switch (selectedDetails?.type) {
      case 'Faktura':
        return {
          to: selectedDetails?.details.company_to,
          from: selectedDetails?.details.company_from,
        };
      case 'Rekvisition':
      case 'Levering':
        return {
          to: selectedDetails?.details.to_company_id,
          from: selectedDetails?.details.from_company_id,
        };
      default:
        return {
          to: -1,
          from: -1,
        };
    }
  };

  return (
    <>
      <NewMsgTab
        customFromId={retrieveProperId().to}
        customRecipientId={retrieveProperId().from}
        setIndicator={setMessageStatus}
        msgReceivers={[]}
      />
      <p>{messageStatus}</p>
    </>
  );
};

export default SendMessage;
