import React from 'react';
import { Expense } from '../../../../types/expenses';

interface PropsFromParent {
  expense: Expense;
}

const ExpenseDetailPanel: React.FC<PropsFromParent> = ({ expense }) => {
  return (
    <div className={'p-2'}>
      <p>
        Denne funktionalitet vil blive tilgængelig i fremtiden. Herinde vil i
        kunne se detaljer om specifikke faste omkostninger.
      </p>
    </div>
  );
};

export default ExpenseDetailPanel;
