import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { Company, companyGetAll } from '../../../store/instructor/company';
import { store } from '../../../store/store';
import {
  BankAccount,
  bankAccountGetCompanyAccounts,
} from '../../../store/instructor/bank';
import { getAccountStatement } from '../../../utils/api';
import { ListedStatementDTO } from '../../../../types/StatementDTO';
import { ModalContext } from '../../../contexts/modalContexts';
import PDFStatement from '../../../components/pdfgenerator/pdfStatement';
import { AxiosError } from 'axios';
import { axiosErrorToString } from '../../../utils/convertions';
import LoadingComponent from '../../../components/LoadingComponent';

type PropsFromParent = {
  companies: Company[];
  loading: boolean;
  userSuperCompanyId: number;
  userCompanyBankAccounts: BankAccount[];
};

export const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

const BankStatementContent: React.FC<PropsFromParent> = ({
  companies,
  loading: propsLoading,
  userSuperCompanyId,
  userCompanyBankAccounts,
}) => {
  const { handleModal } = React.useContext(ModalContext);

  /* TODO-future: Temporary: We should only retrieve required companies => companies_relation_to_super_company_id
        ! Current implementation uses filter on list */
  useEffect(() => {
    store.dispatch(companyGetAll());
    store.dispatch(bankAccountGetCompanyAccounts(userSuperCompanyId));
  }, []);

  const tempDate = new Date();
  tempDate.setFullYear(tempDate.getFullYear() - 1);

  const [fromDate, setFromDate] = React.useState<Date | null>(tempDate);
  const [toDate, setToDate] = React.useState<Date | null>(new Date());
  const [targetCompany, setTargetCompany] = React.useState<string>('none');
  const [checked, setChecked] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const [response, setResponse] = React.useState<ListedStatementDTO | null>(
    null,
  );

  const [isProcessing, setProcessing] = React.useState(false);

  const onSubmit = () => {
    setProcessing(true);
    setResponse(null);
    setError(null);

    getAccountStatement({
      super_company_id: userSuperCompanyId,
      before: toDate,
      after: fromDate,
      ascending: checked,
      ...(targetCompany && { desired_company_id: targetCompany }),
    })
      .then(res => {
        setResponse(res);
        setProcessing(false);
      })
      .catch((err: AxiosError) => {
        setError(axiosErrorToString(err));
        setProcessing(false);
      });
  };

  if (propsLoading) {
    return <LoadingComponent visible={isProcessing} />;
  }

  return (
    <div>
      <p className={'text muted'}>
        På denne side kan du oprette et kontoudtog for specifikke virksomheder i
        bestemte interval.
      </p>

      <div>
        <div className="p-2 d-flex">
          <p className={'m-0 mr-4'}>Oprettelse for: </p>
          <select
            onChange={e => {
              setTargetCompany(e.target.value);
            }}
            value={targetCompany}
          >
            <option value={'none'} disabled={true}>
              Alle virksomheder
            </option>
            {companies
              .filter(cp => cp.relations?.includes(userSuperCompanyId))
              .map((company, key) => {
                return (
                  <option key={key} value={company.id}>
                    {company.name}
                  </option>
                );
              })}
          </select>
        </div>

        <div className="p-2">
          <p className={'m-0 mr-4'}>Fra: </p>
          <DatePicker
            className={'w-auto'}
            selected={fromDate}
            onChange={(date: Date | null) => setFromDate(date)}
          />
          <p className={'m-0 mr-4'}>Til: </p>
          <DatePicker
            className={'w-auto'}
            selected={toDate}
            onChange={(date: Date | null) => setToDate(date)}
          />
        </div>

        <label className={'p-2'}>
          <input
            className={'mr-1'}
            type="checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
          />
          Sorter efter ældste først
        </label>
      </div>

      <div>
        <p className={'m-0 mt-4'}>
          <small>Bemærk at oprettelsen kan vare op til et par minutter </small>
        </p>
        <button
          onClick={onSubmit}
          className={'btn btn-success'}
          disabled={isProcessing}
        >
          Opret kontoudtog
        </button>
      </div>

      <div className={'d-flex justify-content-center'}>
        <LoadingComponent visible={isProcessing} />
      </div>

      {error && (
        <div className="alert alert-danger mt-2" role="alert">
          {error}
        </div>
      )}

      {response && (
        <button
          className={'btn btn-primary mt-3'}
          onClick={() => {
            handleModal(
              'Kontoudtog',
              <>
                <PDFStatement companies={companies} data={response} />
              </>,
              80,
            );
          }}
        >
          Se kontoudtog
        </button>
      )}
    </div>
  );
};

const mapStateToProps = ({
  iCompany,
  userInfo,
  iBankAccount,
}: ApplicationState) => ({
  loading: iCompany.loading,
  companies: iCompany.companies,
  userSuperCompanyId: userInfo.userSuperCompanyId,
  userCompanyBankAccounts: iBankAccount.companyBankAccounts,
});

export default connect(mapStateToProps)(BankStatementContent);
