import React from 'react';

type ModalContent = React.ReactElement | undefined;

export interface ModalContextState {
  modal: boolean;
  modalContent: ModalContent;
  title: string;
  width?: number;
  handleModal: (
    title: string,
    content: ModalContent,
    width?: number | undefined,
  ) => void;
  showModal: (bool?: boolean) => void;
}

export function useModal() {
  let [modal, setModal] = React.useState<boolean>(false);
  let [modalContent, setModalContent] = React.useState<ModalContent>(undefined);
  let [title, setTitle] = React.useState<string>('');
  let [width, setWidth] = React.useState<number | undefined>(undefined);

  let handleModal = (title, content: ModalContent, width?: number) => {
    setModal(!modal);
    setTitle(title);
    setWidth(width);

    if (content) {
      setModalContent(content);
    }
  };

  let showModal = (bool?: boolean) => {
    bool ? setModal(bool) : setModal(!modal);
  };

  return { modal, handleModal, modalContent, title, width, showModal };
}
