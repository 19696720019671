import React from 'react';
import { ExpensesSelectorType } from '../../../pages/instructor-pages/expenses/expensesContent';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ModalContext } from '../../../contexts/modalContexts';
import { addExpenseBasedOnObject, fetchProducts } from '../../../utils/api';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { Field, Form as FormikForm, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import {
  ExpensePOST,
  ExpensePOSTWithHour,
  ExpenseSendTypes,
} from '../../../../types/expenses';
import { Company, CompanyTypes } from '../../../store/instructor/company';
import { Product } from '../../../store/instructor/product';
import { AxiosError } from 'axios';
import ExpenseSendFrequency from './expenseSendFrequency';
import update from 'immutability-helper';
import { formatter } from '../../../utils/convertions';

interface PropsFromParent {
  selectedCompany: ExpensesSelectorType;
}

const CreateNewExpenseModal: React.FC<PropsFromParent> = ({
  selectedCompany,
}) => {
  const queryClient = useQueryClient();
  const { showModal } = React.useContext(ModalContext);
  const [selectedType, setSelectedType] = React.useState<number>(1);
  const [selectedExpenseCompany, setSelectedExpenseCompany] = React.useState<
    string | undefined
  >(undefined);

  const { data: products } = useQuery<Product[], AxiosError>(
    ['products', selectedExpenseCompany],
    () => fetchProducts(selectedExpenseCompany!),
    {
      enabled: !!selectedExpenseCompany,
    },
  );

  const { mutate: addExpense } = useMutation(addExpenseBasedOnObject, {
    onSuccess: () => {
      queryClient.refetchQueries('expenses');
    },
  });

  const addExpenseRequirementsSchema: SchemaOf<ExpensePOST> = Yup.object().shape(
    {
      sender_id: Yup.number().defined().integer().positive(),
      receiver_id: Yup.number().defined().integer().positive(),
      product_id: Yup.number().defined().integer().positive(),
      amount: Yup.number().defined().integer().positive(),
    },
  );

  function cleanOutputData(data) {
    for (var propName in data) {
      if (data[propName] === null || data[propName] === undefined) {
        delete data[propName];
      }
    }

    return data;
  }

  function onAddButtonClick(values: ExpensePOSTWithHour) {
    if (values?.hour_of_the_day) {
      const formattedHour =
        values.hour_of_the_day.getHours() === 0
          ? 24
          : values.hour_of_the_day.getHours();

      const newData = update(values, {
        hour_of_the_day: { $set: formattedHour }, // +1 because getHours is 0-23 but we want 1-24
      });

      addExpense(cleanOutputData(newData));
    } else {
      addExpense(cleanOutputData(values));
    }
    showModal();
  }

  return (
    <Formik
      initialValues={{
        sender_id: -1,
        receiver_id: selectedCompany === 'all' ? -1 : Number(selectedCompany),
        product_id: -1,
        amount: undefined,
        day_of_the_week: null,
        hour_of_the_day: null,
        day_of_the_month: null,
      }}
      validationSchema={addExpenseRequirementsSchema}
      validateOnChange={false}
      onSubmit={(values: ExpensePOST) => {
        onAddButtonClick(values);
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue }) => (
        <FormikForm>
          <p className="h5">Udgift detaljer</p>
          <div className="form-group">
            <label>Modtager</label>

            <Field as="select" name="receiver_id" className="form-control">
              <option value="-1" disabled>
                Vælg en virksomhed
              </option>
              {queryClient
                .getQueryData<Company[]>([
                  'company',
                  { type: CompanyTypes['Super virksomhed'] },
                ])
                ?.map(company => (
                  <option
                    key={company.id}
                    label={company.name}
                    value={company.id}
                  />
                ))}
            </Field>

            {errors.receiver_id && touched.receiver_id && (
              <small className="form-text text-danger">
                {errors.receiver_id}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Afsender</label>
            <Field
              name="sender_id"
              className="form-control"
              as="select"
              onChange={e => {
                handleChange(e);
                setFieldValue('product_id', -1);
                setSelectedExpenseCompany(e.target.value);
              }}
            >
              <option value="-1" disabled>
                Vælg en virksomhed
              </option>
              {queryClient
                .getQueryData<Company[]>([
                  'company',
                  { type: CompanyTypes.Omkostninger },
                ])
                ?.map(company => (
                  <option
                    key={company.id}
                    label={company.name}
                    value={company.id}
                  />
                ))}
            </Field>

            {errors.sender_id && touched.sender_id && (
              <small className="form-text text-danger">
                {errors.sender_id}
              </small>
            )}
          </div>

          <div className="form-group">
            <label>Produkt</label>

            <Field
              as="select"
              name="product_id"
              className="form-control"
              disabled={!products || products.length < 1}
            >
              <option value="-1" disabled>
                Vælg et produkt
              </option>
              {products?.map(product => (
                <option
                  key={product.id}
                  label={
                    product.name +
                    ' (' +
                    formatter.format(product.price_cost) +
                    ')'
                  }
                  value={product.id}
                />
              ))}
            </Field>

            {products && products.length < 1 && (
              <small className="form-text text-danger">
                Den valgte virksomhed har ingen produkter associeret
              </small>
            )}

            {errors.product_id && touched.product_id && (
              <small className="form-text text-danger">
                {errors.product_id}
              </small>
            )}
          </div>

          <div className="form-group">
            <label>Antallet af produkter</label>
            <Field
              name="amount"
              placeholder="Antal"
              className="form-control"
              autoComplete="off"
            />
            {errors.amount && touched.amount && (
              <small className="form-text text-danger">{errors.amount}</small>
            )}
            <small className="text-muted">
              Den samlede pris for omkostningen vil være:{' '}
              <span className={'font-weight-bold'}>
                {formatter.format(
                  (products?.find(p => p.id === Number(values.product_id))
                    ?.price_cost || 0) * Number(values.amount),
                )}
              </span>
            </small>
          </div>

          <div className="form-group">
            <label>Sende frekvens</label>

            <select
              className="form-control"
              value={selectedType}
              onChange={event => {
                setFieldValue('day_of_the_week', null);
                setFieldValue('hour_of_the_day', null);
                setFieldValue('day_of_the_month', null);
                setSelectedType(Number(event.target.value));
              }}
            >
              {Object.keys(ExpenseSendTypes)
                .filter(k => isNaN(Number(k)))
                .map(enumKey => (
                  <option
                    key={ExpenseSendTypes[enumKey]}
                    label={enumKey}
                    value={ExpenseSendTypes[enumKey]}
                  />
                ))}
            </select>

            <ExpenseSendFrequency selectedSendTypeId={selectedType} />
          </div>

          <Modal.Footer>
            <Button type={'submit'} variant="success">
              Gem ændringer
            </Button>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default CreateNewExpenseModal;
