import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import { Form as FormReact } from 'react-bootstrap';
import { store } from '../../../store/store';
import {
  BankAccount,
  bankAccountGetCompanyAccounts,
} from '../../../store/instructor/bank';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';

type SetRequisitionInStepParent = (
  payment_receiving_bank_account_id: number,
  navision_id: number,
) => void;

interface PropsFromState {
  setInvoiceInStepParent: SetRequisitionInStepParent;
  loading: boolean;
  allBankAccounts: BankAccount[];
  userCompanyId: number;
}

interface ThisCompState {
  companyBankAccounts: BankAccount[];
  payment_receiving_bank_account_id: number;
  navision_id: number;
  errors: {
    exist: boolean;
    navision_id: string;
    payment_receiving_bank_account_id: string;
  };
  indicator: string;
}

class InvoiceCreation extends Component<PropsFromState, ThisCompState> {
  constructor(props: PropsFromState) {
    super(props);
    this.state = {
      companyBankAccounts: [],
      payment_receiving_bank_account_id: -1,
      navision_id: -1,
      errors: {
        exist: false,
        navision_id: '',
        payment_receiving_bank_account_id: '',
      },
      indicator: '',
    };
  }

  componentDidMount() {
    store.dispatch(bankAccountGetCompanyAccounts(this.props.userCompanyId));
  }

  componentDidUpdate(prevProps: Readonly<PropsFromState>) {
    if (prevProps.allBankAccounts !== this.props.allBankAccounts) {
      this.setState({
        companyBankAccounts: this.props.allBankAccounts.filter(
          elem => elem.companyId === this.props.userCompanyId,
        ),
      });
    }
  }

  onChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  setErrorState(data) {
    this.setState({
      errors: {
        exist: data.isError,
        navision_id: data.navision_id,
        payment_receiving_bank_account_id:
          data.payment_receiving_bank_account_id,
      },
    });
  }

  validateData() {
    let re1 = /^[1-9]\d*$/; // No commas
    let errors = {
      isError: false,
      payment_receiving_bank_account_id: '',
      navision_id: '',
    };

    if (
      !re1.test(String(this.state.payment_receiving_bank_account_id)) ||
      this.state.payment_receiving_bank_account_id < 0
    ) {
      errors.payment_receiving_bank_account_id =
        'En bank konto kan ikke være mindre end 0';
      errors.isError = true;
    } else if (!re1.test(String(this.state.navision_id))) {
      errors.navision_id = 'Navision id skal være højere end 0';
      errors.isError = true;
    } else {
      errors.isError = false;
    }

    this.setErrorState(errors);

    return errors.isError;
  }

  setIndicator = (msg: string) => {
    this.setState({
      indicator: msg,
    });
  };

  onCreateClicked = e => {
    e.preventDefault();

    const errors = this.validateData();

    if (!errors) {
      this.props.setInvoiceInStepParent(
        this.state.payment_receiving_bank_account_id,
        this.state.navision_id,
      );
      this.setIndicator('Sucessfully created invoice');
    }
  };

  render() {
    return (
      <>
        <Form inline>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label className="mr-sm-2">Navision id:</Label>
            <Input
              min={1}
              name="navision_id"
              type="number"
              placeholder="Indtast navision id."
              onChange={this.onChange}
            />
          </FormGroup>
          <Label className="mr-sm-2">Til bank nr.:</Label>
          <FormReact.Control
            as="select"
            defaultValue="Vælg.."
            name={'payment_receiving_bank_account_id'}
            value={this.state.payment_receiving_bank_account_id}
            onChange={this.onChange}
          >
            <option value={-1}>Vælg..</option>
            {this.state.companyBankAccounts.map(
              (bankAccount: BankAccount, index: number) => {
                return (
                  <option key={index} value={bankAccount.id}>
                    {bankAccount.name}
                  </option>
                );
              },
            )}
          </FormReact.Control>
          <Button color={'success'} onClick={this.onCreateClicked}>
            Opret
          </Button>
        </Form>
        {this.state.indicator ? (
          <div className="alert alert-success" role="alert">
            {this.state.indicator}
          </div>
        ) : (
          ''
        )}

        {this.state.errors.exist ? (
          <>
            {this.state.errors.payment_receiving_bank_account_id ? (
              <Alert color="danger">
                {this.state.errors.payment_receiving_bank_account_id}
              </Alert>
            ) : (
              ''
            )}
            {this.state.errors.navision_id ? (
              <Alert color="danger">{this.state.errors.navision_id}</Alert>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = ({ iBankAccount, userInfo }: ApplicationState) => ({
  loading: iBankAccount.loading,
  allBankAccounts: iBankAccount.companyBankAccounts,
  userCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(InvoiceCreation);
