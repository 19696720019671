import { action } from 'typesafe-actions';
import { IUsersActionTypes, User, UserEdit, UserNew } from './types';

export const usersGetAll = () => action(IUsersActionTypes.IUSERS_GET_ALL);
export const usersGetAllInstructors = () =>
  action(IUsersActionTypes.IUSERS_GET_ALL_INSTRUCTORS);
export const usersGetAllStudents = () =>
  action(IUsersActionTypes.IUSERS_GET_ALL_STUDENTS);
export const usersEdit = (editedUser: UserEdit, userId: number) =>
  action(IUsersActionTypes.IUSERS_EDIT, { editedUser, userId });
export const usersDelete = (userId: number) =>
  action(IUsersActionTypes.IUSERS_DELETE, { userId });
export const usersAdd = (newUser: UserNew) =>
  action(IUsersActionTypes.IUSERS_ADD, { newUser });
export const usersSuccess = (data: User[]) =>
  action(IUsersActionTypes.IUSERS_SUCCESS, data);
export const usersSuccessInstructors = (data: User[]) =>
  action(IUsersActionTypes.IUSERS_GET_ALL_INSTRUCTORS, data);
export const usersSuccessStudents = (data: User[]) =>
  action(IUsersActionTypes.IUSERS_SUCCESS_STUDENTS, data);
export const usersError = (message: string) =>
  action(IUsersActionTypes.IUSERS_ERROR, message);
