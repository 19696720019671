import React, { useEffect } from 'react';
import { Invoice } from '../../store/instructor/orderInvoice';
import { Delivery } from '../../store/instructor/delivery';
import { Requisition } from '../../store/instructor/requisitions';
import {
  collectUniqueActionTypes,
  RenderAction,
} from '../../utils/mistakesUtils';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import {
  fetchUserById,
  updateMistakeDelivery,
  updateMistakeInvoice,
  updateMistakeTransaction,
} from '../../utils/api';
import { User } from '../../store/instructor/users';
import { convertHomemadeDateToISOFormat } from '../../utils/dateUtils';
import Moment from 'react-moment';
import { MistakeActionTypes } from '../../store/reducers/MistakesReducer';
import { MistakeProviderTypes } from '../../store/contexts/MistakeProvider';
import { MistakesTransaction } from '../../store/instructor/mistakes';

export interface Details
  extends Partial<Invoice>,
    Partial<Delivery>,
    Partial<Requisition>,
    Partial<MistakesTransaction> {
  handled?: boolean;
  handlingProcedure?: { id: number; handlingProcedure: string };
}

interface PropsFromParent {
  details?: Details;
  type: MistakeProviderTypes;
}

const MistakeRowDetails: React.FC<PropsFromParent> = ({ details, type }) => {
  const [isHandled, setIsHandled] = React.useState(
    details ? details?.handled : false,
  );

  useEffect(() => {
    setIsHandled(details ? details?.handled : false);
  }, [details]);

  const { data: user } = useQuery<User, AxiosError>(
    ['user', details?.created_by_id],
    () => fetchUserById(details!.created_by_id!),
    {
      enabled: !!details?.created_by_id,
    },
  );

  const {
    isLoading: mutateInvoiceLoading,
    mutate: mutateInvoice,
  } = useMutation(updateMistakeInvoice, {
    onSuccess: (data: Details) => {
      setIsHandled(data?.handled);
    },
  });

  const {
    isLoading: mutateDeliveryLoading,
    mutate: mutateDelivery,
  } = useMutation(updateMistakeDelivery, {
    onSuccess: data => {
      setIsHandled(data?.handled);
    },
  });

  const {
    isLoading: mutateTransactionLoading,
    mutate: mutateTransaction,
  } = useMutation(updateMistakeTransaction, {
    onSuccess: data => {
      setIsHandled(data?.handled);
    },
  });

  const retrieveProperId = () => {
    switch (type) {
      case 'Faktura':
        return details?.invoice_id;
      case 'Rekvisition':
        return details?.requisition_id;
      case 'Levering':
        return details?.delivery_id;
      case 'Transaktion':
        return details?.id;
    }
  };

  const handleOptionChange = data => {
    const id = retrieveProperId();

    switch (parseInt(data.target.value)) {
      case 0:
        if (type === 'Faktura') {
          mutateInvoice({ id: id, handled: false });
        } else if (type === 'Levering') {
          mutateDelivery({ id: id, handled: false });
        } else if (type === 'Transaktion') {
          mutateTransaction({ id: id, handled: false });
        }
        break;
      case 1:
        if (type === 'Faktura') {
          mutateInvoice({ id: id, handled: true });
        } else if (type === 'Levering') {
          mutateDelivery({ id: id, handled: true });
        } else if (type === 'Transaktion') {
          mutateTransaction({ id: id, handled: true });
        }
        break;
    }
  };

  const convertDateBasedOnType = () => {
    switch (type) {
      case 'Levering':
        return details?.delivery_date;
      case 'Rekvisition':
        return details?.requisition_date;
      case 'Faktura':
        return details?.invoice_date;
      case 'Transaktion':
        return details?.created_at;
    }
  };

  let dateISO: string = convertHomemadeDateToISOFormat(
    convertDateBasedOnType()!,
  );

  return (
    <>
      <tr>
        <td>
          {(mutateInvoiceLoading ||
            mutateDeliveryLoading ||
            mutateTransactionLoading) && <p>Gemmer..</p>}
          {!(
            mutateInvoiceLoading ||
            mutateDeliveryLoading ||
            mutateTransactionLoading
          ) && (
            <select
              className={'form-select form-select-sm'}
              onChange={handleOptionChange}
            >
              <option selected={!isHandled} value={0}>
                Ubehandlet
              </option>
              <option selected={isHandled} value={1}>
                Behandlet
              </option>
            </select>
          )}
        </td>
        <td>
          <Moment locale={'da'} format={'DD-MM-YYYY'}>
            {dateISO}
          </Moment>
        </td>
        <td>{type} Fejl</td>
        <td>
          {user?.first_name} {user?.last_name} ({user?.id})
        </td>
        <td>{details?.navision_id}</td>
        <td>
          <Link to={'/instructor/mistakes/invoices/' + details?.invoice_id}>
            {details?.invoice_id}
          </Link>
        </td>
        <td>
          <Link
            to={'/instructor/mistakes/requisitions/' + details?.requisition_id}
          >
            {details?.requisition_id}
          </Link>
        </td>
        <td>
          <Link to={'/instructor/mistakes/deliveries/' + details?.delivery_id}>
            {details?.delivery_id}
          </Link>
        </td>
        <td>
          {details?.errorMessages?.map((error, index) => {
            return (
              <p
                key={index}
                className={details?.handlingProcedure ? 'font-weight-bold' : ''}
              >
                {error.error}
              </p>
            );
          })}
        </td>
        <td className={'text-right font-weight-bold'}>
          {details?.errorMessages &&
            collectUniqueActionTypes(details.errorMessages).map(
              (action, index) => {
                return (
                  <div key={index}>
                    {RenderAction(action, { details, type })}
                  </div>
                );
              },
            )}

          {details && (
            <div>
              {RenderAction(MistakeActionTypes.MESSAGE, { details, type })}
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default MistakeRowDetails;
