export interface Navbar {
  isCollapsed: boolean;
}

export enum NavbarActionTypes {
  NAVBAR_TOGGLE_COLLAPSE = '@@navbar/collapse/TOGGLE',
}

export interface NavbarState {
  readonly navbar: Navbar;
}
