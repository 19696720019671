import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { Company, companyGetAll } from '../../../store/instructor/company';
import { BankAccount, bankAccountGetAll } from '../../../store/instructor/bank';
import * as React from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import CompanyTab from './companyTab';
import {
  BankTransaction,
  BankTransactionGetAll,
} from '../../../store/instructor/bank-transactions';
import { store } from '../../../store/store';
import { User, usersGetAll } from '../../../store/instructor/users';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  allCompanies: Company[];
  allBankAccounts: BankAccount[];
  allTransactions: BankTransaction[];
  allUsers: User[];
}

interface ThisCompState {
  selectedRobotCompany: Company;
  currentSelectedCompanyId: number;
}

class InstructorBankContent extends React.Component<
  PropsFromState,
  ThisCompState
> {
  state = {
    selectedRobotCompany: {
      name: '',
      id: -1,
      payment_terms: -1,
      address: '',
      delivery_time: -1,
      bank_accounts: [],
      company_type_id: -1,
      company_type: '',
    },
    currentSelectedCompanyId: -1,
  };

  componentDidMount() {
    store.dispatch(BankTransactionGetAll());
    store.dispatch(companyGetAll());
    store.dispatch(bankAccountGetAll());
    store.dispatch(usersGetAll());
  }

  renderCompanyTabs = (company: Company, index: number) => {
    const companyBankAccountsActive: BankAccount[] = this.props.allBankAccounts.filter(
      (item: BankAccount) => item.companyId === company.id && item.isActive,
    );
    return (
      <Tab key={index} eventKey={company.name} title={company.name}>
        <CompanyTab
          company={company}
          companyBankAccounts={companyBankAccountsActive}
          allBankAccounts={this.props.allBankAccounts}
          allCompanies={this.props.allCompanies}
          allTransactions={this.props.allTransactions}
          allUsers={this.props.allUsers}
        />
      </Tab>
    );
  };

  retrieveCompanyFromId = (id: number) => {
    return this.props.allCompanies.find(company => company.id === id);
  };

  onChange = e => {
    const selectedCompany: any = this.retrieveCompanyFromId(
      parseInt(e.target.value),
    );

    if (e.target.value !== -1) {
      this.setState({ selectedRobotCompany: selectedCompany });
    }

    this.setState({
      currentSelectedCompanyId: selectedCompany.id,
    });
  };

  renderContent = () => {
    const studentCompanies = this.props.allCompanies.filter(
      (elem: Company) => elem.company_type_id === 1,
    );
    const robotCompanies = this.props.allCompanies.filter(
      (elem: Company) => elem.company_type_id !== 1,
    );
    return (
      <Tabs defaultActiveKey={'studentCompanies'}>
        <Tab eventKey="studentCompanies" title="Elev virksomheder">
          <Tabs>{studentCompanies.map(this.renderCompanyTabs)}</Tabs>
        </Tab>
        <Tab eventKey="robotCompanies" title="Robot virksomheder">
          <Form>
            <Row>
              <Form.Label>Vælg virksomhed:</Form.Label>
              <Col sm="auto">
                <Form.Control
                  as="select"
                  defaultValue="Vælg.."
                  name={'currentSelectedCompanyId'}
                  value={this.state.currentSelectedCompanyId}
                  onChange={this.onChange}
                >
                  <option value={-1}>Vælg..</option>
                  {robotCompanies.map((elem, index) => {
                    return (
                      <option key={index} value={elem.id}>
                        {elem.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Row>
          </Form>
          {this.state.currentSelectedCompanyId !== -1 ? (
            <>
              {this.props.loading ? (
                <div className={'d-flex justify-content-center'}>
                  <LoadingComponent visible={this.props.loading} />
                </div>
              ) : (
                <Tabs>
                  {this.renderCompanyTabs(this.state.selectedRobotCompany, 0)}
                </Tabs>
              )}
            </>
          ) : null}
        </Tab>
      </Tabs>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iBankAccount,
  iCompany,
  iBankTransactions,
  iUsers,
}: ApplicationState) => ({
  loading: iBankAccount.loading || iUsers.loading,
  errors: iBankAccount.errors,
  allCompanies: iCompany.companies,
  allBankAccounts: iBankAccount.bankAccounts,
  allTransactions: iBankTransactions.bankTransactions,
  allUsers: iUsers.allUsers,
});

export default connect(mapStateToProps)(InstructorBankContent);
