import { useContext } from 'react';
import { ToastActionTypes, ToastContext } from '../contexts/ToastContext';

export function useToast() {
  const { dispatch } = useContext(ToastContext);

  function toast(type, message, header) {
    const delay = type === 'error' ? 30000 : 10000; // 30 sec hide delay for errors, 10 sec for everything else
    const id = Math.random().toString(36).substr(2, 9);

    dispatch({
      type: ToastActionTypes.ADD_TOAST,
      toast: {
        type,
        message,
        header,
        id,
      },
    });

    setTimeout(() => {
      dispatch({ type: ToastActionTypes.DELETE_TOAST, id });
    }, delay);
  }

  function deleteToast(id: number) {
    dispatch({ type: ToastActionTypes.DELETE_TOAST, id });
  }

  return { toast, deleteToast };
}
