import { ICompanyProductsActionTypes } from './types';
import { action } from 'typesafe-actions';
import { Product } from '../product';

export const companyProductsGetAll = (companyId: number) =>
  action(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_GET_ALL, { companyId });
export const companyProductsAdd = (companyId: number, productId: number) =>
  action(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_ADD, {
    companyId,
    productId,
  });
export const companyProductsDelete = (companyId: number, productId: number) =>
  action(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_DELETE, {
    companyId,
    productId,
  });
export const companyProductAddAmount = (
  receiverCompanyId: number,
  senderCompanyId: number,
  deliveryId: number,
  productId: number,
  amount: number,
  operationType: string,
) =>
  action(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_AMOUNT_ADD, {
    receiverCompanyId,
    senderCompanyId,
    deliveryId,
    productId,
    amount,
    operationType,
  });
export const companyProductRemoveAmount = (
  receiverCompanyId: number,
  senderCompanyId: number,
  deliveryId: number,
  productId: number,
  amount: number,
  operationType: string,
) =>
  action(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_AMOUNT_REMOVE, {
    receiverCompanyId,
    senderCompanyId,
    deliveryId,
    productId,
    amount,
    operationType,
  });
export const companyProductsSuccess = (
  companyId: number,
  productsArray: Product[],
) =>
  action(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_SUCCESS, {
    companyId,
    productsArray,
  });
export const companyProductsError = (message: string) =>
  action(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_ERROR, message);
