import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Company, CompanyTypes } from '../../../store/instructor/company';
import EditCompanyModalData from './editCompanyModalData';
import AddCompanyModalData from './addCompanyModalData';
import CustomTable from '../../../components/table/customTable';
import { filterBySimilarity } from '../../../utils/filteringAlgorithms';
import { ModalContext } from '../../../contexts/modalContexts';
import AddRelationToCompanyModalContent, {
  CompanyWithRelation,
} from '../../../components/modal/companyRelations/addRelationToCompanyModalContent';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { fetchAllCompanies, removeCompanyBasedOnId } from '../../../utils/api';
import SuperCompanyRelationOverview from '../../../components/modal/companyRelations/superCompanyRelationOverview';
import { confirmAlert } from 'react-confirm-alert';
import LoadingComponent from '../../../components/LoadingComponent';

const CompaniesContent: React.FC = props => {
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = React.useState(false);

  const { isLoading: allCompaniesLoading } = useQuery<
    CompanyWithRelation[],
    AxiosError
  >('allCompanies', fetchAllCompanies, {
    onSuccess: data => {
      setTableData(data);
    },
  });

  const { mutate: removeCompany } = useMutation(removeCompanyBasedOnId, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: data => {
      queryClient.refetchQueries('allCompanies').then(() => {
        setLoading(false);
      });
    },
  });

  let { handleModal } = React.useContext(ModalContext);
  const [indicator, setIndicator] = React.useState<string>('');
  const [tableData, setTableData] = React.useState<CompanyWithRelation[]>([]);

  const [tableHeader] = React.useState([
    { title: 'Virksomhedsnavn', field: 'name', editable: 'never' },
    {
      title: 'Type',
      field: 'company_type_id',
      render: rowData => <span>{CompanyTypes[rowData.company_type_id]}</span>,
      customFilterAndSearch: (term, rowData) =>
        filterBySimilarity(term, CompanyTypes[rowData.company_type_id]),
      editable: 'never',
    },
    { title: 'Adresse', field: 'address', editable: 'never' },
    { title: 'Leveringstid', field: 'delivery_time', editable: 'never' },
    {
      title: 'Betalingsbetingelser',
      field: 'payment_terms',
      editable: 'never',
    },
  ]);

  const [tableActions] = React.useState([
    rowData => ({
      icon: 'account_tree',
      tooltip: 'Relationer',
      onClick: () => {
        handleModal(
          rowData.name + "'s relationer",
          rowData.company_type_id === CompanyTypes['Super virksomhed'] ? (
            <SuperCompanyRelationOverview company={rowData} />
          ) : (
            <AddRelationToCompanyModalContent
              company={rowData}
              setIndicator={setIndicator}
            />
          ),
          35,
        );
      },
    }),
    rowData => ({
      disabled: rowData.company_type_id === CompanyTypes['Super virksomhed'],
      icon: 'edit',
      tooltip: 'Ændre virksomhed',
      onClick: (event, company) => {
        handleModal(
          'Ændre virksomhed',
          <EditCompanyModalData
            company={company}
            setIndicator={setIndicator}
          />,
        );
      },
    }),
    rowData => ({
      disabled: rowData.company_type_id === CompanyTypes['Super virksomhed'],
      icon: 'delete',
      tooltip: 'Fjern virksomhed',
      onClick: (event, company) => confirmDeleteCompany(company),
    }),
  ]);

  const confirmDeleteCompany = (company: Company) => {
    confirmAlert({
      title: 'Bekræft fjernelse af virksomhed: ' + company.name + '?',
      message: 'Er du sikker på at du vil fjerne denne virksomhed?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => removeCompany(company.id),
        },
        {
          label: 'Nej',
          onClick: () => void 0,
        },
      ],
    });
  };

  if (allCompaniesLoading) {
    return (
      <div className={'d-flex justify-content-center'}>
        <LoadingComponent visible={allCompaniesLoading} />
      </div>
    );
  }

  return (
    <div className={'container-fluid'}>
      {indicator && (
        <div className="alert alert-success mt-2" role="alert">
          {indicator}
        </div>
      )}

      <CustomTable
        data={tableData}
        isLoading={isLoading || allCompaniesLoading}
        headers={tableHeader}
        actions={tableActions}
        removeBorder={true}
        titleComponent={
          <button
            type="button"
            className="btn btn-success"
            onClick={() => {
              handleModal(
                'Tilføj virksomhed',
                <AddCompanyModalData setIndicator={setIndicator} />,
                35,
              );
            }}
          >
            <AddIcon /> Tilføj ny virksomhed
          </button>
        }
      />
    </div>
  );
};

export default CompaniesContent;
