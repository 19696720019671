import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import { Company, companyGetAll } from '../../../store/instructor/company';
import { IOrderGen, orderGenGetAll } from '../../../store/instructor/orderGen';
import {
  ProductType,
  productTypeGetAll,
} from '../../../store/instructor/productType';
import OrderGeneratorTab from './tabs/orderGeneratorTab';
import { store } from '../../../store/store';
import { convertCompanyIdToName } from '../../../utils/convertions';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  orderGens: IOrderGen;
  allProductTypes: ProductType[];
  allCompanies: Company[];
}

interface ThisCompState {
  indicator: string;
}

class OrderGeneratorContent extends React.Component<
  PropsFromState,
  ThisCompState
> {
  state = {
    indicator: '',
  };

  componentDidMount() {
    store.dispatch(orderGenGetAll());
    store.dispatch(companyGetAll());
    store.dispatch(productTypeGetAll());
  }

  renderTabs = () => {
    return (
      <>
        <Tabs>
          {this.props.orderGens.orderGens.map((orderGen, index) => {
            return (
              <Tab
                key={index}
                eventKey={orderGen.company_id.toString()}
                title={convertCompanyIdToName(
                  this.props.allCompanies,
                  orderGen.company_id,
                )}
              >
                <OrderGeneratorTab orderGen={orderGen} />
              </Tab>
            );
          })}
        </Tabs>
      </>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderTabs()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iOrderGen,
  iCompany,
  iProductType,
}: ApplicationState) => ({
  loading: iOrderGen.loading,
  orderGens: iOrderGen.orderGens,
  errors: iOrderGen.errors,
  allProductTypes: iProductType.productTypes,
  allCompanies: iCompany.companies,
});

export default connect(mapStateToProps)(OrderGeneratorContent);
