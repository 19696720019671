import { action } from 'typesafe-actions';
import { ICompanyActionTypes, Company, NewCompany, EditCompany } from './types';
import { IProducts } from '../product';

export const companyGetAll = () =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_ALL);
export const companyGetExpenseComps = (companyId: number) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_EXPENSECOMP, {
    companyId: companyId,
  });
export const companyGetDebtors = (companyId: number) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_DEBTORS, { companyId: companyId });
export const companyGetCreditors = (companyId: number) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_CREDITORS, {
    companyId: companyId,
  });
export const companyGetExpenseCompsSuccess = (data: Company) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_EXPENSECOMP_SUCCESS, data);
export const companyGetCreditorsSuccess = (data: Company) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_CREDITORS_SUCCESS, data);
export const companyGetDebtorsSuccess = (data: Company) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_DEBTORS_SUCCESS, data);
export const companyGetProducts = (companyId: number) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_PRODUCTS, { companyId: companyId });
export const companyDelete = (companyId: number) =>
  action(ICompanyActionTypes.ICOMPANY_DELETE, { companyId: companyId });
export const companyEdit = (editedCompany: EditCompany, companyId: number) =>
  action(ICompanyActionTypes.ICOMPANY_EDIT, {
    editedCompany: editedCompany,
    companyId: companyId,
  });
export const companyAdd = (newCompany: NewCompany) =>
  action(ICompanyActionTypes.ICOMPANY_ADD, { newCompany: newCompany });
export const companySuccess = (data: Company[]) =>
  action(ICompanyActionTypes.ICOMPANY_SUCCESS, data);
export const companyGetProductsSuccess = (data: IProducts) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_PRODUCTS_SUCCESS, data);
export const companyGetSuperCompaniesCompany = (superCompanies: number[]) =>
  action(ICompanyActionTypes.ICOMPANY_FETCH_SUPERCOMPANY_COMPANIES, {
    superCompanies: superCompanies,
  });
export const companyGetSuperCompaniesCompanySuccess = (
  data: Map<number, Company[]>,
) => action(ICompanyActionTypes.ICOMPANY_FETCH_SUPERCOMPANY_SUCCESS, data);
export const companyError = (message: string) =>
  action(ICompanyActionTypes.ICOMPANY_ERROR, message);
