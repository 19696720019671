import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Company } from '../../../store/instructor/company';
import { BankAccount } from '../../../store/instructor/bank';
import { Tab, Tabs } from 'react-bootstrap';
import PaymentTab, {
  PaymentContextType,
  PaymentType,
} from '../bank/mainTabs/payment';
import { NewBankTransactionWithId } from '../bank/bankContent';
import ApproveTab from '../bank/mainTabs/approve';
import { User } from '../../../store/instructor/users';

interface PropsFromState {
  allCompanies: Company[];
  allBankAccounts: BankAccount[];
  userCompanyId: number;
  allUsers: User[];
  companyAccounts: BankAccount[];
  studentBankAccount: BankAccount;
}

interface ThisCompState {
  pendingTransactions: NewBankTransactionWithId[];
  activeAccounts: BankAccount[];
}

class PrivateTransaction extends React.Component<
  PropsFromState,
  ThisCompState
> {
  constructor(props: PropsFromState) {
    super(props);
    this.state = {
      pendingTransactions: [],
      activeAccounts: this.props.allBankAccounts.filter(elem => elem.isActive),
    };
  }

  addPendingTransaction = (transaction: NewBankTransactionWithId) => {
    this.setState({
      pendingTransactions: [...this.state.pendingTransactions, transaction],
    });
  };

  removePendingTransaction = (transaction: NewBankTransactionWithId) => {
    const array = this.state.pendingTransactions.filter(
      item => item.id !== transaction.id,
    );
    this.setState({
      pendingTransactions: array,
    });
  };

  public render() {
    return (
      <Tabs defaultActiveKey={'transaction'}>
        <Tab eventKey="transaction" title="Overførsel">
          <PaymentTab
            type={PaymentType.TRANSFER_INTERN}
            context={PaymentContextType.PRIVAT_BANK}
            salaryAccount={this.props.studentBankAccount}
            pendingTransactions={this.state.pendingTransactions}
            addPendingTransactions={this.addPendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            userCompanyBankAccounts={this.props.companyAccounts}
            allUsers={this.props.allUsers}
          />
        </Tab>
        <Tab
          eventKey="privateBank"
          title={
            'Godkend betaling (' + this.state.pendingTransactions.length + ')'
          }
        >
          <ApproveTab
            allCompanies={this.props.allCompanies}
            pendingTransactions={this.state.pendingTransactions}
            removePendingTransaction={this.removePendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            allBankAccounts={this.props.allBankAccounts}
            allUsers={this.props.allUsers}
          />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  userCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(PrivateTransaction);
