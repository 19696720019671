import * as React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { ApplicationState } from '../../../../store';
import KontoudtogCompanyTab from './secondaryTabs/stateOfAccountCompanyTab';
import LoadingComponent from '../../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
}

class StateOfAccountTab extends React.Component<PropsFromParent> {
  renderFastUdgiftKontoTab = (tempName: string, index: number) => {
    return (
      <Tab key={index} eventKey={tempName} title={tempName}>
        <KontoudtogCompanyTab />
      </Tab>
    );
  };

  renderContent = () => {
    const fastCompanies: string[] = [
      'EL firma',
      'benzin firma',
      'noget andet firma',
    ];
    return (
      <>
        <p>Kontoudtog for faste omkostninger</p>
        <Tabs>{fastCompanies.map(this.renderFastUdgiftKontoTab)}</Tabs>
      </>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ iCompany }: ApplicationState) => ({
  loading: iCompany.loading,
});

export default connect(mapStateToProps)(StateOfAccountTab);
