import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { mistakesError, mistakesSuccess } from './actions';
import { IMistakesActionTypes, MistakeTypes } from './types';
import { getEndpoint } from '../../../utils/api';

function* handleFetch() {
  try {
    const requisitions = yield getEndpoint('mistake');
    const transactions = yield getEndpoint('mistake/transaction');
    const invoices = yield getEndpoint('mistake/invoice');

    let error;
    if (requisitions.error || transactions.error || invoices.error) {
      error = [requisitions.error, transactions.error, invoices.error];
    }

    let mistakes = {
      transactions: [...transactions],
      invoices: [...invoices],
      requisitions: [...requisitions],
    };

    mistakes.transactions.forEach(elem => {
      elem.type = MistakeTypes.Transaction;
    });
    mistakes.invoices.forEach(elem => {
      elem.type = MistakeTypes.Invoice;
    });
    mistakes.requisitions.forEach(elem => {
      elem.type = MistakeTypes.Requisition;
    });

    if (error) {
      yield put(mistakesError(error));
    } else {
      yield put(mistakesSuccess(mistakes));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* IMistakesWatcher() {
  yield takeEvery(IMistakesActionTypes.IMISTAKES_FETCH_ALL, handleFetch);
}

function* iMistakesSaga() {
  yield all([fork(IMistakesWatcher)]);
}

export { iMistakesSaga };
