// Type-safe initialState!
import {
  CompanyIdProductsPair,
  ICompanyProductsActionTypes,
  ICompanyProductsState,
} from './types';
import { Reducer } from 'redux';

export const initialState: ICompanyProductsState = {
  companyProducts: [],
  loading: false,
  errors: undefined,
};

const reducer: Reducer<ICompanyProductsState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ICompanyProductsActionTypes.ICOMPANYPRODUCTS_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyProductsActionTypes.ICOMPANYPRODUCTS_ADD: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyProductsActionTypes.ICOMPANYPRODUCTS_DELETE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyProductsActionTypes.ICOMPANYPRODUCTS_AMOUNT_ADD: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyProductsActionTypes.ICOMPANYPRODUCTS_AMOUNT_REMOVE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyProductsActionTypes.ICOMPANYPRODUCTS_SUCCESS: {
      const newPair: CompanyIdProductsPair = {
        companyId: action.payload.companyId,
        products: action.payload.productsArray,
      };
      //Does pair already exist?
      const foundElements: CompanyIdProductsPair[] = state.companyProducts.filter(
        elem => elem.companyId === newPair.companyId,
      );
      let currentStateArray: CompanyIdProductsPair[] = state.companyProducts;
      if (foundElements.length === 1) {
        //Replace old element with new
        const foundElementIndex = currentStateArray.findIndex(
          elem => elem.companyId === newPair.companyId,
        );
        currentStateArray[foundElementIndex] = newPair;

        return {
          ...state,
          companyProducts: currentStateArray,
          loading: false,
        };
      } else if (foundElements.length === 0) {
        //Add new element to array
        currentStateArray.push(newPair);
        return {
          ...state,
          companyProducts: currentStateArray,
          loading: false,
        };
      } else {
        throw Error;
      }
    }
    case ICompanyProductsActionTypes.ICOMPANYPRODUCTS_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iCompanyProductsReducer };
