import { action } from 'typesafe-actions';
import {
  ITaxActionTypes,
  NewTaxDeclaration,
  TaxDeclaration,
  TaxDeclarationYear,
} from './types';

export const TaxGetYear = (year: number, company_id: number) =>
  action(ITaxActionTypes.ITAX_GET_YEAR, { year, company_id });
export const TaxAdd = (data: NewTaxDeclaration) =>
  action(ITaxActionTypes.ITAX_ADD, { newTaxDeclaration: data });
export const TaxDelete = (data: TaxDeclaration, company_id: number) =>
  action(ITaxActionTypes.ITAX_DELETE, {
    taxDeclaration: data,
    company_id: company_id,
  });
export const TaxSuccess = (data: TaxDeclarationYear) =>
  action(ITaxActionTypes.ITAX_SUCCESS, data);
export const TaxAddSuccess = (data: TaxDeclaration) =>
  action(ITaxActionTypes.ITAX_ADD_SUCCESS, data);
export const TaxDeleteSuccess = (data: TaxDeclaration) =>
  action(ITaxActionTypes.ITAX_DELETE_SUCCESS, data);
export const TaxError = (message: string) =>
  action(ITaxActionTypes.ITAX_ERROR, message);
