import React from 'react';
import { Company, CompanyTypes } from '../../../store/instructor/company';
import CompanyRelationRow from './companyRelationRow';
import { useQueryClient } from 'react-query';
import LoadingComponent from '../../LoadingComponent';

export type CompanyWithRelation = Company & { relations: number[] };

interface PropsFromParent {
  company: CompanyWithRelation;
  setIndicator: (msg: string) => void;
}

const AddRelationToCompanyModalContent: React.FC<PropsFromParent> = props => {
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const stateCompany = queryClient
    .getQueryData<CompanyWithRelation[]>('allCompanies')
    ?.find(comp => comp.id === props.company.id);

  const superCompanies = queryClient
    .getQueryData<CompanyWithRelation[]>('allCompanies')
    ?.filter(comp => comp.company_type_id === CompanyTypes['Super virksomhed']);

  if (isLoading) {
    return (
      <div className={'d-flex justify-content-center'}>
        <LoadingComponent visible={isLoading} />
      </div>
    );
  }

  if (stateCompany) {
    return (
      <>
        <div className={'mb-4'}>
          <p className="h5 mb-0">Relationer til super virksomheder</p>
          <small className="text-muted">
            Relationer mellem virksomheder gør det muligt for robotten at vide
            hvilke virksomheder den skal bruge i sine arbejdsprocesser
          </small>
        </div>

        {superCompanies?.map((superCompany, key) => {
          return (
            <CompanyRelationRow
              key={key}
              company={stateCompany}
              superCompany={superCompany}
              setLoading={setLoading}
            />
          );
        })}
      </>
    );
  }

  return null;
};

export default AddRelationToCompanyModalContent;
