import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const Socket = (function () {
  let instance;

  function createInstance() {
    const stompClient = new Client({
      webSocketFactory: () => {
        return new SockJS('https://kopra.khs.dk:9000/wss');
      },
    });

    return stompClient;
  }

  return {
    getInstance: function () {
      if (!instance || instance.CLOSED) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default Socket;
