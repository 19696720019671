import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  getEndpointWithFilters,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';
import {
  requisitionError,
  requisitionGetAll,
  requisitionGetAllNewest,
  requisitionNewestSuccess,
  requisitionSuccess,
  requisitionSuccessFromCompanyWithoutShipPage,
  requisitionSuccessToCompanyActivePage,
  requisitionSuccessToCompanyArchivePage,
} from './actions';
import { FetchTypes, Requisition, RequisitionActionTypes } from './types';
import {
  ArchiveType,
  convertLocalDateTimeToHomemadeDate,
} from '../../../utils/convertions';

/* convertLocalDateTimeToHomemadeDate*/
function* handleGetAllToAndFromCompany(action) {
  try {
    const res = yield getEndpointWithFilters('requisition', {
      tofrom_company_id: action.payload.companyId,
    });

    if (res.error) {
      yield put(requisitionError(res.error));
    } else {
      let requisitions: Requisition[] = [];
      res.data.forEach(elem => {
        let parsedRequisition: Requisition = {
          ...elem,
          requisition_date: convertLocalDateTimeToHomemadeDate(
            elem.requisition_date,
          ),
        };
        requisitions.push(parsedRequisition);
      });

      yield put(requisitionSuccess(requisitions));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAllToAndFromCompanyNewest(action) {
  try {
    const res = yield getEndpointWithFilters('requisition', {
      tofrom_company_id: action.payload.companyId,
      limit: true,
    });

    if (res.error) {
      yield put(requisitionError(res.error));
    } else {
      let requisitions: Requisition[] = [];
      res.data.forEach(elem => {
        let parsedRequisition: Requisition = {
          ...elem,
          requisition_date: convertLocalDateTimeToHomemadeDate(
            elem.requisition_date,
          ),
        };
        requisitions.push(parsedRequisition);
      });

      yield put(requisitionNewestSuccess(requisitions));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetFromCompanyWithoutShipPage(action) {
  try {
    const search = action.payload.searchQuery;
    const after = action.payload.startDate;
    const before = action.payload.endDate;

    const res = yield getEndpointWithFilters('requisition/page', {
      from_company_id: action.payload.companyId,
      NOTto_company_id: 81, //TODO-future: hardcoded cargo company id
      size: action.payload.pageSize,
      page: action.payload.page,
      descending: action.payload.descending,
      ...(search && { search }),
      ...(after && { after }),
      ...(before && { before }),
    });

    if (res.error) {
      yield put(requisitionError(res.error));
    } else {
      let parsedRequisitions: Requisition[] = [];
      res.data.items.forEach(elem => {
        let parsedRequisition: Requisition = {
          ...elem,
          requisition_date: convertLocalDateTimeToHomemadeDate(
            elem.requisition_date,
          ),
        };
        parsedRequisitions.push(parsedRequisition);
      });

      yield put(
        requisitionSuccessFromCompanyWithoutShipPage({
          items: parsedRequisitions,
          total_pages: res.data.total_pages,
          total_items: res.data.total_items,
          page_size: res.data.page_size,
          current_page: res.data.current_page,
        }),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetToCompanyArchiveTypePage(action) {
  try {
    const search = action.payload.searchQuery;
    const after = action.payload.startDate;
    const before = action.payload.endDate;

    const res = yield getEndpointWithFilters('requisition/page', {
      to_company_id: action.payload.companyId,
      archive_type: action.payload.archiveType,
      size: action.payload.pageSize,
      page: action.payload.page,
      descending: action.payload.descending,
      ...(search && { search }),
      ...(after && { after }),
      ...(before && { before }),
    });

    if (res.error) {
      yield put(requisitionError(res.error));
    } else {
      let parseRequisitions: Requisition[] = [];
      res.data.items.forEach(elem => {
        let parsedRequisition: Requisition = {
          ...elem,
          requisition_date: convertLocalDateTimeToHomemadeDate(
            elem.requisition_date,
          ),
        };
        parseRequisitions.push(parsedRequisition);
      });

      switch (action.payload.archiveType) {
        case ArchiveType.DEFAULT:
          yield put(
            requisitionSuccessToCompanyActivePage({
              items: parseRequisitions,
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
              page_size: res.data.page_size,
              current_page: res.data.current_page,
            }),
          );
          break;
        case ArchiveType.PROCESSED:
          yield put(
            requisitionSuccessToCompanyArchivePage({
              items: parseRequisitions,
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
              page_size: res.data.page_size,
              current_page: res.data.current_page,
            }),
          );
          break;
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCreate(action) {
  try {
    const res = yield postEndpoint('requisition', action.payload.data);

    if (res.error) {
      yield put(requisitionError(res.error));
    } else {
      yield put(requisitionSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleSetArchiveType(action) {
  try {
    const res = yield putEndpoint(
      'requisition',
      {
        archive_type_id: action.payload.newArchiveTypeId,
      },
      action.payload.requisitionId,
    );

    if (res.error) {
      yield put(requisitionError(res.error));
    } else {
      if (action.payload.fetchType === FetchTypes.newest) {
        yield put(requisitionGetAllNewest(action.payload.currentUserCompanyId));
      } else {
        yield put(requisitionGetAll(action.payload.currentUserCompanyId));
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* requisitionWatcher() {
  yield takeEvery(
    RequisitionActionTypes.REQUISITION_GET_ALL_TOANDFROM_COMPANY,
    handleGetAllToAndFromCompany,
  );
  yield takeEvery(
    RequisitionActionTypes.REQUISITION_GET_ALL_TOANDFROM_COMPANY_NEWEST,
    handleGetAllToAndFromCompanyNewest,
  );
  yield takeEvery(
    RequisitionActionTypes.REQUISITION_GET_ALL_FROM_COMP_WITHOUT_SHIP_PAGE,
    handleGetFromCompanyWithoutShipPage,
  );
  yield takeEvery(
    RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ACTIVE_PAGE,
    handleGetToCompanyArchiveTypePage,
  );
  yield takeEvery(
    RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ARCHIVED_PAGE,
    handleGetToCompanyArchiveTypePage,
  );
  yield takeEvery(RequisitionActionTypes.REQUISITION_CREATE, handleCreate);
  yield takeEvery(
    RequisitionActionTypes.REQUISITION_SET_ARCHIVE_TYPE,
    handleSetArchiveType,
  );
}

function* requisitionSaga() {
  yield all([fork(requisitionWatcher)]);
}

export { requisitionSaga };
