import { action } from 'typesafe-actions';
import { IInvoiceActionTypes, Invoice } from './types';
import { Page } from '../../../utils/storeTypes';
import { ArchiveType } from '../../../utils/convertions';

export const invoiceGetAll = () => action(IInvoiceActionTypes.IINVOICE_GET_ALL);
export const invoiceGetFromCompanyPage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(IInvoiceActionTypes.IINVOICE_GET_ALL_FROM_COMP_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    searchQuery,
    startDate,
    endDate,
  });
export const invoiceGetToCompanyActivePage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  archiveType: ArchiveType,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ACTIVE_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    archiveType,
    searchQuery,
    startDate,
    endDate,
  });
export const invoiceGetToCompanyArchivePage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  archiveType: ArchiveType,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ARCHIVED_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    archiveType,
    searchQuery,
    startDate,
    endDate,
  });
export const invoiceCreate = data =>
  action(IInvoiceActionTypes.IINVOICE_CREATE, { data });
export const invoiceSetArchiveType = (
  invoiceId: number,
  newArchiveTypeId: number,
  currentUserCompanyId: number,
) =>
  action(IInvoiceActionTypes.IINVOICE_SET_ARCHIVE_TYPE, {
    invoiceId,
    newArchiveTypeId,
    currentUserCompanyId,
  });
export const invoiceSuccess = (data: Invoice[]) =>
  action(IInvoiceActionTypes.IINVOICE_SUCCESS, data);
export const invoiceSuccessFromCompanyPage = (data: Page) =>
  action(IInvoiceActionTypes.IINVOICE_GET_ALL_FROM_COMP_PAGE_SUCCESS, data);
export const invoiceSuccessToCompanyActivePage = (data: Page) =>
  action(
    IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS,
    data,
  );
export const invoiceSuccessToCompanyArchivePage = (data: Page) =>
  action(
    IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS,
    data,
  );
export const invoiceError = (message: string) =>
  action(IInvoiceActionTypes.IINVOICE_ERROR, message);
