import { InvoiceDetailsActionTypes } from './types';
import { action } from 'typesafe-actions';
import { Requisition } from '../requisitions';

export const invoiceDetailsGetRequisition = (requisitionId: number) =>
  action(InvoiceDetailsActionTypes.INVOICEDETAILS_GET_REQUISITION, {
    requisitionId: requisitionId,
  });
export const invoiceDetailsSuccess = (data: Requisition) =>
  action(InvoiceDetailsActionTypes.INVOICEDETAILS_SUCCESS, data);
export const invoiceDetailsError = (message: string) =>
  action(InvoiceDetailsActionTypes.INVOICEDETAILS_ERROR, message);
