import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import styled from 'styled-components';
import ShippingContent from './shippingContent';
import { Helmet } from 'react-helmet-async';

const StudentShipping: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Levering</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Levering</h2>
      </PageHeader>

      <ShippingContent />
    </StudentPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(StudentShipping);
