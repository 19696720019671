import { Reducer } from 'redux';
import { IBankTransactionActionTypes, IBankTransactionState } from './types';
import { Page } from '../../../utils/storeTypes';

export const initialState: IBankTransactionState = {
  loading: false,
  loadingToFromAccount: false,
  loadingToFromAccountPage: false,
  errors: undefined,
  bankTransactions: [],
  bankTransactionsToFromAccount: [],
  bankTransactionsToFromAccountPages: new Map<number, Page>(),
};

const reducer: Reducer<IBankTransactionState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT: {
      return {
        ...state,
        loadingToFromAccount: true,
      };
    }
    case IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_PAGE: {
      return {
        ...state,
        loadingToFromAccountPage: true,
      };
    }
    case IBankTransactionActionTypes.IBANKTRANSACTION_TRANSACTION_CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IBankTransactionActionTypes.IBANKTRANSACTION_TRANSACTION_SUCCESS: {
      return {
        ...state,
        bankTransactions: action.payload,
        loading: false,
      };
    }
    case IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankTransactionsToFromAccount: action.payload,
        loadingToFromAccount: false,
      };
    }
    case IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_PAGE_SUCCESS: {
      return {
        ...state,
        bankTransactionsToFromAccountPages: state.bankTransactionsToFromAccountPages.set(
          action.payload.accountId,
          action.payload.currentPage,
        ),
        loadingToFromAccountPage: false,
      };
    }
    case IBankTransactionActionTypes.IBANKTRANSACTION_TRANSACTION_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as IBankTransactionReducer };
