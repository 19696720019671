import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  fetchRemoveUserInfo,
  loginError,
  loginSuccess,
  logoutError,
  logoutRequest,
  logoutSuccess,
  reAuthError,
  reAuthSuccess,
  refreshTokenAge,
} from './actions';
import {
  getEndpoint,
  postEndpoint,
  userChangePassword,
} from '../../../utils/api';
import history from '../../../utils/history';
import { setAuthorizationHeader } from '../../../utils/authenticationUtil';
import { UserAuthenticationTypes, UserTypes } from './types';
import { store } from '../../store';
import { AxiosError, AxiosResponse } from 'axios';

function* handleLogin(action) {
  try {
    let credentials;
    if (action.payload.usernameIsEmail) {
      credentials = {
        email: action.payload.usernameOrEmail,
        password: action.payload.password,
      };
    } else {
      credentials = {
        username: action.payload.usernameOrEmail,
        password: action.payload.password,
      };
    }

    const res: AxiosResponse = yield postEndpoint('login', credentials);

    if (res.status === 200) {
      setAuthorizationHeader(res.data.token);
      //SaveLoginInformation(res.second.jwt, action.payload.remember);
      yield put(loginSuccess(res.data));

      if (res.data.userDetails.user_type === UserTypes.STUDENT) {
        history.push('/student/dashboard');
      } else if (res.data.userDetails.user_type === UserTypes.INSTRUCTOR) {
        history.push('/instructor/dashboard');
      } else {
        history.push('/');
      }
    }
  } catch (err) {
    const axiosErr: AxiosError = err;

    if (axiosErr.response) {
      let formattedMessage =
        axiosErr.response.status +
        ': ' +
        axiosErr.response.data.error +
        ' (' +
        axiosErr.response.config.method +
        ' @ ' +
        axiosErr.response.config.url +
        ')';
      yield put(loginError(formattedMessage));
    } else {
      yield put(loginError('En ukendt fejl blev registreret'));
    }
  }
}

function* handlePasswordChange(action) {
  try {
    const res = yield userChangePassword(
      action.payload.userId,
      action.payload.data,
    );

    if (res.error) {
      yield put(loginError(res.error));
    } else {
      yield put(loginSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(loginError(err.stack));
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}

function* handleLogout() {
  try {
    //Delete axios token
    setAuthorizationHeader('');
    //Change state: jwtToken, jwtAge: requires dispatch (removes jwt and jwt age)
    store.dispatch(fetchRemoveUserInfo());

    yield put(logoutSuccess());
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(logoutError(err.stack));
    } else {
      yield put(logoutError('An unknown error occured.'));
    }
  }
}

function handleLogoutSuccess() {
  history.push('/');
}

function* handleReauthentication() {
  try {
    const res = yield makeReAuthApiCall();
    if (res.status === 200) {
      yield put(reAuthSuccess(res.data));
    } else {
      yield put(reAuthError(res.error));
      yield put(logoutRequest());
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(reAuthError(err.stack));
      yield put(logoutRequest());
    } else {
      yield put(reAuthError('An unknown error occured.'));
      yield put(logoutRequest());
    }
  }
}

function makeReAuthApiCall() {
  let token = store.getState().userInfo.jwtToken;

  /* Re-set the authorization header in case we have a JWT token that we want to re-authenticate */
  if (token !== null) {
    setAuthorizationHeader(token);
  }

  return getEndpoint('auth');
}

function handleReauthenticationSuccess() {
  store.dispatch(refreshTokenAge());
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleLogin()` saga above.
function* watchUserSagas() {
  yield takeEvery(UserAuthenticationTypes.USER_LOGIN_REQUEST, handleLogin);
  yield takeEvery(
    UserAuthenticationTypes.USER_CHANGE_PASSWORD,
    handlePasswordChange,
  );
  yield takeEvery(UserAuthenticationTypes.USER_LOGOUT_REQUEST, handleLogout);
  yield takeEvery(
    UserAuthenticationTypes.USER_LOGOUT_SUCCESS,
    handleLogoutSuccess,
  );
  yield takeEvery(
    UserAuthenticationTypes.USER_REAUTH_REQUEST,
    handleReauthentication,
  );
  yield takeEvery(
    UserAuthenticationTypes.USER_REAUTH_SUCCESS,
    handleReauthenticationSuccess,
  );
}

// We can also use `fork()` here to split our saga into multiple watchers.
function* userAuthenticationSaga() {
  yield all([fork(watchUserSagas)]);
}

export { userAuthenticationSaga };
