import * as React from 'react';
import { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import ProductTypeContent from './productType/productTypeContent';
import { Company } from '../../../store/instructor/company';
import { store } from '../../../store/store';
import {
  ProductType,
  productTypeGetAll,
} from '../../../store/instructor/productType';
import ProductsOverview from './products/productsOverview';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  allCompanies: Company[];
  allProductTypes: ProductType[];
}

const ProductOverview: React.FC<PropsFromState> = props => {
  useEffect(() => {
    store.dispatch(productTypeGetAll());
  }, []);

  const renderContent = () => {
    return (
      <Tabs defaultActiveKey="products">
        <Tab eventKey="products" title="Produkter">
          <ProductsOverview productTypes={props.allProductTypes} />
        </Tab>
        <Tab eventKey="productTypes" title="Varekategorier">
          <ProductTypeContent allProductTypes={props.allProductTypes} />
        </Tab>
      </Tabs>
    );
  };

  return (
    <div className={'container-fluid'}>
      {props.loading ? (
        <div className={'d-flex justify-content-center'}>
          <LoadingComponent visible={props.loading} />
        </div>
      ) : (
        <>{renderContent()}</>
      )}
    </div>
  );
};

const mapStateToProps = ({ iCompany, iProductType }: ApplicationState) => ({
  allCompanies: iCompany.companies,
  allProductTypes: iProductType.productTypes,
  loading: iCompany.loading || iProductType.loading,
});

export default connect(mapStateToProps)(ProductOverview);
