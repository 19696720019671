import client from '../client';
import { Company } from '../../app/store/instructor/company';

export default {
  getCompanyCreditors: async (companyId: number) =>
    await client.get<Company[]>(
      `/company/filter?belongs_to_super_company_id=${companyId}&creditor=1`,
    ),
  getSuperCompanies: async () => await client.get<Company[]>(`/company/super`),
};
