import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  deleteEndpoint,
  getEndpoint,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';
import {
  productTypeGetAll,
  productTypesError,
  productTypesSuccess,
} from './actions';
import { IProductTypeActionTypes } from './types';

function* handleGetAll() {
  try {
    const res = yield getEndpoint('product-type');

    if (res.error) {
      yield put(productTypesError(res.error));
    } else {
      yield put(productTypesSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleProductTypeAdd(action) {
  try {
    const res = yield postEndpoint('product-type', {
      product_type: action.payload.name,
    });

    if (res.error) {
      yield put(productTypesError(res.error));
    } else {
      yield put(productTypeGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleDelete(action) {
  try {
    const res = yield deleteEndpoint(
      'product-type',
      action.payload.productTypeId,
    ); //TODO: does this work without empty body

    if (res.error) {
      yield put(productTypesError(res.error));
    } else {
      yield put(productTypeGetAll());
    }
  } catch (err) {
    var words: string[] = err.message.split(' ');
    const errorCode: string = words[words.length - 1];
    if (errorCode && errorCode === '403') {
      yield put(
        productTypesError(
          'You cannot delete a productType which has products associated.',
        ),
      );
    }
    console.log(err.message);
  }
}

function* handleEdit(action) {
  try {
    const res = yield putEndpoint(
      'product-type',
      action.payload.editedProductType,
      action.payload.editedProductType.id,
    );

    if (res.error) {
      yield put(productTypesError(res.error));
    } else {
      yield put(productTypeGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchProductType() {
  yield takeEvery(IProductTypeActionTypes.IPRODUCT_TYPE_GET_ALL, handleGetAll);
  yield takeEvery(
    IProductTypeActionTypes.IPRODUCT_TYPE_GET_ADD,
    handleProductTypeAdd,
  );
  yield takeEvery(IProductTypeActionTypes.IPRODUCT_TYPE_GET_EDIT, handleEdit);
  yield takeEvery(
    IProductTypeActionTypes.IPRODUCT_TYPE_GET_DELETE,
    handleDelete,
  );
}

function* productTypeSaga() {
  yield all([fork(watchProductType)]);
}

export { productTypeSaga };
