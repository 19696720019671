import React, { useState } from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import {
  Message,
  MessagePaginated,
  messageSetStatus,
  MessageStatusType,
  MessageType,
} from '../../../store/instructor/inbox';
import InstructorModal from '../../../components/modal/instructorModal';
import { Company } from '../../../store/instructor/company';
import { convertCompanyIdToName } from '../../../utils/convertions';
import { HomemadeDate } from '../../../utils/homemadeDate';
import ViewMsgModalData from './modal/viewMsgModalData';
import { useQuery } from 'react-query';
import { fetchMessagesPaginatedOnType } from '../../../utils/api';
import CustomTablePagination from '../../../components/table/customTablePagination';
import { AxiosError } from 'axios';
import { convertHomemadeDateToString } from '../../../utils/dateUtils';
import DatepickerTableTitle from '../../../components/table/title/datepickerTitle';
import DatePicker from 'react-datepicker';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromParentAndState {
  loading: boolean;
  tabType: InboxTabType;
  allCompanies: Company[];
  thisUserCompanyId: number;
}

export enum InboxTabType {
  UNREAD = 'Ulæst Post',
  INBOX = 'Læst Post',
  SENT = 'Afsendt',
}

const InboxTab: React.FC<PropsFromParentAndState> = props => {
  const [selectedMsg, setSelectedMsg] = useState<Message>({
    msg_datesent: new HomemadeDate(),
    msg_id: -1,
    msg_to_id: -1,
    msg_dateread: new HomemadeDate(),
    msg_status: MessageStatusType.UNREAD,
    msg_type: MessageType.SOMETHING,
    msg_body: '',
    msg_from_id: -1,
    msg_header: '',
    msg_recipient_id: -1,
  });
  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
  });
  const [modalViewMsgVisible, setModalViewMsgVisible] = useState<boolean>(
    false,
  );
  const [tableData, setTableData] = useState<Message[]>([]);
  const [tableHeader] = useState<any[]>([
    {
      title: 'Besked ID',
      field: 'msg_id',
      editable: 'never',
    },
    {
      title: 'Fra virksomhed',
      field: 'msg_from_id',
      editable: 'never',
      render: rowData => (
        <span>
          {convertCompanyIdToName(props.allCompanies, rowData.msg_from_id)}
        </span>
      ),
    },
    {
      title: 'Til virksomhed',
      field: 'msg_to_id',
      editable: 'never',
      render: rowData => (
        <span>
          {convertCompanyIdToName(props.allCompanies, rowData.msg_to_id)}
        </span>
      ),
    },
    {
      title: 'Modtaget dato',
      field: 'msg_datesent',
      editable: 'never',
      render: ({ msg_datesent }) => (
        <span>{convertHomemadeDateToString(msg_datesent)}</span>
      ),
    },
    {
      title: 'Titel',
      field: 'msg_header',
      editable: 'never',
    },
  ]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const generateQueryParams = () => {
    switch (props.tabType) {
      case InboxTabType.UNREAD:
        return { to_company_id: props.thisUserCompanyId, msg_status: 1 };
      case InboxTabType.INBOX:
        return { to_company_id: props.thisUserCompanyId, msg_status: 2 };
      case InboxTabType.SENT:
        return { from_company_id: props.thisUserCompanyId };
    }
  };

  const { isLoading, data, isFetching, refetch } = useQuery<
    MessagePaginated,
    AxiosError
  >(
    ['inbox', props.tabType, { page, searchQuery, selectedDateRange }],
    () =>
      fetchMessagesPaginatedOnType(
        page,
        rowsPerPage,
        generateQueryParams(),
        searchQuery,
        selectedDateRange.startDate,
        selectedDateRange.endDate,
      ),
    {
      keepPreviousData: true,
      onSuccess: data => {
        setTableData(data.items);
      },
    },
  );

  const handleModalViewMsgVisibility = () => {
    setModalViewMsgVisible(!modalViewMsgVisible);
  };

  const setMsgToRead = (msg: Message) => {
    if (props.tabType === InboxTabType.UNREAD) {
      store.dispatch(messageSetStatus(MessageStatusType.READ, msg));
      setPage(0);
      refetch();
    }
  };

  const renderContent = () => {
    return (
      <CustomTablePagination
        titleComponent={
          <DatepickerTableTitle
            datepicker={
              <DatePicker
                selectsRange={true}
                startDate={selectedDateRange.startDate}
                endDate={selectedDateRange.endDate}
                onCalendarClose={() => refetch}
                onChange={dates => {
                  if (dates) {
                    if (Array.isArray(dates)) {
                      if (dates.every(ele => ele == null)) {
                        setSelectedDateRange({
                          startDate: undefined,
                          endDate: undefined,
                        });
                      }
                    }
                    setSelectedDateRange({
                      startDate: dates[0],
                      endDate: dates[1],
                    });
                  }
                }}
                isClearable={true}
              />
            }
          />
        }
        data={tableData}
        headers={tableHeader}
        numberRowPerPage={rowsPerPage}
        totalElementAmount={data?.total_items ?? 0}
        onChangePageFunc={page => setPage(page)}
        onChangeRowsPerPageFunc={rows => setRowsPerPage(rows)}
        onSearchChange={query => setSearchQuery(query)}
        pageNumber={page}
        isLoading={props.loading || isLoading || isFetching}
        actions={[
          {
            icon: 'description',
            tooltip: 'Vis detaljer',
            onClick: (event, message) => {
              setSelectedMsg(message);
              handleModalViewMsgVisibility();
            },
          },
        ]}
      />
    );
  };

  return (
    <>
      <div>
        {props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={props.loading} />
          </div>
        ) : (
          renderContent()
        )}
      </div>

      <InstructorModal
        modalState={modalViewMsgVisible}
        showFunc={handleModalViewMsgVisibility}
        titleHeader="Vis besked detaljer"
      >
        <ViewMsgModalData
          visibilityToggleFunc={handleModalViewMsgVisibility}
          setMsgToReadFunc={setMsgToRead}
          msg={selectedMsg}
          allCompanies={props.allCompanies}
        />
      </InstructorModal>
    </>
  );
};

const mapStateToProps = ({ iMessages, userInfo }: ApplicationState) => ({
  loading: iMessages.loading,
  thisUserCompanyId: userInfo.userSuperCompanyId,
  errors: iMessages.errors,
});

export default connect(mapStateToProps)(InboxTab);
