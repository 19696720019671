import { Requisition } from '../requisitions';

export enum InvoiceDetailsActionTypes {
  INVOICEDETAILS_GET_REQUISITION = '@@public/invoice-details/GET_REQUISITION',
  INVOICEDETAILS_SUCCESS = '@@public/invoice-details/SUCCESS',
  INVOICEDETAILS_ERROR = '@@public/invoice-details/ERROR',
}

export interface InvoiceDetailsState {
  readonly requisition: Requisition;
  readonly loading: boolean;
  readonly errors?: string;
}
