import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ProductsTable from './productsTable';
import { Company } from '../../../../../../store/instructor/company';
import { Product } from '../../../../../../store/instructor/product';
import ShoppingCart from './shoppingCart';
import { NewDelivery } from '../../../../../../store/instructor/delivery';
import { CompanyIdProductsPair } from '../../../../../../store/instructor/company-products';

type SetRequisitionInStepParent = (newDelivery: NewDelivery) => void;
type SetDeliveryTotalCostInStepParent = (deliveryTotalCost: {
  total_amount_without_vax: number;
  total_vax: number;
}) => void;

interface PropsFromState {
  setRequisitionInStepParent: SetRequisitionInStepParent;
  setDeliveryTotalCostInStepParent: SetDeliveryTotalCostInStepParent;
  allCompanies: Company[];
  companyProducts: Product[];
  debtorCompanies: Company[];
  userCompanyId: number;
  allCompanyProductsPairs: CompanyIdProductsPair[];
}

interface ThisCompState {
  requisitionId: number;
  products: Product[];
  toCompany: number; //TODO-future: when user selects to, and this is set.. it is a STRING!!
  fromCompany: number;
}

class NewShipmentPage extends React.Component<PropsFromState, ThisCompState> {
  state = {
    requisitionId: -1,
    products: [],
    toCompany: -1,
    fromCompany: -1,
  };

  setProducts = (products: Product[]) => {
    this.setState({ products: products });
  };

  setToCompany = (companyId: number) => {
    this.setState({ toCompany: companyId });
  };

  setFromCompany = (companyId: number) => {
    this.setState({ fromCompany: companyId });
  };

  setRequisitionId = (requisitionId: number) => {
    this.setState({ requisitionId: requisitionId });
  };

  public render() {
    return (
      <>
        <PageHeader className={'pb-3'}>
          <h3>Opret forsendelse</h3>
        </PageHeader>

        <div className="row">
          <div className="col-8">
            <ProductsTable
              debtorCompanies={this.props.debtorCompanies}
              editProducts={this.setProducts}
              products={this.state.products}
              setToCompany={this.setToCompany}
              setFromCompany={this.setFromCompany}
              fromCompany={this.state.fromCompany}
              setRequisitionId={this.setRequisitionId}
              requisitionId={this.state.requisitionId}
              companyProducts={this.props.companyProducts}
            />
          </div>
          <div className="col-4 text-right">
            <ShoppingCart
              setProducts={this.setProducts}
              from_company_id={this.state.fromCompany}
              to_company_id={this.state.toCompany}
              products={this.state.products}
              requisition_id={this.state.requisitionId}
              userSuperCompany={this.props.userCompanyId}
              allCompanies={this.props.allCompanies}
              setRequisitionInStepParent={this.props.setRequisitionInStepParent}
              setDeliveryTotalCostInStepParent={
                this.props.setDeliveryTotalCostInStepParent
              }
              allCompanyProductsPairs={this.props.allCompanyProductsPairs}
            />
          </div>
        </div>
      </>
    );
  }
}

const PageHeader = styled.div``;

export default connect()(NewShipmentPage);
