import React, { Component } from 'react';
import { Company, companyGetAll } from '../../../store/instructor/company';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import RequisitionTab, {
  TabArchiveType,
} from '../rekvisitioner/requisitionTab';
import DeliveryOverview, {
  ComponentSection,
  DeliveryOverviewType,
} from '../shipping/tabs/delivery/deliveryOverview';
import { PDFTypes } from '../../../components/pdfgenerator/documentPreview';
import Inventory from './inventory';
import {
  CompanyIdProductsPair,
  companyProductsGetAll,
} from '../../../store/instructor/company-products';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromStore {
  allCompanies: Company[];
  userCompanyId: number;
  allCompanyProductsPairs: CompanyIdProductsPair[];
  deliveryLoading: boolean;
}

class TabHolder extends Component<PropsFromStore> {
  constructor(props: Readonly<PropsFromStore>) {
    super(props);
    store.dispatch(companyGetAll());
    store.dispatch(companyProductsGetAll(this.props.userCompanyId));
  }

  public render() {
    return (
      <Tabs defaultActiveKey={'sentTab'} mountOnEnter={true}>
        <Tab title="Varelager" eventKey="inventory">
          <Inventory
            userCompanyId={this.props.userCompanyId}
            allCompanyProductsPairs={this.props.allCompanyProductsPairs}
          />
        </Tab>
        <Tab title="Afsendte købsordre" eventKey="sentTab">
          <RequisitionTab
            allCompanies={this.props.allCompanies}
            type={TabArchiveType.SEND}
            pdfType={PDFTypes.ORDER}
          />
        </Tab>
        <Tab eventKey="receivedDeliveries" title="Modtagne leveringer">
          <Tabs defaultActiveKey="receivedDeliveriesActive">
            <Tab eventKey="receivedDeliveriesActive" title="Modtaget fragt">
              {this.props.deliveryLoading ? (
                <div className={'d-flex justify-content-center'}>
                  <LoadingComponent visible={this.props.deliveryLoading} />
                </div>
              ) : (
                <DeliveryOverview
                  componentSection={ComponentSection.INVENTORY}
                  type={DeliveryOverviewType.INCOMING_ACTIVE}
                  allCompanies={this.props.allCompanies}
                  thisUserCompanyId={this.props.userCompanyId}
                />
              )}
            </Tab>
            <Tab eventKey="receivedDeliveriesArchived" title="Behandlede">
              {this.props.deliveryLoading ? (
                <div className={'d-flex justify-content-center'}>
                  <LoadingComponent visible={this.props.deliveryLoading} />
                </div>
              ) : (
                <DeliveryOverview
                  componentSection={ComponentSection.INVENTORY}
                  type={DeliveryOverviewType.INCOMING_ARCHIVED}
                  allCompanies={this.props.allCompanies}
                  thisUserCompanyId={this.props.userCompanyId}
                />
              )}
            </Tab>
          </Tabs>
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = ({
  iCompany,
  userInfo,
  iCompanyProducts,
  iDelivery,
}: ApplicationState) => ({
  allCompanies: iCompany.companies,
  userCompanyId: userInfo.userSuperCompanyId,
  allCompanyProductsPairs: iCompanyProducts.companyProducts,
  deliveryLoading: iDelivery.loading,
});

export default connect(mapStateToProps)(TabHolder);
