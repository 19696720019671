import { store } from '../store/store';

export const isAuthenticated = () => {
  return false;
};

export const getUserType = () => {
  const state = store.getState();
  return state.userInfo.userType;
};
