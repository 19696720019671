import { Reducer } from 'redux';
import { ICompanyState, ICompanyActionTypes, Company } from './types';

// Type-safe initialState!
export const initialState: ICompanyState = {
  companies: [],
  companyProducts: [],
  companyDebtors: [],
  companyCreditors: [],
  companyExpenseComps: [],
  superCompaniesCompanyLoading: false,
  superCompaniesCompany: new Map<number, Company[]>(),
  loading: false,
  errors: undefined,
};

const reducer: Reducer<ICompanyState> = (state = initialState, action) => {
  switch (action.type) {
    case ICompanyActionTypes.ICOMPANY_FETCH_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_DEBTORS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_CREDITORS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_PRODUCTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_EXPENSECOMP: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_ADD: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_DELETE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_PRODUCTS_SUCCESS: {
      return {
        ...state,
        companyProducts: action.payload,
        loading: false,
      };
    }
    case ICompanyActionTypes.ICOMPANY_SUCCESS: {
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_CREDITORS_SUCCESS: {
      return {
        ...state,
        companyCreditors: action.payload,
        loading: false,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_DEBTORS_SUCCESS: {
      return {
        ...state,
        companyDebtors: action.payload,
        loading: false,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_EXPENSECOMP_SUCCESS: {
      return {
        ...state,
        companyExpenseComps: action.payload,
        loading: false,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_SUPERCOMPANY_SUCCESS: {
      return {
        ...state,
        superCompaniesCompany: action.payload,
        superCompaniesCompanyLoading: false,
      };
    }
    case ICompanyActionTypes.ICOMPANY_FETCH_SUPERCOMPANY_COMPANIES: {
      return {
        ...state,
        superCompaniesCompanyLoading: true,
      };
    }
    case ICompanyActionTypes.ICOMPANY_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as iCompanyReducer };
