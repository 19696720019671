export interface TaxDeclarationYear {
  year: number;
  declarations: TaxDeclarationSet[] | null;
}

export interface TaxDeclarationSet {
  month: number;
  declaration_atp: TaxDeclaration | null;
  declaration_tax: TaxDeclaration | null;
  declaration_a_am_tax: TaxDeclaration | null;
}

export interface NewTaxDeclaration {
  company_id: number;
  bank_account_id: number;
  target_month: number;
  target_year: number;
  type: TaxDeclarationType;
  amount: number;
  details: TaxDecDetailsATP | TaxDecDetailsAAmTax | TaxDecDetailsTax;
}

export interface TaxDeclaration extends NewTaxDeclaration {
  id: number;
  date_of_creating: Date;
  is_pending: boolean;
}

export interface TaxDecDetailsATP {
  salary_receiver_contribution: number;
  employer_contribution: number;
}

export interface TaxDecDetailsAAmTax {
  labor_mc: number;
  a_tax: number;
}

export interface TaxDecDetailsTax {
  outgoingSalesTax: number;
  salesTaxForeign: number;
  incomingSalesTax: number;
  oilAndFlaskTax: number;
  electricityTax: number;
  natureGasAndCityGasTax: number;
  coalTax: number;
  co2Tax: number;
  waterTax: number;
  classificationA: number;
  classificationB: number;
  classificationC: number;
}

export interface TaxGetApiResponse {
  type: {
    id: number;
    type: string;
  };
  taxDeclarationATP: TaxDecDetailsATP & TaxDeclaration;
  taxDeclarationAAMTax: TaxDecDetailsAAmTax & TaxDeclaration;
  taxDeclarationTax: TaxDecDetailsTax & TaxDeclaration;
}

export enum TaxDeclarationType {
  ATP = 'ATP',
  TAX = 'TAX',
  A_AM_TAX = 'A_AM_TAX',
}

export enum ITaxActionTypes {
  ITAX_GET_YEAR = '@@/tax/GET_YEAR',
  ITAX_ADD = '@@/tax/ADD',
  ITAX_ADD_SUCCESS = '@@/tax/ADD_SUCCESS',
  ITAX_DELETE = '@@/tax/DELETE',
  ITAX_DELETE_SUCCESS = '@@/tax/DELETE_SUCCESS',
  ITAX_SUCCESS = '@@/tax/SUCCESS',
  ITAX_ERROR = '@@/tax/ERROR',
}

export interface ITaxState {
  readonly loading: boolean;
  readonly declarationYear: TaxDeclarationYear;
  readonly errors?: string;
}
