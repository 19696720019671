import { Reducer } from 'redux';
import { IProductHistoryActionTypes, IProductHistoryState } from './types';

export const initialState: IProductHistoryState = {
  productHistory: [],
  loading: false,
  errors: undefined,
};

const reducer: Reducer<IProductHistoryState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case IProductHistoryActionTypes.IPRODUCT_HISTORY_GET: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductHistoryActionTypes.IPRODUCT_HISTORY_SUCCESS: {
      return {
        ...state,
        productHistory: action.payload,
        loading: false,
      };
    }
    case IProductHistoryActionTypes.IPRODUCT_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as iProductHistoryReducer };
