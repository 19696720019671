import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import CustomTable from '../../../components/table/customTable';
import { CompanyIdProductsPair } from '../../../store/instructor/company-products';
import { Product } from '../../../store/instructor/product';
import InstructorModal from '../../../components/modal/instructorModal';
import ViewProductHistoryModalData from './featureModal/viewProductHistoryModalData';

interface PropsFromParent {
  loading: boolean;
  allCompanyProductsPairs: CompanyIdProductsPair[];
  userCompanyId: number;
}

interface ThisCompState {
  tableData: Product[];
  tableHeader: any[];
  modalViewProductAmountHistoryVisibility: boolean;
  selectedProduct: Product;
}

class Inventory extends React.Component<PropsFromParent, ThisCompState> {
  constructor(props: Readonly<PropsFromParent>) {
    super(props);
    this.state = {
      modalViewProductAmountHistoryVisibility: false,
      selectedProduct: {
        name: '',
        id: -1,
        product_type_id: -1,
        product_type_code: '',
        price_cost: -1,
        price_unit: -1,
        navision_id: -1,
        amount: -1,
        is_service: false,
        is_vax_free: false,
      },
      tableData: [],
      tableHeader: [
        { title: 'Varenummer', field: 'navision_id', editable: 'never' },
        { title: 'Navn', field: 'name', editable: 'never' },
        {
          title: 'Antal',
          field: 'amount',
          render: rowData => (
            <span>{rowData.is_service ? 'Uendelig' : rowData.amount}</span>
          ),
          editable: 'never',
        },
        {
          title: 'Salgspris',
          field: 'price_unit',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
        {
          title: 'Indkøbspris',
          field: 'price_cost',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
      ],
    };
  }

  componentDidMount() {
    const products = this.getCompanyProducts(
      this.props.userCompanyId,
      this.props.allCompanyProductsPairs,
    );
    this.setState({ tableData: products });
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    const currentCompanyProducts = this.getCompanyProducts(
      this.props.userCompanyId,
      this.props.allCompanyProductsPairs,
    );
    if (currentCompanyProducts.length > 0) {
      const oldCompanyProducts = this.getCompanyProducts(
        this.props.userCompanyId,
        prevProps.allCompanyProductsPairs,
      );
      if (currentCompanyProducts !== oldCompanyProducts) {
        this.setState({ tableData: currentCompanyProducts });
      } else {
        //TODO-future: This should not be nessesary since the above check between prev and current, should 'catch' changes.
        //TODO-future: Somehow componentDidUpdate is not called when props.allCompanyProductsPairs is update, maybe because it is an array in an array?
        if (currentCompanyProducts !== this.state.tableData) {
          this.setState({ tableData: currentCompanyProducts });
        }
      }
    }
  }

  getCompanyProducts = (
    companyId: number,
    allCompanyProductsPairs: CompanyIdProductsPair[],
  ): Product[] => {
    const companyIdProductsPair: CompanyIdProductsPair[] = allCompanyProductsPairs.filter(
      elem => elem.companyId === companyId,
    );

    if (companyIdProductsPair.length === 1) {
      //Found the right one
      return companyIdProductsPair[0].products;
    } else if (companyIdProductsPair.length > 1) {
      //Should never happen
      throw Error;
    } else {
      return [];
    }
  };

  handleModalViewProductAmountHistoryVisibility = () => {
    this.setState({
      modalViewProductAmountHistoryVisibility: !this.state
        .modalViewProductAmountHistoryVisibility,
    });
  };

  setSelectedProduct = (product: Product) => {
    this.setState({ selectedProduct: product });
  };

  render() {
    const actions = [
      {
        icon: 'timeline',
        tooltip: 'Se historik: tilgang/afgang',
        onClick: (event, product: Product) => {
          this.setSelectedProduct(product);
          this.handleModalViewProductAmountHistoryVisibility();
        },
      },
    ];
    return (
      <>
        <CustomTable
          data={this.state.tableData}
          headers={this.state.tableHeader}
          actions={actions}
        />

        <InstructorModal
          modalState={this.state.modalViewProductAmountHistoryVisibility}
          showFunc={this.handleModalViewProductAmountHistoryVisibility}
          titleHeader="Historik"
          width={70}
        >
          <ViewProductHistoryModalData
            product={this.state.selectedProduct}
            thisUserCompanyId={this.props.userCompanyId}
            visibilityToggleFunc={
              this.handleModalViewProductAmountHistoryVisibility
            }
          />
        </InstructorModal>
      </>
    );
  }
}

const mapStateToProps = ({ iCompanyProducts }: ApplicationState) => ({
  loading: iCompanyProducts.loading,
});

export default connect(mapStateToProps)(Inventory);
