import { Product } from '../product';
import { BankAccount } from '../bank';

export interface Company {
  name: string;
  id: number;
  payment_terms: number;
  address: string;
  delivery_time: number;
  bank_accounts: BankAccount[];
  company_type_id: number;
  company_type: string;
  relations?: number[];
}

export enum CompanyTypes {
  'Super virksomhed' = 1,
  'Kreditor og Debitor' = 2,
  'Kreditor' = 3,
  'Debitor' = 4,
  'Omkostninger' = 5,
}

export interface NewCompany {
  name: string;
  payment_terms: number;
  address: string;
  delivery_time: number;
  company_type: CompanyTypes;

  bank_account_name: string;
  currency_code: number;
  balance: number;
}

export interface EditCompany {
  name: string;
  payment_terms: number;
  delivery_time: number;
  address: string;
  company_type_id: number;
}

export enum CompanyType {
  SUPER = 'super_company',
  NORMAL = 'normal_company',
}

export interface ICompany {
  companies: Company[];
}

export enum ICompanyActionTypes {
  ICOMPANY_FETCH_ALL = '@@instructor/company/FETCH_ALL',
  ICOMPANY_FETCH_EXPENSECOMP = '@@instructor/company/FETCH_EXPENSECOMP',
  ICOMPANY_FETCH_DEBTORS = '@@instructor/company/FETCH_DEBTORS',
  ICOMPANY_FETCH_CREDITORS = '@@instructor/company/FETCH_CREDITORS',
  ICOMPANY_FETCH_EXPENSECOMP_SUCCESS = '@@instructor/company/FETCH_EXPENSECOMP_SUCCESS',
  ICOMPANY_FETCH_CREDITORS_SUCCESS = '@@instructor/company/FETCH_CREDITORS_SUCCESS',
  ICOMPANY_FETCH_DEBTORS_SUCCESS = '@@instructor/company/FETCH_DEBTORS_SUCCESS',
  ICOMPANY_FETCH_PRODUCTS = '@@instructor/company/FETCH_PRODUCTS',
  ICOMPANY_FETCH_PRODUCTS_SUCCESS = '@@instructor/company/FETCH_PRODUCTS_SUCCESS',
  ICOMPANY_FETCH_SUPERCOMPANY_COMPANIES = '@@instructor/company/FETCH_SUPERCOMPANY_COMPANIES',
  ICOMPANY_FETCH_SUPERCOMPANY_SUCCESS = '@@instructor/company/FETCH_SUPERCOMPANY_COMPANIES_SUCCESS',
  ICOMPANY_DELETE = '@@instructor/company/DELETE',
  ICOMPANY_ADD = '@@instructor/company/ADD',
  ICOMPANY_EDIT = '@@instructor/company/EDIT',
  ICOMPANY_SUCCESS = '@@instructor/company/SUCCESS',
  ICOMPANY_ERROR = '@@instructor/company/ERROR',
}

export interface ICompanyState {
  readonly loading: boolean;
  readonly companies: Company[];
  readonly companyCreditors: Company[];
  readonly companyDebtors: Company[];
  readonly companyExpenseComps: Company[];
  readonly companyProducts: Product[];
  readonly superCompaniesCompanyLoading: boolean; //Used while mixit and EDP companies are fetching
  readonly superCompaniesCompany: Map<number, Company[]>; //Used while mixit and EDP companies are fetching
  readonly errors?: string;
}
