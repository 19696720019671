export interface User {
  id: number;
  email: string;
  username: string;
  user_type: string;
  user_type_id: number;
  user_group_code: string;
  user_group_id: number;
  first_name: string;
  last_name: string;
  phone_number?: string;
  super_company_id?: number;
}

export interface UserNew {
  email: string;
  username: string;
  type_id: number;
  group_id: number;
  first_name: string;
  last_name: string;
  password_first: string;
  password_second: string;
  phone_number?: string;
  company_id?: number;
}

export interface UserEdit {
  email: string;
  username: string;
  company_id: number;
  type_id: number;
  group_id: number;
  first_name: string;
  last_name: string;
  phone_number?: string;
}

export enum IUsersActionTypes {
  IUSERS_GET_ALL = '@@instructor/users/GET_ALL',
  IUSERS_GET_ALL_INSTRUCTORS = '@@instructor/users/GET_ALL_INSTRUCTORS',
  IUSERS_GET_ALL_STUDENTS = '@@instructor/users/GET_ALL_STUDENTS',
  IUSERS_EDIT = '@@instructor/users/EDIT',
  IUSERS_ADD = '@@instructor/users/ADD',
  IUSERS_DELETE = '@@instructor/users/DELETE',
  IUSERS_SUCCESS = '@@instructor/users/SUCCESS',
  IUSERS_SUCCESS_INSTRUCTORS = '@@instructor/users/SUCCESS_INSTRUCTORS',
  IUSERS_SUCCESS_STUDENTS = '@@instructor/users/SUCCESS_STUDENTS',
  IUSERS_ERROR = '@@instructor/users/ERROR',
}

export interface IUsersState {
  readonly loading: boolean;
  readonly students: User[];
  readonly instructors: User[];
  readonly allUsers: User[];
  readonly errors?: string;
}
