import React, { Component } from 'react';
import {
  NewDelivery,
  ProductQuantity,
} from '../../../../../../store/instructor/delivery';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import {
  FreightCategory,
  FreightPriceAndCategory,
  getFreightPriceAndCategory,
  getSuperCompanyFromCompanyName,
  SuperCompany,
} from '../../../../../../utils/deliveryUtils';
import { getCompanyFromId } from '../../../../../../utils/convertions';
import { Company } from '../../../../../../store/instructor/company';
import { CompanyIdProductsPair } from '../../../../../../store/instructor/company-products';
import { Product } from '../../../../../../store/instructor/product';

type SetRequisitionInStepParent = (newDelivery: NewDelivery) => void;
type SetDeliveryTotalCostInStepParent = (deliveryTotalCost: {
  total_amount_without_vax: number;
  total_vax: number;
}) => void;

interface ProductAmountMismatch {
  product: Product;
  amountDifference: number;
}

interface ProductWithQuantity extends Product {
  quantity: string;
}

interface PropsFromParent {
  setRequisitionInStepParent: SetRequisitionInStepParent;
  setDeliveryTotalCostInStepParent: SetDeliveryTotalCostInStepParent;
  products: any;
  to_company_id: any;
  from_company_id: any;
  setIndicator: any;
  requisition_id: number;
  userSuperCompany: SuperCompany;
  allCompanies: Company[];
  allCompanyProductsPairs: CompanyIdProductsPair[];
}

interface ThisStateProps {
  indicator: string;
  isUfranco: boolean;
  freightCategory: FreightCategory;
  freightPaymentValue: number;
  totals: {
    total_amount_without_vax: number;
    total_vax: number;
    total_with_freight_without_vax: number;
    total_with_freight_with_vax: number;
  };
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

class Checkout extends Component<PropsFromParent, ThisStateProps> {
  constructor(props: Readonly<PropsFromParent>) {
    super(props);
    this.state = {
      indicator: '',
      totals: {
        total_amount_without_vax: 0,
        total_vax: 0,
        total_with_freight_without_vax: 0,
        total_with_freight_with_vax: 0,
      },
      isUfranco: false,
      freightCategory: FreightCategory.FRANCO,
      freightPaymentValue: 0,
    };
  }

  getCompanyProducts = (
    companyId: number,
    allCompanyProductsPairs: CompanyIdProductsPair[],
  ): Product[] => {
    const companyIdProductsPair: CompanyIdProductsPair[] = allCompanyProductsPairs.filter(
      elem => elem.companyId === companyId,
    );

    if (companyIdProductsPair.length === 1) {
      //Found the right one
      return companyIdProductsPair[0].products;
    } else if (companyIdProductsPair.length > 1) {
      //Should never happen
      throw Error;
    } else {
      return [];
    }
  };

  //TODO-future: this is not TYPESAFE!! It does not return what is promised! Type of array is array
  formatProducts(): ProductQuantity[] {
    let products: ProductQuantity[] = [];

    this.props.products.forEach(product => {
      let formattedProduct: any = [
        parseInt(product.id),
        parseInt(product.quantity), //TODO-future: does this even work?! Product has no .quantity field! //Answer: it does have .quantity.. it is added by table. So product is type Product + quantity //.quantity is NOT of type number!!
        product.is_service,
      ];
      products.push(formattedProduct);
    });

    return products;
  }

  formatDataToNewDelivery(): NewDelivery {
    const deliveryCategory = this.state.isUfranco
      ? this.state.freightCategory
      : FreightCategory.D; //Delivery is uFranco, our only option atm, is to set it to D. Api ignores this
    return {
      requisition_id: this.props.requisition_id,
      products: this.formatProducts(),
      franko: !this.state.isUfranco,
      delivery_company_id: 81, //TODO-future: currently: hardcoded shipping company id
      delivery_category: deliveryCategory.toLowerCase(),
    };
  }

  getProductAmountMismatches(): ProductAmountMismatch[] {
    let cartProductsWithAmount: ProductQuantity[] = [];

    this.props.products.forEach(product => {
      let formattedProduct: ProductQuantity = {
        product_id: product.id,
        quantity: parseInt(product.quantity),
        is_service: product.is_service,
      };
      cartProductsWithAmount.push(formattedProduct);
    });

    const companyProduct: Product[] = this.getCompanyProducts(
      this.props.userSuperCompany,
      this.props.allCompanyProductsPairs,
    );

    let productMismatches: ProductAmountMismatch[] = [];
    companyProduct.forEach((elemAllProd: Product) => {
      cartProductsWithAmount.forEach((elemCartProd: ProductQuantity) => {
        if (elemAllProd.id === elemCartProd.product_id) {
          if (!elemCartProd.is_service) {
            if (elemCartProd.quantity > elemAllProd.amount) {
              productMismatches.push({
                product: elemAllProd,
                amountDifference: elemCartProd.quantity - elemAllProd.amount,
              });
            }
          }
        }
      });
    });

    return productMismatches;
  }

  validate = () => {
    const errors = {
      requisition_id: '',
      to_company_id: '',
      franco: '',
      productsAmount: '',
      noProductsInCart: '',
    };

    let productQty: number = 0;

    // If there is added a product where the quantity is 0 added, we do not iterate through the list, otherwise we do
    if (
      !this.props.products.some(product => parseInt(product.quantity) === 0)
    ) {
      this.props.products.forEach((product: ProductWithQuantity) => {
        productQty += parseInt(product.quantity);
      });
    }

    if (productQty < 1 || isNaN(productQty)) {
      errors.noProductsInCart =
        'Der skal være mindst et produkt i kurven for at gå videre';
    } else {
      const productMismatches: ProductAmountMismatch[] = this.getProductAmountMismatches();
      if (productMismatches.length > 0) {
        let errorString: string =
          'De følgende vare(r) har ikke en tilstrækkelig mængde på lager:\n';
        productMismatches.forEach(elem => {
          errorString = errorString.concat(
            elem.product.navision_id +
              ' ' +
              elem.product.name +
              '. Manglende antal: ' +
              elem.amountDifference +
              '\n',
          );
        });
        errors.productsAmount = errorString;
      } else if (this.props.requisition_id < 0) {
        errors.requisition_id = 'Indtast et købsordre id.';
      } else if (this.props.to_company_id < 0) {
        errors.to_company_id = 'Vælg venligst en modtager.';
      } else {
        const correctData: FreightPriceAndCategory = getFreightPriceAndCategory(
          this.getTotalOrderPrice(),
          this.getSuperCompanyType(),
        );

        //Is the state of franco and ufranco right?
        if (!this.state.isUfranco) {
          if (correctData.category !== FreightCategory.FRANCO) {
            errors.franco = 'Den korrekte type er ufranko.';
          }
        } else {
          if (correctData.category === FreightCategory.FRANCO) {
            errors.franco = 'Beløbet er for højt til franko.';
          } else {
            //At this point: the user has selected ufranco, and that is correct. Maybe not categori is selected?
            if (this.state.freightCategory === FreightCategory.FRANCO) {
              errors.franco = 'Vælg venligst en franko categori.';
            } else if (this.state.freightPaymentValue < 1) {
              errors.franco = 'Indtast venligst et beløb for fragt.';
            } else {
              //At this point: franco and a category has been selected, and the price has been entered
              if (this.state.freightCategory !== correctData.category) {
                errors.franco =
                  'Den forkerte categori er valgt. Fragt er kategori: ' +
                  correctData.category.toString();
              } else if (this.state.freightPaymentValue !== correctData.price) {
                errors.franco =
                  'Den forkerte fragt pris er indtastet. Fragt prisen er: ' +
                  correctData.price;
              }
            }
          }
        }
      }
    }

    return errors;
  };

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (this.props.products !== prevProps.products) {
      this.getTotals();
    }
  }

  getSuperCompanyType(): SuperCompany {
    const foundCompany: Company | undefined = getCompanyFromId(
      this.props.allCompanies,
      this.props.userSuperCompany,
    );
    if (foundCompany === undefined) {
      throw Error;
    }
    return getSuperCompanyFromCompanyName(foundCompany.name);
  }

  getTotalOrderPrice(): number {
    let sum: number = 0;
    this.props.products.forEach(
      elem => (sum = sum + elem.quantity * elem.price_unit),
    );
    return sum;
  }

  getTotals(): { total_amount_without_vax: number; total_vax: number } {
    let total_amount_without_vax: number = 0;
    let total_vax: number = 0;
    let total_with_freight_without_vax: number = 0;
    let total_with_freight_with_vax: number = 0;

    this.props.products.forEach(product => {
      total_amount_without_vax += product.quantity * product.price_unit;

      if (!product.is_vax_free) {
        total_vax += product.quantity * product.price_unit * 0.25;
      }
    });

    const shippingCost: number = getFreightPriceAndCategory(
      total_amount_without_vax,
      this.getSuperCompanyType(),
    ).price;

    total_with_freight_without_vax = total_amount_without_vax + shippingCost;
    total_with_freight_with_vax =
      total_amount_without_vax + total_vax + shippingCost * 1.25;

    this.setState({
      totals: {
        total_amount_without_vax,
        total_vax,
        total_with_freight_without_vax,
        total_with_freight_with_vax,
      },
    });

    return { total_amount_without_vax, total_vax };
  }

  handleCheckout() {
    const errors = this.validate();

    if (
      Object.keys(errors.requisition_id).length === 0 &&
      Object.keys(errors.to_company_id).length === 0 &&
      Object.keys(errors.franco).length === 0 &&
      Object.keys(errors.productsAmount).length === 0 &&
      Object.keys(errors.noProductsInCart).length === 0
    ) {
      this.props.setIndicator('Sucessfully checked out', true);
      this.props.setRequisitionInStepParent(this.formatDataToNewDelivery());
      //Below given value should be: "total m. moms m. fragt"
      this.props.setDeliveryTotalCostInStepParent(this.getTotals());
    } else {
      if (Object.keys(errors.requisition_id).length !== 0) {
        this.props.setIndicator(
          'Fejl ved checkout.. ' + errors.requisition_id,
          false,
        );
      } else if (Object.keys(errors.to_company_id).length !== 0) {
        this.props.setIndicator(
          'Fejl ved checkout.. ' + errors.to_company_id,
          false,
        );
      } else if (Object.keys(errors.franco).length !== 0) {
        this.props.setIndicator('Fejl ved checkout.. ' + errors.franco, false);
      } else if (Object.keys(errors.productsAmount).length !== 0) {
        this.props.setIndicator(
          'Fejl ved checkout.. ' + errors.productsAmount,
          false,
        );
      } else if (Object.keys(errors.noProductsInCart).length !== 0) {
        this.props.setIndicator(
          'Fejl ved checkout.. ' + errors.noProductsInCart,
          false,
        );
      }
    }
  }

  onFreightCategoryChange(field: FreightCategory, value) {
    if (this.state.freightCategory === field) {
      this.setState({ freightCategory: FreightCategory.FRANCO });
    } else {
      this.setState({ freightCategory: field });
    }
  }

  render() {
    return (
      <div>
        {this.props.products.length > 0 ? (
          <div>
            <p className="mr-sm-2">
              Total u. moms:{' '}
              {formatter.format(this.state.totals.total_amount_without_vax)}
            </p>
            <p className="mr-sm-2">
              Total m. moms:{' '}
              {formatter.format(
                this.state.totals.total_amount_without_vax +
                  this.state.totals.total_vax,
              )}
            </p>
            <p className={'mr-sm-2'}>
              Total u. moms m. fragt:{' '}
              {formatter.format(
                this.state.totals.total_with_freight_without_vax,
              )}
            </p>
            <p className={'mr-sm-2'}>
              Total m. moms m. fragt:{' '}
              {formatter.format(this.state.totals.total_with_freight_with_vax)}
            </p>
          </div>
        ) : (
          ''
        )}

        <FormGroup tag="fieldset">
          <legend>Fragt muligheder:</legend>
          <FormGroup>
            <Label check>
              <Input
                type="radio"
                name="radioGroupType"
                checked={!this.state.isUfranco}
                onChange={e => {
                  this.setState({ isUfranco: false });
                }}
              />{' '}
              Franko
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                name="radioGroupType"
                checked={this.state.isUfranco}
                onChange={e => {
                  this.setState({ isUfranco: true });
                }}
              />{' '}
              Ufranko
            </Label>
          </FormGroup>
        </FormGroup>

        {this.state.isUfranco ? (
          <>
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="checkboxGroupFreight"
                    checked={this.state.freightCategory === FreightCategory.A}
                    onChange={e =>
                      this.onFreightCategoryChange(
                        FreightCategory.A,
                        e.target.value,
                      )
                    }
                  />{' '}
                  A: 50kr
                </Label>
              </FormGroup>
              <FormGroup>
                <Label check>
                  <Input
                    type="checkbox"
                    name="checkboxGroupFreight"
                    checked={this.state.freightCategory === FreightCategory.B}
                    onChange={e =>
                      this.onFreightCategoryChange(
                        FreightCategory.B,
                        e.target.value,
                      )
                    }
                  />{' '}
                  B: 100kr
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="checkboxGroupFreight"
                    checked={this.state.freightCategory === FreightCategory.C}
                    onChange={e =>
                      this.onFreightCategoryChange(
                        FreightCategory.C,
                        e.target.value,
                      )
                    }
                  />{' '}
                  C: 150kr
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="checkboxGroupFreight"
                    checked={this.state.freightCategory === FreightCategory.D}
                    onChange={e =>
                      this.onFreightCategoryChange(
                        FreightCategory.D,
                        e.target.value,
                      )
                    }
                  />{' '}
                  D: 200kr
                </Label>
              </FormGroup>
            </FormGroup>

            <Form inline>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Label className="mr-sm-2">Fragt pris:</Label>
                <Input
                  name="freightPaymentValue"
                  type="number"
                  placeholder="Indtast beløb for fragt"
                  onChange={e => {
                    this.setState({
                      freightPaymentValue: parseInt(e.target.value),
                    });
                  }}
                />
              </FormGroup>
            </Form>
          </>
        ) : null}

        <button
          onClick={() => this.handleCheckout()}
          className={'btn btn-success'}
        >
          Opret
        </button>
      </div>
    );
  }
}

export default Checkout;
