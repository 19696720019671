import { Reducer } from 'redux';
import { IUsersActionTypes, IUsersState } from './types';

export const initialState: IUsersState = {
  loading: false,
  errors: undefined,
  students: [],
  instructors: [],
  allUsers: [],
};

const reducer: Reducer<IUsersState> = (state = initialState, action) => {
  switch (action.type) {
    case IUsersActionTypes.IUSERS_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IUsersActionTypes.IUSERS_GET_ALL_INSTRUCTORS: {
      return {
        ...state,
        loading: true,
      };
    }
    case IUsersActionTypes.IUSERS_GET_ALL_STUDENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case IUsersActionTypes.IUSERS_EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case IUsersActionTypes.IUSERS_ADD: {
      return {
        ...state,
        loading: true,
      };
    }
    case IUsersActionTypes.IUSERS_DELETE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IUsersActionTypes.IUSERS_SUCCESS: {
      return {
        ...state,
        allUsers: [...action.payload.students, ...action.payload.instructors],
        students: action.payload.students,
        instructors: action.payload.instructors,
        loading: false,
      };
    }
    case IUsersActionTypes.IUSERS_SUCCESS_INSTRUCTORS: {
      return {
        ...state,
        instructors: action.payload,
        loading: false,
      };
    }
    case IUsersActionTypes.IUSERS_SUCCESS_STUDENTS: {
      return {
        ...state,
        students: action.payload,
        loading: false,
      };
    }
    case IUsersActionTypes.IUSERS_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as IUsersReducer };
