import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import styled from 'styled-components';
import TaxContent from './taxContent';

const StudentShipping: React.FC = () => {
  return (
    <StudentPageLayout>
      <PageHeader className={'pb-3'}>
        <h2>SKAT</h2>
      </PageHeader>

      <TaxContent />
    </StudentPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(StudentShipping);
