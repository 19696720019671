import { lightTheme } from '../../../styles/themes';
import { ThemeTypes } from './types';

const initialState = {
  theme: lightTheme,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ThemeTypes.THEME_SWITCH:
      return {
        ...state,
        theme: action.payload,
      };

    default:
      return state;
  }
};

export { reducer as themeReducer };
