import React from 'react';
import { Toast } from 'react-bootstrap';
import Moment from 'react-moment';
import { useToast } from '../../hooks/UseToast';

const ToastAlert = ({ header, message, type, id }) => {
  const time = new Date();
  const { deleteToast } = useToast();

  return (
    <>
      <Toast
        show={true}
        onClose={() => {
          deleteToast(id);
        }}
        style={{
          position: 'relative',
        }}
      >
        <Toast.Header
          style={{
            backgroundColor: type === 'success' ? '#d9ffdb' : '#ffcccc',
          }}
        >
          <strong className="mr-auto">{header}</strong>
          <small style={{ marginLeft: 50 }}>
            <Moment fromNow={true}>{time}</Moment> (
            <Moment format="H:mm">{time}</Moment>)
          </small>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </>
  );
};

export default ToastAlert;
