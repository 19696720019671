import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { store } from '../../../store/store';
import {
  bankAccountAdd,
  BankAccountTypeNumber,
  NewBankAccount,
} from '../../../store/instructor/bank';
import styled from 'styled-components';
import { Company } from '../../../store/instructor/company';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  ownerId: number;
  company?: Company;
  componentType: AddBankAccountModalType;
}

export enum AddBankAccountModalType {
  STUDENT,
  COMPANY,
}

interface ThisCompState {
  newAccount: {
    name: string;
    startBalance: string;
  };
  localErrors: {
    emptyField: string;
  };
}

class AddBankAccountModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      newAccount: {
        name: '',
        startBalance: '',
      },
      localErrors: {
        emptyField: '',
      },
    };
  }

  onChange = e => {
    //Check for not allowing characters in the balance field
    if (e.target.name === 'startBalance' && e.target.value !== '') {
      const re = /^[0-9\b]+$/; //No comma allowed. Api defines these as int
      if (!re.test(e.target.value)) {
        return;
      }
    }

    this.setState({
      newAccount: {
        ...this.state.newAccount,
        [e.target.name]: e.target.value,
      },
    });
  };

  validate = () => {
    const errors = {
      emptyField: '',
    };

    const enteredData = this.state.newAccount;

    if (enteredData.name === '') {
      errors.emptyField = 'Kontonavn, kan ikke være tom';
    } else if (enteredData.startBalance === '') {
      errors.emptyField = 'Balance, kan ikke være tom';
    }

    return errors;
  };

  onAddButtonClick = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({
      localErrors: {
        emptyField: errors.emptyField,
      },
    });

    if (Object.keys(errors.emptyField).length === 0) {
      let newBankAccount: NewBankAccount = {
        currencyCode: 1,
        type_id: BankAccountTypeNumber.COMPANY, //Gets overwritten below
        name: this.state.newAccount.name,
        balance: parseInt(this.state.newAccount.startBalance),
      };
      if (this.props.componentType === AddBankAccountModalType.STUDENT) {
        newBankAccount.userId = this.props.ownerId;
        newBankAccount.type_id = BankAccountTypeNumber.STUDENT;
      } else if (this.props.componentType === AddBankAccountModalType.COMPANY) {
        newBankAccount.companyId = this.props.ownerId;
      } else {
        throw new Error('New type has been added but not handled!');
      }
      store.dispatch(bankAccountAdd(newBankAccount));
      this.props.visibilityToggleFunc();
    }
  };

  renderContent = () => {
    return (
      <div className="px-3 pt-0 pb-3">
        <Form>
          {this.props.componentType === AddBankAccountModalType.COMPANY ? (
            <Row>
              <Form.Label>Virksomhedsnavn</Form.Label>
              <Form.Control placeholder={this.props.company?.name} readOnly />
            </Row>
          ) : null}
          {this.props.componentType === AddBankAccountModalType.STUDENT ? (
            <Row>
              <Form.Label>Bruger</Form.Label>
              <Form.Control
                placeholder={this.props.ownerId.toString()}
                readOnly
              />
            </Row>
          ) : null}
          <Row>
            <Form.Label>Kontonavn</Form.Label>
            <Form.Control
              placeholder="Indtast kontonavn"
              name={'name'}
              value={this.state.newAccount.name}
              onChange={this.onChange}
            />
          </Row>
          <Row>
            <Form.Label>Start saldo</Form.Label>
            <Form.Control
              placeholder="Saldo"
              name={'startBalance'}
              value={this.state.newAccount.startBalance}
              onChange={this.onChange}
            />
          </Row>
        </Form>
        <ErrorDiv>{this.state.localErrors.emptyField}</ErrorDiv>
      </div>
    );
  };

  public render() {
    return (
      <>
        <p>Enter the new information and press save to confirm changes</p>
        <>{this.renderContent()}</>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onAddButtonClick}>
            Save
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

export default connect()(AddBankAccountModalData);
