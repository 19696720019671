import { HomemadeDate } from '../../../utils/homemadeDate';

export interface ProductHistoryChange {
  date: HomemadeDate;
  delta: number;
  stock_after_delivery: number;
  navision_id: string;
  delivery_id: number;
}

export interface StockHistoryDTO {
  id: number;
  historyDate: Date;
  deliveryId: number;
  productId: number;
  amount: number;
  beforeAmount: number;
  newAmount: number;
  senderCompanyId: number;
  receiverCompanyId: number;
  navisionId: string;
}

export enum IProductHistoryActionTypes {
  IPRODUCT_HISTORY_GET = '@@productHistory/GET',
  IPRODUCT_HISTORY_SUCCESS = '@@productHistory/SUCCESS',
  IPRODUCT_HISTORY_ERROR = '@@productHistory/ERROR',
}

export interface IProductHistoryState {
  readonly loading: boolean;
  readonly productHistory: StockHistoryDTO[];
  readonly errors?: string;
}
