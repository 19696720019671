import React from 'react';
import { ModalContextState, useModal } from '../hooks/useModal';
import ModalLayout from '../components/modal/modalLayout';

let ModalContext: React.Context<ModalContextState>;
let { Provider } = (ModalContext = React.createContext(
  {} as ModalContextState,
));

let ModalProvider = ({ children }) => {
  let {
    modal,
    handleModal,
    modalContent,
    title,
    width,
    showModal,
  } = useModal();

  return (
    <Provider
      value={{ modal, handleModal, modalContent, title, width, showModal }}
    >
      <ModalLayout />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
