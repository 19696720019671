import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { Company } from '../../../../../store/instructor/company';
import { Invoice } from '../../../../../store/instructor/orderInvoice';
import InstructorModal from '../../../../../components/modal/instructorModal';
import ViewInvoiceModalData from '../../../shipping/featureModal/viewInvoiceModalData';
import CustomTable from '../../../../../components/table/customTable';
import { filterBySimilarity } from '../../../../../utils/filteringAlgorithms';
import LoadingComponent from '../../../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
  company: Company;
  invoices: Invoice[];
  allCompanies: Company[];
}

interface ThisCompState {
  selectedInvoice?: Invoice;
  modalViewInvoiceVisible: boolean;
  tableData: Invoice[];
  tableHeader: any[];
}

/** TODO-future: could be replaced with a generic component for showing invoices  */
/** This tab is used in the companyOverview tab, as a generic tab for a company. */
class CompanyTab extends React.Component<PropsFromParent, ThisCompState> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      modalViewInvoiceVisible: false,
      tableData: [],
      tableHeader: [
        {
          title: 'Købsodre nr.',
          field: 'requisition_id',
          editable: 'never',
        },
        {
          title: 'Faktura dato',
          field: 'invoice_date',
          editable: 'never',
          customFilterAndSearch: (term, rowData) =>
            filterBySimilarity(term, rowData.invoice_date.getString()),
          render: data => <span>{data.invoice_date.getString()}</span>,
        },
        {
          title: 'Total',
          field: 'amount',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.setStateTableData();
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.invoices !== this.props.invoices) {
      this.setStateTableData();
    }
  }

  setStateTableData = () => {
    this.setState({ tableData: this.props.invoices });
  };

  handleModalViewInvoiceVisibility = () => {
    this.setState({
      modalViewInvoiceVisible: !this.state.modalViewInvoiceVisible,
    });
  };

  renderContent = () => {
    return (
      <CustomTable
        data={this.state.tableData}
        headers={this.state.tableHeader}
        isLoading={this.props.loading}
        actions={[
          {
            icon: 'description',
            tooltip: 'Vis detaljer',
            onClick: (event, invoice) => {
              this.setState({ selectedInvoice: invoice });
              this.handleModalViewInvoiceVisibility();
            },
          },
        ]}
      />
    );
  };

  public render() {
    return (
      <>
        <div>
          {this.props.loading ? (
            <div className={'d-flex justify-content-center'}>
              <LoadingComponent visible={this.props.loading} />
            </div>
          ) : (
            this.renderContent()
          )}
        </div>

        <InstructorModal
          modalState={this.state.modalViewInvoiceVisible}
          showFunc={this.handleModalViewInvoiceVisibility}
          titleHeader="View invoice"
        >
          <ViewInvoiceModalData
            visibilityToggleFunc={this.handleModalViewInvoiceVisibility}
            invoice={this.state.selectedInvoice!}
            allCompanies={this.props.allCompanies}
          />
        </InstructorModal>
      </>
    );
  }
}

const mapStateToProps = ({ iInvoice }: ApplicationState) => ({
  loading: iInvoice.loading,
});

export default connect(mapStateToProps)(CompanyTab);
