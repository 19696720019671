import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../../store';
import styled from 'styled-components';
import { UserNew, usersAdd } from '../../../../store/instructor/users';
import { store } from '../../../../store/store';
import {
  parseUserType,
  parseUserTypeToInt,
  UserTypes,
} from '../../../../store/user/userAuthentication';
import { Company } from '../../../../store/instructor/company';

type VisibilityToggleFuncType = () => void;

interface PropsFromParentAndStore {
  visibilityToggleFunc: VisibilityToggleFuncType;
  errors?: string;
  allSuperCompanies: Company[];
}

interface ThisCompState {
  newUser: {
    userType: string;
    firstname: string;
    lastname: string;
    username: string; //TODO does users have username or/and email?
    email: string; //TODO does users have username or/and email?
    password1: string;
    password2: string;
    phoneNumber: string;
    company_id: string; //If usetype is student, this field is required
  };
  localErrors: {
    password: string;
    emptyField: string;
    userType: string;
  };
}

class AddUserModalData extends React.Component<
  PropsFromParentAndStore,
  ThisCompState
> {
  constructor(props: PropsFromParentAndStore) {
    super(props);
    this.state = {
      newUser: {
        userType: '',
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        password1: '',
        password2: '',
        phoneNumber: '',
        company_id: '',
      },
      localErrors: {
        password: '',
        emptyField: '',
        userType: '',
      },
    };
  }

  onChange = e => {
    //If selector has changed: removed userType/selector error
    if (e.target.name === 'userType') {
      this.setState({
        localErrors: {
          userType: '',
          emptyField: '',
          password: '',
        },
      });
    }

    this.setState({
      newUser: {
        ...this.state.newUser,
        [e.target.name]: e.target.value,
      },
    });
  };

  validate = () => {
    const errors = {
      password: '',
      emptyField: '',
      userType: '',
    };

    const enteredData = this.state.newUser;

    if (enteredData.userType === 'Vælg..' || enteredData.userType === '') {
      errors.userType = 'Brugertype ikke valgt';
    } else {
      const currentUserType: UserTypes = parseUserType(
        this.state.newUser.userType,
      );
      if (currentUserType === UserTypes.INSTRUCTOR) {
        if (enteredData.phoneNumber === '') {
          errors.emptyField = 'Telefonnummer skal indtastes';
        } else if (enteredData.email.length === 0) {
          errors.userType = 'En instructørs email skal indtastes';
        }
      } else if (currentUserType === UserTypes.STUDENT) {
        if (enteredData.username.length === 0) {
          errors.userType = 'Brugernavn skal indtastes';
        } else if (
          enteredData.company_id === '' ||
          enteredData.company_id === 'Vælg..'
        ) {
          errors.emptyField = 'Brugerns virksomhed skal vælges';
        }
      }

      if (enteredData.firstname.length === 0) {
        errors.emptyField = 'Fornavn skal indtastes';
      } else if (enteredData.lastname.length === 0) {
        errors.emptyField = 'Efternavn skal indtastes';
      } else if (enteredData.password1.length === 0) {
        errors.emptyField = 'Første kodeord skal indtastes';
      } else if (enteredData.password2.length === 0) {
        errors.emptyField = 'Andet kodeord skal indtastes';
      } else if (enteredData.userType.length === 0) {
        errors.emptyField = 'Brugertype skal vælges';
      }

      //Error if non-matching passwords
      if (enteredData.password1 !== enteredData.password2) {
        errors.password = 'De to indtastede kodeord er ikke ens!';
      }
    }

    return errors;
  };

  onAddButtonClick = e => {
    e.preventDefault();
    //validates the entered data (client-side)
    const errors = this.validate();
    this.setState({
      localErrors: {
        password: errors.password,
        emptyField: errors.emptyField,
        userType: errors.userType,
      },
    });

    if (
      Object.keys(errors.password).length === 0 &&
      Object.keys(errors.emptyField).length === 0 &&
      Object.keys(errors.userType).length === 0
    ) {
      let formattedNewUser: UserNew = {
        email: this.state.newUser.email,
        username: this.state.newUser.username,
        type_id: parseUserTypeToInt(parseUserType(this.state.newUser.userType)),
        group_id: 1, //TODO-future: currently not used in api/db
        first_name: this.state.newUser.firstname,
        last_name: this.state.newUser.lastname,
        password_first: this.state.newUser.password1,
        password_second: this.state.newUser.password2,
      };

      if (this.state.newUser.userType === UserTypes.INSTRUCTOR) {
        formattedNewUser.phone_number = this.state.newUser.phoneNumber;
      }

      if (this.state.newUser.userType === UserTypes.STUDENT) {
        //TODO temporary email for all student, because api requires this
        formattedNewUser.email = 'placeholder@student.email';
        formattedNewUser.company_id = parseInt(this.state.newUser.company_id);
      }

      store.dispatch(usersAdd(formattedNewUser));
      this.props.visibilityToggleFunc();
    }
  };

  renderSuperCompanyOptions = (superCompany: Company, index: number) => {
    return (
      <option key={index} value={superCompany.id}>
        {superCompany.name}
      </option>
    );
  };

  renderContent() {
    const currentUserType = this.state.newUser.userType;
    return (
      <>
        {currentUserType.length > 0 && currentUserType !== 'Vælg..' ? (
          <>
            {currentUserType === UserTypes.INSTRUCTOR ? (
              <Row>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="Email"
                  name={'email'}
                  value={this.state.newUser.email}
                  onChange={this.onChange}
                />
              </Row>
            ) : null}
            <Row>
              <Form.Label>Brugernavn</Form.Label>
              <Form.Control
                placeholder="Brugernavn"
                name={'username'}
                value={this.state.newUser.username}
                onChange={this.onChange}
              />
            </Row>
            {currentUserType === UserTypes.INSTRUCTOR ? (
              <Row>
                <Form.Label>Telephone number</Form.Label>
                <Form.Control
                  placeholder="Telefonnummer"
                  name={'phoneNumber'}
                  value={this.state.newUser.phoneNumber}
                  onChange={this.onChange}
                />
              </Row>
            ) : null}
            <Row>
              <Form.Label>Fornavn</Form.Label>
              <Form.Control
                placeholder="Fornavn"
                name={'firstname'}
                value={this.state.newUser.firstname}
                onChange={this.onChange}
              />
            </Row>
            <Row>
              <Form.Label>Efternavn</Form.Label>
              <Form.Control
                placeholder="Efternavn"
                name={'lastname'}
                value={this.state.newUser.lastname}
                onChange={this.onChange}
              />
            </Row>
            {currentUserType === UserTypes.STUDENT ? (
              <Row>
                <Form.Label>Virksomhed</Form.Label>
                <Form.Control
                  as="select"
                  defaultValue="Vælg.."
                  name={'company_id'}
                  value={this.state.newUser.company_id}
                  onChange={this.onChange}
                >
                  <option>Vælg..</option>
                  {this.props.allSuperCompanies.map(
                    this.renderSuperCompanyOptions,
                  )}
                </Form.Control>
              </Row>
            ) : null}
            <Row>
              <Form.Label>Kodeord</Form.Label>
              <Form.Control
                placeholder="Kodeord"
                name={'password1'}
                value={this.state.newUser.password1}
                type={'password'}
                onChange={this.onChange}
              />
            </Row>
            <Row>
              <Form.Label>Gentag kodeord</Form.Label>
              <Form.Control
                placeholder="Kodeord"
                name={'password2'}
                value={this.state.newUser.password2}
                type={'password'}
                onChange={this.onChange}
              />
            </Row>
          </>
        ) : null}
      </>
    );
  }

  public render() {
    return (
      <>
        <p>
          Indtast de ønskede oplysninger nedenfor, og tryk på tilføj for at
          bekræfte
        </p>
        <div className="px-3 pt-0 pb-3">
          {this.props.errors && (
            <div className="alert alert-danger">{this.props.errors}</div>
          )}
          <div>
            <Form>
              <Row>
                <Form.Label>Bruger type</Form.Label>
                <Form.Control
                  as="select"
                  name={'userType'}
                  value={this.state.newUser.userType}
                  onChange={this.onChange}
                >
                  <option key={0}>Vælg..</option>
                  <option key={1} value={'instructor'}>
                    Instruktør
                  </option>
                  <option key={2} value={'student'}>
                    Elev
                  </option>
                </Form.Control>
              </Row>
              {this.renderContent()}
            </Form>
          </div>
          <ErrorDiv>
            {this.state.localErrors.password}
            {this.state.localErrors.emptyField}
            {this.state.localErrors.userType}
          </ErrorDiv>
        </div>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onAddButtonClick}>
            Tilføj
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

const mapStateToProps = ({ iUsers }: ApplicationState) => ({
  loading: iUsers.loading,
  errors: iUsers.errors,
});

export default connect(mapStateToProps)(AddUserModalData);
