import { IDeliveryActionTypes, IDeliveryState } from './types';
import { Reducer } from 'redux';

export const initialState: IDeliveryState = {
  deliveries: [],
  deliveriesFrom: [],
  loading: false,
  loadingDeliveriesFrom: false,
  errors: undefined,

  loadingFromCompany: false,
  loadingToCompanyActive: false,
  loadingToCompanyArchive: false,
  deliveriesFromCompany: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
  deliveriesToCompanyActive: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
  deliveriesToCompanyArchive: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
};

const reducer: Reducer<IDeliveryState> = (state = initialState, action) => {
  switch (action.type) {
    case IDeliveryActionTypes.IDELIVERY_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_GET_FROM: {
      return {
        ...state,
        loadingDeliveriesFrom: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_GET_ALL_FROM_COMP_PAGE: {
      return {
        ...state,
        loadingFromCompany: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ACTIVE_PAGE: {
      return {
        ...state,
        loadingToCompanyActive: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ARCHIVED_PAGE: {
      return {
        ...state,
        loadingToCompanyArchive: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_SET_ARCHIVE_TYPE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_SET_ADDEDTOSTOCK_STATUS: {
      return {
        ...state,
        loading: true,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_SUCCESS: {
      return {
        ...state,
        deliveries: action.payload,
        loading: false,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_FROM_SUCCESS: {
      return {
        ...state,
        deliveriesFrom: action.payload,
        loadingDeliveriesFrom: false,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_GET_ALL_FROM_COMP_PAGE_SUCCESS: {
      return {
        ...state,
        deliveriesFromCompany: action.payload,
        loadingFromCompany: false,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS: {
      return {
        ...state,
        deliveriesToCompanyActive: action.payload,
        loadingToCompanyActive: false,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS: {
      return {
        ...state,
        deliveriesToCompanyArchive: action.payload,
        loadingToCompanyArchive: false,
      };
    }
    case IDeliveryActionTypes.IDELIVERY_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as IDeliveryReducer };
