import React from 'react';
import { ModalContext } from '../../contexts/modalContexts';
import Modal from 'react-bootstrap/Modal';

const ModalLayout: React.FC = props => {
  let { modalContent, handleModal, modal, title, width } = React.useContext(
    ModalContext,
  );

  if (modal) {
    return (
      <Modal
        show={modal}
        onHide={handleModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={width ? 'mx-none w-' + width : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    );
  } else return null;
};

export default ModalLayout;
