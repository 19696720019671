import React from 'react';

export const update_20210902 = () => {
  return (
    <ul>
      <li>
        <strong>Ændringer til franko og ufranko</strong>: franco og ufranco er
        blevet oversat til dansk, både på siden og i fejlbeskeder. Franko og
        ufranko er byttet om: dvs. at ufranko nu har kategorierne A-D, og i
        dette tilfælde er det modtager der betaler fragten. Hvis leveringen er
        franko, vil afsender nu senere modtage en fakatura fra Danish Cargo på
        80kr ex moms.
      </li>
    </ul>
  );
};

export const update_240821 = () => {
  return (
    <ul>
      <li>
        <strong>Opdateret skat- og moms-system, version 1</strong>: både det
        visuelle og backend har fået en del ændringer. Når eleven tilgår
        komponenten, bliver de nu mødt med det overblik over alle indberetning
        for et givent år. På denne side kan man oprette nye indbereninger, ændre
        dem der er under behandling, og se hvilke systemet endnu ikke har
        behandlet. Der er stadig nogle ting der skal afklares, hvilket vil blive
        ordnet over mail korrespondance, men pt. er det live i systemet, og de
        nuværende indberetninger er blevet ført over i det nye system.
      </li>
    </ul>
  );
};

export const update_080821 = () => {
  return (
    <ul>
      <li>
        <strong>Varelager historik</strong>: Bevægelser er nu vist som negative
        og positive tal. En ny kolonne med "Beholdning efter bevægelse" er
        tilføjet, og de resterende kolonner har fået mere meningsgivende navne.
      </li>
      <li>
        <strong>Søgning</strong>: Søge funktioner er blevet re-implementeret til
        at fungere med det nye system flow. Der kan nu søges på dato og andet
        information - også på tværs af sider.
      </li>
      <li>
        <strong>Fejlregister</strong>: Tilføjet Fejlregister side som giver
        overblik og handler for fejl der er lavet af brugere. Dette indkluderer
        undersider for overblik over detaljer for; rekvisitioner, leveringer og
        fakturaer.
      </li>
      <li>
        <strong>Dato-format</strong>: Ny dato felter er blevet tilføjet til
        lister for rekvisitioner, leveringer og fakturaer, der gør det muligt at
        søge i dato intervaller.
      </li>
      <li>
        <strong>Fjerning af rekvisitioner</strong>: Rekvisitioner genereret over
        sommerferien er blevet slettet.
      </li>
    </ul>
  );
};

export const update_300721 = () => {
  return (
    <ul>
      <li>
        <strong>Oversættelse af instruktør-siden</strong>: alle
        komponenter/sider på instruktør-siden har fået det sidste engelsk
        oversat til dansk. Dette inkluderer titler på tabel kolonner, tooltips
        m.m.
      </li>
    </ul>
  );
};

//TODO-future: change date to when this goes on live?
export const update_270721_2 = () => {
  return (
    <ul>
      <li>
        <strong>Varelager historik</strong>: Under kategorien <em>Varelager</em>{' '}
        og fanen <em>varelager</em> er der nu tilføjet et ikon, ud for hvert
        produkt, der tillader visning af en liste med tilføjelse og reduktioner.
      </li>
    </ul>
  );
};

//TODO-future: change date to when this goes on live?
export const update_270721 = () => {
  return (
    <ul>
      <li>
        <strong>
          Fjernelse af <em>kr.</em>
        </strong>
        : i kolonner i konti oversigts fanen, konti faner og{' '}
        <em>Godkend betaling</em>.
      </li>
      <li>
        <strong>Dato</strong>: dato og tidspunkt har nu hver deres kolonne i
        konti fanerne.
      </li>
      <li>
        <strong>Søgbar dato</strong>: dato og tidspunkt er nu søgbare.
      </li>
    </ul>
  );
};

//TODO-future: change date to when this goes on live?
export const update_05052021_2 = () => {
  return (
    <ul>
      <li>
        <strong>Elev inbox</strong>: text boksen til beskeden er nu størrer.
      </li>
      <li>
        <strong>Elev bank</strong>: i ekstern overførsel, er feltet
        <em> invoice id</em> nu oversat til dansk.
      </li>
      <li>
        <strong>Elev levering</strong>: indtast beløb fra andet trin,
        <em> faktura</em>, er nu fjernet og bliver taget fra først trin. Værdien
        der bliver brugt er <em>total m. moms m. fragt</em>.
      </li>
    </ul>
  );
};

//TODO-future: change date to when this goes on live?
export const update_05052021 = () => {
  return (
    <ul>
      <li>
        <strong>Løn</strong>: I fanen privat bank har eleven nu adgang til at
        oprette overførsler fra deres lønkonto til en af virksomheds konti.
      </li>
    </ul>
  );
};

export const update_14032021 = () => {
  return (
    <ul>
      <li>
        <strong>Elev &gt; Indbakke</strong>: Vi har fikset at man kan sende
        mails mellem firmaer (e.g. Fra MixIT til 4you only)
      </li>
      <li>
        <strong>Elev &gt; Indbakke</strong>: Man kan nu kun se modtagere som er
        relateret til ens firma (f.eks. Kan man ikke se firmaer fra EDP når man
        er logget på som Mix)
      </li>
      <li>
        <strong>Instruktør &gt; Post og pakker: </strong> Denne side tillader
        instruktører at se alle beskeder som der er blevet sendt, og se enkelte
        firmaers modtagne post. Derudover kan instruktører sende mails ved at gå
        ind på den pågældende firma og trykke &quot;Opret ny besked&quot;. Her
        vil modtageren få mailen tilsendt hvor det pågældende firma er
        afsenderen.
      </li>
      <li>
        <strong>Instruktør &gt; Bank:</strong> Denne side er blevet
        implementeret til at give instruktører en oversigt over alle firma
        kontoer som findes i systemet. Og derudover så giver det mulighed for
        instruktører at lave overførsler på vegne af en leverandør eller kunde.
        Dette gøres ved at instruktøren vælger den pågældende virksomhed og
        trykker derefter &quot;Opret ny overførsel&quot;.
      </li>
      <li>
        <strong>Varelager &gt; Modtagne leveringer:</strong> Førhen ville
        robotten oprette en levering med alt fragt som kunne findes på denne
        side. Dette er ikke tilfældet længere og i modtager ikke længere en
        levering for fragten. De leveringer som den har opretter før, de vil
        fortsat være der indtil vi får ryddet op i databasen.
      </li>
    </ul>
  );
};

export const update_07032021 = () => {
  return (
    <ul>
      <li>
        Alle fragt fakturaer samles nu i en samlet faktura. Denne faktura sendes
        til virksomhederne klokken 15:00 alle dage.
      </li>
      <li>
        Det er fra nu af muligt at genindlæse på hjemmesiden uden at man skal
        til forsiden for at komme ind igen.
      </li>
    </ul>
  );
};

export const update_01032021_2 = () => {
  return (
    <ul>
      <li>
        <p>
          <strong>Ordregeneratoren:</strong> sender hermed rigtigt antal
          rekvisitioner. I må meget gerne være ekstra opmærksomme på om det ikke
          er tilfældet, da vi kun har testet en times tid. Men det ser
          umiddelbart korrekt ud nu.
        </p>
      </li>
      <li>
        <p>
          <strong>Ordregeneratoren:</strong> Leveringer oprettes en gang om
          dagen. Lige nu er det sat til at ske hver dag mellem 05:00 til 06:00,
          så det er inden i møder om morgenen. Planen er at vi vil bruge de
          rigtige leveringsrum som i har oplyst, men dette var det hurtigste
          fiks til i morgen.
        </p>
      </li>
      <li>
        <p>
          <strong>Levering</strong>: Leveringer oprettes nu ligesom da det
          virkede før.
        </p>
      </li>
      <li>
        <p>
          <strong>Overførsler</strong> fra robotten har nu en besked som følger
          følgende struktur: <em>Betaling for faktura_navision_id</em> hvor
          faktura_navision_id selvfølgelig er ID&#39;et i har oplyst.
        </p>
        <p>
          Bemærk dog at de overførsler der er oprettet indtil nu, fortsat har
          forkerte beskeder, det forventer vi at få ændret i løbet af i morgen.
          Hvis det haster, så skal i sige til så smider vi det øverst på vores
          liste.
        </p>
      </li>
      <li>
        <p>
          <strong>Bank</strong>:
        </p>
        <ul>
          <li>
            Fanen <em>godkend betaling</em>, er der tilføjet en kolonne med
            hvilket firma betalingen er til.
          </li>
          <li>
            Fanen <em>konti</em>, konti oversigt, er der tilføjet kolonne for
            hvilket firma overførslen kommer fra.
          </li>
        </ul>
      </li>
      <li>
        <strong>Faktura</strong>:
        <ul>
          <li>
            handling, vis detaljer, <em>betalingsbetingelser</em> er nu ændret
            til formattet <em>x dage</em>.
          </li>
          <li>
            handling, vis detaljer, hvis fakturaen er fra{' '}
            <strong>Danish Cargo</strong>, er <em>fragt for købsordre</em>{' '}
            ændret til <em>fragt for salgsordre</em>.
          </li>
          <li>
            handling, vis detaljer, feltet <em>navision id fra faktura</em>{' '}
            viser nu id fra købsordre.
          </li>
        </ul>
      </li>
    </ul>
  );
};

export const update_01032021_1 = () => {
  return (
    <ul>
      <li>
        <strong>Bank:</strong> Besked felt er blevet aktiveret. Når der oprettes
        en overførsel skal brugeren altid indtaste en besked. Denne vises på
        konto oversigten.
      </li>
      <li>
        <strong>Faktura:</strong> Faktura PDF&#39;en indeholder nu både Faktura
        dato og Betalingsbetingelser{' '}
      </li>
      <li>
        <strong>Instruktør forside</strong>: Vi har valgt at gøre forsiden til
        en <em>Versionsoversigt</em> hvor i kan finde en oversigt over de
        opdateringer der er blevet foretaget og se hvad der er blevet opdateret.
        Vi håber på at kunne holde denne opdateret hver gang der implementeres
        noget nyt.
      </li>
      <li>
        <strong>Elev forside:</strong> Vi har oprettet en simpel forside hvor
        system logoet er i midten og under den er der to kolonner med tekst i.{' '}
      </li>
      <li>
        <strong>Levering:</strong> Vi har tilføjet to felter;{' '}
        <em>Total m. moms m. fragt</em> og <em>Total u. moms m. fragt</em> som
        viser totallen for leveringen med fragt.{' '}
      </li>
    </ul>
  );
};

export const update_21022021 = () => {
  return (
    <ul>
      <li>
        <strong>Design:</strong> Farve ændringer på navigation bar
      </li>
      <li>
        <strong>Design:</strong> Faner har fået et nyt design
      </li>
      <li>
        <strong>Design:</strong> Kurven i <em>Købsordre</em> og{' '}
        <em>Ny Levering</em> har fået et nyt design for at gøre det mere
        overskueligt
      </li>
      <li>
        <strong>Design:</strong> Tabellerne har fået fjernet kolonnen{' '}
        <em>Handling</em> og titlerne på de handlinger man kan lave kan nu
        findes ved at holde musen over.
      </li>
      <li>
        <strong>Design:</strong> Alle beløber i tabellerne følger nu samme type
        formatering (punktummer og kommaer).
      </li>
      <li>
        <strong>Faktura:</strong> Arkiverings funktion af fakturaer er blevet
        tilføjet
      </li>
      <li>
        <strong>Fragt:</strong> Fragt bliver nu udregnet og kan nu betales. Hvis
        en ny levering bliver sendt, og fragttype er franko, så modtager elevens
        firma en faktura med betalingsoplysninger og prisen på fragten. Hvis
        fragttypen er ufranko, betaler modtager, og i vores tilfælde er dette
        robotten. I begge tilfælde vil fragt firmaet modtage betalingen for
        fragten.
      </li>
      <li>
        <strong>Fragt:</strong> Når der oprettes fragt så modtager man en
        faktura fra fragt firmaet.
      </li>
      <li>
        <strong>Ordregenerator:</strong> En instruktør kan styre
        ordregeneratoren for hvert super firma (EDP, MIXIT).
      </li>
      <li>
        <strong>Ordregenerator:</strong> Robotten tager nu højde for det
        interval og tidsrum som der er angivet i ordregeneratoren når den
        opretter rekvisitioner.
      </li>
      <li>
        <strong>Ordregenerator:</strong> En Robot Konsol er blevet tilføjet.
        Ideen med den er at i vil kunne sende forespørgsler til robotten direkte
        fra ordregenerator siden.
        <ul>
          <li>
            Vi har tilføjet &quot;Opret rekvisitioner&quot; funktion, som gør
            det muligt at sende forespørgsler på x antal rekvisitioner for et
            givet firma til robotten. Med andre ord, så kan i sige til robotten
            at i vil have mixit får 100 rekvisitioner, også opretter den dem med
            det samme.
          </li>
        </ul>
      </li>
    </ul>
  );
};
