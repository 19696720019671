import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { IProductHistoryActionTypes, StockHistoryDTO } from './types';
import { getEndpoint } from '../../../utils/api';
import { productHistoryError, productHistorySuccess } from './actions';

function* handleGet(action) {
  try {
    const res = yield getEndpoint(
      'product/' + action.payload.companyId + '/' + action.payload.productId,
    );

    if (res.error) {
      yield put(productHistoryError(res.error));
    } else {
      let parsedHistoryChanges: StockHistoryDTO[] = [];
      res.data.forEach(elem => {
        const parsedHistory: StockHistoryDTO = {
          ...elem,
          historyDate: new Date(elem.historyDate),
        };
        parsedHistoryChanges.push(parsedHistory);
      });

      yield put(productHistorySuccess(parsedHistoryChanges));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchIProductHistory() {
  yield takeEvery(IProductHistoryActionTypes.IPRODUCT_HISTORY_GET, handleGet);
}

function* iProductHistorySaga() {
  yield all([fork(watchIProductHistory)]);
}

export { iProductHistorySaga };
