import { Requisition } from '../requisitions';
import { HomemadeDate } from '../../../utils/homemadeDate';
import { ErrorMessage } from '../orderInvoice';

export enum MistakeTypes {
  Invoice = 'Invoice',
  Transaction = 'Transaction',
  Requisition = 'Requisition',
}

export interface Mistake {
  id: number;
  created_by_id: number;
  type: MistakeTypes;
}

export interface MistakesTransaction extends Mistake {
  amount: number;
  bank_account_from_balance: number;
  bank_account_to_balance: number;
  created_at: HomemadeDate;
  errorMessages: ErrorMessage[];
  from_bank_account_id: number;
  handled: boolean;
  message: string;
  to_bank_account_id: number;
}

export interface MistakesInvoice extends Mistake {
  mistake: string;
  invoiceDate: HomemadeDate;
  to_bank_account_id: number;
  amount: number;
  requisitionId: number;
}

export interface MistakesRequisition extends Mistake {
  difference: number;
  underpaid: boolean;
  requisition: Requisition;
}

export interface IMistakes {
  transactions: MistakesTransaction[];
  invoices: MistakesInvoice[];
  requisitions: MistakesRequisition[];
}

export enum IMistakesActionTypes {
  IMISTAKES_FETCH_ALL = '@@instructor/mistakes/FETCH_ALL',
  IMISTAKES_SUCCESS = '@@instructor/mistakes/SUCCESS',
  IMISTAKES_ERROR = '@@instructor/mistakes/ERROR',
}

export interface IMistakesState {
  readonly mistakes: IMistakes;
  readonly loading: boolean;
  readonly errors?: string;
}
