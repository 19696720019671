import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { InvoiceDetailsActionTypes } from './types';
import { getEndpointId } from '../../../utils/api';
import { invoiceDetailsError, invoiceDetailsSuccess } from './actions';

function* handleGet(action) {
  try {
    const res = yield getEndpointId(
      'requisition',
      action.payload.requisitionId,
    );

    if (res.error) {
      yield put(invoiceDetailsError(res.error));
    } else {
      yield put(invoiceDetailsSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchInvoiceDetails() {
  yield takeEvery(
    InvoiceDetailsActionTypes.INVOICEDETAILS_GET_REQUISITION,
    handleGet,
  );
}

function* iInvoiceDetailsSaga() {
  yield all([fork(watchInvoiceDetails)]);
}

export { iInvoiceDetailsSaga };
