import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ProductNoIdAndTypeCode } from '../../../../../../store/instructor/product';
import { ProductType } from '../../../../../../store/instructor/productType';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { Field, Form as FormikForm, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { addProductBasedOnObject } from '../../../../../../utils/api';
import { ModalContext } from '../../../../../../contexts/modalContexts';
import { Company } from '../../../../../../store/instructor/company';

interface PropsFromParent {
  productTypes: ProductType[];
  selectedCompany: number;
  creditors: Company[];
}

const AddProductModalData: React.FC<PropsFromParent> = ({
                                                          productTypes,
                                                          selectedCompany,
                                                          creditors,
                                                        }) => {
  const queryClient = useQueryClient();
  const { showModal } = React.useContext(ModalContext);
  const { mutate: addProduct } = useMutation(addProductBasedOnObject, {
    onSuccess: () => {
      queryClient.refetchQueries('products');
    },
  });

  const addProductRequirementSchema: SchemaOf<ProductNoIdAndTypeCode> = Yup.object().shape(
    {
      name: Yup.string().max(50).defined().min(2),
      price_cost: Yup.number().defined().positive(),
      price_unit: Yup.number().defined().positive(),
      product_type_id: Yup.number().defined().integer(),
      navision_id: Yup.number().defined().integer(),
      is_vax_free: Yup.boolean().defined(),
      is_service: Yup.boolean().defined(),
      creditor_id: Yup.number().defined().integer(),
    },
  );

  const isServiceOrFixedCost = (productTypeId: number): boolean => {
    switch (productTypeId) {
      case 3:
      case 4:
        return true;
      default:
        return false;
    }
  };

  function onAddButtonClick(values: ProductNoIdAndTypeCode) {
    let formattedRequest = {
      ...values,
      company_id: selectedCompany,
      creditor_id: isServiceOrFixedCost(Number(values.product_type_id))
        ? undefined
        : values.creditor_id,
    };

    addProduct(formattedRequest);
    showModal();
  }

  return (
    <Formik
      initialValues={{
        product_type_id: 1,
        creditor_id: creditors[0] ? creditors[0].id : -1,
        is_vax_free: false,
        is_service: false,
      }}
      validationSchema={addProductRequirementSchema}
      validateOnChange={false}
      onSubmit={(values: ProductNoIdAndTypeCode) => {
        onAddButtonClick(values);
      }}
    >
      {({ errors, touched, values }) => (
        <FormikForm>
          <p className="h5">Produkt detaljer</p>
          <div className="form-group">
            <label>Produktnavn</label>
            <Field name="name" placeholder="Navn" className="form-control" />
            {errors.name && touched.name && (
              <small className="form-text text-danger">{errors.name}</small>
            )}
          </div>
          <div className="form-group">
            <label>Navision ID</label>
            <Field
              name="navision_id"
              placeholder="Navision ID"
              className="form-control"
            />
            {errors.navision_id && touched.navision_id && (
              <small className="form-text text-danger">
                {errors.navision_id}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Produkt type</label>

            <Field as="select" name="product_type_id" className="form-control">
              {productTypes.map(productType => (
                <option
                  key={productType.id}
                  label={productType.code}
                  value={productType.id}
                />
              ))}
            </Field>
            {errors.product_type_id && touched.product_type_id && (
              <small className="form-text text-danger">
                {errors.product_type_id}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Indkøbspris</label>
            <Field
              name="price_cost"
              placeholder="Indkøbspris"
              className="form-control"
            />
            {errors.price_cost && touched.price_cost && (
              <small className="form-text text-danger">
                {errors.price_cost}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Salgspris</label>
            <Field
              name="price_unit"
              placeholder="Salgspris"
              className="form-control"
            />
            {errors.price_unit && touched.price_unit && (
              <small className="form-text text-danger">
                {errors.price_unit}
              </small>
            )}
          </div>

          <div className="form-group">
            <label>Uden moms</label>

            <Field
              name="is_vax_free"
              type="checkbox"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Er en service</label>

            <Field name="is_service" type="checkbox" className="form-control" />
          </div>
          {!isServiceOrFixedCost(Number(values.product_type_id)) && (
            <div className="form-group">
              <label>Sælges af</label>

              <Field as="select" name="creditor_id" className="form-control">
                {creditors.map(cred => (
                  <option key={cred.id} label={cred.name} value={cred.id} />
                ))}
              </Field>
              {errors.creditor_id && touched.creditor_id && (
                <small className="form-text text-danger">
                  {errors.creditor_id}
                </small>
              )}
            </div>
          )}
          <Modal.Footer>
            <Button type={'submit'} variant="success">
              Gem ændringer
            </Button>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default AddProductModalData;
