import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Product } from '../../../../store/instructor/product';
import { store } from '../../../../store/store';
import {
  productHistoryGet,
  StockHistoryDTO,
} from '../../../../store/instructor/product-history';
import { ApplicationState } from '../../../../store';
import CustomTable from '../../../../components/table/customTable';
import { filterBySimilarity } from '../../../../utils/filteringAlgorithms';
import { format } from 'date-fns';
import { Company } from '../../../../store/instructor/company';
import { ExportCsv } from '@material-table/exporters';
import { exportRenderDataPatch } from '../../../../utils/tableUtils';
import LoadingComponent from '../../../../components/LoadingComponent';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  product: Product;
  thisUserCompanyId: number;
  productHistory: StockHistoryDTO[];
  loading: boolean;
  allCompanies: Company[];
}

interface ComponentState {
  tableData: StockHistoryDTO[];
  tableHeader: any[];
}

class ViewProductHistoryModalData extends React.Component<
  PropsFromParent,
  ComponentState
> {
  state = {
    tableData: [],
    tableHeader: [
      {
        title: 'Dato for bevægelse',
        field: 'historyDate',
        editable: 'never',
        customFilterAndSearch: (term, rowData: StockHistoryDTO) =>
          filterBySimilarity(term, rowData.historyDate.toString()),
        render: (data: StockHistoryDTO) => {
          return format(data.historyDate, 'yyyy-MM-dd HH:mm');
        },
      },
      {
        title: 'Virksomhed',
        editable: 'never',
        field: 'senderCompanyId',
        render: (data: StockHistoryDTO) => {
          return this.determineCompanyName(data);
        },
      },
      /*{ title: 'Levering ID', field: 'delivery_id', editable: 'never' },*/
      {
        title: 'Købsordre nr. eller navision nr.',
        field: 'navisionId',
        editable: 'never',
      },
      { title: 'Ændring', field: 'amount', editable: 'never' },
      {
        title: 'Beholdning efter bevægelse',
        field: 'newAmount',
        editable: 'never',
      },
      {
        title: 'Regulering',
        editable: 'never',
        field: 'amount',
        render: (data: StockHistoryDTO) => {
          return data.amount > 0 ? 'Op' : 'Ned';
        },
      },
    ],
  };

  determineCompanyName = (data: StockHistoryDTO) => {
    if (data.receiverCompanyId === data.senderCompanyId) {
      return 'Regulering';
    }

    if (data.amount < 0) {
      return this.props.allCompanies.find(
        comp => comp.id === data.receiverCompanyId,
      )?.name;
    } else {
      return this.props.allCompanies.find(
        comp => comp.id === data.senderCompanyId,
      )?.name;
    }
  };

  componentDidMount() {
    store.dispatch(
      productHistoryGet(this.props.thisUserCompanyId, this.props.product.id),
    );
    this.setState({ tableData: this.props.productHistory });
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.productHistory !== this.props.productHistory) {
      this.setState({ tableData: this.props.productHistory });
    }
  }

  public render() {
    return (
      <>
        <p className={'mb-1'}>
          Her ses historik for produktet:{' '}
          <strong>{this.props.product.name}</strong>
        </p>
        <p className="text-info mb-0">
          Afhængig om varen er blevet modtaget eller afsendt vil kolonnen{' '}
          <i>Købsordre nr. eller navision nr.</i> ændre sig.
        </p>
        <p className={'text-info'}>
          Ved modtagelse er det <strong>købsordre navision nr.</strong> som
          vises, og ved afsendelse er det <strong>købsordre nr.</strong> der
          vises.
        </p>
        <div className="px-3 pt-0 pb-3">
          {this.props.loading ? (
            <div className={'d-flex justify-content-center'}>
              <LoadingComponent visible={this.props.loading} />
            </div>
          ) : (
            this.renderContent()
          )}
        </div>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={e => this.props.visibilityToggleFunc()}
          >
            Luk vindue
          </Button>
        </Modal.Footer>
      </>
    );
  }

  renderContent = () => {
    return (
      <CustomTable
        data={this.state.tableData}
        headers={this.state.tableHeader}
        actions={[]}
        exportOptions={{
          enabled: true,
          functions: [
            {
              label: 'Eksporter CSV',
              exportFunc: (columns, _) => {
                ExportCsv(
                  columns,
                  exportRenderDataPatch(columns, this.state.tableData),
                  `${format(Date.now(), 'yyyyMMdd-HHmm')}_${
                    this.props.product.id
                  }_export`,
                );
              },
            },
          ],
        }}
      />
    );
  };
}

const mapStateToProps = ({ iProductHistory, iCompany }: ApplicationState) => ({
  productHistory: iProductHistory.productHistory,
  loading: iProductHistory.loading,
  allCompanies: iCompany.companies,
});

export default connect(mapStateToProps)(ViewProductHistoryModalData);
