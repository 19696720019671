import React from 'react';
import styled from 'styled-components';
import { Company } from '../../store/instructor/company';
import ReactToPrint from 'react-to-print';
import { ListedStatementDTO } from '../../../types/StatementDTO';
import { convertLocalDateTimeToString } from '../../utils/convertions';

export enum PDFTypes {
  REQUISITION,
  ORDER,
  INVOICE,
}

interface PropsFromParent {
  companies: Company[];
  data: ListedStatementDTO;
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

const PDFStatement: React.FC<PropsFromParent> = ({
  companies,
  data: statements,
}) => {
  let componentRef: HTMLDivElement | null = null;

  const setComponentRef = (ref: HTMLDivElement) => {
    componentRef = ref;
  };

  const reactToPrintTrigger = () => {
    return (
      <button type="button" className={'btn btn-outline-primary btn-sm'}>
        Print som PDF
      </button>
    );
  };

  const reactToPrintContent = () => {
    return componentRef;
  };

  return (
    <>
      <div className={'container'} ref={setComponentRef}>
        <Sections className="d-flex justify-content-between align-items-center p-5">
          <div>
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle="KOPRA_kontoudtog"
              removeAfterPrint
              trigger={reactToPrintTrigger}
            />
          </div>
          <div className="text-right">
            <p>
              <strong>
                {companies.find(c => c.id === statements.superCompanyID)?.name}
              </strong>
            </p>
            <strong>
              {companies.find(c => c.id === statements.superCompanyID)?.address}
            </strong>
            <p>CVR nr.: 12345678</p>
          </div>
        </Sections>
        <Sections className="d-flex justify-content-between align-items-center p-5">
          <div>
            <p>
              <strong>
                {
                  companies.find(c => c.id === statements.desiredCompanyID)
                    ?.name
                }
              </strong>
            </p>
            <strong>
              {
                companies.find(c => c.id === statements.desiredCompanyID)
                  ?.address
              }
            </strong>
            <p>CVR nr.: 12345678</p>
          </div>
          <div className="text-right">
            <h1>Kontoudtog</h1>
            <p className={'text-uppercase head'}>Detaljer</p>
            <p>
              {convertLocalDateTimeToString(statements.dateRangeStart)} t.o.m.{' '}
              {convertLocalDateTimeToString(statements.dateRangeEnd)}
            </p>
            <p>Antal fakturaer: {statements.statements.length}</p>
          </div>
        </Sections>

        <SumSection>
          <table className="table table-hover">
            <thead>
              <tr className={'text-uppercase'}>
                <th scope="col">dato</th>
                <th scope="col">titel</th>
                <th className="text-right" scope="col">
                  beløb m. moms
                </th>
                <th className="text-right" scope="col">
                  beløb rest
                </th>
                <th scope="col">betalt?</th>
                <th scope="col">behandlet?</th>
              </tr>
            </thead>
            <tbody>
              {statements.statements.map((statement, key) => {
                return (
                  <>
                    <tr key={key}>
                      <th scope="row">
                        {convertLocalDateTimeToString(statement.invoiceDate)}
                      </th>
                      <td>{statement.title}</td>
                      <td className="text-right">
                        {formatter.format(statement.amountPlusVAT)}
                      </td>
                      <td className="text-right">
                        {formatter.format(statement.amountDifference)}
                      </td>
                      <td>{statement.paid ? 'Betalt' : 'Ikke betalt'}</td>
                      <td>
                        {statement.processed ? 'Behandlet' : 'Ikke behandlet'}
                      </td>
                    </tr>
                    {statement.associatedTransactions.map(
                      (transaction, key) => {
                        return (
                          <tr key={key}>
                            <td key={key}>
                              <small>
                                <em>Overførsel</em>
                              </small>
                            </td>
                            <td>
                              <small>
                                {transaction.created_at &&
                                  convertLocalDateTimeToString(
                                    transaction.created_at,
                                  )}
                              </small>
                            </td>
                            <td>
                              <small>Titel: {transaction.message}</small>
                            </td>
                            <td>
                              <small>
                                Beløb: {formatter.format(transaction.amount)}
                              </small>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      },
                    )}
                  </>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex flex-row-reverse">
            <TotalPart>
              <table className="table table-hover text-right">
                <tbody>
                  <tr>
                    <th className={'head text-left'}>SUBTOTAL</th>
                    <td></td>
                    <td>{formatter.format(statements.totalAmount)}</td>
                    <td>{formatter.format(statements.totalAmount * 0.25)}</td>
                  </tr>
                  <tr>
                    <th className={'text-left '}>TOTAL</th>
                    <td></td>
                    <td></td>
                    <td className={'total '}>
                      <h3>{formatter.format(statements.totalAmountWithVAT)}</h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TotalPart>
          </div>
        </SumSection>
      </div>
    </>
  );
};

// TODO-future: Make table follow same coloumns but 50% width and different styling
// background-color: ${props => props.theme.theme.colors.secondary};
// TODO-future: Color styling. will props be passed to this component?

const SumSection = styled.div`
  thead {
    color: #787878;
  }
`;

const TotalPart = styled.div`
  width: 50%;

  .head {
    color: #787878;
  }

  .total {
    color: #497f96;
  }
`;

const Sections = styled.div`
  p {
    margin: 0px;
    color: #787878;
  }

  p strong {
    color: #787878;
  }

  p.head {
    color: black;
    font-weight: bold;
  }

  background-color: #fbfbfb;
`;

export default PDFStatement;
