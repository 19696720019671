import { IProductTypeActionTypes, IProductTypesState } from './types';
import { Reducer } from 'redux';

export const initialState: IProductTypesState = {
  productTypes: [],
  loading: false,
  errors: undefined,
};

const reducer: Reducer<IProductTypesState> = (state = initialState, action) => {
  switch (action.type) {
    case IProductTypeActionTypes.IPRODUCT_TYPE_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductTypeActionTypes.IPRODUCT_TYPE_GET_ADD: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductTypeActionTypes.IPRODUCT_TYPE_GET_EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductTypeActionTypes.IPRODUCT_TYPE_GET_DELETE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IProductTypeActionTypes.IPRODUCT_TYPE_GET_SUCCESS: {
      return {
        ...state,
        productTypes: action.payload,
        loading: false,
      };
    }
    case IProductTypeActionTypes.IPRODUCT_TYPE_GET_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iProductTypeReducer };
