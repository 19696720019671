import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  Product,
  ProductNoTypeCode,
} from '../../../../../../store/instructor/product';
import { ModalContext } from '../../../../../../contexts/modalContexts';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { Field, Form as FormikForm, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import {
  updateProductActiveState,
  updateProductBasedOnId,
} from '../../../../../../utils/api';
import { ProductType } from '../../../../../../store/instructor/productType';

interface PropsFromParent {
  product: Product;
  productTypes: ProductType[];
}

const EditProductModalData: React.FC<PropsFromParent> = ({
  product,
  productTypes,
}) => {
  const queryClient = useQueryClient();
  const { showModal } = React.useContext(ModalContext);

  const [isActive, setActive] = React.useState(product?.active ?? false);

  const { mutate: setActiveStateProduct } = useMutation(
    updateProductActiveState,
    {
      onSuccess: () => {
        setActive(!isActive);
        queryClient.refetchQueries('products');
      },
    },
  );

  const { mutate: updateProduct } = useMutation(updateProductBasedOnId, {
    onSuccess: () => {
      queryClient.refetchQueries('products');
    },
  });

  function onEditButtonClick(values: ProductNoTypeCode) {
    updateProduct(values);
    showModal();
  }

  const editProductRequirementsSchema: SchemaOf<ProductNoTypeCode> = Yup.object().shape(
    {
      name: Yup.string().max(50).defined().min(2),
      id: Yup.number().defined().integer(),
      price_cost: Yup.number().defined().positive(),
      price_unit: Yup.number().defined().positive(),
      product_type_id: Yup.number().defined().integer(),
      navision_id: Yup.number().defined().integer(),
      is_vax_free: Yup.boolean().defined(),
      is_service: Yup.boolean().defined(),
    },
  );

  return (
    <Formik
      initialValues={{
        name: product.name,
        id: product.id,
        price_cost: product.price_cost,
        price_unit: product.price_unit,
        product_type_id: product.product_type_id,
        navision_id: product.navision_id,
        is_vax_free: product.is_vax_free ?? false,
        is_service: product.is_service ?? false,
        active: product.active,
      }}
      validationSchema={editProductRequirementsSchema}
      validateOnChange={false}
      onSubmit={(values: ProductNoTypeCode) => {
        onEditButtonClick(values);
      }}
    >
      {({ errors, touched }) => (
        <FormikForm>
          <p className="h5">Produkt detaljer</p>
          <div className="form-group">
            <label>Produktnavn</label>
            <Field name="name" placeholder="Navn" className="form-control" />
            {errors.name && touched.name && (
              <small className="form-text text-danger">{errors.name}</small>
            )}
          </div>
          <div className="form-group">
            <label>Navision ID</label>
            <Field
              name="navision_id"
              placeholder="Navision ID"
              className="form-control"
            />
            {errors.navision_id && touched.navision_id && (
              <small className="form-text text-danger">
                {errors.navision_id}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Produkt type</label>

            <Field as="select" name="product_type_id" className="form-control">
              {productTypes.map(productType => (
                <option
                  key={productType.id}
                  label={productType.code}
                  value={productType.id}
                />
              ))}
            </Field>
            {errors.product_type_id && touched.product_type_id && (
              <small className="form-text text-danger">
                {errors.product_type_id}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Indkøbspris</label>
            <Field
              name="price_cost"
              placeholder="Indkøbspris"
              className="form-control"
            />
            {errors.price_cost && touched.price_cost && (
              <small className="form-text text-danger">
                {errors.price_cost}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Salgspris</label>
            <Field
              name="price_unit"
              placeholder="Salgspris"
              className="form-control"
            />
            {errors.price_unit && touched.price_unit && (
              <small className="form-text text-danger">
                {errors.price_unit}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Uden moms</label>

            <Field
              name="is_vax_free"
              type="checkbox"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Er en service</label>

            <Field name="is_service" type="checkbox" className="form-control" />
          </div>
          <Modal.Footer className={'justify-content-between'}>
            <Button
              onClick={() => {
                setActiveStateProduct({
                  id: product.id,
                  active: !isActive,
                });
                showModal();
              }}
              variant={'outline-' + (isActive ? 'danger' : 'success')}
              className={'left'}
            >
              Gør produkt {isActive ? 'inaktiv' : 'aktiv'}
            </Button>
            <Button type={'submit'} variant="success">
              Gem ændringer
            </Button>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default EditProductModalData;
