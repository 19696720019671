import * as React from 'react';
import { Message, MessageStatusType } from '../../../../store/instructor/inbox';
import { Button, Form, Modal } from 'react-bootstrap';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { Company } from '../../../../store/instructor/company';
import { convertCompanyIdToName } from '../../../../utils/convertions';
import ReactToPrint from 'react-to-print';

type VisibilityToggleFuncType = () => void;
type SetMsgToReadFuncType = (msg: Message) => void;

interface PropsFromParent {
  setMsgToReadFunc: SetMsgToReadFuncType;
  visibilityToggleFunc: VisibilityToggleFuncType;
  msg: Message;
  allCompanies: Company[];
}

class ViewMsgModalData extends React.Component<PropsFromParent> {
  componentWillUnmount() {
    //Mark this msg as read, if need
    if (this.props.msg.msg_status === MessageStatusType.UNREAD) {
      this.props.setMsgToReadFunc(this.props.msg);
    }
  }

  componentRef: HTMLDivElement | null = null;

  setComponentRef = (ref: HTMLDivElement) => {
    this.componentRef = ref;
  };

  reactToPrintContent = () => {
    return this.componentRef;
  };

  reactToPrintTrigger = () => {
    return (
      <button type="button" className={'btn btn-primary btn-sm"'}>
        Print som PDF
      </button>
    );
  };

  renderContent = () => {
    return (
      <div className="px-3 pt-0 pb-3" ref={this.setComponentRef}>
        <p>
          Fra:{' '}
          {convertCompanyIdToName(
            this.props.allCompanies,
            this.props.msg.msg_from_id,
          )}
        </p>
        <p>Emne: {this.props.msg.msg_header}</p>
        <Form>
          <Form.Group>
            <Form.Control
              as="textarea"
              defaultValue={this.props.msg.msg_body}
              rows={8}
              readOnly
            />
          </Form.Group>
        </Form>
      </div>
    );
  };

  public render() {
    return (
      <>
        <>{this.renderContent()}</>
        <Modal.Footer>
          <ReactToPrint
            content={this.reactToPrintContent}
            documentTitle="AwesomeFileName"
            removeAfterPrint
            trigger={this.reactToPrintTrigger}
          />
          <Button variant="primary" onClick={this.props.visibilityToggleFunc}>
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ iMessages }: ApplicationState) => ({
  loading: iMessages.loading,
  errors: iMessages.errors,
});

export default connect(mapStateToProps)(ViewMsgModalData);
