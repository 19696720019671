import React from 'react';
import { Product } from '../../../store/instructor/product';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { Field, Form as FormikForm, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../../contexts/modalContexts';
import { updateQuantityOfProduct } from '../../../utils/api';

interface PropsFromParent {
  product: Product;
  selectedCompanyId: number;
}

export interface ProductQuantityDTO {
  product_id: number;
  company_id: number;
  amount: number;
}

const EditProductQuantityModalContent: React.FC<PropsFromParent> = ({
  product,
  selectedCompanyId,
}) => {
  const queryClient = useQueryClient();
  const { showModal } = React.useContext(ModalContext);
  const { mutate: updateQuantity } = useMutation(updateQuantityOfProduct, {
    onSuccess: () => {
      queryClient.refetchQueries('products');
    },
  });

  function onButtonClicked(values: ProductQuantityDTO) {
    values.amount =
      Number(values.amount) + product.amount < 0
        ? -product.amount
        : Number(values.amount);
    updateQuantity(values);
    showModal();
  }

  const editProductAmountRequirementsSchema: SchemaOf<ProductQuantityDTO> = Yup.object().shape(
    {
      product_id: Yup.number().defined().integer(),
      company_id: Yup.number().defined().integer(),
      amount: Yup.number().defined().integer(),
    },
  );

  const addValuesTogether = (value1, value2) => {
    let result = value1 + value2;
    return result < 0 ? 0 : result;
  };

  return (
    <Formik
      initialValues={{
        product_id: product.id,
        company_id: selectedCompanyId,
        amount: 0,
      }}
      validationSchema={editProductAmountRequirementsSchema}
      validateOnChange={false}
      onSubmit={(values: ProductQuantityDTO) => {
        onButtonClicked(values);
      }}
    >
      {({ values, errors, touched }) => (
        <FormikForm>
          <div className={'mb-4'}>
            <p className="h5">{product.name}</p>
            <small className="text-muted">
              Der er på nuværende tidspunkt ({product.amount}) produkter på
              lageret. Indtast enten en negativ eller positiv værdi for neden
              for at regulere varelageret.
            </small>
          </div>
          <div className="form-row align-items-center">
            <div className="form-group col-md-6">
              <label>Tilføj / fjern antal af varer</label>
              <Field
                name="amount"
                placeholder="Mængde"
                className="form-control"
              />
              {errors.amount && touched.amount && (
                <small className="form-text text-danger">{errors.amount}</small>
              )}
            </div>
            <div className="col-md-6 justify-content-center d-flex">
              <p>
                Ny mængde:{' '}
                <span className={'font-weight-bold'}>
                  {addValuesTogether(product.amount, Number(values.amount))}
                </span>
              </p>
            </div>
          </div>
          <Modal.Footer>
            <Button type={'submit'} variant="success">
              Gem ændringer
            </Button>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default EditProductQuantityModalContent;
