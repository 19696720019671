import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

class DashboardContent extends Component {
  loremGenerate = () => {
    return (
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultrices
        tortor vel mi molestie, sed condimentum urna pulvinar. Sed a arcu eget
        ex lobortis vulputate quis at diam. Donec interdum tincidunt nisl nec
        tristique. Vivamus nec aliquet mi. Suspendisse id ex diam. Pellentesque
        ut justo ac velit ornare cursus. Quisque non orci at lacus pulvinar
        cursus eget eu neque. Morbi maximus lectus nec vulputate accumsan.
        Curabitur vel neque quis ex aliquet pulvinar. Vivamus faucibus egestas
        diam quis eleifend. Fusce sit amet porta nunc. Morbi leo massa,
        consequat eget rutrum vitae, dictum at ante. In non est ut nunc interdum
        ultricies vel sit amet felis. Interdum et malesuada fames ac ante ipsum
        primis in faucibus. Curabitur ac massa id augue auctor iaculis. Aliquam
        non tortor lobortis, placerat leo vel, porttitor mi. Etiam nec mattis
        mi. Fusce ut molestie dolor. Curabitur sed justo dignissim, facilisis
        dolor at, malesuada odio. Etiam ex libero, rutrum vitae viverra vitae,
        faucibus vitae lacus. Duis tincidunt nunc eget tristique consectetur.
        Donec consectetur eros leo. Aliquam nec nulla rhoncus, congue mi sed,
        elementum tellus. Aenean iaculis ipsum eu enim consequat, eu aliquam
        nulla posuere. Praesent diam justo, sagittis ac ullamcorper sed,
        venenatis vitae mi. Pellentesque non est at odio euismod suscipit quis
        id nisi. Interdum et malesuada fames ac ante ipsum primis in faucibus.
        Suspendisse eget risus lectus. Pellentesque id vestibulum odio. Aliquam
        eu pulvinar lacus. Quisque sit amet congue justo, a mollis velit. Etiam
        vitae facilisis dui. Aenean ut placerat felis. Donec volutpat hendrerit
        nibh quis suscipit. Nulla ac justo leo. Donec justo quam, malesuada
        vitae tellus rutrum, rhoncus tincidunt urna. Donec vel ex at augue
        mattis commodo. Phasellus sed nunc egestas, tristique tellus non,
        volutpat leo. Maecenas viverra vehicula sapien non vestibulum. Fusce
        luctus quam hendrerit risus convallis semper. Morbi convallis quam sem.
        Proin convallis, nulla quis tempus pretium, ante tortor interdum nibh,
        ac lobortis felis turpis eget dolor. Nam vestibulum, dui porta finibus
        aliquam, nibh massa eleifend nisi, eu varius turpis justo sed leo. Nam
        ultrices leo vitae ex luctus, sit amet tempus nunc tempus. Mauris congue
        volutpat quam, lacinia semper ipsum. Fusce quis eros risus. Cras luctus
        dui leo, et sodales sapien vehicula in. Aliquam vitae risus vel nunc
        volutpat blandit. Fusce efficitur rutrum feugiat. Suspendisse quis
        consequat risus, ac aliquam lorem. Sed bibendum tincidunt odio et
        egestas. Maecenas rhoncus nibh vitae odio condimentum tincidunt. Donec
        feugiat semper sagittis. Sed leo lacus, tincidunt eget semper id,
        suscipit ac justo.
      </p>
    );
  };

  render() {
    return (
      <>
        <div className={'text-center py-3'}>
          <Logo
            alt={'kopra_logo'}
            src={process.env.PUBLIC_URL + '/images/placeholder-logo.png'}
          />
        </div>

        <Row className={'pt-5'}>
          <TextColumns>
            <h2>Overskrift</h2>
            {this.loremGenerate()}
          </TextColumns>
          <TextColumns>
            <h2 className={'text-center'}>Overskrift</h2>
            {this.loremGenerate()}
          </TextColumns>
        </Row>
      </>
    );
  }
}

const TextColumns = styled(Col)`
  text-align: justify;
  text-justify: inter-word;
`;

const Logo = styled.img`
  display: inline-block;
`;

export default DashboardContent;
