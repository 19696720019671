import { action } from 'typesafe-actions';
import {
  IProducts,
  IProductActionTypes,
  ProductNoIdAndTypeCode,
  ProductNoTypeCode,
} from './types';

export const productGetAll = () => action(IProductActionTypes.IPRODUCT_GET_ALL);
export const productDeletion = (productId: number) =>
  action(IProductActionTypes.IPRODUCT_DELETE, { productId });
export const productEdit = (editedProduct: ProductNoTypeCode) =>
  action(IProductActionTypes.IPRODUCT_EDIT, { editedProduct });
export const productAdd = (newProduct: ProductNoIdAndTypeCode) =>
  action(IProductActionTypes.IPRODUCT_ADD, { newProduct });
export const productSuccess = (data: IProducts) =>
  action(IProductActionTypes.IPRODUCT_SUCCESS, data);
export const productError = (message: string) =>
  action(IProductActionTypes.IPRODUCT_ERROR, message);
