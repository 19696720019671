import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import {
  iCompanyReducer,
  iCompanySaga,
  ICompanyState,
} from './instructor/company';
import {
  iOrderGenReducer,
  iOrderGenSaga,
  IOrderGenState,
} from './instructor/orderGen';
import {
  iProductReducer,
  IProductState,
  productSaga,
} from './instructor/product';
import {
  iInvoiceReducer,
  iInvoiceSaga,
  IInvoiceState,
} from './instructor/orderInvoice';
import { IUsersReducer, iUsersSaga, IUsersState } from './instructor/users';
import {
  iMessagesReducer,
  iMessagesSaga,
  IMessageState,
} from './instructor/inbox';
import { themeReducer, ThemeState } from './theme';
import {
  bankAccountReducer,
  bankAccountSaga,
  BankAccountState,
} from './instructor/bank';
import {
  iProductTypeReducer,
  IProductTypesState,
  productTypeSaga,
} from './instructor/productType';
import {
  userAuthenticationReducer,
  userAuthenticationSaga,
  UserInformation,
} from './user/userAuthentication';
import {
  IBankTransactionReducer,
  iBankTransactionSaga,
  IBankTransactionState,
} from './instructor/bank-transactions';
import {
  iMistakesReducer,
  iMistakesSaga,
  IMistakesState,
} from './instructor/mistakes';
import {
  requisitionReducer,
  requisitionSaga,
  RequisitionState,
} from './instructor/requisitions';
import {
  companyProductsSaga,
  iCompanyProductsReducer,
  ICompanyProductsState,
} from './instructor/company-products';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  IDeliveryReducer,
  iDeliverySaga,
  IDeliveryState,
} from './instructor/delivery';
import { navbarReducer, NavbarState } from './instructor/navbar';
import {
  iInvoiceDetailsReducer,
  iInvoiceDetailsSaga,
  InvoiceDetailsState,
} from './instructor/invoice-details';
import {
  iProductHistoryReducer,
  iProductHistorySaga,
  IProductHistoryState,
} from './instructor/product-history';
import { iTaxReducer, iTaxSaga, ITaxState } from './instructor/tax';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userInfo', 'navbar'],
};

// The top-level state object
export interface ApplicationState {
  userInfo: UserInformation;
  iCompany: ICompanyState;
  iProduct: IProductState;
  iProductType: IProductTypesState;
  iOrderGen: IOrderGenState;
  iInvoice: IInvoiceState;
  iUsers: IUsersState;
  theme: ThemeState;
  iMessages: IMessageState;
  iBankAccount: BankAccountState;
  iBankTransactions: IBankTransactionState;
  iMistakes: IMistakesState;
  Requisitions: RequisitionState;
  iCompanyProducts: ICompanyProductsState;
  iDelivery: IDeliveryState;
  navbar: NavbarState;
  iInvoiceDetails: InvoiceDetailsState;
  iProductHistory: IProductHistoryState;
  iTax: ITaxState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = () =>
  persistReducer(
    persistConfig,
    combineReducers({
      userInfo: userAuthenticationReducer,
      iCompany: iCompanyReducer,
      iProduct: iProductReducer,
      iProductType: iProductTypeReducer,
      iOrderGen: iOrderGenReducer,
      iInvoice: iInvoiceReducer,
      iUsers: IUsersReducer,
      theme: themeReducer,
      iMessages: iMessagesReducer,
      iBankAccount: bankAccountReducer,
      iBankTransactions: IBankTransactionReducer,
      iMistakes: iMistakesReducer,
      Requisitions: requisitionReducer,
      iCompanyProducts: iCompanyProductsReducer,
      iDelivery: IDeliveryReducer,
      navbar: navbarReducer,
      iInvoiceDetails: iInvoiceDetailsReducer,
      iProductHistory: iProductHistoryReducer,
      iTax: iTaxReducer,
    }),
  );

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(userAuthenticationSaga),
    fork(iCompanySaga),
    fork(productSaga),
    fork(productTypeSaga),
    fork(iOrderGenSaga),
    fork(iInvoiceSaga),
    fork(iUsersSaga),
    fork(iMessagesSaga),
    fork(bankAccountSaga),
    fork(iBankTransactionSaga),
    fork(iMistakesSaga),
    fork(requisitionSaga),
    fork(companyProductsSaga),
    fork(iDeliverySaga),
    fork(iInvoiceDetailsSaga),
    fork(iProductHistorySaga),
    fork(iTaxSaga),
  ]);
}
