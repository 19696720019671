import React from 'react';
import {
  FetchTypes,
  Requisition,
  requisitionGetFromCompanyWithoutShipPage,
  requisitionGetToCompanyActivePage,
  requisitionGetToCompanyArchivePage,
  requisitionSetArchiveType,
} from '../../../store/instructor/requisitions';
import { Company } from '../../../store/instructor/company';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import InstructorModal from '../../../components/modal/instructorModal';
import DocumentPreview, {
  PDFTypes,
} from '../../../components/pdfgenerator/documentPreview';
import { filterBySimilarity } from '../../../utils/filteringAlgorithms';
import {
  ArchiveType,
  convertCompanyIdToName,
} from '../../../utils/convertions';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import { Container } from 'reactstrap';
import { store } from '../../../store/store';
import { Page } from '../../../utils/storeTypes';
import CustomTablePagination from '../../../components/table/customTablePagination';
import DatePicker from 'react-datepicker';
import DatepickerTableTitle from '../../../components/table/title/datepickerTitle';

export enum TabArchiveType {
  ACTIVE,
  PROCESSED,
  SEND,
}

interface PropsFromStore {
  loading: boolean;
  allCompanies: Company[];
  thisUserCompanyId: number;
  type: TabArchiveType;
  pdfType: PDFTypes;

  requisitionsFromCompany: Page;
  requisitionsToCompanyActive: Page;
  requisitionsToCompanyArchive: Page;
  loadingFromCompany: boolean;
  loadingToCompanyActive: boolean;
  loadingToCompanyArchive: boolean;
}

interface ThisCompState {
  searchQuery: string | undefined;
  startDate: Date | null;
  endDate: Date | null;

  tableData: Requisition[];
  tableHeader: any[];
  modalDetailsVisible: boolean;
  selectedRequisition?: any;
  selectedCompany?: any;

  currentPageRequisitions: Requisition[];
  totalElementAmount: number;
  pageSize: number;
  currentPage: number;
  //TODO-future: optimize: above four should be replaced with type Page
}

class RequisitionTab extends React.Component<PropsFromStore, ThisCompState> {
  constructor(props: Readonly<PropsFromStore>) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      searchQuery: undefined,
      currentPageRequisitions: [],
      pageSize: 10,
      totalElementAmount: -1,
      currentPage: 0,
      modalDetailsVisible: false,
      tableData: [],
      tableHeader: [
        this.props.pdfType === PDFTypes.REQUISITION
          ? {
              title: 'Rekvisitionsnummer',
              field: 'requisition_id',
              editable: 'never',
            }
          : {
              title: 'Navision Nr.',
              field: 'navision_id',
              editable: 'never',
            },
        {
          title: 'Dato',
          field: 'requisition_date',
          editable: 'never',
          render: data => <span>{data.requisition_date.getString()}</span>,
        },
        {
          title:
            this.props.type === TabArchiveType.SEND
              ? 'Leverandør'
              : 'Fra Kunde',
          field:
            this.props.type === TabArchiveType.SEND
              ? 'to_company_id'
              : 'from_company_id',
          editable: 'never',
          customFilterAndSearch: (term, rowData) =>
            filterBySimilarity(
              term,
              convertCompanyIdToName(
                this.props.allCompanies,
                this.props.type === TabArchiveType.SEND
                  ? rowData.to_company_id
                  : rowData.from_company_id,
              ),
            ),
          customSort: (a, b) =>
            sortStringAlphabetically(
              convertCompanyIdToName(
                this.props.allCompanies,
                this.props.type === TabArchiveType.SEND
                  ? a.to_company_id
                  : a.from_company_id,
              ),
              convertCompanyIdToName(
                this.props.allCompanies,
                this.props.type === TabArchiveType.SEND
                  ? b.to_company_id
                  : b.from_company_id,
              ),
            ),
          render: rowData => (
            <span>
              {convertCompanyIdToName(
                this.props.allCompanies,
                this.props.type === TabArchiveType.SEND
                  ? rowData.to_company_id
                  : rowData.from_company_id,
              )}
            </span>
          ),
        },
        {
          title: 'Beløb ex. moms',
          field: 'total_amount_without_vax',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchPage();
  }

  getPageMatchingTabType(): Page | undefined {
    switch (this.props.type) {
      case TabArchiveType.ACTIVE:
        return this.props.requisitionsToCompanyActive;
      case TabArchiveType.SEND:
        return this.props.requisitionsFromCompany;
      case TabArchiveType.PROCESSED:
        return this.props.requisitionsToCompanyArchive;
      default:
        throw new Error('A new tab type has been added in TabArchiveType!');
    }
  }

  handleModalDetailsVisible = () => {
    this.setState({
      modalDetailsVisible: !this.state.modalDetailsVisible,
    });
  };

  onActionDetailsClick = requisition => {
    this.setState({ selectedRequisition: requisition });
    this.handleModalDetailsVisible();
  };

  onActionGeneratePdfClick = requisition => {
    this.setState({ selectedRequisition: requisition });
  };

  componentDidUpdate(
    prevProps: Readonly<PropsFromStore>,
    prevState: Readonly<ThisCompState>,
  ) {
    let page: Page | undefined = this.getPageMatchingTabType(); //Current tab: current props requisition page

    if (page !== undefined) {
      if (page.items !== prevState.tableData) {
        this.setState({
          totalElementAmount: page.total_items,
          tableData: page.items,
        });
      }
    }
  }

  onActionProcessedClick(requisition: Requisition) {
    store.dispatch(
      requisitionSetArchiveType(
        requisition.requisition_id,
        ArchiveType.PROCESSED,
        this.props.thisUserCompanyId,
        FetchTypes.newest,
      ),
    );
  }

  onChangePage = (targetPageNumber: number, pageSize: number): void => {
    this.setState({ currentPage: targetPageNumber }, () => {
      this.fetchPage();
    });
  };

  onChangeRowPerPage = (newRowsPerPage: number) => {
    this.setState({ pageSize: newRowsPerPage }, () => {
      this.fetchPage();
    });
  };

  onSearchChange = (query: string) => {
    this.setState(
      {
        searchQuery: query,
        currentPage: 0,
      },
      () => {
        this.fetchPage();
      },
    );
  };

  fetchPage = () => {
    switch (this.props.type) {
      case TabArchiveType.ACTIVE:
        store.dispatch(
          requisitionGetToCompanyActivePage(
            this.props.thisUserCompanyId,
            this.state.currentPage,
            this.state.pageSize,
            true,
            ArchiveType.DEFAULT,
            this.state.searchQuery,
            this.state.startDate,
            this.state.endDate,
          ),
        );
        break;
      case TabArchiveType.PROCESSED:
        store.dispatch(
          requisitionGetToCompanyArchivePage(
            this.props.thisUserCompanyId,
            this.state.currentPage,
            this.state.pageSize,
            true,
            ArchiveType.PROCESSED,
            this.state.searchQuery,
            this.state.startDate,
            this.state.endDate,
          ),
        );
        break;
      case TabArchiveType.SEND:
        store.dispatch(
          requisitionGetFromCompanyWithoutShipPage(
            this.props.thisUserCompanyId,
            this.state.currentPage,
            this.state.pageSize,
            true,
            this.state.searchQuery,
            this.state.startDate,
            this.state.endDate,
          ),
        );
        break;
      default:
        throw new Error('New type for TabArchiveType has been added!');
    }
  };

  getPageLoadingBoolean(): boolean {
    switch (this.props.type) {
      case TabArchiveType.SEND:
        return this.props.loadingFromCompany;
      case TabArchiveType.ACTIVE:
        return this.props.loadingToCompanyActive;
      case TabArchiveType.PROCESSED:
        return this.props.loadingToCompanyArchive;
    }
  }

  render() {
    const actions = [
      {
        icon: 'description',
        tooltip: 'Vis detaljer',
        onClick: (event, company) => this.onActionDetailsClick(company),
      },
    ];
    if (this.props.type === TabArchiveType.ACTIVE) {
      actions.push({
        icon: 'done_outline',
        tooltip: 'Flyt til behandlet',
        onClick: (event, requisition) =>
          this.onActionProcessedClick(requisition),
      });
    }

    return (
      <>
        <Container fluid={true}>
          <CustomTablePagination
            titleComponent={
              <DatepickerTableTitle
                datepicker={
                  <DatePicker
                    selectsRange={true}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onCalendarClose={() => this.fetchPage()}
                    onChange={dates => {
                      if (dates) {
                        if (Array.isArray(dates)) {
                          if (dates.every(ele => ele == null)) {
                            this.setState(
                              {
                                startDate: null,
                                endDate: null,
                              },
                              () => {
                                this.fetchPage();
                              },
                            );
                          }
                        }
                        this.setState({
                          startDate: dates[0],
                          endDate: dates[1],
                        });
                      }
                    }}
                    isClearable={true}
                  />
                }
              />
            }
            onSearchChange={this.onSearchChange}
            data={this.state.tableData}
            headers={this.state.tableHeader}
            isLoading={this.props.loading}
            actions={actions}
            onChangePageFunc={this.onChangePage}
            totalElementAmount={this.state.totalElementAmount}
            numberRowPerPage={this.state.pageSize}
            pageDataLoading={this.getPageLoadingBoolean()}
            onChangeRowsPerPageFunc={this.onChangeRowPerPage}
            pageNumber={this.state.currentPage}
          />
        </Container>
        <InstructorModal
          width={80}
          modalState={this.state.modalDetailsVisible}
          showFunc={this.handleModalDetailsVisible}
          titleHeader="Detaljer"
        >
          <DocumentPreview
            companies={this.props.allCompanies}
            document={this.state.selectedRequisition}
            type={this.props.pdfType}
            receiverLabel={
              this.props.pdfType === PDFTypes.ORDER ? 'Leverandør' : 'Modtager'
            }
          />
        </InstructorModal>
      </>
    );
  }
}

const mapStateToProps = ({
  iCompany,
  Requisitions,
  userInfo,
}: ApplicationState) => ({
  loading: iCompany.loading || Requisitions.loading,
  thisUserCompanyId: userInfo.userSuperCompanyId,

  requisitionsFromCompany: Requisitions.requisitionsFromCompany,
  requisitionsToCompanyActive: Requisitions.requisitionsToCompanyActive,
  requisitionsToCompanyArchive: Requisitions.requisitionsToCompanyArchive,
  loadingFromCompany: Requisitions.loadingFromCompany,
  loadingToCompanyActive: Requisitions.loadingToCompanyActive,
  loadingToCompanyArchive: Requisitions.loadingToCompanyArchive,
});

export default connect(mapStateToProps)(RequisitionTab);
