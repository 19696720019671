import { Reducer } from 'redux';
import { IMistakesActionTypes, IMistakesState } from './types';

export const initialState: IMistakesState = {
  mistakes: {
    transactions: [],
    invoices: [],
    requisitions: [],
  },
  loading: false,
  errors: undefined,
};

const reducer: Reducer<IMistakesState> = (state = initialState, action) => {
  switch (action.type) {
    case IMistakesActionTypes.IMISTAKES_FETCH_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IMistakesActionTypes.IMISTAKES_SUCCESS: {
      return {
        ...state,
        loading: false,
        mistakes: action.payload,
      };
    }
    case IMistakesActionTypes.IMISTAKES_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iMistakesReducer };
