import { Reducer } from 'redux';
import { IInvoiceActionTypes, IInvoiceState } from './types';

export const initialState: IInvoiceState = {
  loading: false,
  loadingFromCompany: false,
  loadingToCompanyActive: false,
  loadingToCompanyArchive: false,
  errors: undefined,
  invoices: [],
  invoicesFromCompany: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
  invoicesToCompanyActive: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
  invoicesToCompanyArchive: {
    page_size: -1,
    current_page: -1,
    items: [],
    total_items: -1,
    total_pages: -1,
  },
};

const reducer: Reducer<IInvoiceState> = (state = initialState, action) => {
  switch (action.type) {
    case IInvoiceActionTypes.IINVOICE_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IInvoiceActionTypes.IINVOICE_GET_ALL_FROM_COMP_PAGE: {
      return {
        ...state,
        loadingFromCompany: true,
      };
    }
    case IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ACTIVE_PAGE: {
      return {
        ...state,
        loadingToCompanyActive: true,
      };
    }
    case IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ARCHIVED_PAGE: {
      return {
        ...state,
        loadingToCompanyArchive: true,
      };
    }
    case IInvoiceActionTypes.IINVOICE_CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IInvoiceActionTypes.IINVOICE_SET_ARCHIVE_TYPE: {
      return {
        ...state,
        loading: true,
      };
    }
    case IInvoiceActionTypes.IINVOICE_SUCCESS: {
      return {
        ...state,
        invoices: action.payload,
        loading: false,
      };
    }
    case IInvoiceActionTypes.IINVOICE_GET_ALL_FROM_COMP_PAGE_SUCCESS: {
      return {
        ...state,
        invoicesFromCompany: action.payload,
        loadingFromCompany: false,
      };
    }
    case IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS: {
      return {
        ...state,
        invoicesToCompanyActive: action.payload,
        loadingToCompanyActive: false,
      };
    }
    case IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS: {
      return {
        ...state,
        invoicesToCompanyArchive: action.payload,
        loadingToCompanyArchive: false,
      };
    }
    case IInvoiceActionTypes.IINVOICE_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iInvoiceReducer };
