import React, { Component } from 'react';
import Checkout from './checkout';
import { SuperCompany } from '../../../../../../utils/deliveryUtils';
import { NewDelivery } from '../../../../../../store/instructor/delivery';
import { CompanyIdProductsPair } from '../../../../../../store/instructor/company-products';

type SetRequisitionInStepParent = (newDelivery: NewDelivery) => void;
type SetDeliveryTotalCostInStepParent = (deliveryTotalCost: {
  total_amount_without_vax: number;
  total_vax: number;
}) => void;

interface PropsFromParent {
  setRequisitionInStepParent: SetRequisitionInStepParent;
  setDeliveryTotalCostInStepParent: SetDeliveryTotalCostInStepParent;
  products?: any;
  to_company_id: any;
  from_company_id: any;
  setProducts: any;
  requisition_id: number;
  userSuperCompany: SuperCompany;
  allCompanies: any;
  allCompanyProductsPairs: CompanyIdProductsPair[];
}

interface ThisCompState {
  indicator: string;
  indicatorStatus: string;
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

class ShoppingCart extends Component<PropsFromParent, ThisCompState> {
  constructor(props: Readonly<PropsFromParent>) {
    super(props);
    this.state = {
      indicator: '',
      indicatorStatus: '',
    };
  }

  setIndicator = (msg, operationSuccesses: boolean) => {
    this.setState({
      indicator: msg,
    });
    if (operationSuccesses) {
      this.setState({ indicatorStatus: 'alert alert-success' });
      this.props.setProducts();
    } else {
      this.setState({ indicatorStatus: 'alert alert-danger' });
    }
  };

  render() {
    const alertStringWithNewlines = this.state.indicator
      .split('\n')
      .map(str => <p>{str}</p>);
    return (
      <div>
        <h2 className={'mt-5'}>Produkter i kurven</h2>

        {this.state.indicator ? (
          <div className={this.state.indicatorStatus} role="alert">
            {alertStringWithNewlines}
          </div>
        ) : (
          ''
        )}

        {this.props.products ? (
          <>
            {this.props.products.map((product, index) => {
              return (
                <div
                  className={
                    'basket row align-items-center text-left p-2 bg-light my-1'
                  }
                >
                  <div className={'basket-item col-7'}>
                    {product.name} <br />
                    Varenr: {product.navision_id}
                  </div>
                  <div className={'basket-item col-1 text-center border mx-3'}>
                    {product.quantity}
                  </div>
                  <div className={'basket-item col-3 flex-fill text-right'}>
                    {formatter.format(product.quantity * product.price_unit)}
                  </div>
                </div>
              );
            })}
            <Checkout
              from_company_id={this.props.from_company_id}
              to_company_id={this.props.to_company_id}
              products={this.props.products}
              setIndicator={this.setIndicator}
              requisition_id={this.props.requisition_id}
              userSuperCompany={this.props.userSuperCompany}
              allCompanies={this.props.allCompanies}
              setRequisitionInStepParent={this.props.setRequisitionInStepParent}
              setDeliveryTotalCostInStepParent={
                this.props.setDeliveryTotalCostInStepParent
              }
              allCompanyProductsPairs={this.props.allCompanyProductsPairs}
            />
          </>
        ) : (
          'There is currently no products in cart'
        )}
      </div>
    );
  }
}

export default ShoppingCart;
