import React from 'react';
import { Route } from 'react-router-dom';
import { store } from '../store/store';
import { userRedirect } from '../utils/userTypeRedirector';

/* TODO: Currently we just look at the usertype to determine whether or not they are currently logged in.
 *   We should probably make a method that returns true or false depending on the current store state. (If a user seem to be logged in)*/
export const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !store.getState().userInfo.jwtToken ? (
        <Component {...props} />
      ) : (
        userRedirect(store.getState().userInfo.userType)
      )
    }
  />
);
