import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Company } from '../../../store/instructor/company';
import { BankAccount } from '../../../store/instructor/bank';
import { Tab, Tabs } from 'react-bootstrap';
import PaymentTab, {
  PaymentContextType,
  PaymentType,
} from '../bank/mainTabs/payment';
import { NewBankTransactionWithId } from '../bank/bankContent';
import ApproveTab from '../bank/mainTabs/approve';
import { User } from '../../../store/instructor/users';

interface PropsFromState {
  allCompanies: Company[];
  allBankAccounts: BankAccount[];
  allStudentBankAccounts: BankAccount[];
  userCompanyId: number;
  allUsers: User[];
  companyAccounts: BankAccount[];
}

interface ThisCompState {
  pendingTransactions: NewBankTransactionWithId[];
  activeAccounts: BankAccount[];
  companyStudentBankAccounts: BankAccount[];
}

class Transaction extends React.Component<PropsFromState, ThisCompState> {
  constructor(props: PropsFromState) {
    super(props);
    this.state = {
      pendingTransactions: [],
      companyStudentBankAccounts: this.getThisCompanyStudentAccounts(
        this.props.allStudentBankAccounts,
        this.props.allUsers,
      ),
      activeAccounts: this.props.allBankAccounts.filter(elem => elem.isActive),
    };
  }

  componentDidUpdate(prevProps: Readonly<PropsFromState>) {
    if (
      prevProps.allStudentBankAccounts !== this.props.allStudentBankAccounts
    ) {
      this.setState({
        companyStudentBankAccounts: this.getThisCompanyStudentAccounts(
          this.props.allStudentBankAccounts,
          this.props.allUsers,
        ),
      });
    }
  }

  getThisCompanyStudentAccounts(
    allStudentBankAccounts: BankAccount[],
    allStudents: User[],
  ): BankAccount[] {
    const usersInCompany: User[] = allStudents.filter(
      elem => this.props.userCompanyId === elem.super_company_id,
    );
    let foundCompanyAccounts: BankAccount[] = [];
    allStudentBankAccounts.forEach((account: BankAccount) => {
      usersInCompany.forEach((student: User) => {
        if (account.userId === student.id) {
          if (student.super_company_id === this.props.userCompanyId) {
            foundCompanyAccounts.push(account);
          }
        } else if (account.userId === undefined) {
          throw new Error(
            'Salary: the given account was not a user bank account!',
          );
        }
      });
    });

    return foundCompanyAccounts;
  }

  addPendingTransaction = (transaction: NewBankTransactionWithId) => {
    this.setState({
      pendingTransactions: [...this.state.pendingTransactions, transaction],
    });
  };

  removePendingTransaction = (transaction: NewBankTransactionWithId) => {
    const array = this.state.pendingTransactions.filter(
      item => item.id !== transaction.id,
    );
    this.setState({
      pendingTransactions: array,
    });
  };

  public render() {
    return (
      <Tabs defaultActiveKey={'transaction'}>
        <Tab eventKey="transaction" title="Overførsel">
          <PaymentTab
            type={PaymentType.TRANSFER_INTERN}
            context={PaymentContextType.SALARY}
            salaryAccounts={this.state.companyStudentBankAccounts}
            pendingTransactions={this.state.pendingTransactions}
            addPendingTransactions={this.addPendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            userCompanyBankAccounts={this.props.companyAccounts}
            allUsers={this.props.allUsers}
          />
        </Tab>
        <Tab
          eventKey="privateBank"
          title={
            'Godkend betaling (' + this.state.pendingTransactions.length + ')'
          }
        >
          <ApproveTab
            allCompanies={this.props.allCompanies}
            pendingTransactions={this.state.pendingTransactions}
            removePendingTransaction={this.removePendingTransaction}
            activeBankAccounts={this.state.activeAccounts}
            allBankAccounts={this.props.allBankAccounts}
            allUsers={this.props.allUsers}
          />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  userCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(Transaction);
