import * as React from 'react';
import { connect } from 'react-redux';
import { InstructorPageLayout } from '../_layout';
import styled from 'styled-components';
import InstructorBankContent from './instructorBankContent';
import { Helmet } from 'react-helmet-async';

const IBankPage: React.FC = () => {
  return (
    <InstructorPageLayout>
      <Helmet>
        <title>Bank</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Bank</h2>
      </PageHeader>

      <InstructorBankContent />
    </InstructorPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(IBankPage);
