import * as React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { BankAccount } from '../../../../store/instructor/bank';
import AccountTab from './secondaryTabs/accountTab';
import CustomTable from '../../../../components/table/customTable';
import { floatToDKFloatString } from '../../../../utils/convertions';

interface PropsFromParent {
  userCompanyBankAccounts: BankAccount[];
}

interface ComponentState {
  tableData: BankAccount[];
  tableHeader: any[];
}

class OverviewTab extends React.Component<PropsFromParent, ComponentState> {
  state = {
    tableData: this.props.userCompanyBankAccounts.filter(item => item.isActive),
    tableHeader: [
      { title: 'Kontonummer', field: 'id', editable: 'never' },
      { title: 'Kontonavn', field: 'name', editable: 'never' },
      { title: 'Tilknyttet IBAN', field: '.', editable: 'never' },
      { title: 'Valuta', field: 'DKK', editable: 'never' },
      {
        title: 'Saldo (DKK)',
        field: 'balance',
        editable: 'never',
        render: rowData => (
          <p className={'float-right'}>
            {floatToDKFloatString(rowData.balance)}
          </p>
        ),
      },
      { title: 'Rente', field: '.', editable: 'never' },
    ],
  };

  renderOverviewTab = () => {
    return (
      <CustomTable
        data={this.state.tableData}
        headers={this.state.tableHeader}
        actions={[]}
      />
    );
  };

  renderAccountTab = (account: BankAccount, index: number) => {
    return (
      <Tab key={index} eventKey={account.name} title={account.name}>
        <AccountTab bankAccount={account} />
      </Tab>
    );
  };

  public render() {
    const activeCompanyBankAccounts: BankAccount[] = this.props.userCompanyBankAccounts.filter(
      item => item.isActive,
    );
    return (
      <Tabs>
        <Tab eventKey="all" title="Alle">
          {this.renderOverviewTab()}
        </Tab>
        {activeCompanyBankAccounts.map(this.renderAccountTab)}
      </Tabs>
    );
  }
}

export default connect()(OverviewTab);
