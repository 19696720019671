import * as React from 'react';
import { store } from '../../../../store/store';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Company } from '../../../../store/instructor/company';
import { BankAccount } from '../../../../store/instructor/bank';
import {
  NewTaxDeclaration,
  TaxAdd,
  TaxDeclarationType,
} from '../../../../store/instructor/tax';
import {
  addThousandDots,
  removeDotsFromString,
} from '../../../../utils/convertions';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  thisUserCompanyId: number;
  taxCompany: Company;
  userCompanyBankAccounts: BankAccount[];
  selectedMonth: number;
  selectedYear: number;
}

interface ThisCompState {
  type: TaxDeclarationType;
  enteredDataTax: {
    outgoingSalesTax: string;
    salesTaxForeign: string;
    incomingSalesTax: string;
    oilAndFlaskTax: string;
    electricityTax: string;
    natureGasAndCityGasTax: string;
    coalTax: string;
    co2Tax: string;
    waterTax: string;
  };
  accountFrom: number;
  //This value may be 0 or below. But only for this type of declaration.
  totalTaxAmountEntered: string;
  enteredDataClassification: {
    classificationA: string;
    classificationB: string;
    classificationC: string;
  };
  localErrors: {
    deduction: string | null;
    outgoing: string | null;
    classification: string | null;
    accountFrom: string | null;
    totalAmount: string | null;
  };
}

//MOMS
class DeclareSalesTaxModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      type: TaxDeclarationType.TAX,
      enteredDataTax: {
        outgoingSalesTax: '0',
        salesTaxForeign: '0',
        incomingSalesTax: '0',
        oilAndFlaskTax: '0',
        electricityTax: '0',
        natureGasAndCityGasTax: '0',
        coalTax: '0',
        co2Tax: '0',
        waterTax: '0',
      },
      accountFrom: -1,
      totalTaxAmountEntered: '0',
      enteredDataClassification: {
        classificationA: '0',
        classificationB: '0',
        classificationC: '0',
      },
      localErrors: {
        deduction: null,
        outgoing: null,
        classification: null,
        accountFrom: null,
        totalAmount: null,
      },
    };
  }

  onChangeAccountTo = e => {
    const re = /^(\s*|\d+)$/; //Integer that allow empty string also
    if (!re.test(e.target.value)) {
      return;
    } else {
      this.setState({
        accountFrom: parseInt(e.target.value),
      });
    }
  };

  onChangeTaxValue = e => {
    const re = /^(-)?(\d*(\.\d{2,4})*)$/; //allows: space | integer dotted per thousand | integer
    if (!re.test(e.target.value)) {
      return;
    } else {
      this.setState(
        {
          enteredDataTax: {
            ...this.state.enteredDataTax,
            [e.target.name]: addThousandDots(
              removeDotsFromString(e.target.value),
            ),
          },
        },
        () => {
          this.recalculateTotalTaxAmount();
        },
      );
    }
  };

  recalculateTotalTaxAmount() {
    const deductionDataError = this.validateEnteredDeductionData();
    const outgoingDataError = this.validateEnteredOutgoingData();
    if (deductionDataError === null && outgoingDataError === null) {
      //NO ERRORS -> calculate
      const totalTaxAmount =
        parseInt(
          removeDotsFromString(this.state.enteredDataTax.outgoingSalesTax),
        ) +
        parseInt(
          removeDotsFromString(this.state.enteredDataTax.salesTaxForeign),
        ) -
        this.getTotalDeductionDataAmount();
      this.setState({
        totalTaxAmountEntered: addThousandDots(totalTaxAmount.toString()),
      });
    } else {
      this.setState({ totalTaxAmountEntered: 'Ej Muligt' });
    }
  }

  getTotalDeductionDataAmount(): number {
    const data = this.state.enteredDataTax;
    return (
      parseInt(removeDotsFromString(data.incomingSalesTax)) +
      parseInt(removeDotsFromString(data.oilAndFlaskTax)) +
      parseInt(removeDotsFromString(data.electricityTax)) +
      parseInt(removeDotsFromString(data.natureGasAndCityGasTax)) +
      parseInt(removeDotsFromString(data.coalTax)) +
      parseInt(removeDotsFromString(data.co2Tax)) +
      parseInt(removeDotsFromString(data.waterTax))
    );
  }

  onChangeClassificationValue = e => {
    const re = /^(\s*|\d+)$/; //Integer that allow empty string also
    if (!re.test(e.target.value)) {
      return;
    } else {
      this.setState({
        enteredDataClassification: {
          ...this.state.enteredDataClassification,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  validate = () => {
    return {
      deduction: this.validateEnteredDeductionData(),
      classification: this.validateEnteredClassificationData(),
      outgoing: this.validateEnteredOutgoingData(),
      accountFrom: this.validateEnteredAccountFrom(),
      totalAmount: this.validateTotalAmount(),
    };
  };

  validateEnteredAccountFrom(): string | null {
    let error: string | null = null;

    if (this.state.accountFrom === -1) {
      error = 'Vælg venligst en konto.';
    }

    return error;
  }

  validateEnteredClassificationData(): string | null {
    let error: string | null = null;
    const data = this.state.enteredDataClassification;

    if (data.classificationA === '') {
      error = 'Rubrik A skal være et tal.';
    } else if (data.classificationB === '') {
      error = 'Rubrik B skal være et tal.';
    } else if (data.classificationC === '') {
      error = 'Rubrik C skal være et tal.';
    }

    return error;
  }

  validateEnteredOutgoingData(): string | null {
    let error: string | null = null;
    const data = this.state.enteredDataTax;

    if (data.outgoingSalesTax === '') {
      error = 'Salgsmoms (udgående moms) skal være et tal.';
    } else if (data.salesTaxForeign === '') {
      error = 'Moms af varekøb mv. i udlandet skal være et tal.';
    }

    return error;
  }

  validateEnteredDeductionData(): string | null {
    let error: string | null = null;
    const data = this.state.enteredDataTax;

    if (data.incomingSalesTax === '') {
      error = 'Købsmoms (indgående moms) skal være et tal.';
    } else if (data.oilAndFlaskTax === '') {
      error = 'Olie- og flaskeafgift skal være et tal.';
    } else if (data.electricityTax === '') {
      error = 'Elafgift skal være et tal.';
    } else if (data.natureGasAndCityGasTax === '') {
      error = 'Kulafgift skal være et tal.';
    } else if (data.coalTax === '') {
      error = 'Kulafgift skal være et tal.';
    } else if (data.co2Tax === '') {
      error = 'CO2-afgift skal være et tal.';
    } else if (data.waterTax === '') {
      error = 'Vandafgift skal være et tal.';
    }

    return error;
  }

  validateTotalAmount(): string | null {
    let error: string | null = null;

    if (isNaN(parseInt(this.state.totalTaxAmountEntered))) {
      error = 'Totalt beløb er ikke gyldigt.';
    }

    return error;
  }

  onSubmitButtonClick = e => {
    e.preventDefault();
    //validates the entered data (client-side)
    const errors = this.validate();
    this.setState({
      localErrors: {
        deduction: errors.deduction,
        outgoing: errors.outgoing,
        classification: errors.classification,
        accountFrom: errors.accountFrom,
        totalAmount: errors.totalAmount,
      },
    });

    if (
      errors.classification === null &&
      errors.deduction === null &&
      errors.outgoing === null &&
      errors.accountFrom === null &&
      errors.totalAmount === null
    ) {
      //At this point, all values are an int
      if (this.props.taxCompany.bank_accounts.length < 1) {
        throw new Error('The tax company has no bank accounts!');
      } else {
        const data = this.state.enteredDataTax;
        const declaration: NewTaxDeclaration = {
          company_id: this.props.thisUserCompanyId,
          bank_account_id: this.state.accountFrom,
          target_month: this.props.selectedMonth,
          target_year: this.props.selectedYear,
          type: TaxDeclarationType.TAX,
          amount: parseInt(
            removeDotsFromString(this.state.totalTaxAmountEntered),
          ), //TODO-future: CHANGE WHEN OVER ALL CALC IS CHANGED!
          details: {
            outgoingSalesTax: parseInt(
              removeDotsFromString(data.outgoingSalesTax),
            ),
            salesTaxForeign: parseInt(
              removeDotsFromString(data.salesTaxForeign),
            ),
            incomingSalesTax: parseInt(
              removeDotsFromString(data.incomingSalesTax),
            ),
            oilAndFlaskTax: parseInt(removeDotsFromString(data.oilAndFlaskTax)),
            electricityTax: parseInt(removeDotsFromString(data.electricityTax)),
            natureGasAndCityGasTax: parseInt(
              removeDotsFromString(data.natureGasAndCityGasTax),
            ),
            coalTax: parseInt(removeDotsFromString(data.coalTax)),
            co2Tax: parseInt(removeDotsFromString(data.co2Tax)),
            waterTax: parseInt(removeDotsFromString(data.waterTax)),
            classificationA: parseInt(
              removeDotsFromString(
                this.state.enteredDataClassification.classificationA,
              ),
            ),
            classificationB: parseInt(
              removeDotsFromString(
                this.state.enteredDataClassification.classificationB,
              ),
            ),
            classificationC: parseInt(
              removeDotsFromString(
                this.state.enteredDataClassification.classificationC,
              ),
            ),
          },
        };

        store.dispatch(TaxAdd(declaration));
        this.props.visibilityToggleFunc();
      }
    }
  };

  //The current from account is not rendered as an option
  renderToAccountOptions = (allAccounts: BankAccount[]) => {
    const fromAccountRemoved: BankAccount[] = [...allAccounts];
    const removeIndex: number = allAccounts.findIndex(
      elem => elem.id === this.state.accountFrom,
    );
    if (removeIndex !== -1) {
      fromAccountRemoved.splice(removeIndex, 1);
    }

    return this.renderAccountOptions(fromAccountRemoved);
  };

  renderAccountOptions = (allAccounts: BankAccount[]) => {
    return (
      <>
        {allAccounts.map((account, index) => {
          return (
            <option key={index} value={account.id}>
              {account.name}
            </option>
          );
        })}
      </>
    );
  };

  /** This function is used when a field is un-focused/blur.
   If the value is empty, it is it to 0. */
  onEnteredDataTaxFieldBlur = e => {
    if (e.target.value === '') {
      //The value of the field should be set to 0
      this.setState(
        {
          enteredDataTax: {
            ...this.state.enteredDataTax,
            [e.target.name]: '0',
          },
        },
        () => {
          this.recalculateTotalTaxAmount();
        },
      );
    }
  };

  renderContent() {
    const activeCompanyAccounts: BankAccount[] = this.props.userCompanyBankAccounts.filter(
      elem => elem.isActive,
    );

    return (
      <>
        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-9">
              <Form.Label>Salgsmoms (udgående moms):</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'outgoingSalesTax'}
                value={this.state.enteredDataTax.outgoingSalesTax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Moms af varekøb mv. i udlandet:</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'salesTaxForeign'}
                value={this.state.enteredDataTax.salesTaxForeign}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Form.Label>
            <b>Fradrag</b>
          </Form.Label>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Købsmoms (indgående moms):</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'incomingSalesTax'}
                value={this.state.enteredDataTax.incomingSalesTax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Olie- og flaskeafgift:</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'oilAndFlaskTax'}
                value={this.state.enteredDataTax.oilAndFlaskTax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Elafgift:</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'electricityTax'}
                value={this.state.enteredDataTax.electricityTax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Naturgas- og bygasafgift:</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'natureGasAndCityGasTax'}
                value={this.state.enteredDataTax.natureGasAndCityGasTax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Kulafgift:</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'coalTax'}
                value={this.state.enteredDataTax.coalTax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>CO2-afgift:</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'co2Tax'}
                value={this.state.enteredDataTax.co2Tax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Vandafgift:</Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'waterTax'}
                value={this.state.enteredDataTax.waterTax}
                onChange={this.onChangeTaxValue}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label className={'font-weight-bold'}>
                <i>Afgiftsbeløb i alt</i>:
              </Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'totalTaxAmountEntered'}
                value={this.state.totalTaxAmountEntered}
                disabled={true}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-9">
              <Form.Label>Rubrik A:</Form.Label>
              <Form.Label>
                Værdien uden moms af varekøb i andre EU-lande (EU erhvervelser).
              </Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'classificationA'}
                value={this.state.enteredDataClassification.classificationA}
                onChange={this.onChangeClassificationValue}
                disabled
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Rubrik B:</Form.Label>
              <Form.Label>
                Værdien af varesalg uden moms til andre EU-lande
                (EU-leverancer). Udfyldes rubrik B, skal der indsendes liste.
              </Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'classificationB'}
                value={this.state.enteredDataClassification.classificationB}
                onChange={this.onChangeClassificationValue}
                disabled
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-9">
              <Form.Label>Rubrik C:</Form.Label>
              <Form.Label>
                Værdien af varer og ydelser, som sælges momsfrit til udlandet
                efter lovens § 15-21 og 34, bortset fra vareslag til andre
                EU-lande, jf. rubrik B.
              </Form.Label>
            </div>
            <div className="col-sm-2">
              <Form.Control
                placeholder=""
                name={'classificationC'}
                value={this.state.enteredDataClassification.classificationC}
                onChange={this.onChangeClassificationValue}
                disabled
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-5">
              <Form.Label>Fra konto</Form.Label>
            </div>
            <div className="col-sm-7">
              <Col sm="auto">
                <Form.Control
                  as="select"
                  defaultValue="Vælg.."
                  name={'accountFrom'}
                  value={this.state.accountFrom}
                  onChange={this.onChangeAccountTo}
                >
                  <option value={-1}>Vælg..</option>
                  {this.renderAccountOptions(activeCompanyAccounts)}
                </Form.Control>
              </Col>
            </div>
          </Row>
        </div>

        <ErrorDiv>
          {this.state.localErrors.outgoing}
          {this.state.localErrors.deduction}
          {this.state.localErrors.classification}
          {this.state.localErrors.accountFrom}
          {this.state.localErrors.totalAmount}
        </ErrorDiv>

        <Button variant="success" onClick={e => this.onSubmitButtonClick(e)}>
          Indberet
        </Button>
      </>
    );
  }

  public render() {
    return <div>{this.renderContent()}</div>;
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  thisUserCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(DeclareSalesTaxModalData);
