import React, { useEffect } from 'react';
import Socket from '../../utils/socket';
import { store } from '../../store/store';
import { OrderGen } from '../../store/instructor/orderGen';
import { Client } from '@stomp/stompjs';
import { useRobotStateContext } from '../../contexts/RobotContexts';
import { useRobot } from '../../hooks/UseRobotContext';

interface PropsFromParent {
  orderGen: OrderGen;
}

const RobotConsole: React.FC<PropsFromParent> = props => {
  const [quantity, setQuantity] = React.useState(null);
  const { messages: robotMessages } = useRobotStateContext();
  const robotConsolePush = useRobot();

  let socketClient: Client = Socket.getInstance();

  socketClient.configure({
    onConnect: () => {
      socketClient.subscribe('/robot/messages', payload => {
        const message = JSON.parse(payload.body).message;

        robotConsolePush(message);
      });
    },
  });

  useEffect(() => {
    socketClient.activate();
    // Anything in here is fired on component mount.
    return () => {
      // Anything in here is fired on component unmount.
      socketClient.deactivate();
    };
  }, [socketClient]);

  const handleQuantityChange = e => {
    if (e.target.value !== '') {
      e.target.value = parseInt(e.target.value);
    }

    if (e.target.value > 0 && e.target.value <= 500) {
      setQuantity(e.target.value);
    } else {
      setQuantity(null);
    }
  };

  const sendStatusRequest = event => {
    event.preventDefault();

    if (socketClient.connected) {
      socketClient.publish({
        destination: '/robot/actions',
        body: JSON.stringify({ type: 'GET_STATUS' }),
      });
    } else {
      robotConsolePush('[Fejl]: Der er ikke forbindelse til robotten');
    }
  };

  const sendOrderOnDemandRequest = event => {
    event.preventDefault();

    if (socketClient.connected) {
      if (quantity !== null) {
        let globalStore = store.getState();
        let payload = {
          type: 'ORDER_ON_DEMAND',
          data: {
            quantity: quantity,
            user_id: globalStore.userInfo.id,
            company_id: props.orderGen.company_id,
          },
        };
        socketClient.publish({
          destination: '/robot/actions',
          body: JSON.stringify(payload),
        });
      } else {
        robotConsolePush('[Fejl]: Antallet skal være mellem 0 til 500');
      }
    } else {
      robotConsolePush('[Fejl]: Der er ikke forbindelse til robotten');
    }
  };

  return (
    <>
      <div className={'pt-4'}>
        <h4>Robot konsol</h4>
        <div className={'robot-terminal rounded bg-dark w-100 py-3 px-2 my-3'}>
          {robotMessages.map(msg => {
            return (
              <p key={msg.id} className={'mb-0'}>
                <label className={'text-warning'}>>> Robot </label> {msg.body}
              </p>
            );
          })}
        </div>
      </div>

      <div className="form-inline">
        <div className="form-group mr-3 mb-2">
          <input
            type="number"
            className="form-control"
            placeholder="Indtast antal"
            onChange={e => handleQuantityChange(e)}
          />
        </div>
        <button
          type="button"
          className="btn btn-outline-success mb-2"
          onClick={e => sendOrderOnDemandRequest(e)}
        >
          Opret rekvisitioner
        </button>
        <button
          type="button"
          className="btn btn-outline-info mb-2 ml-2"
          onClick={e => sendStatusRequest(e)}
        >
          Process detaljer
        </button>
        <button
          type="button"
          className="btn btn-outline-info mb-2 ml-2"
          onClick={() => {
            const status = socketClient.connected ? 'Online' : 'Offline';
            robotConsolePush('Robotten er: [' + status + ']');
          }}
        >
          Tjek Robot status
        </button>
      </div>
    </>
  );
};

export default RobotConsole;
