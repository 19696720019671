import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import CustomTablePagination from '../../../components/table/customTablePagination';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import {
  deleteExpenseBasedOnId,
  fetchCompanyByTypeId,
  fetchExpenses,
} from '../../../utils/api';
import {
  DayOfWeek,
  Expense,
  ExpensePaginated,
} from '../../../../types/expenses';
import { ModalContext } from '../../../contexts/modalContexts';
import CreateNewExpenseModal from '../../../components/modal/expenses/createNewExpenseModal';
import { Company, CompanyTypes } from '../../../store/instructor/company';
import ExpenseDetailPanel from '../../../components/modal/expenses/expenseDetailPanel';
import EditExpenseModal from '../../../components/modal/expenses/editExpenseModal';
import { convertLocalDateTimeToHomemadeDate } from '../../../utils/convertions';
import { HomemadeDate } from '../../../utils/homemadeDate';
import { confirmAlert } from 'react-confirm-alert';

export type ExpensesSelectorType = string | number;

const ExpensesContent = props => {
  let { handleModal } = React.useContext(ModalContext);
  const queryClient = useQueryClient();

  const { mutate: removeExpense } = useMutation(deleteExpenseBasedOnId, {
    onSuccess: () => {
      queryClient.refetchQueries('expenses');
    },
  });

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(20);
  const [selectedCompany, setSelectedCompany] = React.useState<
    ExpensesSelectorType
  >('all');
  const [tableActions] = React.useState([
    {
      icon: 'edit',
      tooltip: 'Rediger omkostning',
      onClick: (event, expense) => {
        handleModal(
          'Rediger omkostning',
          <EditExpenseModal selectedExpense={expense} />,
        );
      },
    },
    {
      icon: 'delete',
      tooltip: 'Fjern omkostning',
      onClick: (event, expense) => {
        confirmAlert({
          title: 'Bekræft sletning af omkostning',
          message: 'Er du sikker?',
          buttons: [
            {
              label: 'Ja',
              onClick: () => removeExpense(expense.id),
            },
            {
              label: 'Nej',
              onClick: () => void 0,
            },
          ],
        });
      },
    },
  ]);
  const [tableData, setTableData] = React.useState<Expense[]>([]);
  const [tableHeader] = React.useState([
    {
      title: 'Status',
      field: 'active',
      editable: 'never',
      render: rowData => isActiveSpan(rowData.active),
    },
    { title: 'Afsender', field: 'sender.name', editable: 'never' },
    { title: 'Modtager', field: 'receiver.name', editable: 'never' },
    { title: 'Produkt', field: 'product.name', editable: 'never' },
    { title: 'Antal', field: 'amount', editable: 'never' },
    {
      title: 'Sende frekvens',
      field: 'dayOfTheWeek',
      editable: 'never',
      render: rowData => generateSendingFrequency(rowData),
    },
    {
      title: 'Sidst sendt',
      field: 'lastSent',
      editable: 'never',
      render: rowData => {
        if (rowData.lastSent !== null) {
          const formattedDate: HomemadeDate = convertLocalDateTimeToHomemadeDate(
            rowData.lastSent,
          );
          return <span>{formattedDate.getStringWithTime()}</span>;
        } else {
          return null;
        }
      },
    },
  ]);
  const [page, setPage] = React.useState<number>(0);

  const generateSendingFrequency = rowData => {
    if (rowData.dayOfTheMonth !== 0) {
      return <span>Den {rowData.dayOfTheMonth}. i måneden</span>;
    }
    if (rowData.dayOfTheWeek !== 0) {
      return <span>Alle {DayOfWeek[rowData.dayOfTheWeek].toLowerCase()}e</span>;
    }
    if (rowData.hourOfTheDay !== 0) {
      let today = new Date();
      today.setHours(rowData.hourOfTheDay);
      today.setMinutes(0);
      return (
        <span>
          Hver dag kl.{' '}
          {today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </span>
      );
    }
  };

  const { isLoading: expenseCompaniesLoading } = useQuery<
    Company[],
    AxiosError
  >(['company', { type: CompanyTypes.Omkostninger }], () =>
    fetchCompanyByTypeId(CompanyTypes.Omkostninger),
  );

  const { data: superCompanies, isLoading: superCompaniesLoading } = useQuery<
    Company[],
    AxiosError
  >(['company', { type: CompanyTypes['Super virksomhed'] }], () =>
    fetchCompanyByTypeId(CompanyTypes['Super virksomhed']),
  );

  const { isLoading: expensesLoading, data: expenses } = useQuery<
    ExpensePaginated,
    AxiosError
  >(
    ['expenses', [page, selectedCompany, rowsPerPage]],
    () => fetchExpenses(page, rowsPerPage, selectedCompany),
    {
      onSuccess: data => {
        setTableData(data.items);
      },
    },
  );

  const isActiveSpan = (isActive: boolean) => {
    return (
      <span className={isActive ? 'text-success' : 'text-danger'}>
        {isActive ? 'Aktiv' : 'Inaktiv'}
      </span>
    );
  };

  let isLoading = [
    expenseCompaniesLoading,
    superCompaniesLoading,
    expensesLoading,
  ].some(e => e);

  const onChangeCompany = event => {
    setSelectedCompany(event.target.value);
    setPage(0);
  };

  if (isLoading) {
    return null;
  }

  return (
    <CustomTablePagination
      data={tableData}
      headers={tableHeader}
      actions={tableActions}
      onChangePageFunc={(page: number) => setPage(page)}
      totalElementAmount={expenses?.total_items ?? 0}
      numberRowPerPage={rowsPerPage}
      pageDataLoading={false}
      onChangeRowsPerPageFunc={size => setRowsPerPage(size)}
      pageNumber={page}
      searchAble={false}
      removeBorder={true}
      detailPanel={(exp: Expense) => <ExpenseDetailPanel expense={exp} />}
      components={{
        Toolbar: props => (
          <div>
            <div className="d-flex justify-content-between p-2">
              <div>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    handleModal(
                      'Tilføj ny omkostning',
                      <CreateNewExpenseModal
                        selectedCompany={selectedCompany}
                      />,
                      35,
                    );
                  }}
                >
                  <AddIcon /> Tilføj ny omkostning
                </button>
              </div>
              <div className={'d-flex align-items-center'}>
                <label className={'mb-0 mr-3 text-muted font-italic'}>
                  Filtrer
                </label>
                <select
                  className={'form-control'}
                  onChange={onChangeCompany}
                  value={selectedCompany}
                >
                  <option value="all">Alle virksomheder</option>
                  {superCompanies?.map(company => {
                    return (
                      <option
                        key={company.id}
                        value={company.id}
                        label={company.name}
                      />
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        ),
      }}
    />
  );
};

export default ExpensesContent;
