import { action } from 'typesafe-actions';
import { IOrderGen, IOrderGenActionTypes, OrderGen } from './types';

export const orderGenGetAll = () =>
  action(IOrderGenActionTypes.IORDERGEN_GET_ALL);
export const orderGenEdit = (editedOrderGen: OrderGen) =>
  action(IOrderGenActionTypes.IORDERGEN_EDIT, { editedOrderGen });
export const orderGenSuccess = (data: IOrderGen) =>
  action(IOrderGenActionTypes.IORDERGEN_SUCCESS, data);
export const orderGenError = (message: string) =>
  action(IOrderGenActionTypes.IORDERGEN_ERROR, message);
export const orderGenSetIsActive = (
  newStatus: boolean,
  editedOrderGen: OrderGen,
) =>
  action(IOrderGenActionTypes.IORDERGEN_SET_IS_ACTIVE, {
    newStatus,
    editedOrderGen,
  });
