export interface Product {
  name: string;
  id: number;
  price_cost: number;
  price_unit: number;
  product_type_id: number;
  product_type_code: string;
  navision_id: number;
  amount: number;
  active?: boolean;
  is_vax_free?: boolean;
  is_service?: boolean;
}

export interface ProductNoIdAndTypeCode {
  name?: string;
  product_type_id?: number;
  price_cost?: number;
  price_unit?: number;
  navision_id?: number;
  creditor_id?: number;
  is_vax_free?: boolean;
  is_service?: boolean;
}

export interface ProductNoTypeCode {
  name: string;
  id: number;
  price_cost: number;
  price_unit: number;
  product_type_id: number;
  navision_id: number;
  is_vax_free: boolean;
  is_service: boolean;
}

export interface IProducts {
  products: Product[];
}

export enum IProductActionTypes {
  IPRODUCT_GET_ALL = '@@instructor/product/GET_ALL',
  IPRODUCT_DELETE = '@@instructor/product/DELETE',
  IPRODUCT_EDIT = '@@instructor/product/EDIT',
  IPRODUCT_ADD = '@@instructor/product/ADD',
  IPRODUCT_SUCCESS = '@@instructor/product/SUCCESS',
  IPRODUCT_ERROR = '@@instructor/product/ERROR',
}

export interface IProductState {
  readonly loading: boolean;
  readonly products: Product[];
  readonly errors?: string;
}
