import { Reducer } from 'redux';
import { IMessagesActionTypes, IMessageState } from './types';

export const initialState: IMessageState = {
  messages: [],
  loading: false,
  errors: undefined,
};

const reducer: Reducer<IMessageState> = (state = initialState, action) => {
  switch (action.type) {
    case IMessagesActionTypes.IMESSAGES_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case IMessagesActionTypes.IMESSAGES_SET_STATUS: {
      return {
        ...state,
      };
    }
    case IMessagesActionTypes.IMESSAGES_CREATE: {
      return {
        ...state,
      };
    }
    case IMessagesActionTypes.IMESSAGES_SUCCESS: {
      return {
        ...state,
        messages: action.payload,
        loading: false,
      };
    }
    case IMessagesActionTypes.IMESSAGES_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iMessagesReducer };
