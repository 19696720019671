import * as React from 'react';
import { useEffect } from 'react';
import { Company } from '../../../../store/instructor/company';
import { Product } from '../../../../store/instructor/product';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  fetchAllCompanies,
  fetchProducts,
  removeProductBasedOnCompanyIdAndProductId,
} from '../../../../utils/api';
import { ModalContext } from '../../../../contexts/modalContexts';
import { confirmAlert } from 'react-confirm-alert';
import EditProductModalData from './allProducts/modals/editProductModalData';
import CustomTable from '../../../../components/table/customTable';
import AddIcon from '@material-ui/icons/Add';
import { ProductType } from '../../../../store/instructor/productType';
import AddProductModalData from './allProducts/modals/addProductModalData';
import EditProductQuantityModalContent from '../../../../components/modal/products/editProductQuantityModalContent';
import CompanyService from '../../../../../api/services/CompanyService';

interface PropsFromParent {
  productTypes: ProductType[];
}

const ProductsOverview: React.FC<PropsFromParent> = props => {
  const queryClient = useQueryClient();
  const [tableData, setTableData] = React.useState<Product[]>([]);
  let { handleModal } = React.useContext(ModalContext);
  const [selectedCompanyId, setSelectedCompanyId] = React.useState<
    'all' | string
  >('all');

  const { data: allCompanies, isLoading: allCompaniesLoading } = useQuery<
    Company[],
    AxiosError
  >('allCompanies', fetchAllCompanies);

  const { data: products, isLoading: allProductsLoading } = useQuery<
    Product[],
    AxiosError
  >(['products', selectedCompanyId], () => fetchProducts(selectedCompanyId));

  const { data: creditors, isLoading: creditorsLoading } = useQuery<
    AxiosResponse<Company[]> | undefined,
    AxiosError
  >(
    ['creditors', selectedCompanyId],
    () => {
      if (selectedCompanyId === 'all') {
        // Do nothing, we can't add a product in this state
      } else if (
        allCompanies?.find(c => c.id === Number(selectedCompanyId))!
          .company_type_id === 1
      ) {
        // It's a super company that have been chosen
        return CompanyService.getCompanyCreditors(Number(selectedCompanyId));
      } else {
        // It's not a super company
        return CompanyService.getSuperCompanies();
      }
    },
    {
      // The query will not execute until the userId exists
      enabled: !!allCompanies,
    },
  );

  const { mutate: removeProduct } = useMutation(
    removeProductBasedOnCompanyIdAndProductId,
    {
      onSuccess: () => {
        queryClient.refetchQueries(['products', selectedCompanyId]);
      },
    },
  );

  useEffect(() => {
    setTableData(products ?? []);
  }, [products]);

  const confirmDeleteProduct = (product: Product) => {
    confirmAlert({
      title: 'Bekræft sletning af produkt: ' + product.name + '?',
      message: 'Er du sikker?',
      buttons: [
        {
          label: 'Ja',
          onClick: () =>
            removeProduct({
              company_id: parseInt(selectedCompanyId),
              product_id: product.id,
            }),
        },
        {
          label: 'Nej',
          onClick: () => void 0,
        },
      ],
    });
  };

  const isActiveSpan = (isActive: boolean) => {
    return (
      <span className={isActive ? 'text-success' : 'text-danger'}>
        {isActive ? 'Aktiv' : 'Inaktiv'}
      </span>
    );
  };

  let isLoading = [
    allCompaniesLoading,
    allProductsLoading,
    creditorsLoading,
  ].some(e => e);

  // Bemærk at tableHeader og tableActions er rykket ned i render metoden fordi vi bruger conditionals i vores header og actions
  return (
    <div className={'container-fluid'}>
      <CustomTable
        data={tableData}
        isLoading={isLoading}
        headers={[
          {
            title: 'Status',
            field: 'active',
            editable: 'never',
            render: rowData => isActiveSpan(rowData.active),
          },
          { title: 'Navision ID', field: 'navision_id', editable: 'never' },
          { title: 'Kategori', field: 'product_type_code', editable: 'never' },
          { title: 'Navn', field: 'name', editable: 'never' },
          ...(selectedCompanyId !== 'all'
            ? [
                {
                  title: 'Antal på lager',
                  field: 'amount',
                  editable: 'never',
                  render: rowData => (
                    <span>
                      {rowData.is_service ? 'Uendelig' : rowData.amount}
                    </span>
                  ),
                },
              ]
            : []),
          {
            title: 'Salgspris',
            field: 'price_unit',
            editable: 'never',
            type: 'currency',
            maximumFractionDigits: 2,
            currencySetting: {
              currencyCode: 'DKK',
              locale: 'da-DK',
            },
          },
          {
            title: 'Indkøbspris',
            field: 'price_cost',
            editable: 'never',
            type: 'currency',
            maximumFractionDigits: 2,
            currencySetting: {
              currencyCode: 'DKK',
              locale: 'da-DK',
            },
          },
        ]}
        actions={[
          ...(selectedCompanyId !== 'all'
            ? [
                {
                  icon: 'production_quantity_limits',
                  tooltip: 'Ændre antal på lager',
                  onClick: (event, product) => {
                    if (product.is_service) {
                      handleModal(
                        'Produktet er en service',
                        <p>
                          Dette er en service og antallet kan derfor ikke
                          ændres.
                        </p>,
                      );
                    } else {
                      handleModal(
                        'Antal produkter på lager',
                        <EditProductQuantityModalContent
                          product={product}
                          selectedCompanyId={parseInt(selectedCompanyId)}
                        />,
                      );
                    }
                  },
                },
              ]
            : []),
          {
            icon: 'edit',
            tooltip: 'Rediger produkt',
            onClick: (event, product: Product) => {
              handleModal(
                'Rediger produkt',
                <EditProductModalData
                  product={product}
                  productTypes={props.productTypes}
                />,
                35,
              );
            },
          },
          {
            disabled: selectedCompanyId === 'all',
            icon: 'delete',
            tooltip:
              selectedCompanyId === 'all'
                ? 'Vælg en virksomhed'
                : 'Slet produkt',
            onClick: (event, product) => confirmDeleteProduct(product),
          },
        ]}
        removeBorder={true}
        titleComponent={
          <div className={'d-flex align-items-center'}>
            <div>
              <button
                type="button"
                className="btn btn-success mr-2"
                disabled={selectedCompanyId === 'all'}
                onClick={() => {
                  handleModal(
                    'Tilføj produkt',
                    <AddProductModalData
                      productTypes={props.productTypes}
                      selectedCompany={parseInt(selectedCompanyId)}
                      creditors={creditors?.data ?? []}
                    />,
                    35,
                  );
                }}
              >
                <AddIcon /> Tilføj nyt produkt
              </button>
            </div>

            <div>
              <select
                className={'form-control'}
                onChange={e => setSelectedCompanyId(e.target.value)}
                value={selectedCompanyId}
              >
                <option value="all">Alle produkter</option>
                {allCompanies?.map((company, key) => {
                  return (
                    <option key={key} value={company.id}>
                      {company.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ProductsOverview;
