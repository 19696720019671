import * as React from 'react';
import { connect } from 'react-redux';
import { InstructorPageLayout } from '../_layout';
import styled from 'styled-components';
import { useState } from 'react';
import { store } from '../../../store/store';
import ProductsTable from '../../student-pages/requisition/productsTable';
import ShoppingCart from '../../student-pages/requisition/shoppingCart';
import { Helmet } from 'react-helmet-async';

const AdminRequisition: React.FC = () => {
  const [products, setProducts] = useState();
  const [toCompany, setToCompany] = useState(-1);
  const [fromCompany, setFromCompany] = useState(
    store.getState().userInfo.userSuperCompanyId
      ? store.getState().userInfo.userSuperCompanyId
      : -1,
  );

  return (
    <InstructorPageLayout>
      <Helmet>
        <title>Købsordre</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Opret Købsordre</h2>
      </PageHeader>

      <div className="row">
        <div className="col-8">
          <ProductsTable
            isInstructor={true}
            editProducts={setProducts}
            products={products}
            setToCompany={setToCompany}
            setFromCompany={setFromCompany}
            fromCompany={fromCompany}
          />
        </div>
        <div className="col-4 text-right">
          <ShoppingCart
            setProducts={setProducts}
            from_company_id={fromCompany}
            to_company_id={toCompany}
            products={products}
          />
        </div>
      </div>
    </InstructorPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(AdminRequisition);
