import * as React from 'react';
import { connect } from 'react-redux';
import Validator from 'validator';
import { ApplicationState } from '../../../store';
import { store } from '../../../store/store';
import { loginRequest } from '../../../store/user/userAuthentication';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import LoadingComponent from '../../../components/LoadingComponent';

// Separate state props + dispatch props to their own interfaces.
interface PropsFromState {
  loading: boolean;
  errors?: string;
}

interface State {
  enteredData: {
    emailOrUsername: string;
    password: string;
    remember: boolean;
  };
  localErrors: {
    emailOrUsername: string;
    username: string;
    password: string;
  };
}

class LoginPage extends React.Component<PropsFromState, State> {
  constructor(props: PropsFromState) {
    super(props);

    this.state = {
      enteredData: {
        emailOrUsername: '',
        password: '',
        remember: false,
      },
      localErrors: {
        username: '',
        password: '',
        emailOrUsername: '',
      },
    };
  }

  onRememberChange = e => {
    this.setState({
      enteredData: {
        ...this.state.enteredData,
        remember: !this.state.enteredData.remember,
      },
    });
  };

  onChange = e =>
    this.setState({
      enteredData: {
        ...this.state.enteredData,
        [e.target.name]: e.target.value,
      },
    });

  onSubmit = e => {
    e.preventDefault();
    const errors = this.validate(this.state.enteredData); //validates the entered data (client-side)
    this.setState({
      localErrors: {
        password: errors.password,
        username: errors.username,
        emailOrUsername: errors.emailOrUsername,
      },
    });

    if (
      Object.keys(errors.emailOrUsername).length === 0 &&
      Object.keys(errors.password).length === 0
    ) {
      let isItAnEmail = this.doesStringContainGiven(
        this.state.enteredData.emailOrUsername,
        '@',
      );
      store.dispatch(
        loginRequest(
          this.state.enteredData.emailOrUsername,
          isItAnEmail,
          this.state.enteredData.password,
          this.state.enteredData.remember,
        ),
      );
    }
  };

  //The basic errors shown in UI
  validate = enteredData => {
    const errors = {
      password: '',
      username: '',
      emailOrUsername: '',
    };

    if (!enteredData.emailOrUsername) errors.emailOrUsername = "Can't be blank";
    if (!enteredData.password) errors.password = "Can't be blank";

    if (this.doesStringContainGiven(enteredData.emailOrUsername, '@')) {
      if (!Validator.isEmail(enteredData.emailOrUsername))
        errors.emailOrUsername = 'Invalid email';
      if (!this.doesStringContainGiven(enteredData.emailOrUsername, '.'))
        errors.emailOrUsername = 'Invalid email';
    }

    return errors;
  };

  doesStringContainGiven(inputString: string, searchableChar: string) {
    const chars: string[] = Array.from(inputString);
    let wasFound: boolean = false;
    chars.forEach(c => {
      if (c === searchableChar) {
        wasFound = true;
      }
    });
    return wasFound;
  }

  renderLogin = () => {
    return (
      <form onSubmit={this.onSubmit}>
        {this.props.errors && (
          <div className="m-2 alert alert-danger">
            [FEJL] {this.props.errors}
          </div>
        )}
        <div className="row justify-content-lg-center">
          <div className={'col col-lg-3'}>
            <h3>Log in</h3>
            <label htmlFor="email"> Email</label>
            <input
              type="emailOrUsername"
              id="emailOrUsername"
              name="emailOrUsername"
              placeholder="Enter email or username"
              value={this.state.enteredData.emailOrUsername}
              onChange={this.onChange}
              className={
                this.state.localErrors.emailOrUsername
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            <div className="form-group">
              {this.state.localErrors.emailOrUsername}
            </div>
            <label htmlFor="password"> Password</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Enter password"
              value={this.state.enteredData.password}
              onChange={this.onChange}
              className={
                this.state.localErrors.password
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
            />
            <div className="invalid-feedback">
              {this.state.localErrors.password}
            </div>

            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customCheck1"
                  onChange={this.onRememberChange}
                  checked={this.state.enteredData.remember}
                />
                <label className="custom-control-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div>
            <button type="submit" className="btn btn-dark btn-lg btn-block">
              Login
            </button>
            <p className="forgot-password text-right">
              Forgot <Link to={'/'}>password?</Link>
            </p>
          </div>
        </div>
      </form>
    );
  };

  public render() {
    return (
      <div>
        <Helmet>
          <title>Login</title>
        </Helmet>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderLogin()
        )}
      </div>
    );
  }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  loading: userInfo.loading,
  errors: userInfo.errors,
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(mapStateToProps)(LoginPage);
