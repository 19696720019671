import { Reducer } from 'redux';
import { InvoiceDetailsActionTypes, InvoiceDetailsState } from './types';
import { HomemadeDate } from '../../../utils/homemadeDate';

export const initialState: InvoiceDetailsState = {
  loading: false,
  errors: undefined,
  requisition: {
    requisition_id: -1,
    paid: false,
    items: [],
    navision_id: -1,
    from_company_id: -1,
    to_company_id: -1,
    requisition_date: new HomemadeDate(),
    requisition_amount: -1,
    archive_type: '',
    archive_type_id: -1,
    delivery_parent_id: -1,
    total_amount_without_vax: -1,
    total_vax: -1,
  },
};

const reducer: Reducer<InvoiceDetailsState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case InvoiceDetailsActionTypes.INVOICEDETAILS_GET_REQUISITION: {
      return {
        ...state,
        loading: true,
      };
    }
    case InvoiceDetailsActionTypes.INVOICEDETAILS_SUCCESS: {
      return {
        ...state,
        requisition: action.payload,
        loading: false,
      };
    }
    case InvoiceDetailsActionTypes.INVOICEDETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as iInvoiceDetailsReducer };
