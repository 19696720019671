import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { Button } from 'react-bootstrap';
import LoadingComponent from '../../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
}

interface StateProps {
  numPages: number;
  setNumPages: number;
  pageNumber: number;
  setPageNumber: number;
}

class Catalog extends React.Component<PropsFromParent, StateProps> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      numPages: -1,
      setNumPages: -1,
      pageNumber: 1,
      setPageNumber: 1,
    };
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages: numPages });
  };

  incrementPageNum = () => {
    if (this.state.pageNumber !== this.state.numPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
    }
  };

  decrementPageNum = () => {
    if (this.state.pageNumber !== 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1 });
    }
  };

  uploadNewCatalogClick = () => {
    console.log('Should open pop-up window for uploading new pdf'); //TODO missing feature
  };

  renderContent = () => {
    return (
      <div>
        <Button variant="primary" onClick={this.uploadNewCatalogClick}>
          Upload new catalog
        </Button>
        <p>
          Page {this.state.pageNumber} of {this.state.numPages}
        </p>
        <Button variant="primary" onClick={this.decrementPageNum}>
          Previous page
        </Button>
        <Button variant="primary" onClick={this.incrementPageNum}>
          Next page
        </Button>
      </div>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

//TODO change store
const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  loading: userInfo.loading,
});

export default connect(mapStateToProps)(Catalog);
