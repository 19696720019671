import { Product } from '../product';

export interface CompanyIdProductsPair {
  companyId: number;
  products: Product[];
}

export interface ICompanyProducts {
  companyProducts: CompanyIdProductsPair[];
}

export enum ICompanyProductsActionTypes {
  ICOMPANYPRODUCTS_GET_ALL = '@@instructor/company_products/GET_ALL',
  ICOMPANYPRODUCTS_DELETE = '@@instructor/company_products/DELETE',
  ICOMPANYPRODUCTS_ADD = '@@instructor/company_products/ADD',
  ICOMPANYPRODUCTS_AMOUNT_ADD = '@@instructor/company_products/AMOUNT/ADD',
  ICOMPANYPRODUCTS_AMOUNT_REMOVE = '@@instructor/company_products/AMOUNT/REMOVE',
  ICOMPANYPRODUCTS_SUCCESS = '@@instructor/company_products/SUCCESS',
  ICOMPANYPRODUCTS_ERROR = '@@instructor/company_products/ERROR',
}

export interface ICompanyProductsState {
  readonly loading: boolean;
  readonly companyProducts: CompanyIdProductsPair[];
  readonly errors?: string;
}
