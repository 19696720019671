import { Reducer } from 'redux';
import { ITaxActionTypes, ITaxState } from './types';

// Type-safe initialState!
export const initialState: ITaxState = {
  declarationYear: { year: -1, declarations: [] },
  loading: false,
  errors: undefined,
};

const reducer: Reducer<ITaxState> = (state = initialState, action) => {
  switch (action.type) {
    case ITaxActionTypes.ITAX_GET_YEAR: {
      return {
        ...state,
        loading: true,
      };
    }
    case ITaxActionTypes.ITAX_ADD: {
      return {
        ...state,
        loading: true,
      };
    }
    case ITaxActionTypes.ITAX_DELETE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ITaxActionTypes.ITAX_SUCCESS: {
      return {
        ...state,
        declarationYear: action.payload,
        loading: false,
      };
    }
    case ITaxActionTypes.ITAX_ADD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ITaxActionTypes.ITAX_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ITaxActionTypes.ITAX_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as iTaxReducer };
