import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { store } from '../../../../store/store';
import {
  ApiRequestEditedBankAccount,
  BankAccount,
  bankAccountEdit,
} from '../../../../store/instructor/bank';
import styled from 'styled-components';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  bankAccount: BankAccount;
}

interface ThisCompState {
  editedBankAccount: {
    name: string;
    balance: number;
  };
  localErrors: {
    emptyField: string;
  };
}

class EditBankAccountModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      editedBankAccount: {
        name: this.props.bankAccount.name,
        balance: this.props.bankAccount.balance,
      },
      localErrors: {
        emptyField: '',
      },
    };
  }

  onChange = e => {
    //Check for not allowing characters in the balance field
    if (e.target.name === 'balance' && e.target.value !== '') {
      const re = /^[0-9\b]+$/; //No comma allowed. Api defines these as int
      if (!re.test(e.target.value)) {
        return;
      }
    }

    this.setState({
      editedBankAccount: {
        ...this.state.editedBankAccount,
        [e.target.name]: e.target.value,
      },
    });
  };

  validate = () => {
    const errors = {
      emptyField: '',
    };

    const enteredData = this.state.editedBankAccount;

    if (enteredData.name === '') {
      errors.emptyField = 'Kontonavn, kan ikke være tom';
    } else if (enteredData.balance === null) {
      errors.emptyField = 'Balance, kan ikke være tom';
    }

    return errors;
  };

  onEditButtonClick = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({
      localErrors: {
        emptyField: errors.emptyField,
      },
    });

    if (Object.keys(errors.emptyField).length === 0) {
      let editedBankAccount: ApiRequestEditedBankAccount = {
        currency_code: this.props.bankAccount.currencyCode,
        name: this.state.editedBankAccount.name,
        balance: this.state.editedBankAccount.balance,
      };

      store.dispatch(
        bankAccountEdit(editedBankAccount, this.props.bankAccount.id),
      );
      this.props.visibilityToggleFunc();
    }
  };

  renderContent = () => {
    return (
      <div className="px-3 pt-0 pb-3">
        <Form>
          <Row>
            <Form.Label>Kontonummer</Form.Label>
            <Form.Control
              placeholder={this.props.bankAccount.id.toString()}
              readOnly
            />
            <Form.Label>Kontonavn</Form.Label>
            <Form.Control
              placeholder={'Indtast navn'}
              name={'name'}
              value={this.state.editedBankAccount.name}
              onChange={this.onChange}
            />
          </Row>
          <Row>
            <Form.Label>Saldo</Form.Label>
            <Form.Control
              placeholder="Saldo"
              name={'balance'}
              value={this.state.editedBankAccount.balance}
              onChange={this.onChange}
            />
          </Row>
        </Form>
        <ErrorDiv>{this.state.localErrors.emptyField}</ErrorDiv>
      </div>
    );
  };

  public render() {
    return (
      <>
        <p>
          Indtast den nye information og tryk gem for at bekræfte ændringerne
        </p>
        <>{this.renderContent()}</>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onEditButtonClick}>
            Gem
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

export default connect()(EditBankAccountModalData);
