import * as React from 'react';
import { StudentPageLayout } from '../_layout';
import { Helmet } from 'react-helmet-async';
import BankStatementContent from './bankStatementContent';

const BankStatement: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Opret kontoudtog</title>
      </Helmet>
      <div className={'pb-3'}>
        <h2>Opret kontoudtog</h2>
      </div>

      <BankStatementContent />
    </StudentPageLayout>
  );
};

export default BankStatement;
