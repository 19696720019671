import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Table } from 'react-bootstrap';
import { store } from '../../../../store/store';
import {
  OrderGen,
  orderGenEdit,
  OrderIntervalType,
  parseOrderInvervalTypeString,
  parserOrderIntervalTypeNumber,
} from '../../../../store/instructor/orderGen';
import { ApplicationState } from '../../../../store';
import LoadingComponent from '../../../../components/LoadingComponent';

interface PropsFromParent {
  orderGen: OrderGen;
  loading: boolean;
  setIndicator: any;
}

interface ThisCompState {
  editedOrderGen: OrderGen;
}

class CompanyTab extends React.Component<PropsFromParent, ThisCompState> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      editedOrderGen: { ...this.props.orderGen },
    };
  }

  parseOrderIntervalTypeToDanishString(
    enumInterval: OrderIntervalType,
  ): string {
    switch (enumInterval) {
      case OrderIntervalType.MINUTE:
        return 'Minut';
      case OrderIntervalType.HOUR:
        return 'Time';
      case OrderIntervalType.DAY:
        return 'Dag';
      default:
        return 'OrderIntervalType.UNDEFINED';
    }
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.setIndicator('Ændringer er gemt!');
    store.dispatch(orderGenEdit(this.state.editedOrderGen));
  };

  onChangeDayDist = e => {
    if (e.target.value !== '') {
      e.target.value = parseInt(e.target.value);
    }

    this.setState({
      editedOrderGen: {
        ...this.state.editedOrderGen,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeIntervalAndTimespan = e => {
    let editedOrderCopy: OrderGen = JSON.parse(
      JSON.stringify(this.state.editedOrderGen),
    );
    switch (e.target.name) {
      case 'startAt':
        editedOrderCopy.timespanAndFreq.startAt = parseInt(e.target.value);
        break;
      case 'endAt':
        editedOrderCopy.timespanAndFreq.endAt = parseInt(e.target.value);
        break;
      case 'interval':
        editedOrderCopy.timespanAndFreq.interval = parseOrderInvervalTypeString(
          e.target.value,
        );
        break;
    }

    this.setState({ editedOrderGen: editedOrderCopy });
  };

  renderTimeSpanOptions = times => {
    return (
      <>
        {times.map((item, index) => {
          return (
            <option key={index} value={item}>
              {item}
            </option>
          );
        })}
      </>
    );
  };

  renderIntervalOptions = (intervals: string[]) => {
    return (
      <>
        {intervals.map((item, index) => {
          if (item !== 'UNDEFINED') {
            return (
              <option key={index} value={item}>
                {this.parseOrderIntervalTypeToDanishString(
                  parseOrderInvervalTypeString(item),
                )}
              </option>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  };

  renderIntervalAndTimeSpan = () => {
    // eslint-disable-next-line prettier/prettier
    const allDayHours = [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
    ];
    return (
      <>
        <Table borderless variant="dark">
          <tbody>
            <tr>
              <td>Interval Start</td>
              <td>Interval Slut</td>
              <td>Frekvens</td>
            </tr>
            <tr>
              <td>
                <Form>
                  <Form.Control
                    as="select"
                    name={'startAt'}
                    value={this.state.editedOrderGen.timespanAndFreq.startAt}
                    onChange={this.onChangeIntervalAndTimespan}
                  >
                    {this.renderTimeSpanOptions(allDayHours)}
                  </Form.Control>
                </Form>
              </td>
              <td>
                <Form>
                  <Form.Control
                    as="select"
                    name={'endAt'}
                    value={this.state.editedOrderGen.timespanAndFreq.endAt}
                    onChange={this.onChangeIntervalAndTimespan}
                  >
                    {this.renderTimeSpanOptions(allDayHours)}
                  </Form.Control>
                </Form>
              </td>
              <td>
                <Form>
                  <Form.Control
                    as="select"
                    name={'interval'}
                    value={parserOrderIntervalTypeNumber(
                      this.state.editedOrderGen.timespanAndFreq.interval,
                    )}
                    onChange={this.onChangeIntervalAndTimespan}
                  >
                    {this.renderIntervalOptions(
                      Object.keys(OrderIntervalType).filter(
                        k => typeof OrderIntervalType[k as any] === 'number',
                      ),
                    )}
                  </Form.Control>
                </Form>
              </td>
            </tr>
          </tbody>
        </Table>
        <Button onClick={this.onSubmit}>Gem ændringer</Button>
      </>
    );
  };

  renderEditableCellDayDist = (name: string, value) => {
    return (
      <input
        name={name}
        type="text"
        onChange={this.onChangeDayDist}
        value={value}
      />
    );
  };

  renderInterval = () => {
    return (
      <Table variant="dark">
        <thead>
          <tr>
            <th>Mandag</th>
            <th>Tirsdag</th>
            <th>Onsdag</th>
            <th>Torsdag</th>
            <th>Fredag</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {this.renderEditableCellDayDist(
                'monday',
                this.state.editedOrderGen.monday,
              )}
            </td>
            <td>
              {this.renderEditableCellDayDist(
                'tuesday',
                this.state.editedOrderGen.tuesday,
              )}
            </td>
            <td>
              {this.renderEditableCellDayDist(
                'wednesday',
                this.state.editedOrderGen.wednesday,
              )}
            </td>
            <td>
              {this.renderEditableCellDayDist(
                'thursday',
                this.state.editedOrderGen.thursday,
              )}
            </td>
            <td>
              {this.renderEditableCellDayDist(
                'friday',
                this.state.editedOrderGen.friday,
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  otherRender = () => {
    return (
      <>
        {this.renderInterval()}
        {this.renderIntervalAndTimeSpan()}
      </>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.otherRender()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ iOrderGen }: ApplicationState) => ({
  loading: iOrderGen.loading,
});

export default connect(mapStateToProps)(CompanyTab);
