import * as React from 'react';
import { ComponentState } from 'react';
import { connect } from 'react-redux';
import {
  Invoice,
  invoiceGetFromCompanyPage,
  invoiceGetToCompanyActivePage,
  invoiceGetToCompanyArchivePage,
  invoiceSetArchiveType,
} from '../../../store/instructor/orderInvoice';
import InstructorModal from '../../../components/modal/instructorModal';
import { Company } from '../../../store/instructor/company';
import {
  ArchiveType,
  convertCompanyIdToName,
} from '../../../utils/convertions';
import DocumentPreview, {
  PDFTypes,
} from '../../../components/pdfgenerator/documentPreview';
import { store } from '../../../store/store';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import { filterBySimilarity } from '../../../utils/filteringAlgorithms';
import CustomTablePagination from '../../../components/table/customTablePagination';
import { ApplicationState } from '../../../store';
import { Page } from '../../../utils/storeTypes';
import DatePicker from 'react-datepicker';
import DatepickerTableTitle from '../../../components/table/title/datepickerTitle';

export enum InvoiceTabType {
  RECEIVED_ACTIVE,
  RECEIVED_ARCHIVE,
  SEND,
}

interface PropsFromParent {
  allCompanies: Company[];
  userCompanyId: number;
  invoiceTabType: InvoiceTabType;

  invoicesFromCompany: Page;
  invoicesToCompanyActive: Page;
  invoicesToCompanyArchive: Page;
  loadingFromCompany: boolean;
  loadingToCompanyActive: boolean;
  loadingToCompanyArchive: boolean;
}

interface ThisCompState {
  searchQuery: string | undefined;
  startDate: Date | null;
  endDate: Date | null;
  selectedInvoice?: any;
  tableData: Invoice[];
  modalDetailsVisible: boolean;
  tableHeader: any[];

  currentPageInvoices: Invoice[];
  totalElementAmount: number;
  pageSize: number;
  currentPage: number;
  //TODO-future: optimize: above four should be replaced with type Page
}

class InvoiceTab extends React.Component<PropsFromParent, ThisCompState> {
  constructor(props: Readonly<PropsFromParent>) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      searchQuery: undefined,
      currentPageInvoices: [],
      pageSize: 10,
      totalElementAmount: -1,
      currentPage: 0,
      modalDetailsVisible: false,
      tableData: [],
      tableHeader: [
        {
          title: 'Navision faktura ID',
          field: 'navision_id',
          editable: 'never',
        },
        ...(this.isTabReceived()
          ? [
              {
                title: 'Navision ID fra købsordre',
                field: 'requisition_navision_id',
                editable: 'never',
              },
            ]
          : []),
        {
          title: 'Faktura dato',
          field: 'invoice_date',
          editable: 'never',
          render: data => <span>{data.invoice_date.getString()}</span>,
        },
        {
          title: this.isTabReceived() ? 'Leverandør' : 'Kunde',
          field: this.isTabReceived() ? 'company_from' : 'company_to',
          editable: 'never',
          customFilterAndSearch: (term, rowData) =>
            filterBySimilarity(
              term,
              convertCompanyIdToName(
                this.props.allCompanies,
                this.isTabReceived()
                  ? rowData.company_from
                  : rowData.company_to,
              ),
            ),
          customSort: (a, b) =>
            sortStringAlphabetically(
              convertCompanyIdToName(
                this.props.allCompanies,
                this.isTabReceived() ? a.company_from : a.company_to,
              ),
              convertCompanyIdToName(
                this.props.allCompanies,
                this.isTabReceived() ? b.company_from : b.company_to,
              ),
            ),
          render: rowData => (
            <span>
              {convertCompanyIdToName(
                this.props.allCompanies,
                this.isTabReceived()
                  ? rowData.company_from
                  : rowData.company_to,
              )}
            </span>
          ),
        },
        {
          title: 'Total uden moms',
          field: 'total_amount_without_vax',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
      ],
    };
  }

  isTabReceived(): boolean {
    return (
      this.props.invoiceTabType === InvoiceTabType.RECEIVED_ARCHIVE ||
      this.props.invoiceTabType === InvoiceTabType.RECEIVED_ACTIVE
    );
  }

  componentDidMount() {
    this.fetchPage();
  }

  getPageMatchingTabType(): Page | undefined {
    switch (this.props.invoiceTabType) {
      case InvoiceTabType.RECEIVED_ACTIVE:
        return this.props.invoicesToCompanyActive;
      case InvoiceTabType.SEND:
        return this.props.invoicesFromCompany;
      case InvoiceTabType.RECEIVED_ARCHIVE:
        return this.props.invoicesToCompanyArchive;
      default:
        throw new Error('A new tab type has been added in InvoiceTab!');
    }
  }

  componentDidUpdate(
    prevProps: Readonly<PropsFromParent>,
    prevState: Readonly<ComponentState>,
  ) {
    let page: Page | undefined = this.getPageMatchingTabType(); //Current tab: current props invoice page

    if (page !== undefined) {
      if (page.items !== prevState.currentPageInvoices) {
        const array: Invoice[] = page.items;
        this.setState({
          currentPageInvoices: array,
          totalElementAmount: page.total_items,
          tableData: this.state.currentPageInvoices,
        });
      }
    }
  }

  onActionDetailsClick = invoice => {
    this.setState({ selectedInvoice: invoice });
    this.handleModalDetailsVisible();
  };

  handleModalDetailsVisible = () => {
    this.setState({
      modalDetailsVisible: !this.state.modalDetailsVisible,
    });
  };

  onActionProcessedClick(invoice: Invoice) {
    store.dispatch(
      invoiceSetArchiveType(
        invoice.invoice_id,
        ArchiveType.PROCESSED,
        this.props.userCompanyId,
      ),
    );
    setTimeout(() => {
      this.fetchPage();
    }, 600);
  }

  onChangePage = (targetPageNumber: number, pageSize: number): void => {
    this.setState({ currentPage: targetPageNumber }, () => {
      this.fetchPage();
    });
  };

  onChangeRowPerPage = (newRowsPerPage: number) => {
    this.setState({ pageSize: newRowsPerPage }, () => {
      this.fetchPage();
    });
  };

  onSearchChange = (query: string) => {
    this.setState(
      {
        searchQuery: query,
        currentPage: 0,
      },
      () => {
        this.fetchPage();
      },
    );
  };

  fetchPage = () => {
    if (this.props.invoiceTabType === InvoiceTabType.RECEIVED_ACTIVE) {
      store.dispatch(
        invoiceGetToCompanyActivePage(
          this.props.userCompanyId,
          this.state.currentPage,
          this.state.pageSize,
          true,
          ArchiveType.DEFAULT,
          this.state.searchQuery,
          this.state.startDate,
          this.state.endDate,
        ),
      );
    } else if (this.props.invoiceTabType === InvoiceTabType.RECEIVED_ARCHIVE) {
      store.dispatch(
        invoiceGetToCompanyArchivePage(
          this.props.userCompanyId,
          this.state.currentPage,
          this.state.pageSize,
          true,
          ArchiveType.PROCESSED,
          this.state.searchQuery,
          this.state.startDate,
          this.state.endDate,
        ),
      );
    } else if (this.props.invoiceTabType === InvoiceTabType.SEND) {
      store.dispatch(
        invoiceGetFromCompanyPage(
          this.props.userCompanyId,
          this.state.currentPage,
          this.state.pageSize,
          true,
          this.state.searchQuery,
          this.state.startDate,
          this.state.endDate,
        ),
      );
    } else {
      throw new Error('New tabType for InvoiceTab has been added!');
    }
  };

  getPageLoadingBoolean(): boolean {
    switch (this.props.invoiceTabType) {
      case InvoiceTabType.SEND:
        return this.props.loadingFromCompany;
      case InvoiceTabType.RECEIVED_ACTIVE:
        return this.props.loadingToCompanyActive;
      case InvoiceTabType.RECEIVED_ARCHIVE:
        return this.props.loadingToCompanyArchive;
    }
  }

  renderContent = () => {
    const actions: any[] = [
      {
        icon: 'description',
        tooltip: 'Vis detaljer',
        onClick: (event, company) => this.onActionDetailsClick(company),
      },
    ];
    if (this.props.invoiceTabType === InvoiceTabType.RECEIVED_ACTIVE) {
      actions.push({
        icon: 'done_outline',
        tooltip: 'Flyt til behandlet',
        onClick: (event, invoice) => this.onActionProcessedClick(invoice),
      });
    }

    return (
      <CustomTablePagination
        titleComponent={
          <DatepickerTableTitle
            datepicker={
              <DatePicker
                selectsRange={true}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onCalendarClose={() => this.fetchPage()}
                onChange={dates => {
                  if (dates) {
                    if (Array.isArray(dates)) {
                      if (dates.every(ele => ele == null)) {
                        this.setState(
                          {
                            startDate: null,
                            endDate: null,
                          },
                          () => {
                            this.fetchPage();
                          },
                        );
                      }
                    }
                    this.setState({
                      startDate: dates[0],
                      endDate: dates[1],
                    });
                  }
                }}
                isClearable={true}
              />
            }
          />
        }
        onSearchChange={this.onSearchChange}
        data={this.state.tableData}
        headers={this.state.tableHeader}
        actions={actions}
        onChangePageFunc={this.onChangePage}
        totalElementAmount={this.state.totalElementAmount}
        numberRowPerPage={this.state.pageSize}
        pageDataLoading={this.getPageLoadingBoolean()}
        onChangeRowsPerPageFunc={this.onChangeRowPerPage}
        pageNumber={this.state.currentPage}
      />
    );
  };

  public render() {
    return (
      <div>
        {this.renderContent()}
        <InstructorModal
          width={80}
          modalState={this.state.modalDetailsVisible}
          showFunc={this.handleModalDetailsVisible}
          titleHeader="Detaljer"
        >
          <DocumentPreview
            companies={this.props.allCompanies}
            document={this.state.selectedInvoice}
            type={PDFTypes.INVOICE}
            invoiceTabType={this.props.invoiceTabType}
            receiverLabel={'Modtager'}
          />
        </InstructorModal>
      </div>
    );
  }
}

const mapStateToProps = ({ iInvoice }: ApplicationState) => ({
  invoicesFromCompany: iInvoice.invoicesFromCompany,
  invoicesToCompanyActive: iInvoice.invoicesToCompanyActive,
  invoicesToCompanyArchive: iInvoice.invoicesToCompanyArchive,
  loadingFromCompany: iInvoice.loadingFromCompany,
  loadingToCompanyActive: iInvoice.loadingToCompanyActive,
  loadingToCompanyArchive: iInvoice.loadingToCompanyArchive,
});

export default connect(mapStateToProps)(InvoiceTab);
