/* Maybe utilize a theme generator in the future. e.g.
 * https://materialtheme.arcsine.dev/ */

export const darkTheme = {
  title: 'dark',

  colors: {
    primary: '#525252',
    secondary: '#383838',
    highlight: '#cbcbcb',
    highlight_secondary: '#efefef',
    background: '#2c2c2c',
    text: '#718896',
    success: '#01B471',
  },
};

export const lightTheme = {
  title: 'light',

  colors: {
    primary: '#222C32',
    secondary: '#1D282B',
    highlight: '#2A3B41',
    highlight_secondary: '#8ba0a9',
    background: '#fafafa',
    text: '#415460',
    success: '#01B471',
  },
};
