import { HomemadeDate } from '../../../utils/homemadeDate';
import { ErrorMessage, OrderItem } from '../orderInvoice';
import { Page } from '../../../utils/storeTypes';

export interface Delivery {
  delivery_id: number;
  requisition_id: number;
  delivery_date: HomemadeDate;
  items: OrderItem[];
  archive_type_id: number;
  archive_type: string;
  added_to_stock: boolean;
  // NEW
  delivery_category: string;
  delivery_payment_requisition_id: number;
  franko: boolean;
  from_company_id: number;
  from_company_name: string;
  requisition_navision_id: string;
  to_company_id: number;
  to_company_name: string;
  errorMessages?: ErrorMessage[];
  cost?: number;
  created_by_id?: number;
}

export interface ProductQuantity {
  product_id: number;
  quantity: number;
  is_service: boolean;
}

export interface NewDelivery {
  products: ProductQuantity[];
  requisition_id: number;
  delivery_category: string;
  delivery_company_id: number;
  franko: boolean;
}

export enum IDeliveryActionTypes {
  IDELIVERY_GET_ALL = '@@instructor/delivery/GET_ALL',
  IDELIVERY_GET_FROM = '@@instructor/delivery/GET_FROM',
  IDELIVERY_GET_ALL_TO_COMP_ACTIVE_PAGE = '@@instructor/delivery/GET_ALL_TO_COMP_ACTIVE_PAGE',
  IDELIVERY_GET_ALL_TO_COMP_ARCHIVED_PAGE = '@@instructor/delivery/GET_ALL_TO_COMP_ARCHIVED_PAGE',
  IDELIVERY_GET_ALL_FROM_COMP_PAGE = '@@instructor/delivery/GET_ALL_FROM_COMP_PAGE',
  IDELIVERY_CREATE = '@@instructor/delivery/CREATE',
  IDELIVERY_SET_ARCHIVE_TYPE = '@@instructor/delivery/SET_ARCHIVE_TYPE',
  IDELIVERY_SET_ADDEDTOSTOCK_STATUS = '@@instructor/delivery/SET_ADDEDTOSTOCK_STATUS',
  IDELIVERY_SUCCESS = '@@instructor/delivery/SUCCESS',
  IDELIVERY_FROM_SUCCESS = '@@instructor/delivery/FROM_SUCCESS',
  IDELIVERY_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS = '@@instructor/delivery/GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS',
  IDELIVERY_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS = '@@instructor/delivery/GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS',
  IDELIVERY_GET_ALL_FROM_COMP_PAGE_SUCCESS = '@@instructor/delivery/GET_ALL_FROM_COMP_PAGE_SUCCESS',
  IDELIVERY_ERROR = '@@instructor/delivery/ERROR',
}

export interface IDeliveryState {
  readonly deliveries: Delivery[];
  readonly deliveriesFrom: Delivery[]; //TODO-future: optimize: remove
  readonly loading: boolean;
  readonly loadingDeliveriesFrom: boolean;
  readonly errors?: string;

  readonly loadingToCompanyActive: boolean;
  readonly loadingToCompanyArchive: boolean;
  readonly loadingFromCompany: boolean;
  readonly deliveriesToCompanyActive: Page;
  readonly deliveriesToCompanyArchive: Page;
  readonly deliveriesFromCompany: Page;
}
