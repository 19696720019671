import React, { Component } from 'react';
import NavigationBar from '../newnavbar/navigationBar';
import 'react-pro-sidebar/dist/css/styles.css';
import OverviewRequisition from '../../pages/student-pages/rekvisitioner/';
import { NavbarDataStudent } from '../../data/NavbarDataStudent';

interface ThisCompState {
  toggled: boolean;
  collapsed: boolean;
}

class TempDevPage extends Component<{}, ThisCompState> {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
      collapsed: false,
    };
  }

  handleToggleSidebar = () => {
    this.setState({ toggled: !this.state.toggled });
  };

  handleToggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <>
        <div className={'sidebar-wrapper'}>
          <NavigationBar navbarData={NavbarDataStudent} />

          <div className={'layout-wrapper p-4 w-100'}>
            <div className={'pb-3'}>
              <h2>Købsordre</h2>
            </div>

            <OverviewRequisition />
          </div>
        </div>
      </>
    );
  }
}

export default TempDevPage;
