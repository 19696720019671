import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import PatchCard from './patchCard';
import {
  update_01032021_1,
  update_01032021_2,
  update_05052021,
  update_05052021_2,
  update_07032021,
  update_080821,
  update_14032021,
  update_20210902,
  update_21022021,
  update_240821,
  update_270721,
  update_270721_2,
  update_300721,
} from './updateNotes';

/**
 * https://markdowntohtml.com/
 * Markdown to HMTL converter. Use to easily make update notes
 */

class Dashboard extends Component {
  render() {
    return (
      <>
        <Jumbotron>
          <h1>Versionsoversigt</h1>
          <p>
            På denne side kan du finde en liste over de seneste opdateringer der
            er blevet tilføjet til systemet.
            <br />
            Tryk på version nummeret for at tilgå oplysninger om den pågældende
            opdatering
          </p>
        </Jumbotron>

        <PatchCard
          version={'0.0.10'}
          date={'2. September 2021'}
          title={'Ændringer til blandt andet ny levering'}
        >
          {update_20210902()}
        </PatchCard>

        <PatchCard
          version={'0.0.9'}
          date={'9. August 2021'}
          title={'Nyt skat og moms indberetningssystem (version 1)'}
        >
          {update_240821()}
        </PatchCard>

        <PatchCard
          version={'0.0.8'}
          date={'9. August 2021'}
          title={'Fejlregister, søgning, datoer og varelager historik'}
        >
          {update_080821()}
        </PatchCard>

        <PatchCard
          version={'0.0.7'}
          date={'4. August 2021'}
          title={'Resterende oversættelse af intruktør-siden'}
        >
          {update_300721()}
        </PatchCard>

        <PatchCard
          version={'0.0.7'}
          date={'4. August 2021'}
          title={'Tilføjelse af varelager historik'}
        >
          {update_270721_2()}
        </PatchCard>

        <PatchCard
          version={'0.0.7'}
          date={'4. August 2021'}
          title={'Ændringer fra excel ark: elev bank'}
        >
          {update_270721()}
        </PatchCard>

        <PatchCard
          version={'0.0.7'}
          date={'4. August 2021'}
          title={'Ændringer fra excel ark: inbox, bank og levering'}
        >
          {update_05052021_2()}
        </PatchCard>
        <PatchCard
          version={'0.0.7'}
          date={'4. August 2021'}
          title={'Privat bank: overførsel'}
        >
          {update_05052021()}
        </PatchCard>
        <PatchCard
          version={'0.0.6'}
          date={'14. Marts 2021'}
          title={'Overførsel og post'}
        >
          {update_14032021()}
        </PatchCard>
        <PatchCard
          version={'0.0.5'}
          date={'10. Marts 2021'}
          title={'Reimplementeret faktura'}
        >
          {update_07032021()}
        </PatchCard>
        <PatchCard
          version={'0.0.4'}
          date={'7. Marts 2021'}
          title={'Rettelser af fejl i ny levering'}
        >
          {update_07032021()}
        </PatchCard>
        <PatchCard
          version={'0.0.3'}
          date={'1. Marts 2021'}
          title={'Generelle opdateringer'}
        >
          {update_01032021_2()}
        </PatchCard>
        <PatchCard
          version={'0.0.2'}
          date={'1. Marts 2021'}
          title={'Generelle opdateringer'}
        >
          {update_01032021_1()}
        </PatchCard>

        <PatchCard
          version={'0.0.1'}
          date={'25. Januar 2021'}
          title={'Opdatering'}
        >
          {update_21022021()}
        </PatchCard>
      </>
    );
  }
}

export default Dashboard;
