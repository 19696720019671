import { IProductHistoryActionTypes, StockHistoryDTO } from './types';
import { action } from 'typesafe-actions';

export const productHistoryGet = (companyId: number, productId: number) =>
  action(IProductHistoryActionTypes.IPRODUCT_HISTORY_GET, {
    companyId,
    productId,
  });
export const productHistorySuccess = (data: StockHistoryDTO[]) =>
  action(IProductHistoryActionTypes.IPRODUCT_HISTORY_SUCCESS, data);
export const productHistoryError = (message: string) =>
  action(IProductHistoryActionTypes.IPRODUCT_HISTORY_ERROR, message);
