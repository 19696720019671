import {
  TaxDecDetailsAAmTax,
  TaxDecDetailsATP,
  TaxDecDetailsTax,
  TaxDeclaration,
  TaxDeclarationType,
} from '../../../store/instructor/tax';
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Row } from 'react-bootstrap';
import { getMonthFromIndex } from './taxContent';
import { addThousandDots } from '../../../utils/convertions';

type VisibilityToggleFuncType = () => void;
type DeleteTaxDeclarationFuncType = (declaration: TaxDeclaration) => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  deleteTaxDeclarationFunc: DeleteTaxDeclarationFuncType;
  taxDeclaration: TaxDeclaration;
}

class ViewTaxDeclaration extends React.Component<PropsFromParent> {
  onDeleteClick = e => {
    e.preventDefault();
    this.props.deleteTaxDeclarationFunc(this.props.taxDeclaration);
    this.props.visibilityToggleFunc();
  };

  onCloseClick = e => {
    e.preventDefault();
    this.props.visibilityToggleFunc();
  };

  isDetailsATP(object: any): object is TaxDecDetailsATP {
    return 'salary_receiver_contribution' in object;
  }

  isDetailsTax(object: any): object is TaxDecDetailsTax {
    return 'outgoingSalesTax' in object;
  }

  isDetailsAAmTax(object: any): object is TaxDecDetailsAAmTax {
    return 'labor_mc' in object;
  }

  getTaxCombinedAmount(data: TaxDecDetailsTax): number {
    const deductionAmount: number =
      data.incomingSalesTax +
      data.oilAndFlaskTax +
      data.electricityTax +
      data.natureGasAndCityGasTax +
      data.coalTax +
      data.co2Tax +
      data.waterTax;
    return data.outgoingSalesTax + data.salesTaxForeign - deductionAmount;
  }

  getAAmTaxContent = () => {
    if (!this.isDetailsAAmTax(this.props.taxDeclaration.details)) {
      throw Error('Should never happen!');
    }

    return (
      <>
        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label>Arbejdsmarkedsbidrag:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.labor_mc.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>A-skat:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.a_tax.toString(),
              ) + ',00'}
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label className={'font-weight-bold'}>
                <i>Afgiftsbeløb i alt</i>:
              </Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              <Form.Control
                placeholder=""
                name={'totalTaxAmountEntered'}
                value={
                  addThousandDots(
                    (
                      this.props.taxDeclaration.details.a_tax +
                      this.props.taxDeclaration.details.labor_mc
                    ).toString(),
                  ) + ',00'
                }
                disabled={true}
              />
            </div>
          </Row>
        </div>
      </>
    );
  };

  getTaxContent = () => {
    if (!this.isDetailsTax(this.props.taxDeclaration.details)) {
      throw Error('Should never happen!');
    }

    return (
      <>
        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label>Salgsmoms (udgående moms):</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.outgoingSalesTax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Moms af varekøb mv. i udlandet:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.salesTaxForeign.toString(),
              ) + ',00'}
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Form.Label>
            <b>Fradrag</b>
          </Form.Label>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Købsmoms (indgående moms):</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.incomingSalesTax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Olie- og flaskeafgift:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.oilAndFlaskTax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Elafgift:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.electricityTax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Naturgas- og bygasafgift:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.natureGasAndCityGasTax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Kulafgift:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.coalTax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>CO2-afgift:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.co2Tax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Vandafgift:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.waterTax.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label className={'font-weight-bold'}>
                <i>Afgiftsbeløb i alt</i>:
              </Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.getTaxCombinedAmount(
                  this.props.taxDeclaration.details,
                ).toString(),
              ) + ',00'}
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label>Rubrik A:</Form.Label>
              <Form.Label>
                Værdien uden moms af varekøb i andre EU-lande (EU erhvervelser).
              </Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.classificationA.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Rubrik B:</Form.Label>
              <Form.Label>
                Værdien af varesalg uden moms til andre EU-lande
                (EU-leverancer). Udfyldes rubrik B, skal der indsendes liste.
              </Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.classificationB.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Rubrik C:</Form.Label>
              <Form.Label>
                Værdien af varer og ydelser, som sælges momsfrit til udlandet
                efter lovens § 15-21 og 34, bortset fra vareslag til andre
                EU-lande, jf. rubrik B.
              </Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.classificationC.toString(),
              ) + ',00'}
            </div>
          </Row>
        </div>
      </>
    );
  };

  getAtpContent = () => {
    if (!this.isDetailsATP(this.props.taxDeclaration.details)) {
      throw Error('Should never happen!');
    }

    return (
      <>
        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label>Lønmodtagerbidrag:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.salary_receiver_contribution.toString(),
              ) + ',00'}
            </div>
          </Row>
          <Row>
            <div className="col-sm-8">
              <Form.Label>Arbejdsgiverbidrag:</Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                this.props.taxDeclaration.details.employer_contribution.toString(),
              ) + ',00'}
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-8">
              <Form.Label className={'font-weight-bold'}>
                <i>Afgiftsbeløb i alt</i>:
              </Form.Label>
            </div>
            <div className="col-sm-3 text-right">
              {addThousandDots(
                (
                  this.props.taxDeclaration.details
                    .salary_receiver_contribution +
                  this.props.taxDeclaration.details.employer_contribution
                ).toString(),
              ) + ',00'}
            </div>
          </Row>
        </div>
      </>
    );
  };

  /** Takes a taxDeclaration type and returns the danish work/string matching the given type. */
  getDanishTaxType(type: TaxDeclarationType): string {
    switch (type) {
      case TaxDeclarationType.TAX:
        return 'moms';
      case TaxDeclarationType.A_AM_TAX:
        return 'A-skat og AM-bidrag';
      case TaxDeclarationType.ATP:
        return 'ATP';
      default:
        throw Error('A new TaxDeclarationType has been added but not handled!');
    }
  }

  getAccountFooter = () => {
    return (
      <>
        <div className="pt-0">
          <Row>
            <div className="col-sm-6">
              <Form.Label>Fra konto</Form.Label>
            </div>
            <div className="col-sm-5 text-right">
              {this.props.taxDeclaration.bank_account_id}
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-6">
              <Form.Label>Betaling: </Form.Label>
            </div>
            <div className="col-sm-5 text-right">
              {this.props.taxDeclaration.is_pending
                ? 'Under behandling'
                : 'Gennemført'}
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-11 float-right">
              <Button
                variant="danger"
                onClick={e => {
                  this.onDeleteClick(e);
                }}
                disabled={!this.props.taxDeclaration.is_pending}
              >
                Slet indberetning
              </Button>
            </div>
          </Row>
        </div>
      </>
    );
  };

  public render() {
    let content;
    switch (this.props.taxDeclaration.type) {
      case TaxDeclarationType.ATP:
        content = this.getAtpContent();
        break;
      case TaxDeclarationType.A_AM_TAX:
        content = this.getAAmTaxContent();
        break;
      case TaxDeclarationType.TAX:
        content = this.getTaxContent();
        break;
      default:
        throw Error(
          'Unhandled tax declaration type has been added and not handled!',
        );
    }

    return (
      <>
        <span>
          <i>
            Detalieret visning af{' '}
            {this.getDanishTaxType(this.props.taxDeclaration.type)} indberetning
            for {getMonthFromIndex(this.props.taxDeclaration.target_month)}{' '}
            {this.props.taxDeclaration.target_year}.
          </i>
          <br />
          <br />
        </span>
        {content}
        {this.getAccountFooter()}
      </>
    );
  }
}

export default connect()(ViewTaxDeclaration);
