import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CompanyTypes, NewCompany } from '../../../store/instructor/company';
import { Field, Form as FormikForm, Formik } from 'formik';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { ModalContext } from '../../../contexts/modalContexts';
import { useMutation, useQueryClient } from 'react-query';
import { addNewCompany } from '../../../utils/api';

interface PropsFromParent {
  setIndicator: (msg: string) => void;
}

const AddCompanyModalData: React.FC<PropsFromParent> = props => {
  const queryClient = useQueryClient();
  const { showModal } = React.useContext(ModalContext);

  const { mutate: addCompany } = useMutation(addNewCompany, {
    onSuccess: () => {
      queryClient.refetchQueries('allCompanies');
      props.setIndicator('Tilføjede ny virksomhed!');
    },
  });

  const onAddButtonClick = (values: NewCompany) => {
    addCompany(values);
    props.setIndicator('Tilføjede ny virksomhed!');
    showModal();
  };

  const addCompanyRequirementSchema: SchemaOf<NewCompany> = Yup.object().shape({
    name: Yup.string().max(50).defined().min(2),
    payment_terms: Yup.number().defined().integer().min(1),
    delivery_time: Yup.number().defined().integer().min(1),
    address: Yup.string().defined(),
    bank_account_name: Yup.string().defined().max(25).min(2),
    balance: Yup.number().defined().min(0),
    company_type: Yup.number().defined().min(1).max(5),
    currency_code: Yup.number().defined().positive(),
  });

  return (
    <Formik
      initialValues={{
        name: '',
        payment_terms: 14,
        address: '',
        delivery_time: 4,
        company_type: 2,

        bank_account_name: '',
        balance: 100000,
        currency_code: 1, //TODO-future: always 1 atm
      }}
      validationSchema={addCompanyRequirementSchema}
      validateOnChange={false}
      onSubmit={(values: NewCompany) => {
        onAddButtonClick(values);
      }}
    >
      {({ errors, touched }) => (
        <FormikForm>
          <p className="h5">Virksomhedsdetaljer</p>
          <div className="form-group">
            <label>Virksomhedsnavn</label>
            <Field name="name" placeholder="Navn" className="form-control" />
            {errors.name && touched.name && (
              <small className="form-text text-danger">{errors.name}</small>
            )}
          </div>
          <div className="form-row">
            <div className="form-group col-md-5">
              <label>Type</label>
              <Field as="select" name="company_type" className="form-control">
                {Object.keys(CompanyTypes)
                  .filter(k => isNaN(Number(k)))
                  .map(enumKey => (
                    <option
                      disabled={enumKey === 'Super virksomhed'}
                      key={CompanyTypes[enumKey]}
                      label={enumKey}
                      value={CompanyTypes[enumKey]}
                    />
                  ))}
              </Field>
              {errors.company_type && touched.company_type && (
                <small className="form-text text-danger">
                  {errors.company_type}
                </small>
              )}
            </div>
            <div className="form-group col-md-3">
              <label>Leveringstid</label>
              <Field
                name="delivery_time"
                placeholder="Leveringstid"
                className="form-control"
              />
              {errors.delivery_time && touched.delivery_time && (
                <small className="form-text text-danger">
                  {errors.delivery_time}
                </small>
              )}
            </div>
            <div className="form-group col-md-4">
              <label>Betalingsbetingelser</label>
              <Field
                name="payment_terms"
                placeholder="Betalingsbetingelser"
                className="form-control"
              />
              {errors.payment_terms && touched.payment_terms && (
                <small className="form-text text-danger">
                  {errors.payment_terms}
                </small>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Adresse</label>
            <Field
              name="address"
              placeholder="Adresse"
              className="form-control"
            />
            {errors.address && touched.address && (
              <small className="form-text text-danger">{errors.address}</small>
            )}
          </div>
          <p className="h5">Indledende bankkonto</p>
          <div className="form-group">
            <label>Kontonavn</label>
            <Field
              name="bank_account_name"
              placeholder="Kontonavn"
              className="form-control"
            />
            {errors.bank_account_name && touched.bank_account_name && (
              <small className="form-text text-danger">
                {errors.bank_account_name}
              </small>
            )}
          </div>
          <div className="form-group">
            <label>Start balance</label>
            <Field
              name="balance"
              placeholder="Balance"
              className="form-control"
            />
            {errors.balance && touched.balance && (
              <small className="form-text text-danger">{errors.balance}</small>
            )}
          </div>
          <Modal.Footer>
            <Button type={'submit'} variant="success">
              Opret virksomhed
            </Button>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default AddCompanyModalData;
