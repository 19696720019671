import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { IInvoiceActionTypes, Invoice } from './types';
import {
  getEndpoint,
  getEndpointWithFilters,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';
import {
  invoiceError,
  invoiceSuccess,
  invoiceSuccessFromCompanyPage,
  invoiceSuccessToCompanyActivePage,
  invoiceSuccessToCompanyArchivePage,
} from './actions';
import {
  ArchiveType,
  convertLocalDateTimeToHomemadeDate,
} from '../../../utils/convertions';

function* handleGetAll() {
  try {
    const res = yield getEndpoint('invoice');

    if (res.error) {
      yield put(invoiceError(res.error));
    } else {
      let invoices: Invoice[] = [];
      res.data.forEach(elem => {
        let parsedInvoice: Invoice = {
          ...elem,
          invoice_date: convertLocalDateTimeToHomemadeDate(elem.invoice_date),
        };
        invoices.push(parsedInvoice);
      });

      yield put(invoiceSuccess(invoices));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetFromCompanyPage(action) {
  try {
    const search = action.payload.searchQuery;
    const after = action.payload.startDate;
    const before = action.payload.endDate;

    const res = yield getEndpointWithFilters('invoice/page', {
      from_company_id: action.payload.companyId,
      size: action.payload.pageSize,
      page: action.payload.page,
      descending: action.payload.descending,
      ...(search && { search }),
      ...(after && { after }),
      ...(before && { before }),
    });

    if (res.error) {
      yield put(invoiceError(res.error));
    } else {
      let invoices: Invoice[] = [];
      res.data.items.forEach(elem => {
        let parsedInvoice: Invoice = {
          ...elem,
          invoice_date: convertLocalDateTimeToHomemadeDate(elem.invoice_date),
        };
        invoices.push(parsedInvoice);
      });

      yield put(
        invoiceSuccessFromCompanyPage({
          items: invoices,
          total_pages: res.data.total_pages,
          total_items: res.data.total_items,
          page_size: res.data.page_size,
          current_page: res.data.current_page,
        }),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetToCompanyArchiveTypePage(action) {
  try {
    const search = action.payload.searchQuery;
    const after = action.payload.startDate;
    const before = action.payload.endDate;

    const res = yield getEndpointWithFilters('invoice/page', {
      to_company_id: action.payload.companyId,
      archive_type: action.payload.archiveType,
      size: action.payload.pageSize,
      page: action.payload.page,
      descending: action.payload.descending,
      ...(search && { search }),
      ...(after && { after }),
      ...(before && { before }),
    });

    if (res.error) {
      yield put(invoiceError(res.error));
    } else {
      let invoices: Invoice[] = [];
      res.data.items.forEach(elem => {
        let parsedInvoice: Invoice = {
          ...elem,
          invoice_date: convertLocalDateTimeToHomemadeDate(elem.invoice_date),
        };
        invoices.push(parsedInvoice);
      });

      switch (action.payload.archiveType) {
        case ArchiveType.DEFAULT:
          yield put(
            invoiceSuccessToCompanyActivePage({
              items: invoices,
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
              page_size: res.data.page_size,
              current_page: res.data.current_page,
            }),
          );
          break;
        case ArchiveType.PROCESSED:
          yield put(
            invoiceSuccessToCompanyArchivePage({
              items: invoices,
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
              page_size: res.data.page_size,
              current_page: res.data.current_page,
            }),
          );
          break;
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCreate(action) {
  try {
    const res = yield postEndpoint('invoice', action.payload.data);

    if (res.error) {
      yield put(invoiceError(res.error));
    } else {
      yield put(invoiceSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleSetArchiveType(action) {
  try {
    const res = yield putEndpoint(
      'invoice',
      {
        archive_type_id: action.payload.newArchiveTypeId,
      },
      action.payload.invoiceId,
    );

    if (res.error) {
      yield put(invoiceError(res.error));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchInvoice() {
  yield takeEvery(IInvoiceActionTypes.IINVOICE_GET_ALL, handleGetAll);
  yield takeEvery(
    IInvoiceActionTypes.IINVOICE_GET_ALL_FROM_COMP_PAGE,
    handleGetFromCompanyPage,
  );
  yield takeEvery(
    IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ACTIVE_PAGE,
    handleGetToCompanyArchiveTypePage,
  );
  yield takeEvery(
    IInvoiceActionTypes.IINVOICE_GET_ALL_TO_COMP_ARCHIVED_PAGE,
    handleGetToCompanyArchiveTypePage,
  );
  yield takeEvery(IInvoiceActionTypes.IINVOICE_CREATE, handleCreate);
  yield takeEvery(
    IInvoiceActionTypes.IINVOICE_SET_ARCHIVE_TYPE,
    handleSetArchiveType,
  );
}

function* iInvoiceSaga() {
  yield all([fork(watchInvoice)]);
}

export { iInvoiceSaga };
