import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Company,
  companyGetAll,
  companyGetSuperCompaniesCompany,
} from '../../../store/instructor/company';
import { store } from '../../../store/store';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import InboxInner from './inboxInner';
import { useEffect, useState } from 'react';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
  allCompanies: Company[];
  superCompaniesCompany: Map<number, Company[]>;
}

const InboxContent: React.FC<PropsFromParent> = props => {
  const [mixitCompanies, setMixitCompanies] = useState<Company[]>([]);
  const [EDPCompanies, setEDPCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<Company>({
    name: '',
    id: -1,
    payment_terms: -1,
    address: '',
    delivery_time: -1,
    bank_accounts: [],
    company_type_id: -1,
    company_type: '',
  });
  const [currentSelectedCompany, setCurrentSelectedCompany] = useState<string>(
    '',
  );
  const [currentSelectedCompanyId, setCurrentSelectedCompanyId] = useState<
    number
  >(-1);

  useEffect(() => {
    const mixitSubCompanies:
      | Company[]
      | undefined = props.superCompaniesCompany.get(1);
    if (mixitSubCompanies !== undefined) {
      setMixitCompanies(mixitSubCompanies);
    }
    const edpSubCompanies:
      | Company[]
      | undefined = props.superCompaniesCompany.get(2);
    if (edpSubCompanies !== undefined) {
      setEDPCompanies(edpSubCompanies);
    }
  }, [props.superCompaniesCompany]);

  useEffect(() => {
    store.dispatch(companyGetAll());
    store.dispatch(companyGetSuperCompaniesCompany([1, 2]));
  }, []);

  const onChange = e => {
    if (e.target.value !== '-1') {
      const selectedCompany: Company = JSON.parse(e.target.value);
      if (selectedCompany === undefined) {
        throw new Error(
          'Inbox: select company: could not find company matching id.',
        );
      } else {
        setSelectedCompany(selectedCompany);
        setCurrentSelectedCompanyId(selectedCompany.id);
        setCurrentSelectedCompany(e.target.value);
      }
    } else {
      //default values
      setSelectedCompany({
        name: '',
        id: -1,
        payment_terms: -1,
        address: '',
        delivery_time: -1,
        bank_accounts: [],
        company_type_id: -1,
        company_type: '',
      });
      setCurrentSelectedCompany('');
      setCurrentSelectedCompanyId(-1);
    }
  };

  const generateOptList = (superCompany: Company, index) => {
    let subCompanies: Company[] = [];
    if (superCompany.id === 1) {
      subCompanies = mixitCompanies;
    } else if (superCompany.id === 2) {
      subCompanies = EDPCompanies;
    } else {
      throw new Error(
        'InboxContent: new super company has been added or wrong id given to function.',
      );
    }
    return (
      <optgroup key={index} label={superCompany.name}>
        {subCompanies
          .sort((a, b) => sortStringAlphabetically(a.name, b.name))
          .map((comp, index) => {
            return (
              <option key={index} value={JSON.stringify(comp)}>
                {comp.id} {comp.name}
              </option>
            );
          })}
      </optgroup>
    );
  };

  return (
    <div>
      {props.loading ? (
        <div className={'d-flex justify-content-center'}>
          <LoadingComponent visible={props.loading} />
        </div>
      ) : (
        <>
          <Form>
            <Row>
              <Form.Label>Vælg virksomhed:</Form.Label>
              <Col sm="auto">
                <Form.Control
                  as="select"
                  name={'currentSelectedCompanyId'}
                  value={currentSelectedCompany}
                  onChange={onChange}
                >
                  <option value={-1}>Alle beskeder</option>
                  {props.allCompanies
                    .filter(company => company.company_type_id === 1)
                    .map((elem: Company, index) => {
                      return (
                        <option key={index} value={JSON.stringify(elem)}>
                          {elem.id} {elem.name}
                        </option>
                      );
                    })}
                  {props.allCompanies
                    .filter(company => company.company_type_id === 1)
                    .map((superCompany: Company, index) =>
                      generateOptList(superCompany, index),
                    )}
                </Form.Control>
              </Col>
            </Row>
          </Form>
          <InboxInner
            selectedCompany={selectedCompany}
            currentSelectedCompanyId={currentSelectedCompanyId}
            allCompanies={props.allCompanies}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ iMessages, iCompany }: ApplicationState) => ({
  loading: iCompany.loading || iCompany.superCompaniesCompanyLoading,
  errors: iMessages.errors,
  allCompanies: iCompany.companies,
  superCompaniesCompany: iCompany.superCompaniesCompany,
});

export default connect(mapStateToProps)(InboxContent);
