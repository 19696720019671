import { Reducer } from 'redux';
import { UserAuthenticationTypes, UserInformation, UserTypes } from './types';

export const initialState: UserInformation = {
  id: -1,
  userFirstName: '',
  userLastName: '',
  jwtToken: null,
  userType: UserTypes.NOT_LOGGED_IN,
  errors: undefined,
  loading: false,
  jwtTokenAge: null,
  userSuperCompanyId: -1,
  userEmail: '',
  userTypeId: -1,
  userGroupId: -1,
  userGroupCode: '',
  userPhoneNo: '',
};

const reducer: Reducer<UserInformation> = (state = initialState, action) => {
  switch (action.type) {
    case UserAuthenticationTypes.USER_LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
        usernameOrEmail: action.payload,
        password: action.payload,
      };
    }
    case UserAuthenticationTypes.USER_LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        id: action.payload.userDetails.id,
        userType: parseUserType(action.payload.userDetails.user_type),
        userFirstName: action.payload.userDetails.first_name,
        userLastName: action.payload.userDetails.last_name,
        userEmail: action.payload.userDetails.email,
        userTypeId: action.payload.userDetails.user_type_id,
        userGroupId: action.payload.userDetails.user_group_id,
        userGroupCode: action.payload.userDetails.user_group_code,
        userPhoneNo: action.payload.userDetails.phone_number,
        userSuperCompanyId: action.payload.userDetails.super_company_id,
        jwtToken: action.payload.token
          ? action.payload.token
          : localStorage.getItem('jwt'),
        jwtTokenAge: action.payload.jwtTokenAge
          ? action.payload.jwtTokenAge
          : localStorage.getItem('jwtTokenAge'),
      };
    }
    case UserAuthenticationTypes.USER_LOGIN_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case UserAuthenticationTypes.USER_LOGIN_REMOVE_USER_INFO: {
      return {
        ...state,
        loading: false,
        userFirstName: '',
        userLastName: '',
        jwtToken: null,
        userType: UserTypes.NOT_LOGGED_IN,
        errors: undefined,
        jwtTokenAge: null,
        id: -1,
        userSuperCompanyId: -1,
        userEmail: '',
        userTypeId: -1,
        userGroupId: -1,
        userGroupCode: '',
        userPhoneNo: '',
      };
    }
    case UserAuthenticationTypes.USER_REFRESH_TOKEN_AGE: {
      return {
        ...state,
        loading: false,
        jwtTokenAge: JSON.stringify(new Date()),
      };
    }
    case UserAuthenticationTypes.USER_CHANGE_PASSWORD: {
      return {
        ...state,
        loading: false,
      };
    }
    case UserAuthenticationTypes.USER_LOGOUT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserAuthenticationTypes.USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UserAuthenticationTypes.USER_LOGOUT_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case UserAuthenticationTypes.USER_REAUTH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UserAuthenticationTypes.USER_REAUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UserAuthenticationTypes.USER_REAUTH_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export function parseUserType(userTypeString: string) {
  switch (userTypeString) {
    case 'instructor':
      return UserTypes.INSTRUCTOR;
    case 'student':
      return UserTypes.STUDENT;
    default:
      throw new Error(
        'API returned invalid userType on login! ' + userTypeString,
      );
  }
}

export function parseUserTypeToInt(userType: UserTypes) {
  switch (userType) {
    case UserTypes.INSTRUCTOR:
      return 1;
    case UserTypes.STUDENT:
      return 2;
    default:
      return -1; //TODO Server responded with unexpected response
  }
}

export { reducer as userAuthenticationReducer };
