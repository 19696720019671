import React, { Component } from 'react';
import { Company } from '../../../store/instructor/company';
import CompanyInboxTab from './companyInboxTab';
import InstructorModal from '../../../components/modal/instructorModal';
import NewMsgTab from '../../student-pages/inbox/newMsgTab';

interface PropsFromParent {
  selectedCompany: Company;
  currentSelectedCompanyId: number;
  allCompanies: Company[];
}

interface ThisCompState {
  modalVisibility: boolean;
  indicatorMsg: string;
}

class InboxInner extends Component<PropsFromParent, ThisCompState> {
  state = {
    modalVisibility: false,
    indicatorMsg: '',
  };

  handleModalVisibility = () => {
    this.setState({ modalVisibility: !this.state.modalVisibility });
  };

  changeMessageIndicator = msg => {
    this.setState({ indicatorMsg: msg });
  };

  render() {
    const { selectedCompany, currentSelectedCompanyId } = this.props;
    const criteria = parseInt(String(currentSelectedCompanyId)) !== -1;
    return (
      <>
        <div
          className={
            'd-flex justify-content-center py-1 my-2 border border-secondary rounded'
          }
        >
          <h3>{criteria ? selectedCompany.name : 'Alle beskeder'}</h3>
        </div>
        <CompanyInboxTab
          companyId={selectedCompany.id}
          isUserCompany={selectedCompany.company_type_id === 1}
          allCompanies={this.props.allCompanies}
        />
        {criteria ? (
          <>
            <div>
              <button
                className={'btn btn-success my-4'}
                onClick={() => this.handleModalVisibility()}
              >
                Opret ny besked
              </button>

              <p>{this.state.indicatorMsg}</p>
            </div>
            <InstructorModal
              modalState={this.state.modalVisibility}
              showFunc={this.handleModalVisibility}
              titleHeader={'Opret besked'}
            >
              {/* TODO-future: Retrieve list of all associated super companies for a given robot company and put them in this list */}
              <NewMsgTab
                msgReceivers={this.props.allCompanies.filter(
                  company => company.company_type_id === 1,
                )}
                setIndicator={this.changeMessageIndicator}
                customFromId={selectedCompany.id}
              />
            </InstructorModal>
          </>
        ) : null}
      </>
    );
  }
}

export default InboxInner;
