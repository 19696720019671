export interface IOrderGen {
  orderGens: OrderGen[];
}
export interface OrderGen {
  active: boolean;
  company_id: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  sunday: number;
  saturday: number;
  timespanAndFreq: TimespanAndFreq;
}

export enum IOrderGenActionTypes {
  IORDERGEN_SET_IS_ACTIVE = '@@instructor/orderGen/SET_IS_ACTIVE',
  IORDERGEN_GET_ALL = '@@instructor/orderGen/GET_ALL',
  IORDERGEN_EDIT = '@@instructor/orderGen/EDIT',
  IORDERGEN_SUCCESS = '@@instructor/orderGen/SUCCESS',
  IORDERGEN_ERROR = '@@instructor/orderGen/ERROR',
}

export interface IOrderGenState {
  readonly orderGens: IOrderGen;
  readonly loading: boolean;
  readonly errors?: string;
}

export enum OrderIntervalType {
  UNDEFINED,
  MINUTE,
  HOUR,
  DAY,
}

export function parseOrderInvervalTypeString(stringEnum: string) {
  switch (stringEnum) {
    case 'MINUTE':
      return OrderIntervalType.MINUTE;
    case 'HOUR':
      return OrderIntervalType.HOUR;
    case 'DAY':
      return OrderIntervalType.DAY;
    default:
      return OrderIntervalType.UNDEFINED;
  }
}

export function parserOrderIntervalTypeNumber(typeNumber: number) {
  switch (typeNumber) {
    case 1:
      return 'MINUTE';
    case 2:
      return 'HOUR';
    case 3:
      return 'DAY';
    default:
      return 'UNDEFINED';
  }
}

export interface TimespanAndFreq {
  startAt: number;
  endAt: number;
  interval: OrderIntervalType;
}

export interface CompanyProductRelation {
  average: number;
  company_id: number;
  product_type_id: number;
}
