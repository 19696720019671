import React, { Component } from 'react';
import MaterialTable, { Components } from '@material-table/core';

/* Docs:
 * https://material-table-core.com/
 */

interface PropsFromParent {
  data: any[];
  headers: any[];
  actions?: any[];
  selection?: boolean;
  editFunction?: any;
  isLoading?: boolean;
  removeBorder?: boolean;
  titleComponent?: React.ReactElement<any>;
  components?: Components;
  searchAble?: boolean;
  pageSize?: number;
  paging?: boolean;
  exportOptions?: { enabled: boolean; functions: any[] };
}

/* TODO-Future: Enable editing within
 *   https://material-table.com/#/docs/features/editable */
class CustomTable extends Component<PropsFromParent> {
  render() {
    return (
      <>
        <MaterialTable
          style={{
            border: this.props.removeBorder ? 'none' : '',
          }}
          title={this.props?.titleComponent}
          data={this.props.data}
          actions={this.props.actions}
          columns={this.props.headers}
          components={this.props.components}
          isLoading={this.props.isLoading ? this.props.isLoading : false}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                const re = /^0$|^[1-9][0-9]*$/;
                if (re.test(newValue)) {
                  this.props.editFunction(rowData, newValue);
                  resolve();
                } else {
                  reject();
                }
              });
            },
            isCellEditable(rowData, columnDef) {
              return true;
            },
          }}
          options={{
            search: this.props.searchAble ?? true,
            paging: this.props.paging ?? true,
            pageSize: this.props.pageSize ?? 10,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [10, 20, 50, 100],
            filtering: false,
            actionsColumnIndex: -1,
            showTitle: !!this.props.titleComponent,
            draggable: false,
            selection: this.props.selection ? this.props.selection : false,
            ...(this.props.exportOptions && {
              exportMenu: [...this.props.exportOptions.functions],
            }),
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'Der er intet data at vise',
            },
            toolbar: {
              searchTooltip: 'Søg',
              searchPlaceholder: 'Søg',
            },
            header: {
              actions: '',
            },
            pagination: {
              labelRowsSelect: 'rækker',
              labelDisplayedRows: '({count}) Række {from}-{to}',
              firstTooltip: 'Første side',
              previousTooltip: 'Forrige side',
              nextTooltip: 'Næste side',
              lastTooltip: 'Sidste side',
            },
          }}
        />
      </>
    );
  }
}

export default CustomTable;
