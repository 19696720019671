import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../../../store';
import {
  ProductType,
  productTypeAdd,
} from '../../../../../store/instructor/productType';
import { store } from '../../../../../store/store';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  allProductTypes: ProductType[];
}

interface ThisCompState {
  newProductTypeName: string;
}

class AddProductTypeModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      newProductTypeName: '',
    };
  }

  onChange = e => {
    this.setState({ newProductTypeName: e.target.value });
  };

  onAddButtonClick = e => {
    e.preventDefault();

    store.dispatch(productTypeAdd(this.state.newProductTypeName));
    this.props.visibilityToggleFunc();
  };

  renderOptions = allTypes => {
    return (
      <>
        {allTypes.map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item.code}
            </option>
          );
        })}
      </>
    );
  };

  public render() {
    return (
      <>
        <p>
          Indtast de ønskede oplysninger nedenfor, og tryk på tilføj for at
          bekræfte
        </p>
        <div className="px-3 pt-0 pb-3">
          <div>
            <Form>
              <Row>
                <Form.Label>Produkt type navn</Form.Label>
                <Form.Control
                  placeholder="Produkt type navn"
                  name={'newProductTypeName'}
                  value={this.state.newProductTypeName}
                  onChange={this.onChange}
                />
              </Row>
            </Form>
          </div>
        </div>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onAddButtonClick}>
            Add
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ iProductType }: ApplicationState) => ({
  loading: iProductType.loading,
});

export default connect(mapStateToProps)(AddProductTypeModalData);
