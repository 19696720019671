import React from 'react';
import { CompanyWithRelation } from './addRelationToCompanyModalContent';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

interface PropsFromParent {
  company: CompanyWithRelation;
}

const SuperCompanyRelationOverview: React.FC<PropsFromParent> = ({
  company,
}) => {
  const queryClient = useQueryClient();

  const relatedCompanies: CompanyWithRelation[] =
    queryClient
      .getQueryData<CompanyWithRelation[]>('allCompanies')
      ?.filter(relatedCompany =>
        company.relations.includes(relatedCompany.id),
      ) ?? [];

  console.log(relatedCompanies);

  return (
    <>
      <div className={'mb-4'}>
        <p className="h5 mb-0">Relationer forbundet til {company.name}</p>
        <small className="text-muted">
          Virksomheder i nedenstående liste indgår i robottens processer
        </small>
      </div>

      <ListGroup className={'list-group'}>
        {relatedCompanies.map((comp, key) => {
          return (
            <li key={key} className="list-group-item">
              <label className={'text-muted mr-3'}>{comp.id}</label>
              {comp.name}
            </li>
          );
        })}
      </ListGroup>
    </>
  );
};

const ListGroup = styled.ul`
  overflow: scroll;
  height: 30rem;
  overflow-x: hidden;
`;

export default SuperCompanyRelationOverview;
