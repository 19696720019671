import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.
import { routerMiddleware } from 'connected-react-router';
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension';

// Import persist store
import { persistStore } from 'redux-persist';
import history from '../utils/history';

// Import the state interface and our combined reducers/sagas.
import { createRootReducer, rootSaga } from './index';

// create the composing function for our middlewares
const composeEnhancers = composeWithDevTools({});
// create the redux-saga middleware
const sagaMiddleware = createSagaMiddleware();

// We'll create our store with the combined reducers/sagas, and the initial Redux state that
// we'll be passing from our entry point.
export const store = createStore(
  createRootReducer(),
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
);

export const persistor = persistStore(store);

// Don't forget to run the root saga, and return the store object.
sagaMiddleware.run(rootSaga);
//export default { store, persistor };
