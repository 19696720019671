import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { Company, CompanyTypes, ICompanyActionTypes } from './types';
import {
  companyError,
  companyGetAll,
  companyGetCreditorsSuccess,
  companyGetDebtorsSuccess,
  companyGetExpenseCompsSuccess,
  companyGetProductsSuccess,
  companyGetSuperCompaniesCompanySuccess,
  companySuccess,
} from './actions';
import {
  deleteEndpoint,
  getEndpoint,
  getEndpointWithFilters,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';
import { BankAccount, parseBankAccount } from '../bank';

export function parseApiCompany(apiCompany): Company {
  let parsedBankAccounts: BankAccount[] = [];
  apiCompany.bank_accounts.forEach(unparsedAccount =>
    parsedBankAccounts.push(parseBankAccount(unparsedAccount)),
  );

  return {
    ...apiCompany,
    bank_accounts: parsedBankAccounts,
  };
}

function* handleFetch() {
  try {
    const res = yield getEndpoint('company');

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      let parsedCompanies: Company[] = [];
      res.data.forEach(unparsedCompany => {
        parsedCompanies.push(parseApiCompany(unparsedCompany));
      });

      yield put(companySuccess(parsedCompanies));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleFetchProducts(action) {
  try {
    const res = yield getEndpoint(
      'company/' + action.payload.companyId + '/product',
    );

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      yield put(companyGetProductsSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCompanyDeletion(action) {
  try {
    const res = yield deleteEndpoint('company', action.payload.companyId);

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      yield put(companyGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCompanyEdit(action) {
  console.log(action.payload);
  try {
    const res = yield putEndpoint(
      'company',
      action.payload.editedCompany,
      action.payload.companyId,
    );

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      yield put(companyGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCompanyAdd(action) {
  try {
    const res = yield postEndpoint('company', action.payload.newCompany);

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      yield put(companyGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleFetchDebtors(action) {
  try {
    const res = yield getEndpointWithFilters('company', {
      belongs_to_super_company_id: action.payload.companyId,
      debtor: 1,
    });

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      yield put(companyGetDebtorsSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleFetchCreditors(action) {
  try {
    const res = yield getEndpointWithFilters('company', {
      belongs_to_super_company_id: action.payload.companyId,
      creditor: 1,
    });

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      yield put(companyGetCreditorsSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleFetchExpenseComps(action) {
  try {
    const res = yield getEndpointWithFilters('company', {
      belongs_to_super_company_id: action.payload.companyId,
      company_type_id: CompanyTypes.Omkostninger,
    });

    if (res.error) {
      yield put(companyError(res.error));
    } else {
      yield put(companyGetExpenseCompsSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleFetchSuperCompaniesCompany(action) {
  try {
    const superCompanyIds: number[] = action.payload.superCompanies;
    let fetchedCompanies: Map<number, Company[]> = new Map<number, Company[]>();

    for (const item of superCompanyIds) {
      yield getEndpointWithFilters('company', {
        belongs_to_super_company_id: item,
      })
        .then(res => {
          fetchedCompanies.set(item, res.data);
        })
        .catch(e => put(companyError(e)));
    }

    yield put(companyGetSuperCompaniesCompanySuccess(fetchedCompanies));
  } catch (err) {
    console.log(err.message);
  }
}

function* watchICompany() {
  yield takeEvery(
    ICompanyActionTypes.ICOMPANY_FETCH_EXPENSECOMP,
    handleFetchExpenseComps,
  );
  yield takeEvery(ICompanyActionTypes.ICOMPANY_FETCH_ALL, handleFetch);
  yield takeEvery(ICompanyActionTypes.ICOMPANY_DELETE, handleCompanyDeletion);
  yield takeEvery(
    ICompanyActionTypes.ICOMPANY_FETCH_PRODUCTS,
    handleFetchProducts,
  );
  yield takeEvery(ICompanyActionTypes.ICOMPANY_EDIT, handleCompanyEdit);
  yield takeEvery(ICompanyActionTypes.ICOMPANY_ADD, handleCompanyAdd);
  yield takeEvery(
    ICompanyActionTypes.ICOMPANY_FETCH_DEBTORS,
    handleFetchDebtors,
  );
  yield takeEvery(
    ICompanyActionTypes.ICOMPANY_FETCH_CREDITORS,
    handleFetchCreditors,
  );
  yield takeEvery(
    ICompanyActionTypes.ICOMPANY_FETCH_SUPERCOMPANY_COMPANIES,
    handleFetchSuperCompaniesCompany,
  );
}

function* iCompanySaga() {
  yield all([fork(watchICompany)]);
}

export { iCompanySaga };
