import React from 'react';
import { Company } from '../../../../store/instructor/company';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Invoice } from '../../../../store/instructor/orderInvoice';

interface PropsFromParent {
  to_company?: Company;
  from_company?: Company;
  invoice: Invoice;
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

const InvoiceDetails: React.FC<PropsFromParent> = ({
  to_company,
  from_company,
  invoice,
}) => {
  return (
    <>
      <Row>
        <Col>
          <h4>Afsender</h4>
          <p className={'mb-0'}>{from_company?.name}</p>
          <p>{from_company?.address}</p>
        </Col>
        <Col>
          <h4>Modtager</h4>
          <p className={'mb-0'}>{to_company?.name}</p>
          <p>{to_company?.address}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Faktura detaljer</h4>
          <p>Internt ID: {invoice.invoice_id}</p>
          <p>Navision ID: {invoice.navision_id}</p>
          <p>
            Rekvisition internt ID:
            <Link
              to={'/instructor/mistakes/requisitions/' + invoice.requisition_id}
            >
              {invoice.requisition_id}
            </Link>
          </p>
          <p>
            Dato: {invoice.invoice_date.dayOfMonth}/
            {invoice.invoice_date.monthValue}/{invoice.invoice_date.year}
          </p>
          <p>Beløb: {formatter.format(invoice.amount)}</p>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceDetails;
