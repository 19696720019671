import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ModalContext } from '../../../contexts/modalContexts';
import { editExpenseBasedOnObject } from '../../../utils/api';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { Field, Form as FormikForm, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import {
  Expense,
  ExpensePUT,
  ExpensePUTWithHour,
} from '../../../../types/expenses';
import { Company, CompanyTypes } from '../../../store/instructor/company';
import ExpenseSendFrequency from './expenseSendFrequency';
import update from 'immutability-helper';
import { formatter } from '../../../utils/convertions';

interface PropsFromParent {
  selectedExpense: Expense;
}

const EditExpenseModal: React.FC<PropsFromParent> = ({ selectedExpense }) => {
  const queryClient = useQueryClient();
  const { showModal } = React.useContext(ModalContext);
  const [isActive] = React.useState(selectedExpense.active ?? false);

  const { mutate: editExpense } = useMutation(editExpenseBasedOnObject, {
    onSuccess: () => {
      queryClient.refetchQueries('expenses');
    },
  });

  const editExpenseRequirementsSchema: SchemaOf<ExpensePUT> = Yup.object().shape(
    {
      receiver_id: Yup.number().defined().integer().positive(),
      amount: Yup.number().defined().integer().positive(),
      active: Yup.boolean(),
    },
  );
  const changeActiveState = () => {
    const formattedBody = {
      id: selectedExpense.id,
      active: !selectedExpense.active,
    };

    editExpense(formattedBody);
    showModal();
  };

  function cleanOutputData(data) {
    for (var propName in data) {
      if (data[propName] === null || data[propName] === undefined) {
        delete data[propName];
      }
    }

    return data;
  }

  // Based on ExpenseSendTypes enum
  const extractAmountTypeIdBasedOnExpense = () => {
    if (selectedExpense.hourOfTheDay !== 0) {
      return 1;
    } else if (selectedExpense.dayOfTheWeek !== 0) {
      return 2;
    } else if (selectedExpense.dayOfTheMonth !== 0) {
      return 3;
    }
    return 0;
  };

  function onConfirmClicked(values: ExpensePUTWithHour) {
    if (values?.hour_of_the_day) {
      const formattedHour =
        values.hour_of_the_day.getHours() === 0
          ? 24
          : values.hour_of_the_day.getHours();

      const newData = update(values, {
        hour_of_the_day: { $set: formattedHour }, // +1 because getHours is 0-23 but we want 1-24
      });

      editExpense({ ...cleanOutputData(newData), id: selectedExpense.id });
    } else {
      editExpense({ ...cleanOutputData(values), id: selectedExpense.id });
    }
    showModal();
  }

  return (
    <Formik
      initialValues={{
        receiver_id: selectedExpense.receiver.id,
        amount: selectedExpense.amount,
        day_of_the_week: null,
        hour_of_the_day: null,
        day_of_the_month: null,
      }}
      validationSchema={editExpenseRequirementsSchema}
      validateOnChange={false}
      onSubmit={(values: ExpensePUT) => {
        onConfirmClicked(values);
      }}
    >
      {({
        errors,
        values,
        touched,
        handleChange,
        setFieldValue,
        getFieldProps,
      }) => (
        <FormikForm>
          <p className="h5">Udgift detaljer</p>
          <div className="form-group">
            <label>Modtager</label>

            <Field as="select" name="receiver_id" className="form-control">
              <option value="-1" disabled>
                Vælg en virksomhed
              </option>
              {queryClient
                .getQueryData<Company[]>([
                  'company',
                  { type: CompanyTypes['Super virksomhed'] },
                ])
                ?.map(company => (
                  <option
                    key={company.id}
                    label={company.name}
                    value={company.id}
                  />
                ))}
            </Field>

            {errors.receiver_id && touched.receiver_id && (
              <small className="form-text text-danger">
                {errors.receiver_id}
              </small>
            )}
          </div>

          <div className="form-group">
            <label>Antallet af produkter</label>
            <Field
              name="amount"
              placeholder="Antal"
              className="form-control"
              autoComplete="off"
            />
            {errors.amount && touched.amount && (
              <small className="form-text text-danger">{errors.amount}</small>
            )}
            <small className="text-muted">
              Den samlede pris for omkostningen vil være:{' '}
              <span className={'font-weight-bold'}>
                {formatter.format(
                  selectedExpense.product.price_cost * Number(values.amount),
                )}
              </span>
            </small>
          </div>

          <div className="form-group">
            <label>Sende frekvens</label>

            <ExpenseSendFrequency
              selectedSendTypeId={extractAmountTypeIdBasedOnExpense()}
            />
          </div>
          <Modal.Footer className={'justify-content-between'}>
            <Button
              onClick={() => changeActiveState()}
              variant={'outline-' + (isActive ? 'danger' : 'success')}
              className={'left'}
            >
              Gør omkostning {isActive ? 'inaktiv' : 'aktiv'}
            </Button>
            <Button type={'submit'} variant="success">
              Gem ændringer
            </Button>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  );
};

export default EditExpenseModal;
