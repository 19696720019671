import * as React from 'react';
import { Message, MessageStatusType } from '../../../../store/instructor/inbox';
import { Button, Form, Modal } from 'react-bootstrap';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { Company } from '../../../../store/instructor/company';
import { convertCompanyIdToName } from '../../../../utils/convertions';

type VisibilityToggleFuncType = () => void;
type SetMsgToReadFuncType = (msg: Message) => void;

interface PropsFromParent {
  setMsgToReadFunc: SetMsgToReadFuncType;
  visibilityToggleFunc: VisibilityToggleFuncType;
  msg: Message;
  allCompanies: Company[];
}

class ViewMsgModalData extends React.Component<PropsFromParent> {
  componentWillUnmount() {
    //Mark this msg as read, if UNREAD and robot company
    if (this.props.msg.msg_status === MessageStatusType.UNREAD) {
      const toCompany: Company | undefined = this.props.allCompanies.find(
        elem => elem.id === this.props.msg.msg_to_id,
      );
      if (toCompany === undefined) {
        throw new Error(
          'Instructor: inbox: viewMsgModal: company was not found!',
        );
      } else {
        //to robot company?
        if (toCompany.company_type_id !== 1) {
          this.props.setMsgToReadFunc(this.props.msg);
        }
      }
    }
  }

  renderContent = () => {
    return (
      <div className="px-3 pt-0 pb-3">
        <p>
          Fra:{' '}
          {convertCompanyIdToName(
            this.props.allCompanies,
            this.props.msg.msg_from_id,
          )}
        </p>
        <p>Emne: {this.props.msg.msg_header}</p>
        <Form>
          <Form.Group>
            <Form.Control
              as="textarea"
              defaultValue={this.props.msg.msg_body}
              rows={8}
              readOnly
            />
          </Form.Group>
        </Form>
      </div>
    );
  };

  public render() {
    return (
      <>
        <>{this.renderContent()}</>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.visibilityToggleFunc}>
            Luk
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ iMessages }: ApplicationState) => ({
  loading: iMessages.loading,
  errors: iMessages.errors,
});

export default connect(mapStateToProps)(ViewMsgModalData);
