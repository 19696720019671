import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  TaxGetApiResponse,
  ITaxActionTypes,
  TaxDeclarationType,
  TaxDeclarationYear,
} from './types';
import {
  TaxAddSuccess,
  TaxDeleteSuccess,
  TaxError,
  TaxGetYear,
  TaxSuccess,
} from './actions';
import {
  deleteEndpoint,
  getEndpointWithTwoIds,
  postEndpoint,
} from '../../../utils/api';

export function taxDeclarationTypeIdToType(typeId: number): TaxDeclarationType {
  switch (typeId) {
    case 1:
      return TaxDeclarationType.ATP;
    case 2:
      return TaxDeclarationType.TAX;
    case 3:
      return TaxDeclarationType.A_AM_TAX;
    default:
      throw Error();
  }
}

export function taxDeclarationTypeStringToType(
  typeString: string,
): TaxDeclarationType {
  switch (typeString) {
    case TaxDeclarationType.ATP.valueOf():
      return TaxDeclarationType.ATP;
    case TaxDeclarationType.TAX.valueOf():
      return TaxDeclarationType.TAX;
    case TaxDeclarationType.A_AM_TAX.valueOf():
      return TaxDeclarationType.A_AM_TAX;
    default:
      throw Error('The given string: ' + typeString);
  }
}

function processSuccessData2(
  apiResponseData: TaxGetApiResponse[],
  year,
): TaxDeclarationYear {
  let taxYear: TaxDeclarationYear = {
    year: year,
    declarations: [],
  };
  if (taxYear.declarations === null) {
    throw new Error('This should never happen! taxYear.declarations is null!');
  }
  for (let index = 0; index < 12; index++) {
    taxYear.declarations.push({
      declaration_a_am_tax: null,
      declaration_tax: null,
      declaration_atp: null,
      month: index,
    });
  }
  apiResponseData.forEach(elem => {
    if (taxYear.declarations === null) {
      throw new Error(
        'This should never happen! taxYear.declarations is null!',
      );
    } else {
      const elemType: TaxDeclarationType = taxDeclarationTypeStringToType(
        elem.type.type,
      );
      let targetMonth: number;

      switch (elemType) {
        case TaxDeclarationType.ATP:
          targetMonth = elem.taxDeclarationATP.target_month;
          if (taxYear.declarations[targetMonth].declaration_atp !== null) {
            throw new Error('Has received duplicate tax entries!');
          } else {
            taxYear.declarations[targetMonth].declaration_atp =
              elem.taxDeclarationATP;
            let temp = taxYear.declarations[targetMonth].declaration_atp;
            if (temp !== null) {
              temp.details = elem.taxDeclarationATP;
              temp.type = elemType;
              temp.is_pending = elem.taxDeclarationATP.is_pending;
            } else {
              throw new Error('Should not happen!');
            }
          }
          break;
        case TaxDeclarationType.TAX:
          targetMonth = elem.taxDeclarationTax.target_month;
          if (taxYear.declarations[targetMonth].declaration_tax !== null) {
            throw new Error('Has received duplicate tax entries!');
          } else {
            taxYear.declarations[targetMonth].declaration_tax =
              elem.taxDeclarationTax;
            let temp = taxYear.declarations[targetMonth].declaration_tax;
            if (temp !== null) {
              temp.details = elem.taxDeclarationTax;
              temp.type = elemType;
              temp.is_pending = elem.taxDeclarationTax.is_pending;
            } else {
              throw new Error('Should not happen!');
            }
          }
          break;
        case TaxDeclarationType.A_AM_TAX:
          targetMonth = elem.taxDeclarationAAMTax.target_month;
          if (taxYear.declarations[targetMonth].declaration_a_am_tax !== null) {
            throw new Error('Has received duplicate tax entries!');
          } else {
            taxYear.declarations[targetMonth].declaration_a_am_tax =
              elem.taxDeclarationAAMTax;
            let temp = taxYear.declarations[targetMonth].declaration_a_am_tax;
            if (temp !== null) {
              temp.details = elem.taxDeclarationAAMTax;
              temp.type = elemType;
              temp.is_pending = elem.taxDeclarationAAMTax.is_pending;
            } else {
              throw new Error('Should not happen!');
            }
          }
          break;
      }
    }
  });

  return taxYear;
}

/*
function processSuccessData(apiResponseData, year): TaxDeclarationYear {
  let taxYear: TaxDeclarationYear = {
    year: year,
    declarations: [],
  };
  if (taxYear.declarations === null) {
    throw new Error('This should never happen! taxYear.declarations is null!');
  }
  for (let index = 0; index < 12; index++) {
    taxYear.declarations.push({
      declaration_a_am_tax: null,
      declaration_tax: null,
      declaration_atp: null,
      month: index,
    });
  }
  apiResponseData.forEach(elem => {
    if (taxYear.declarations === null) {
      throw new Error(
        'This should never happen! taxYear.declarations is null!',
      );
    } else {
      if (elem.target_month < 0 || elem.target_month > 11) {
        throw new Error('Non-valid target month given! ' + elem.target_month);
      } else {
        const elemType: TaxDeclarationType = taxDeclarationTypeStringToType(
          elem.type_string,
        );

        switch (elemType) {
          case TaxDeclarationType.ATP:
            if (
              taxYear.declarations[elem.target_month].declaration_atp !== null
            ) {
              throw new Error('Has received duplicate tax entries!');
            } else {
              taxYear.declarations[elem.target_month].declaration_atp = elem;
              let temp =
                taxYear.declarations[elem.target_month].declaration_atp;
              if (temp !== null) {
                temp.details = elem.details_atp;
                temp.type = elemType;
                temp.is_pending = elem.pending;
              } else {
                throw new Error('Should not happen!');
              }
            }
            break;
          case TaxDeclarationType.TAX:
            if (
              taxYear.declarations[elem.target_month].declaration_tax !== null
            ) {
              throw new Error('Has received duplicate tax entries!');
            } else {
              taxYear.declarations[elem.target_month].declaration_tax = elem;
              let temp =
                taxYear.declarations[elem.target_month].declaration_tax;
              if (temp !== null) {
                temp.details = elem.details_tax;
                temp.type = elemType;
                temp.is_pending = elem.pending;
              } else {
                throw new Error('Should not happen!');
              }
            }
            break;
          case TaxDeclarationType.A_AM_TAX:
            if (
              taxYear.declarations[elem.target_month].declaration_a_am_tax !==
              null
            ) {
              throw new Error('Has received duplicate tax entries!');
            } else {
              taxYear.declarations[
                elem.target_month
              ].declaration_a_am_tax = elem;
              let temp =
                taxYear.declarations[elem.target_month].declaration_a_am_tax;
              if (temp !== null) {
                temp.details = elem.details_a_am_tax;
                temp.type = elemType;
                temp.is_pending = elem.pending;
              } else {
                throw new Error('Should not happen!');
              }
            }
            break;
        }
      }
    }
  });

  return taxYear;
}
*/

function* handleGetTaxYear(action) {
  try {
    const res = yield getEndpointWithTwoIds(
      'tax-declaration',
      action.payload.year,
      action.payload.company_id,
    );

    if (res.error) {
      yield put(TaxError(res.error));
    } else {
      yield put(TaxSuccess(processSuccessData2(res.data, action.payload.year)));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleAdd(action) {
  try {
    const res = yield postEndpoint(
      'tax-declaration',
      action.payload.newTaxDeclaration,
    );

    if (res.error) {
      yield put(TaxError(res.error));
    } else {
      yield put(TaxAddSuccess(res.data));
      yield put(
        TaxGetYear(
          action.payload.newTaxDeclaration.target_year,
          action.payload.newTaxDeclaration.company_id,
        ),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleDelete(action) {
  try {
    const res = yield deleteEndpoint(
      'tax-declaration',
      action.payload.taxDeclaration.id,
    );

    if (res.error) {
      yield put(TaxError(res.error));
    } else {
      yield put(TaxDeleteSuccess(res.data));
      yield put(
        TaxGetYear(
          action.payload.taxDeclaration.target_year,
          action.payload.company_id,
        ),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchTax() {
  yield takeEvery(ITaxActionTypes.ITAX_GET_YEAR, handleGetTaxYear);
  yield takeEvery(ITaxActionTypes.ITAX_ADD, handleAdd);
  yield takeEvery(ITaxActionTypes.ITAX_DELETE, handleDelete);
}

function* iTaxSaga() {
  yield all([fork(watchTax)]);
}

export { iTaxSaga };
