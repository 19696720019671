import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const LoadingComponent: React.FC<{ visible: boolean }> = ({ visible }) => {
  return (
    <ThreeDots
      height="100"
      width="100"
      radius="9"
      color="#00BFFF"
      ariaLabel="three-dots-loading"
      visible={visible}
    />
  );
};

export default LoadingComponent;
