import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Requisition } from '../../store/instructor/requisitions';
import { Company } from '../../store/instructor/company';
import { getCompanyFromId } from '../../utils/convertions';
import { Product, productGetAll } from '../../store/instructor/product';
import { store } from '../../store/store';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Invoice } from '../../store/instructor/orderInvoice';
import { InvoiceTabType } from '../../pages/student-pages/invoice-overview/invoiceTab';
import RequisitionService from '../../../api/services/RequisitionService';
import LoadingComponent from '../LoadingComponent';

export enum PDFTypes {
  REQUISITION,
  ORDER,
  INVOICE,
}

interface DocumentType extends Requisition, Invoice {}

interface PropsFromParent {
  loading: boolean;
  document: DocumentType;
  companies: Company[];
  products: Product[];
  type: PDFTypes;
  invoiceRequisition?: Requisition;
  invoiceTabType?: InvoiceTabType;
  receiverLabel: string;
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

/* TODO-future?: Moms udregning sker manuelt (25%) lige nu */
const DocumentPreview: React.FC<PropsFromParent> = props => {
  const [fromCompany, setFromCompany] = React.useState<Company | undefined>(
    undefined,
  );
  const [toCompany, setToCompany] = React.useState<Company | undefined>(
    undefined,
  );
  const [requisitionDetails, setRequisitionDetails] = React.useState<
    Requisition | undefined
  >(undefined);

  useEffect(() => {
    store.dispatch(productGetAll());

    if (props.type !== PDFTypes.INVOICE) {
      // Order or Requisition
      setFromCompany(
        getCompanyFromId(props.companies, props.document.from_company_id),
      );
      setToCompany(
        getCompanyFromId(props.companies, props.document.to_company_id),
      );
    } else if (props.type === PDFTypes.INVOICE) {
      RequisitionService.getRequisition(props.document.requisition_id).then(
        ({ data }) => {
          setRequisitionDetails(data);
          setFromCompany(
            getCompanyFromId(props.companies, data.from_company_id),
          );
          setToCompany(getCompanyFromId(props.companies, data.to_company_id));
        },
      );
    }
  }, []);

  useEffect(() => {
    console.log(requisitionDetails);
  }, [requisitionDetails]);

  let componentRef: HTMLDivElement | null = null;

  const setComponentRef = (ref: HTMLDivElement) => {
    componentRef = ref;
  };

  const reactToPrintTrigger = () => {
    return (
      <button type="button" className={'btn btn-primary btn-sm"'}>
        Print som PDF
      </button>
    );
  };

  const reactToPrintContent = () => {
    return componentRef;
  };

  const isInvoiceTabReceived = (): boolean => {
    if (props.invoiceTabType) {
      return props.invoiceTabType !== InvoiceTabType.SEND;
    } else {
      return false;
    }
  };

  const renderProducts = () => {
    return (
      <>
        {props.type !== PDFTypes.INVOICE
          ? props.document.items.map((order, index) => {
              let cost: number = order.cost;
              let total: number = order.total_cost;
              let moms: number = order.is_vax_free ? 0 : order.total_vax_amount;

              return (
                <tr key={index}>
                  <th scope="row">{order.navision_id}</th>
                  <td>
                    {props.products.find(elem => elem.id === order.product_id)
                      ?.name
                      ? props.products.find(
                          elem => elem.id === order.product_id,
                        )?.name
                      : 'name_not_found'}

                    {order.is_service ? (
                      <span className="ml-2 badge badge-info">Service</span>
                    ) : null}
                  </td>
                  <td className="text-center">{order.product_quantity}</td>
                  <td className="text-right">{formatter.format(cost)}</td>
                  <td className="text-right">{formatter.format(total)}</td>
                  <td className="text-right">{formatter.format(moms)}</td>
                </tr>
              );
            })
          : requisitionDetails &&
            requisitionDetails.items.map((order, index) => {
              let cost: number = order.cost;
              let total: number = order.total_cost;
              let moms: number = order.is_vax_free ? 0 : order.total_vax_amount;

              return (
                <tr key={index}>
                  <th scope="row">{order.navision_id}</th>
                  <td>
                    {props.products.find(elem => elem.id === order.product_id)
                      ?.name
                      ? props.products.find(
                          elem => elem.id === order.product_id,
                        )?.name
                      : 'name_not_found'}

                    {order.is_service ? (
                      <span className="ml-2 badge badge-info">Service</span>
                    ) : null}
                  </td>
                  <td className="text-center">{order.product_quantity}</td>
                  <td className="text-right">{formatter.format(cost)}</td>
                  <td className="text-right">{formatter.format(total)}</td>
                  <td className="text-right">{formatter.format(moms)}</td>
                </tr>
              );
            })}
      </>
    );
  };

  const generateHeaderText = (type: PDFTypes) => {
    switch (type) {
      case PDFTypes.INVOICE:
        return 'Faktura';
      case PDFTypes.ORDER:
        return 'Købsordre';
      case PDFTypes.REQUISITION:
        return 'Rekvisition';
      default:
        return 'Notfound';
    }
  };

  const getDocumentNumberFromType = () => {
    if (props.type === PDFTypes.REQUISITION) {
      return props.document.requisition_id;
    } else if (props.type === PDFTypes.ORDER) {
      if (props.document.navision_id) {
        return props.document.navision_id;
      } else {
        return null;
      }
    } else if (props.type === PDFTypes.INVOICE) {
      return props.document.invoice_id;
    } else {
      return null;
    }
  };

  const getFromCompanyDataSection = () => {
    if (fromCompany) {
      return (
        <div className="text-right">
          <p>
            <strong>{fromCompany?.name}</strong>
          </p>
          <p>{fromCompany?.address}</p>
          <p>CVR nr.: 12345678</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderContent = () => {
    return (
      <>
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle="AwesomeFileName"
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
        <div className={'container'} ref={setComponentRef}>
          <Sections className="d-flex justify-content-between align-items-center p-5">
            <div className="">
              {/* TODO-Future: Logo url doesn't exist in our database model */}
              <InvoiceLogo
                src={'https://cdn.logo.com/hotlink-ok/logo-social-sq.png'}
              />
            </div>
            {getFromCompanyDataSection()}
          </Sections>
          <Sections className="d-flex justify-content-between align-items-center p-5">
            <div className="">
              <p className={'text-uppercase head mb-2'}>
                {props.receiverLabel}
              </p>
              <p>
                <strong>{toCompany?.name}</strong>
              </p>
              <p>{toCompany?.address}</p>
              <p>CVR nr.: 12345678</p>
            </div>
            <div className="text-right">
              <h1>{generateHeaderText(props.type)}</h1>
              <p className={'text-uppercase head'}>
                {generateHeaderText(props.type) + ' nr.'}
              </p>
              <p>{getDocumentNumberFromType()}</p>
              <p className={'text-uppercase head'}>
                {generateHeaderText(props.type) + ' dato'}
              </p>
              <p>
                {props.type === PDFTypes.INVOICE
                  ? props.document.invoice_date.getString()
                  : props.document.requisition_date.getString()}
              </p>
              {props.type === PDFTypes.INVOICE ? (
                <>
                  <p className={'text-uppercase head'}>Betalingsbetingelser</p>
                  <p>
                    {
                      props.companies.find(
                        elem => elem.id === props.document.company_from,
                      )!.payment_terms
                    }{' '}
                    dage
                  </p>
                </>
              ) : (
                ''
              )}
              {props.type === PDFTypes.INVOICE && requisitionDetails ? (
                <>
                  <p className={'text-uppercase head'}>Bank nr.</p>
                  {props.document.payment_receiving_bank_account_id}
                  {isInvoiceTabReceived() ? (
                    <>
                      <p className={'text-uppercase head'}>
                        Navision ID fra faktura
                      </p>
                      {requisitionDetails.navision_id}
                    </>
                  ) : (
                    <>
                      <p className={'text-uppercase head'}>
                        Navision ID fra købsordre
                      </p>
                      {requisitionDetails.navision_id}
                    </>
                  )}
                  {props.type === PDFTypes.INVOICE &&
                  requisitionDetails?.delivery_parent_id !== -1 ? (
                    <>
                      <p className={'text-uppercase head'}>
                        Fragt for salgsordre nr.
                      </p>
                      {requisitionDetails.delivery_parent_id}
                    </>
                  ) : null}
                </>
              ) : null}
              {props.type !== PDFTypes.INVOICE ? (
                <>
                  <p className={'mt-2 text-uppercase head'}>Ordre Dato</p>
                  <p>{props.document.requisition_date.getString()}</p>
                </>
              ) : null}
            </div>
          </Sections>

          <SumSection className={''}>
            <table className="table table-hover">
              <thead>
                <tr className={'text-uppercase'}>
                  <th scope="col">varenr</th>
                  <th scope="col">varenavn</th>
                  <th className="text-center" scope="col">
                    antal
                  </th>
                  <th className="text-right" scope="col">
                    stk. pris
                  </th>
                  <th className="text-right" scope="col">
                    total
                  </th>
                  <th className="text-right" scope="col">
                    moms
                  </th>
                </tr>
              </thead>
              <tbody>{renderProducts()}</tbody>
            </table>

            <div className="d-flex flex-row-reverse">
              <TotalPart>
                <table className="table table-hover text-right">
                  <tbody>
                    <tr>
                      <th className={'head text-left'}>SUBTOTAL</th>
                      <td></td>
                      <td>
                        {formatter.format(
                          props.type !== PDFTypes.INVOICE
                            ? props.document.total_amount_without_vax
                            : (requisitionDetails &&
                                requisitionDetails.total_amount_without_vax) ??
                                0,
                        )}
                      </td>
                      <td>
                        {formatter.format(
                          props.type !== PDFTypes.INVOICE
                            ? props.document.total_vax
                            : (requisitionDetails &&
                                requisitionDetails.total_vax) ??
                                0,
                        )}
                      </td>
                    </tr>
                    {/* TODO-future: Disabled/Hidden until needed
                    <tr>
                      <th className={'head text-left'}>RABAT</th>
                      <td>0 %</td>
                      <td>0.00 kr.</td>
                      <td>0.00 kr.</td>
                    </tr>
                    */}
                    <tr>
                      <th className={'text-left '}>TOTAL</th>
                      <td></td>
                      <td></td>
                      <td className={'total '}>
                        <h3>
                          {formatter.format(
                            props.type !== PDFTypes.INVOICE
                              ? props.document.requisition_amount
                              : (requisitionDetails &&
                                  requisitionDetails.requisition_amount) ??
                                  0,
                          )}
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TotalPart>
            </div>
          </SumSection>
        </div>
      </>
    );
  };

  return (
    <div>
      {props.loading ? (
        <div className={'d-flex justify-content-center'}>
          <LoadingComponent visible={props.loading} />
        </div>
      ) : (
        renderContent()
      )}
    </div>
  );
};

// TODO-future: Make table follow same coloumns but 50% width and different styling
// background-color: ${props => props.theme.theme.colors.secondary};
// TODO-future: Color styling. will props be passed to this component?

const SumSection = styled.div`
  thead {
    color: #787878;
  }
`;

const TotalPart = styled.div`
  width: 50%;
  .head {
    color: #787878;
  }

  .total {
    color: #497f96;
  }
`;

const Sections = styled.div`
  p {
    margin: 0px;
    color: #787878;
  }

  p strong {
    color: #787878;
  }

  p.head {
    color: black;
    font-weight: bold;
  }

  background-color: #fbfbfb;
`;

const InvoiceLogo = styled.img`
  width: 6em;
  height: 6em;
`;

const mapStateToProps = ({ iProduct, iInvoiceDetails }: ApplicationState) => ({
  loading: iProduct.loading || iInvoiceDetails.loading,
  errors: iProduct.errors,
  products: iProduct.products,
  invoiceRequisition: iInvoiceDetails.requisition,
});

export default connect(mapStateToProps)(DocumentPreview);

/*

export default DocumentPreview;

 */
