/**
 * This helper method are based on the issue addressed on https://github.com/mbrn/material-table/issues/543
 * The main fix is that the material-table does not export the rendered data but instead only the field data.
 * This fixes it by calling the render method on the data and returning a list of the rendered version of the data.
 * @param columnList
 * @param initialData
 */
export const exportRenderDataPatch = (columnList, initialData) => {
  return initialData.map(rowData =>
    columnList.map(columnDef => {
      return columnDef.render
        ? columnDef.render(rowData)
        : rowData[columnDef.field];
    }),
  );
};
