import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Company } from '../../../store/instructor/company';
import { BankAccount } from '../../../store/instructor/bank';
import { Col, Form, Row } from 'react-bootstrap';
import { User } from '../../../store/instructor/users';
import { getAccountHeader } from './SalaryContent';
import InstructorModal from '../../../components/modal/instructorModal';
import AddBankAccountModalData, {
  AddBankAccountModalType,
} from '../../../components/modal/modals/addBankAccountModalData';
import AddIcon from '@material-ui/icons/Add';

interface StudentAccount {
  account: BankAccount | null;
  owner: User;
}

interface PropsFromState {
  allCompanies: Company[];
  allStudentBankAccounts: BankAccount[];
  userCompanyId: number;
  allCompanyUsers: User[];
}

interface ThisCompState {
  modalAddVisible: boolean;
  employees: StudentAccount[];
  selectedEmployerAccount?: StudentAccount;
  currentSelectedEmployerId: number;
}

class AccountOverview extends React.Component<PropsFromState, ThisCompState> {
  constructor(props: PropsFromState) {
    super(props);
    this.state = {
      modalAddVisible: false,
      employees: this.getEmployeeAccounts(),
      selectedEmployerAccount: undefined,
      currentSelectedEmployerId: -1,
    };
  }

  componentDidUpdate(prevProps: Readonly<PropsFromState>) {
    if (this.props.allCompanyUsers !== prevProps.allCompanyUsers) {
      this.setState({ employees: this.getEmployeeAccounts() });
    }
    if (
      this.props.allStudentBankAccounts !== prevProps.allStudentBankAccounts
    ) {
      this.setState({ employees: this.getEmployeeAccounts() });
    }
  }

  getEmployeeAccounts(): StudentAccount[] {
    let employees: StudentAccount[] = [];
    this.props.allCompanyUsers.forEach(companyUser => {
      const userBankAccount:
        | BankAccount
        | undefined = this.props.allStudentBankAccounts.find(
        elem => elem.userId === companyUser.id,
      );
      employees.push({
        account: userBankAccount === undefined ? null : userBankAccount,
        owner: companyUser,
      });
    });
    return employees;
  }

  getRenderUserWithoutAccount = () => {
    return (
      <>
        Den valgte bruger har ikke en lønkonto.
        <div className={'container-fluid py-2'}>
          <button
            type="button"
            className="btn btn-success rounded-pill"
            onClick={this.handleModalAddVisibility}
          >
            <AddIcon /> Tilføj ny konto
          </button>
        </div>
      </>
    );
  };

  getRenderUserWithAccount = () => {
    if (
      this.state.selectedEmployerAccount !== undefined &&
      this.state.selectedEmployerAccount.account !== null
    ) {
      return (
        <>
          Den valgte bruger har en lønkonto:{' '}
          {getAccountHeader(this.state.selectedEmployerAccount?.account)}
        </>
      );
    } else {
      throw Error('Should never happen!');
    }
  };

  getRenderNoUserSelected = () => {
    return <>Vælg venligst en elev</>;
  };

  getEmployeeContent = () => {
    if (this.state.currentSelectedEmployerId !== -1) {
      if (
        this.state.selectedEmployerAccount !== undefined &&
        this.state.selectedEmployerAccount.account !== null
      ) {
        //User with account
        return this.getRenderUserWithAccount();
      } else {
        //User selected but no account
        return this.getRenderUserWithoutAccount();
      }
    }
  };

  onChange = e => {
    if (e.target.value !== -1) {
      const studentAccount:
        | StudentAccount
        | undefined = this.state.employees.find(
        elem => elem.owner.id === parseInt(e.target.value),
      );
      this.setState({ selectedEmployerAccount: studentAccount });
    }

    this.setState({
      currentSelectedEmployerId: parseInt(e.target.value),
    });
  };

  handleModalAddVisibility = () => {
    this.setState({ modalAddVisible: !this.state.modalAddVisible });
  };

  renderEmployeeOptions = (allStudentAccounts: StudentAccount[]) => {
    return (
      <>
        {allStudentAccounts.map((account, index) => {
          return (
            <option key={index} value={account.owner.id}>
              {account.owner.id} {account.owner.first_name}{' '}
              {account.owner.last_name}
            </option>
          );
        })}
      </>
    );
  };

  public render() {
    return (
      <div>
        <Form>
          <Row>
            {this.state.currentSelectedEmployerId === -1
              ? this.getRenderNoUserSelected()
              : null}
          </Row>
          <Row>
            <Form.Label>Bruger:</Form.Label>
            <Col sm="auto">
              <Form.Control
                as="select"
                defaultValue="Vælg.."
                name={'currentSelectedEmployerId'}
                value={this.state.currentSelectedEmployerId}
                onChange={this.onChange}
              >
                <option value={-1}>Vælg..</option>
                {this.renderEmployeeOptions(this.state.employees)}
              </Form.Control>
            </Col>
          </Row>
          <Row>{this.getEmployeeContent()}</Row>
        </Form>
        <InstructorModal
          modalState={this.state.modalAddVisible}
          showFunc={this.handleModalAddVisibility}
          titleHeader="Add Bank Account"
        >
          <AddBankAccountModalData
            visibilityToggleFunc={this.handleModalAddVisibility}
            ownerId={this.state.currentSelectedEmployerId}
            componentType={AddBankAccountModalType.STUDENT}
          />
        </InstructorModal>
      </div>
    );
  }
}

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  userCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(AccountOverview);
