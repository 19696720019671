import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import { Company, companyGetAll } from '../../../store/instructor/company';
import {
  BankAccount,
  bankAccountGetAll,
  bankAccountGetCompanyAccounts,
  bankAccountGetSpecificAccount,
  bankAccountGetStudentAccounts,
} from '../../../store/instructor/bank';
import AccountOverview from './AccountOverview';
import Transaction from './Transaction';
import { User, usersGetAll } from '../../../store/instructor/users';
import {
  BankTransaction,
  BankTransactionGetAll,
} from '../../../store/instructor/bank-transactions';
import AccountTabNoPagination from '../../instructor-pages/bank/accountTabNoPagination';
import PrivateTransaction from './PrivateTransaction';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  allCompanies: Company[];
  allBankAccounts: BankAccount[];
  allStudentBankAccounts: BankAccount[];
  userCompanyId: number;
  loading: boolean;
  allStudents: User[];
  allUsers: User[];
  userId: number;
  userCompanyBankAccounts: BankAccount[];
  allTransactions: BankTransaction[];
  thisUserSalaryAccount: BankAccount;
  loadingSpecificBankAccount: boolean;
  loadingTransactions: boolean;
  loadingPrivateBankAccount: boolean;
}

interface ThisCompState {
  thisCompanyUsers: User[];
  userBankAccount: BankAccount | null;
  companyAccounts: BankAccount[];
}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

export function getAccountHeader(account: BankAccount) {
  return (
    <div className="d-flex flex-row bd-highlight mb-3 bg-light text-dark border rounded border-muted py-2 container-fluid">
      <div className="p-3 h5 mb-0 bd-highlight">Konto nr.: {account.id}</div>
      <div className="p-3 h5 mb-0 bd-highlight">Konti: {account.name}</div>
      <div className="p-3 h5 mb-0 bd-highlight">
        Saldo:{' '}
        <span className={'text-custom'}>
          {formatter.format(account.balance)}
        </span>
      </div>
    </div>
  );
}

class SalaryContent extends React.Component<PropsFromState, ThisCompState> {
  constructor(props: PropsFromState) {
    super(props);
    this.state = {
      thisCompanyUsers: [],
      userBankAccount: null,
      companyAccounts: [],
    };
  }

  componentDidMount() {
    store.dispatch(companyGetAll());
    store.dispatch(bankAccountGetAll());
    store.dispatch(bankAccountGetStudentAccounts());
    store.dispatch(usersGetAll());
    store.dispatch(bankAccountGetCompanyAccounts(this.props.userCompanyId));
    store.dispatch(BankTransactionGetAll());
  }

  componentDidUpdate(prevProps: Readonly<PropsFromState>) {
    if (this.props.allStudents !== prevProps.allStudents) {
      this.setState({
        thisCompanyUsers: this.getCompanyUsers(
          this.props.userCompanyId,
          this.props.allStudents,
        ),
      });
    }

    if (this.props.allBankAccounts !== prevProps.allBankAccounts) {
      const account: BankAccount | undefined = this.props.allBankAccounts.find(
        elem => elem.userId === this.props.userId,
      );
      if (account !== undefined) {
        this.setState({ userBankAccount: account });
      }
    }

    if (this.props.thisUserSalaryAccount !== prevProps.thisUserSalaryAccount) {
      this.setState({ userBankAccount: this.props.thisUserSalaryAccount });
    }

    if (
      prevProps.userCompanyBankAccounts !== this.props.userCompanyBankAccounts
    ) {
      this.setState({
        companyAccounts: this.props.userCompanyBankAccounts.filter(
          elem => elem.companyId === this.props.userCompanyId,
        ),
      });
    }

    if (prevProps.allTransactions !== this.props.allTransactions) {
      if (this.state.userBankAccount?.id) {
        store.dispatch(
          bankAccountGetSpecificAccount(this.state.userBankAccount?.id),
        );
      }
    }
  }

  getCompanyUsers(companyId: number, users: User[]): User[] {
    let companyUsers: User[] = [];
    users.forEach((user: User) => {
      if (user.super_company_id === companyId) {
        companyUsers.push(user);
      }
    });
    return companyUsers;
  }

  getAccountTab = (account: BankAccount | null) => {
    if (account !== null) {
      return (
        <Tabs>
          <Tab eventKey="account" title="Konti">
            {this.getPrivatBankAccountTabContent(account)}
          </Tab>
          <Tab eventKey="transaction" title="Overførsel">
            <PrivateTransaction
              allBankAccounts={this.props.allBankAccounts}
              studentBankAccount={account}
              allCompanies={this.props.allCompanies}
              allUsers={this.props.allUsers}
              companyAccounts={this.state.companyAccounts}
            />
          </Tab>
        </Tabs>
      );
    } else {
      return <>Denne bruger har endnu ikke en lønkonto.</>;
    }
  };

  getPrivatBankAccountTabContent = (account: BankAccount) => {
    return (
      <>
        {this.props.loadingPrivateBankAccount ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loadingPrivateBankAccount} />
          </div>
        ) : (
          <>
            {getAccountHeader(account)}
            <AccountTabNoPagination
              bankAccount={account}
              allBankAccounts={this.props.allBankAccounts}
              allCompanies={this.props.allCompanies}
              transactions={this.props.allTransactions.filter(
                elem =>
                  elem.fromBankAccountId === account.id ||
                  elem.toBankAccountId === account.id,
              )}
              allUsers={this.props.allStudents}
            />
          </>
        )}
      </>
    );
  };

  handleTabSelect = key => {
    if (key === 'privateBank') {
      if (this.state.userBankAccount?.id) {
        store.dispatch(
          bankAccountGetSpecificAccount(this.state.userBankAccount?.id),
        );
      }
    }
  };

  renderContent = () => {
    return (
      <Tabs defaultActiveKey={'privateBank'} onSelect={this.handleTabSelect}>
        <Tab eventKey="privateBank" title="Privat bank">
          {this.getAccountTab(this.state.userBankAccount)}
        </Tab>
        <Tab eventKey="accounts" title="Konti">
          <AccountOverview
            allCompanies={this.props.allCompanies}
            allCompanyUsers={this.state.thisCompanyUsers}
            allStudentBankAccounts={this.props.allStudentBankAccounts}
          />
        </Tab>
        <Tab eventKey="transaction" title="Virksomheds bank">
          <Transaction
            allBankAccounts={this.props.allBankAccounts}
            allStudentBankAccounts={this.props.allStudentBankAccounts}
            allCompanies={this.props.allCompanies}
            allUsers={this.props.allUsers}
            companyAccounts={this.state.companyAccounts}
          />
        </Tab>
      </Tabs>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iCompany,
  userInfo,
  iBankAccount,
  iUsers,
  iBankTransactions,
}: ApplicationState) => ({
  loading:
    userInfo.loading ||
    iCompany.loading ||
    iBankAccount.loading ||
    iUsers.loading,
  allBankAccounts: iBankAccount.bankAccounts,
  allCompanies: iCompany.companies,
  userCompanyId: userInfo.userSuperCompanyId,
  allStudents: iUsers.students,
  userId: userInfo.id,
  allStudentBankAccounts: iBankAccount.studentAccounts,
  allUsers: iUsers.allUsers,
  userCompanyBankAccounts: iBankAccount.companyBankAccounts,
  allTransactions: iBankTransactions.bankTransactions,
  thisUserSalaryAccount: iBankAccount.specificAccount,
  loadingSpecificBankAccount: iBankAccount.loadingSpecificAccount,
  loadingTransactions: iBankTransactions.loading,
  loadingPrivateBankAccount:
    iBankAccount.loadingSpecificAccount ||
    iBankTransactions.loading ||
    iBankAccount.loadingSpecificAccount,
});

export default connect(mapStateToProps)(SalaryContent);
