import { action } from 'typesafe-actions';
import { FetchTypes, Requisition, RequisitionActionTypes } from './types';
import { Page } from '../../../utils/storeTypes';
import { ArchiveType } from '../../../utils/convertions';

export const requisitionGetAll = (companyId: number) =>
  action(RequisitionActionTypes.REQUISITION_GET_ALL_TOANDFROM_COMPANY, {
    companyId,
  });
export const requisitionGetAllNewest = (companyId: number) =>
  action(RequisitionActionTypes.REQUISITION_GET_ALL_TOANDFROM_COMPANY_NEWEST, {
    companyId,
  });
export const requisitionGetFromCompanyWithoutShipPage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(
    RequisitionActionTypes.REQUISITION_GET_ALL_FROM_COMP_WITHOUT_SHIP_PAGE,
    {
      companyId,
      page,
      pageSize,
      descending,
      searchQuery,
      startDate,
      endDate,
    },
  );
export const requisitionGetToCompanyActivePage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  archiveType: ArchiveType,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ACTIVE_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    archiveType,
    searchQuery,
    startDate,
    endDate,
  });
export const requisitionGetToCompanyArchivePage = (
  companyId: number,
  page: number,
  pageSize: number,
  descending: boolean,
  archiveType: ArchiveType,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ARCHIVED_PAGE, {
    companyId,
    page,
    pageSize,
    descending,
    archiveType,
    searchQuery,
    startDate,
    endDate,
  });
export const requisitionCreate = data =>
  action(RequisitionActionTypes.REQUISITION_CREATE, { data });
export const requisitionSetArchiveType = (
  requisitionId: number,
  newArchiveTypeId: number,
  currentUserCompanyId: number,
  fetchType: FetchTypes,
) =>
  action(RequisitionActionTypes.REQUISITION_SET_ARCHIVE_TYPE, {
    requisitionId,
    newArchiveTypeId,
    currentUserCompanyId,
    fetchType,
  });
export const requisitionSuccess = (data: Requisition[]) =>
  action(RequisitionActionTypes.REQUISITION_SUCCESS, data);
export const requisitionNewestSuccess = (data: Requisition[]) =>
  action(RequisitionActionTypes.REQUISITION_SUCCESS_NEWEST, data);
export const requisitionSuccessFromCompanyWithoutShipPage = (data: Page) =>
  action(
    RequisitionActionTypes.REQUISITION_GET_ALL_FROM_COMP_WITHOUT_SHIP_PAGE_SUCCESS,
    data,
  );
export const requisitionSuccessToCompanyActivePage = (data: Page) =>
  action(
    RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS,
    data,
  );
export const requisitionSuccessToCompanyArchivePage = (data: Page) =>
  action(
    RequisitionActionTypes.REQUISITION_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS,
    data,
  );
export const requisitionError = (message: string) =>
  action(RequisitionActionTypes.REQUISITION_ERROR, message);
