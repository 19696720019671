import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BusinessIcon from '@material-ui/icons/Business';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import EmailIcon from '@material-ui/icons/Email';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import BallotIcon from '@material-ui/icons/Ballot';
import { NavbarDataType } from './NavbarDataStudent';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PeopleIcon from '@material-ui/icons/People';
import Payment from '@material-ui/icons/Payment';

export const NavbarDataAdmin: NavbarDataType[] = [
  {
    title: 'Forside',
    url: '/instructor/dashboard',
    icon: <HomeIcon />,
  },
  {
    title: 'Virksomheder',
    url: '/instructor/company',
    icon: <BusinessIcon />,
  },
  {
    title: 'Fejlregister',
    url: '/instructor/mistakes',
    icon: <FindInPageIcon />,
  },
  {
    title: 'Post og pakker',
    url: '/instructor/inbox',
    icon: <EmailIcon />,
  },
  {
    title: 'Ordregenerator',
    url: '/instructor/orderGenerator',
    icon: <SettingsApplicationsIcon />,
  },
  {
    title: 'Bank',
    url: '/instructor/bank',
    icon: <AccountBalanceIcon />,
  },
  {
    title: 'Faste omkostninger',
    url: '/instructor/expenses',
    icon: <Payment />,
  },
  {
    title: 'Ordrestyring',
    url: '/instructor/orderInvoices',
    icon: <BallotIcon />,
  },
  {
    title: 'Opret Købsordre',
    url: '/instructor/requisition',
    icon: <NoteAddIcon />,
  },
  {
    title: 'Konti',
    url: '/instructor/users',
    icon: <PeopleIcon />,
  },
  {
    title: 'Varer',
    url: '/instructor/product',
    icon: <ShoppingCartIcon />,
  },
];
