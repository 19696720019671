import { HomemadeDate } from '../../../utils/homemadeDate';
import { Page } from '../../../utils/storeTypes';

export interface Invoice {
  amount: number;
  invoice_date: HomemadeDate;
  invoice_id: number;
  requisition_id: number;
  company_to: number;
  company_from: number;
  payment_receiving_bank_account_id?: number;
  navision_id: number;
  archive_type: string;
  archive_type_id: number;
  requisition_navision_id: string;
  errorMessages?: ErrorMessage[];
  created_by_id?: number;
}

export interface ErrorMessage {
  id: number;
  error: string;
}

export interface OrderItem {
  id: number;
  product_id: number;
  product_quantity: number;
  cost: number;
  navision_id: number;
  is_vax_free: boolean;
  is_service: boolean;
  total_cost: number;
  total_vax_amount: number;
}

export enum IInvoiceActionTypes {
  IINVOICE_GET_ALL = '@@instructor/invoice/GET_ALL',
  IINVOICE_GET_ALL_TO_COMP_ACTIVE_PAGE = '@@instructor/invoice/GET_ALL_TO_COMP_ACTIVE_PAGE',
  IINVOICE_GET_ALL_TO_COMP_ARCHIVED_PAGE = '@@instructor/invoice/GET_ALL_TO_COMP_ARCHIVED_PAGE',
  IINVOICE_GET_ALL_FROM_COMP_PAGE = '@@instructor/invoice/GET_ALL_FROM_COMP_PAGE',
  IINVOICE_CREATE = '@@instructor/invoice/CREATE',
  IINVOICE_SET_ARCHIVE_TYPE = '@@instructor/invoice/SET_ARCHIVE_TYPE',
  IINVOICE_SUCCESS = '@@instructor/invoice/SUCCESS',
  IINVOICE_GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS = '@@instructor/invoice/GET_ALL_TO_COMP_ACTIVE_PAGE_SUCCESS',
  IINVOICE_GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS = '@@instructor/invoice/GET_ALL_TO_COMP_ARCHIVED_PAGE_SUCCESS',
  IINVOICE_GET_ALL_FROM_COMP_PAGE_SUCCESS = '@@instructor/invoice/GET_ALL_FROM_COMP_PAGE_SUCCESS',
  IINVOICE_ERROR = '@@instructor/invoice/ERROR',
}

export interface IInvoiceState {
  readonly loading: boolean;
  readonly loadingToCompanyActive: boolean;
  readonly loadingToCompanyArchive: boolean;
  readonly loadingFromCompany: boolean;
  readonly invoices: Invoice[];
  readonly errors?: string;

  readonly invoicesToCompanyActive: Page;
  readonly invoicesToCompanyArchive: Page;
  readonly invoicesFromCompany: Page;
}
