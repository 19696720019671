import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../../../store/store';
import { ApplicationState } from '../../../../../store';
import CustomTable from '../../../../../components/table/customTable';
import { Product } from '../../../../../store/instructor/product';
import {
  companyProductsAdd,
  companyProductsDelete,
} from '../../../../../store/instructor/company-products';
import { ModalContext } from '../../../../../contexts/modalContexts';
import LoadingComponent from '../../../../../components/LoadingComponent';

interface PropsFromStore {
  companyId: number;
  companyProducts: Product[];
  allProducts: Product[];
  loading: boolean;
}

interface ComponentState {
  companyProductsTableData: Product[];
  companyProductsTableHeader: any[];
  notCompanyProductsTableData: Product[];
  notCompanyProductsTableHeader: any[];
}

class CompanyProducts extends React.Component<PropsFromStore, ComponentState> {
  static contextType = ModalContext;

  constructor(props: Readonly<PropsFromStore>) {
    super(props);
    this.state = {
      companyProductsTableData: [],
      companyProductsTableHeader: [
        {
          title: 'Status',
          field: 'active',
          editable: 'never',
          render: rowData => this.isActiveSpan(rowData.active),
        },
        { title: 'Navision ID', field: 'navision_id', editable: 'never' },
        { title: 'Navn', field: 'name', editable: 'never' },
        {
          title: 'Pris /Enhed',
          field: 'price_unit',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
        {
          title: 'Pris',
          field: 'price_cost',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
      ],
      notCompanyProductsTableData: [],
      notCompanyProductsTableHeader: [
        { title: 'Navision ID', field: 'navision_id', editable: 'never' },
        { title: 'Navn', field: 'name', editable: 'never' },
        { title: 'Kategori', field: 'product_type_code', editable: 'never' },
        {
          title: 'Pris /Enhed',
          field: 'price_unit',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
        {
          title: 'Pris',
          field: 'price_cost',
          editable: 'never',
          type: 'currency',
          maximumFractionDigits: 2,
          currencySetting: {
            currencyCode: 'DKK',
            locale: 'da-DK',
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.setTableData();
  }

  isActiveSpan = (isActive: boolean) => {
    return (
      <span className={isActive ? 'text-success' : 'text-danger'}>
        {isActive ? 'Aktiv' : 'Inaktiv'}
      </span>
    );
  };

  componentDidUpdate(prevProps: Readonly<PropsFromStore>) {
    if (prevProps.companyProducts !== this.props.companyProducts) {
      this.setTableData();
    }
    if (prevProps.allProducts !== this.props.allProducts) {
      this.setTableData();
    }
  }

  setTableData = () => {
    this.setState({ companyProductsTableData: this.props.companyProducts });
    this.setState({
      notCompanyProductsTableData: this.getAllProductsNotInCompany(),
    });
  };

  /* TODO-future should be optimised!! */
  getAllProductsNotInCompany = () => {
    let productsNotInCompany: Product[] = [];
    this.props.allProducts.forEach(allProduct => {
      let isInCompany: boolean = false;
      this.props.companyProducts.forEach(compProduct => {
        if (allProduct.id === compProduct.id) {
          isInCompany = true;
        }
      });
      if (!isInCompany) {
        productsNotInCompany.push(allProduct);
      }
    });
    return productsNotInCompany;
  };

  addProductToCompany = (product: Product) => {
    store.dispatch(companyProductsAdd(this.props.companyId, product.id));
  };

  removeProductFromCompany = (product: Product) => {
    store.dispatch(companyProductsDelete(this.props.companyId, product.id));
  };

  renderProductsTable = () => {
    return (
      <>
        <h4>Produkter i virksomheden</h4>
        <CustomTable
          data={this.state.companyProductsTableData}
          headers={this.state.companyProductsTableHeader}
          actions={[
            {
              icon: 'close',
              tooltip: 'Fjern produkt fra virksomhed',
              onClick: (event, product) =>
                this.removeProductFromCompany(product),
            },
          ]}
        />
        {/*
        <h4>Produkter ikke i firmaet</h4>
        <CustomTable
          data={this.state.notCompanyProductsTableData}
          headers={this.state.notCompanyProductsTableHeader}
          actions={[
            {
              icon: 'add',
              tooltip: 'Add product to company',
              onClick: (event, product) => this.addProductToCompany(product),
            },
          ]}
        />
        */}
      </>
    );
  };

  public render() {
    return (
      <div className={'container-fluid'}>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          <>{this.renderProductsTable()}</>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iCompany,
  iCompanyProducts,
  iProduct,
}: ApplicationState) => ({
  loading: iCompany.loading || iCompanyProducts.loading || iProduct.loading,
});

export default connect(mapStateToProps)(CompanyProducts);
