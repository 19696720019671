import { action } from 'typesafe-actions';
import {
  ApiRequestEditedBankAccount,
  BankAccount,
  BankAccountActionTypes,
  NewBankAccount,
} from './types';

export const bankAccountGetAll = () =>
  action(BankAccountActionTypes.BANK_ACCOUNT_GET_ALL);
export const bankAccountGetCompanyAccounts = (companyId: number) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_GET_COMPANY_ACCOUNTS, {
    companyId: companyId,
  });
export const bankAccountGetStudentAccounts = () =>
  action(BankAccountActionTypes.BANK_ACCOUNT_GET_STUDENT_ACCOUNTS);
export const bankAccountGetSpecificAccount = (id: number) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_GET_SPECIFIC_ACCOUNT, { id: id });
export const bankAccountAdd = (newBankAccount: NewBankAccount) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_CREATE, {
    newBankAccount: newBankAccount,
  });
export const bankAccountDelete = (id: number) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_DELETE, { id: id });
export const bankAccountEdit = (
  editedBankAccount: ApiRequestEditedBankAccount,
  bankAccountId: number,
) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_EDIT, {
    editedBankAccount: editedBankAccount,
    bankAccountId,
  });
export const bankAccountSuccess = (data: BankAccount[]) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_SUCCESS, data);
export const bankAccountCompanyAccountsSuccess = (data: BankAccount[]) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_COMPANY_ACCOUNTS_SUCCESS, data);
export const bankAccountStudentAccountsSuccess = (data: BankAccount[]) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_STUDENT_ACCOUNTS_SUCCESS, data);
export const bankAccountSpecificAccountSuccess = (data: BankAccount) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_SPECIFIC_ACCOUNT_SUCCESS, data);
export const bankAccountError = (message: string) =>
  action(BankAccountActionTypes.BANK_ACCOUNT_ERROR, message);
