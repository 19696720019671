/** This method returns an error msg if the given input does not fulfill:
 * - contains both a dot
 * - contains a comma but does not have decimals before or after the comma
 * - is undefined or nothing */
export function validateDoubleWithCommaOrInteger(input: string): string {
  if (input === undefined || input === null) {
    return 'er undefined eller null';
  } else if (doesNumberContainDot(input)) {
    return 'indeholder punktum';
  } else if (!isNumericWithCommaAndDot(input)) {
    return 'indeholder andre tegn end tal, komma og punktum';
  } else if (!doesNumberHaveDecimalsAtBothSidesOfComma(input)) {
    return 'indeholder komma men mangler tal på den ene side eller den anden';
  } else {
    return '';
  }
}

/** returns true if all chars in given input is either a number, comma or dot. */
function isNumericWithCommaAndDot(input: string): boolean {
  let foundInvalidChar: boolean = false;
  for (let i = 0; i < input.length; i++) {
    let currentChar: string = input.charAt(i);
    if (currentChar !== '.' && currentChar !== ',') {
      if (isNaN(input.charAt(i) as any)) {
        foundInvalidChar = true;
      }
    }
  }

  return !foundInvalidChar;
}

function doesNumberContainDot(input: string): boolean {
  const position: number = input.toString().indexOf('.');
  return position !== -1;
}

/** Also returns true if no comma is present */
function doesNumberHaveDecimalsAtBothSidesOfComma(input: string): boolean {
  const inputString: string = input.toString();
  const positionOfComma: number = inputString.indexOf(',');

  if (positionOfComma === -1) {
    return true;
  } else {
    if (positionOfComma === 0) {
      return false;
    } else if (positionOfComma === inputString.length - 1) {
      return false;
    } else {
      const charBeforeComma: string = inputString.charAt(positionOfComma - 1);
      const charAfterComma: string = inputString.charAt(positionOfComma + 1);
      if (parseInt(charBeforeComma) !== undefined) {
        return true;
      } else if (parseInt(charAfterComma) !== undefined) {
        return true;
      } else {
        return false;
      }
    }
  }
}
