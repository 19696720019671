import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import styled from 'styled-components';
import InvoiceOverview from './invoiceOverview';
import { Helmet } from 'react-helmet-async';

const InvoiceOverviewPage: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Fakturaer</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Fakturaer</h2>
      </PageHeader>

      <InvoiceOverview />
    </StudentPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(InvoiceOverviewPage);
