import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import styled from 'styled-components';
import TabHolder from './tabHolder';
import { Helmet } from 'react-helmet-async';

const InventoryPage: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Varelager</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Varelager</h2>
      </PageHeader>

      <TabHolder />
    </StudentPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(InventoryPage);
