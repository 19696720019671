import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  IOrderGenActionTypes,
  OrderGen,
  OrderIntervalType,
  parseOrderInvervalTypeString,
  parserOrderIntervalTypeNumber,
} from './types';
import { getEndpoint, putEndpoint } from '../../../utils/api';
import {
  orderGenEdit,
  orderGenError,
  orderGenGetAll,
  orderGenSuccess,
} from './actions';

function convertFrontEndOrderGenToApi(frontEndOrderGen: OrderGen) {
  return {
    company_id: frontEndOrderGen.company_id,
    active: frontEndOrderGen.active,
    monday: frontEndOrderGen.monday,
    tuesday: frontEndOrderGen.tuesday,
    wednesday: frontEndOrderGen.wednesday,
    thursday: frontEndOrderGen.thursday,
    friday: frontEndOrderGen.friday,
    saturday: frontEndOrderGen.saturday,
    sunday: frontEndOrderGen.sunday,
    start_at: frontEndOrderGen.timespanAndFreq.startAt,
    end_at: frontEndOrderGen.timespanAndFreq.endAt,
    interval: parserOrderIntervalTypeNumber(
      frontEndOrderGen.timespanAndFreq.interval,
    ),
  };
}

function* handleEdit(action) {
  try {
    let convertedOrderGen = convertFrontEndOrderGenToApi(
      action.payload.editedOrderGen,
    );

    const res = yield putEndpoint(
      'order_generator',
      convertedOrderGen,
      action.payload.editedOrderGen.company_id,
    );

    if (res.error) {
      yield put(orderGenError(res.error));
    } else {
      yield put(orderGenGetAll()); //TODO optimization: should be put(orderGenSuccess()) and handle conversion in this saga.
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAll() {
  try {
    const res = yield getEndpoint('order_generator');

    if (res.error) {
      yield put(orderGenError(res.error));
    } else {
      let orderGens: OrderGen[] = [];

      res.data.forEach(resOrderGenElem => {
        let convertedOrderIntervalType: OrderIntervalType = parseOrderInvervalTypeString(
          resOrderGenElem.order_interval,
        );

        orderGens.push({
          company_id: resOrderGenElem.company_id,
          active: resOrderGenElem.active,
          monday: resOrderGenElem.monday,
          tuesday: resOrderGenElem.tuesday,
          wednesday: resOrderGenElem.wednesday,
          thursday: resOrderGenElem.thursday,
          friday: resOrderGenElem.friday,
          saturday: resOrderGenElem.saturday,
          sunday: resOrderGenElem.sunday,
          timespanAndFreq: {
            startAt: resOrderGenElem.start_at,
            endAt: resOrderGenElem.end_at,
            interval: convertedOrderIntervalType,
          },
        });
      });

      yield put(orderGenSuccess({ orderGens: orderGens }));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleSetIsActive(action) {
  let orderGen: OrderGen = action.payload.editedOrderGen;
  orderGen.active = action.payload.newStatus;

  yield put(orderGenEdit(orderGen));
}

function* watchOrderGen() {
  yield takeEvery(IOrderGenActionTypes.IORDERGEN_GET_ALL, handleGetAll);
  yield takeEvery(IOrderGenActionTypes.IORDERGEN_EDIT, handleEdit);
  yield takeEvery(
    IOrderGenActionTypes.IORDERGEN_SET_IS_ACTIVE,
    handleSetIsActive,
  );
}

function* iOrderGenSaga() {
  yield all([fork(watchOrderGen)]);
}

export { iOrderGenSaga };
