import { User, UserEdit, usersEdit } from '../../../../store/instructor/users';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { Company } from '../../../../store/instructor/company';
import { store } from '../../../../store/store';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  userToEdit: User;
  userEditingTypeId: number;
  userEditingId: number;
  allSuperCompanies: Company[];
}

interface ThisCompState {
  selectedUserSuperCompany: number;
}

/** THIS CAN ONLY BE PERFORMED ON STUDENTS */
class ChangeUserSuperCompanyModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      selectedUserSuperCompany: -1,
    };
  }

  componentDidMount() {
    if (this.props.userToEdit.super_company_id) {
      this.setState({
        selectedUserSuperCompany: this.props.userToEdit.super_company_id,
      });
    } else {
      throw Error;
    }
  }

  onChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  onSubmitButtonClick = e => {
    e.preventDefault();

    if (
      this.props.userToEdit.super_company_id !==
      this.state.selectedUserSuperCompany
    ) {
      const userEdited: UserEdit = {
        email: this.props.userToEdit.username,
        username: this.props.userToEdit.username,
        company_id: this.state.selectedUserSuperCompany,
        type_id: this.props.userToEdit.user_type_id,
        group_id: this.props.userToEdit.user_group_id,
        first_name: this.props.userToEdit.first_name,
        last_name: this.props.userToEdit.last_name,
      };

      store.dispatch(usersEdit(userEdited, this.props.userToEdit.id));
    }

    this.props.visibilityToggleFunc();
  };

  renderSuperCompanyOptions = (company: Company, index: number) => {
    return (
      <option key={index} value={company.id}>
        {company.name}
      </option>
    );
  };

  public render() {
    return (
      <>
        <p>
          Vælg brugerens virksomhed {this.props.userToEdit.first_name}{' '}
          {this.props.userToEdit.last_name}
        </p>
        <div className="px-3 pt-0 pb-3">
          <div>
            <Form>
              <Row>
                <Form.Label>Brugerens virksomhed</Form.Label>
                <Form.Control
                  as="select"
                  //TODO defaultValue="Vælg.."
                  name={'selectedUserSuperCompany'}
                  value={this.state.selectedUserSuperCompany}
                  type={'password'}
                  onChange={this.onChange}
                >
                  {this.props.allSuperCompanies.map(
                    this.renderSuperCompanyOptions,
                  )}
                </Form.Control>
              </Row>
            </Form>
          </div>
        </div>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onSubmitButtonClick}>
            Gem
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ iUsers }: ApplicationState) => ({
  loading: iUsers.loading,
  errors: iUsers.errors,
});

export default connect(mapStateToProps)(ChangeUserSuperCompanyModalData);
