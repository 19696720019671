import { Reducer } from 'redux';
import { BankAccountActionTypes, BankAccountState } from './types';

export const initialState: BankAccountState = {
  bankAccounts: [],
  companyBankAccounts: [],
  studentAccounts: [],
  specificAccount: {
    id: -1,
    isActive: false,
    type_id: -1,
    name: '',
    currencyCode: -1,
    balance: -1,
  },
  loading: false,
  loadingSpecificAccount: false,
  errors: undefined,
};

const reducer: Reducer<BankAccountState> = (state = initialState, action) => {
  switch (action.type) {
    case BankAccountActionTypes.BANK_ACCOUNT_GET_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_GET_SPECIFIC_ACCOUNT: {
      return {
        ...state,
        loadingSpecificAccount: true,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_GET_COMPANY_ACCOUNTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_GET_STUDENT_ACCOUNTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_CREATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_DELETE: {
      return {
        ...state,
        loading: true,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_EDIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_SUCCESS: {
      return {
        ...state,
        bankAccounts: action.payload,
        loading: false,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_COMPANY_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        companyBankAccounts: action.payload,
        loading: false,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_STUDENT_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        studentAccounts: action.payload,
        loading: false,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_SPECIFIC_ACCOUNT_SUCCESS: {
      return {
        ...state,
        specificAccount: action.payload,
        loadingSpecificAccount: false,
      };
    }
    case BankAccountActionTypes.BANK_ACCOUNT_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as bankAccountReducer };
