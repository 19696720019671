import { Company } from '../app/store/instructor/company';
import { Product } from '../app/store/instructor/product';
import { Page } from '../app/utils/storeTypes';

export interface Expense {
  id: number;
  sender: Company;
  receiver: Company;
  product: Product;
  amount: number;
  varianceAmount: number;
  lastSent: Date;
  dayOfTheWeek: number;
  dayOfTheMonth: number;
  varianceDays: number;
  hourOfTheDay: number;
  varianceMinutes: number;
  active: boolean;
}

export interface ExpensePaginated extends Page {}

export interface ExpensePOST {
  sender_id?: number;
  receiver_id?: number;
  product_id?: number;
  amount?: number;
}

export interface ExpensePUT {
  receiver_id?: number;
  amount?: number;
  active?: boolean;
}

export type ExpensePOSTWithHour = ExpensePOST & { hour_of_the_day?: any };
export type ExpensePUTWithHour = ExpensePUT & {
  id?: number;
  hour_of_the_day?: any;
};

export enum ExpenseSendTypes {
  'Hver dag' = 1,
  'Bestemt ugedag' = 2,
  'Bestemt dag i måneden' = 3,
}

export enum DayOfWeek {
  'Mandag' = 1,
  'Tirsdag' = 2,
  'Onsdag' = 3,
  'Torsdag' = 4,
  'Fredag' = 5,
  'Lørdag' = 6,
  'Søndag' = 7,
}
