/** Returns all transactions which subtracts or adds to the giving account. */
import { BankTransaction } from '../store/instructor/bank-transactions';
import { HomemadeDate } from './homemadeDate';
import { VisualTransaction } from '../pages/student-pages/bank/mainTabs/secondaryTabs/accountTab';
import { convertCompanyIdToName, convertUserIdToName } from './convertions';
import { BankAccount, BankAccountTypeNumber } from '../store/instructor/bank';
import { Company } from '../store/instructor/company';
import { User } from '../store/instructor/users';

export interface TransactionWithFinalAmount extends BankTransaction {
  finalAmount: number;
}

/** Returns all transactions to and from the given account id */
export function getAllTransactionConcerningAccount(
  accountId: number,
  allTransactions: BankTransaction[],
): BankTransaction[] {
  return allTransactions.filter(
    elem =>
      elem.fromBankAccountId === accountId &&
      elem.toBankAccountId === accountId,
  );
}

export function compareNumbers(a: number, b: number) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}

/** The returned array will be sorted based on date */
export function convertToVisualTransactions(
  accountId: number,
  allAccountTransactions: BankTransaction[],
  allBankAccounts: BankAccount[],
  allCompanies: Company[],
  allUsers: User[],
): VisualTransaction[] {
  //Sort all trans after date
  const copyOfArray = [...allAccountTransactions];
  const givenArraySorted = copyOfArray.sort((a, b) =>
    compareNumbers(a.id, b.id),
  );
  let convertedValues: VisualTransaction[] = [];
  //Generate visual objects
  givenArraySorted.forEach(elem => {
    convertedValues.push(
      convertBankTransactionToVisualTransactions(
        accountId,
        elem,
        allBankAccounts,
        allCompanies,
        allUsers,
      ),
    );
  });

  return convertedValues;
}

export function convertBankTransactionToVisualTransactions(
  accountId: number,
  bankTransaction: BankTransaction,
  allBankAccounts: BankAccount[],
  allCompanies: Company[],
  allUsers: User[],
): VisualTransaction {
  let createdAt: HomemadeDate = bankTransaction.createdAt;
  let dateString: string =
    createdAt.getString() +
    ' ' +
    bankTransaction.createdAt.hour +
    ':' +
    bankTransaction.createdAt.minute +
    ':' +
    bankTransaction.createdAt.second;
  return {
    date: bankTransaction.createdAt,
    dateString: dateString,
    fromCompanyId: getIdOfBankAccountOwner(
      bankTransaction.fromBankAccountId,
      allBankAccounts,
      allUsers,
      allCompanies,
    ),
    text: bankTransaction.message,
    amount:
      bankTransaction.toBankAccountId === accountId
        ? bankTransaction.amount
        : -bankTransaction.amount,
    balanceAfterTransaction:
      bankTransaction.toBankAccountId === accountId
        ? bankTransaction.toAfterBalance
        : bankTransaction.fromAfterBalance,
  };
}

export function getIdOfBankAccountOwner(
  bankAccountId: number,
  allBankAccounts,
  allUsers: User[],
  allCompanies: Company[],
): number {
  const ownerId = getInfoOfBankAccountOwner(
    bankAccountId,
    allBankAccounts,
    allUsers,
    allCompanies,
    false,
  );
  if (typeof ownerId === 'number') {
    return ownerId;
  } else {
    throw new Error(
      'Cannot happen unless getInfoOfBankAccountOwner() has been changed.',
    );
  }
}

export function getNameOfBankAccountOwner(
  bankAccountId: number,
  allBankAccounts,
  allUsers: User[],
  allCompanies: Company[],
): string {
  const ownerId = getInfoOfBankAccountOwner(
    bankAccountId,
    allBankAccounts,
    allUsers,
    allCompanies,
    true,
  );
  if (typeof ownerId === 'string') {
    return ownerId;
  } else {
    throw new Error(
      'Cannot happen unless getInfoOfBankAccountOwner() has been changed.',
    );
  }
}

export function getInfoOfBankAccountOwner(
  bankAccountId: number,
  allBankAccounts,
  allUsers: User[],
  allCompanies: Company[],
  shouldReturnName: boolean,
) {
  const foundAccount: BankAccount[] = allBankAccounts.filter(
    elem => elem.id === bankAccountId,
  );
  //Bank accounts have unique ids, so only one should exist
  if (foundAccount.length !== 1) {
    if (foundAccount.length === 0) {
      throw Error('No bank account was found with given id: ' + bankAccountId);
    } else {
      throw Error(
        'Multiple bank accounts exits with given id: ' + bankAccountId,
      );
    }
  } else {
    if (foundAccount[0].type_id === BankAccountTypeNumber.STUDENT) {
      if (foundAccount[0].userId === undefined) {
        throw new Error('Account with invalid information was found!');
      } else {
        if (shouldReturnName) {
          return convertUserIdToName(allUsers, foundAccount[0].userId);
        } else {
          return foundAccount[0].userId;
        }
      }
    } else if (foundAccount[0].type_id === BankAccountTypeNumber.COMPANY) {
      if (foundAccount[0].companyId === undefined) {
        throw new Error('Account with invalid information was found!');
      } else {
        if (shouldReturnName) {
          return convertCompanyIdToName(
            allCompanies,
            foundAccount[0].companyId,
          );
        } else {
          return foundAccount[0].companyId;
        }
      }
    } else {
      throw new Error(
        'A new type has been added! The found bankAccount, did not have a recognised type_id.',
      );
    }
  }
}
