import React, { useContext } from 'react';
import ToastAlert from './ToastAlert';
import { ToastContext } from '../../contexts/ToastContext';

const ToastContainer = () => {
  const { state } = useContext(ToastContext);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 25,
        left: 25,
        zIndex: 9999,
      }}
    >
      {state.toasts.map((toast, key) => {
        return (
          <ToastAlert
            key={key}
            id={toast.id}
            type={toast.type}
            message={toast.message}
            header={toast.header}
          />
        );
      })}
    </div>
  );
};

export default ToastContainer;
