import React from 'react';

interface PropsFromParent {
  datepicker: React.ReactElement<any>;
}

const DatepickerTableTitle: React.FC<PropsFromParent> = ({ datepicker }) => {
  return (
    <>
      <div className={'d-flex ml-2 align-items-center'}>
        <p className="lead mb-0 mr-3">Dato </p>
        {datepicker}
      </div>
    </>
  );
};

export default DatepickerTableTitle;
