import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import BallotIcon from '@material-ui/icons/Ballot';
import StorefrontIcon from '@material-ui/icons/Storefront';
import GavelIcon from '@material-ui/icons/Gavel';
import InsertChartIcon from '@material-ui/icons/InsertChart';

export interface NavbarDataType {
  title: string;
  url: string;
  icon: any;
}

export const NavbarDataStudent: NavbarDataType[] = [
  {
    title: 'Forside',
    url: '/student/dashboard',
    icon: <HomeIcon />,
  },
  {
    title: 'Indbakke',
    url: '/student/inbox',
    icon: <AllInboxIcon />,
  },
  {
    title: 'Bank',
    url: '/student/bank',
    icon: <AccountBalanceIcon />,
  },
  {
    title: 'Opret kontoudtog',
    url: '/student/statement',
    icon: <InsertChartIcon />,
  },
  {
    title: 'Løn',
    url: '/student/salary',
    icon: <CreditCardIcon />,
  },
  {
    title: 'Produktkatalog',
    url: '/student/products',
    icon: <ShoppingCartIcon />,
  },
  {
    title: 'Rekvisitioner',
    url: '/student/requisitions',
    icon: <BallotIcon />,
  },
  {
    title: 'Varelager',
    url: '/student/inventory',
    icon: <StorefrontIcon />,
  },
  {
    title: 'Fakturaer',
    url: '/student/invoice',
    icon: <MonetizationOnIcon />,
  },
  {
    title: 'Opret Købsordre',
    url: '/student/requisition',
    icon: <NoteAddIcon />,
  },
  {
    title: 'Levering',
    url: '/student/shipping',
    icon: <LocalShippingIcon />,
  },
  {
    title: 'SKAT',
    url: '/student/tax',
    icon: <GavelIcon />,
  },
];
