import React, { Component } from 'react';
import styled from 'styled-components';
import { Carousel as BootCarousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Frontpage extends Component {
  render() {
    return (
      <>
        <Body className={''}>
          <nav className="navbar navbar-dark justify-content-between fixed-top navbar-expand-lg">
            <div className={'container-fluid'}>
              <div className="navbar-brand cursor-pointer">
                <NavLogo src={'images/placeholder-logo.png'} />
              </div>
            </div>
          </nav>

          <Header>
            <div className={'container'}>
              <div className="row">
                <div className="col-md-7">
                  <h1>Løft din praktikplads med et simulationssystem</h1>
                  <p className="lead">
                    Vivamus sagittis lacus vel augue laoreet rutrum faucibus
                    dolor auctor. Duis mollis, est non commodo luctus.
                  </p>
                  <div className={'pt-3'}>
                    <Link to={'/apply'}>
                      <Button isApply type="button" className="btn px-4 py-2">
                        Ansøg om Adgang
                      </Button>
                    </Link>
                    <span className={'mx-4'}>
                      <em>eller</em>
                    </span>
                    <Link to={'/login'}>
                      <Button type="button" className="btn px-4 py-2">
                        Log Ind
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Header>

          <CarouselSection>
            <div className={'container-fluid'}>
              <BootCarousel controls={false} indicators={false} interval={5000}>
                <BootCarousel.Item>
                  <div className="d-flex flex-row justify-content-center py-2">
                    <div className="py-2 px-4 w-20">
                      <CarouselImage
                        src={'images/koege.png'}
                        alt={'Køge Handelsskole'}
                      />
                    </div>
                    <div className="py-2 px-4 w-20">
                      <CarouselImage
                        src={'images/koege.png'}
                        alt={'Køge Handelsskole'}
                      />
                    </div>
                    <div className="py-2 px-4 w-20">
                      <CarouselImage
                        src={'images/koege.png'}
                        alt={'Køge Handelsskole'}
                      />
                    </div>
                  </div>
                </BootCarousel.Item>
                <BootCarousel.Item>
                  <div className="d-flex flex-row justify-content-center py-2">
                    <div className="py-2 px-4 w-20">
                      <CarouselImage
                        src={'images/koege.png'}
                        alt={'Køge Handelsskole'}
                      />
                    </div>
                    <div className="py-2 px-4 w-20">
                      <CarouselImage
                        src={'images/koege.png'}
                        alt={'Køge Handelsskole'}
                      />
                    </div>
                    <div className="py-2 px-4 w-20">
                      <CarouselImage
                        src={'images/koege.png'}
                        alt={'Køge Handelsskole'}
                      />
                    </div>
                  </div>
                </BootCarousel.Item>
              </BootCarousel>
            </div>
          </CarouselSection>

          <Section className={'text-center'}>
            <div className={'container'}>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </p>
            </div>
          </Section>
        </Body>
      </>
    );
  }
}

interface ButtonProps {
  readonly isApply?: any;
}

const NavLogo = styled.img`
  max-width: 100%;
  width: 100px;
  height: auto;
`;

const Body = styled.div`
  background-color: #f3f6fb;
`;

const CarouselImage = styled.img`
  max-width: 100%;
  height: auto;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.5;
  &:hover {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    cursor: pointer;
    opacity: 1;
  }
`;

const CarouselSection = styled.section`
  background-color: #fff;
`;

const Section = styled.section`
  padding: 6rem 0;
`;

const Button = styled.button<ButtonProps>`
  font-weight: 600;
  color: ${props => (props.isApply ? '#fff' : '#000')};
  border: none;
  background-size: 300% 100%;
  border-radius: 10px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  ${props =>
    props.isApply
      ? `background-image: linear-gradient(
    to right,
    #25aae1,
    #4481eb,
    #04befe,
    #3f86ed
  );
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.35);`
      : `background-image: linear-gradient(
    to right,
    #e0e0e0,
    #ebebeb,
    #fcfcfc,
    #ededed
  );
  box-shadow: 0 4px 15px 0 rgba(235, 235, 235, 0.35);`}

  &:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
`;

const Header = styled.header`
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  color: #fff;
  background-image: url('images/header-bg.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
`;

export default Frontpage;
