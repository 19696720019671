import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { BankAccount } from '../../../../store/instructor/bank';
import { NewBankTransactionWithId } from '../bankContent';
import { NewBankTransaction } from '../../../../store/instructor/bank-transactions';
import { User } from '../../../../store/instructor/users';
import { validateDoubleWithCommaOrInteger } from '../../../../utils/validators';
import { parseDanishDouble } from '../../../../utils/convertions';
import { FormGroup } from 'reactstrap';

//If this is SALARY, salaryAccounts is not undefined
export enum PaymentContextType {
  SALARY,
  PRIVAT_BANK,
  OTHER,
}

export enum PaymentType {
  TRANSFER_INTERN,
  TRANSFER_EXTERN,
  PAYMENT_CARD,
}

interface PropsFromParent {
  type: PaymentType;
  context: PaymentContextType;
  activeBankAccounts: BankAccount[];
  pendingTransactions: NewBankTransaction[];
  addPendingTransactions: any;
  userCompanyBankAccounts: BankAccount[];
  salaryAccounts?: BankAccount[];
  allUsers?: User[];
  salaryAccount?: BankAccount;
}

interface ThisCompState {
  indicator: string;
  transactionNextId: number;
  enteredDetails: {
    accountFrom: number;
    accountTo: string;
    receiverName: string;
    transferDate: string;
    amount: string;
    message: string;
    paymentCardTypeCode: string;
    paymentCardPaymentIdentificationNumber: string;
    paymentCardCreditorNumber: string;
    invoiceId: string;
  };
  localErrors: {
    amount: string;
    accountFrom: string;
    accountTo: string;
    invoiceId: string;
    paymentCard: {
      type: string;
      paymentIdentificationNumber: string;
      creditorNumber: string;
      checkCiffer: string;
    };
    message: string;
  };
  isPaymentToInvoice: boolean;
}

class PaymentTab extends React.Component<PropsFromParent, ThisCompState> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      indicator: '',
      transactionNextId: 0,
      enteredDetails: {
        accountFrom: -1,
        accountTo: '',
        receiverName: '',
        transferDate: '',
        amount: '',
        message: '',
        paymentCardTypeCode: '71',
        paymentCardPaymentIdentificationNumber: '',
        paymentCardCreditorNumber: '',
        invoiceId: '',
      },
      localErrors: {
        amount: '',
        accountFrom: '',
        accountTo: '',
        invoiceId: '',
        paymentCard: {
          type: '',
          paymentIdentificationNumber: '',
          creditorNumber: '',
          checkCiffer: '',
        },
        message: '',
      },
      isPaymentToInvoice: false,
    };

    if (this.props.context === PaymentContextType.PRIVAT_BANK) {
      this.state.enteredDetails.accountFrom = this.props.salaryAccount!.id;
    }
  }

  componentDidUpdate(
    prevProps: Readonly<PropsFromParent>,
    prevState: Readonly<ThisCompState>,
  ) {
    if (this.props.context === PaymentContextType.PRIVAT_BANK) {
      if (prevProps.salaryAccount !== this.props.salaryAccount) {
        if (this.props.salaryAccount!.id !== -1) {
          this.setState({
            enteredDetails: {
              ...this.state.enteredDetails,
              accountFrom: this.props.salaryAccount!.id,
            },
          });
        }
      }
    }
  }

  setIndicator = (msg: string) => {
    this.setState({
      indicator: msg,
    });
  };

  incrementTransactionNextId = () => {
    this.setState({ transactionNextId: this.state.transactionNextId + 1 });
  };

  doesBankAccountExist(accountId: number) {
    const foundAccount: BankAccount[] = this.props.activeBankAccounts.filter(
      elem => elem.id === accountId,
    );
    return foundAccount.length !== 0;
  }

  validate = () => {
    const errors = {
      amount: '',
      accountFrom: '',
      accountTo: '',
      invoiceId: '',
      paymentCard: {
        type: '',
        paymentIdentificationNumber: '',
        creditorNumber: '',
        checkCiffer: '',
      },
      message: '',
    };

    const enteredData = this.state.enteredDetails;

    //accountFrom checks
    if (enteredData.accountFrom === -1) {
      errors.accountFrom = 'Vælg en Fra konto.';
    }
    // message empty check
    if (enteredData.message.trim() === '') {
      errors.message = 'Besked feltet kan ikke være tomt';
    }

    //accountTo checks
    if (this.props.type === PaymentType.TRANSFER_INTERN) {
      if (enteredData.accountTo === '') {
        errors.accountTo = 'Modtager konto, vælg en bruger.';
      }
    } else if (this.props.type === PaymentType.TRANSFER_EXTERN) {
      if (enteredData.accountTo === '') {
        errors.accountTo = 'Modtager konto, må ikke være tomt.';
      } else {
        const accountToDoesExist = this.doesBankAccountExist(
          parseInt(enteredData.accountTo),
        );
        if (!accountToDoesExist) {
          errors.accountTo = 'Modtager konto, findes ikke.';
        }
      }
    } else if (this.props.type === PaymentType.PAYMENT_CARD) {
      if (this.state.enteredDetails.paymentCardTypeCode === '71') {
        //ex: 0000002075283 //length has to be 15!
        if (enteredData.paymentCardPaymentIdentificationNumber === '') {
          errors.paymentCard.paymentIdentificationNumber =
            'Indtast betalingsidentifikationsnummeret i OCR koden.';
        } else if (
          enteredData.paymentCardPaymentIdentificationNumber.length !== 15
        ) {
          errors.paymentCard.paymentIdentificationNumber =
            'Længden af betalingsidentifikationsnummeret i OCR koden skal være 15.';
        } else {
          //Check control ciffer
          const firstNumberMinusCheckCifferAndAppendixType: number = parseInt(
            enteredData.paymentCardPaymentIdentificationNumber.substr(
              0,
              enteredData.paymentCardPaymentIdentificationNumber.length - 2,
            ),
          );
          const checkCiffer: number = parseInt(
            enteredData.paymentCardPaymentIdentificationNumber.charAt(
              enteredData.paymentCardPaymentIdentificationNumber.length - 1,
            ),
          );
          const correctCheckCiffer: number =
            firstNumberMinusCheckCifferAndAppendixType % 10;
          if (checkCiffer !== correctCheckCiffer) {
            errors.paymentCard.checkCiffer =
              'Der er en fejl i betalingsidentifikation nummeret i OCR koden.';
          }
        }

        //length = 8
        if (enteredData.paymentCardCreditorNumber === '') {
          errors.paymentCard.creditorNumber =
            'Indtast kreditornummer i OCR koden.';
        } else if (enteredData.paymentCardCreditorNumber.length !== 8) {
          errors.paymentCard.creditorNumber =
            'Længden af kreditornummeret i OCR koden skal være 8.';
        }
      } else {
        errors.paymentCard.type =
          'Indbetalingskort type er forkert. (Kun +71 understøttes)';
      }
    } else {
      throw new Error('Unrecognized type');
    }

    //Amount checks
    if (enteredData.amount === '' || enteredData.amount === '0') {
      errors.amount = 'Beløb må ikke være tomt.';
    } else {
      const amountErrorMsg: string = validateDoubleWithCommaOrInteger(
        enteredData.amount,
      );
      if (amountErrorMsg !== '') {
        errors.amount = 'Beløb: ' + amountErrorMsg + '.';
      }
    }

    //Invoice id check
    if (
      this.props.type === PaymentType.TRANSFER_EXTERN &&
      this.state.isPaymentToInvoice
    ) {
      if (enteredData.invoiceId === '') {
        errors.invoiceId = 'Faktura nr. må ikke være tomt.';
      } else {
        const re = /^[0-9]+(\\.[0-9]+)?$/; //Int
        if (!re.test(enteredData.invoiceId)) {
          errors.invoiceId =
            'Det indtastede faktura nr. er ikke gyldigt. Sørg for kun at indtaste talene.';
        }
      }
    }

    return errors;
  };

  onSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({
      localErrors: {
        amount: errors.amount,
        accountFrom: errors.accountFrom,
        accountTo: errors.accountTo,
        invoiceId: errors.invoiceId,
        paymentCard: {
          type: errors.paymentCard.type,
          checkCiffer: errors.paymentCard.checkCiffer,
          paymentIdentificationNumber:
            errors.paymentCard.paymentIdentificationNumber,
          creditorNumber: errors.paymentCard.creditorNumber,
        },
        message: errors.message,
      },
    });

    if (
      Object.keys(errors.amount).length === 0 &&
      Object.keys(errors.accountTo).length === 0 &&
      Object.keys(errors.accountFrom).length === 0 &&
      Object.keys(errors.invoiceId).length === 0 &&
      Object.keys(errors.message).length === 0 &&
      Object.keys(errors.paymentCard.type).length === 0 &&
      Object.keys(errors.paymentCard.paymentIdentificationNumber).length ===
        0 &&
      Object.keys(errors.paymentCard.creditorNumber).length === 0 &&
      Object.keys(errors.paymentCard.checkCiffer).length === 0
    ) {
      let currentTransaction: NewBankTransactionWithId;
      if (this.props.type === PaymentType.TRANSFER_EXTERN) {
        currentTransaction = {
          id: this.state.transactionNextId,
          fromBankAccountId: this.state.enteredDetails.accountFrom,
          toBankAccountId: parseInt(
            this.state.enteredDetails.accountTo.toString(),
          ),
          amount: parseDanishDouble(this.state.enteredDetails.amount),
          bankTransactionTypeId: this.state.isPaymentToInvoice ? 1 : 2,
          invoiceId: parseInt(this.state.enteredDetails.invoiceId),
          message: this.state.enteredDetails.message,
        };
      } else if (this.props.type === PaymentType.TRANSFER_INTERN) {
        currentTransaction = {
          id: this.state.transactionNextId,
          fromBankAccountId: this.state.enteredDetails.accountFrom,
          toBankAccountId: parseInt(
            this.state.enteredDetails.accountTo.toString(),
          ),
          amount: parseDanishDouble(this.state.enteredDetails.amount),
          bankTransactionTypeId: 2,
          message: this.state.enteredDetails.message,
        };
      } else if (this.props.type === PaymentType.PAYMENT_CARD) {
        currentTransaction = {
          id: this.state.transactionNextId,
          fromBankAccountId: this.state.enteredDetails.accountFrom,
          amount: parseDanishDouble(this.state.enteredDetails.amount),
          message: this.state.enteredDetails.message,
          bankTransactionTypeId: 3,
          slip_paymentTypeCode: 71,
          slip_identificationNumber: parseInt(
            this.state.enteredDetails.paymentCardPaymentIdentificationNumber,
          ),
          slip_creditorNumber: parseInt(
            this.state.enteredDetails.paymentCardCreditorNumber,
          ),
        };
      } else {
        throw new Error('Payment: new enum of PaymentType has been added!');
      }
      this.incrementTransactionNextId();
      this.props.addPendingTransactions(currentTransaction);
      this.setIndicator('Tilføjet transaktion til godkendelse');
      //Clean input fields/state
      this.setState({
        enteredDetails: {
          accountFrom:
            this.props.context === PaymentContextType.PRIVAT_BANK
              ? this.state.enteredDetails.accountFrom
              : -1,
          accountTo: '',
          receiverName: '',
          transferDate: '',
          amount: '',
          message: '',
          invoiceId: '',
          paymentCardTypeCode: '71',
          paymentCardPaymentIdentificationNumber: '',
          paymentCardCreditorNumber: '',
        },
      });
    }
  };

  onChange = e => {
    //Only allow integers to field included in if-statement
    if (
      (e.target.name === 'accountTo' && e.target.value !== '') ||
      (e.target.name === 'paymentCardPaymentIdentificationNumber' &&
        e.target.value !== '') ||
      (e.target.name === 'paymentCardCreditorNumber' && e.target.value !== '')
    ) {
      const re = /^[0-9\b]+$/; //No comma allowed
      if (!re.test(e.target.value)) {
        return;
      }
    }

    if (e.target.name === 'accountFrom') {
      this.setState({
        enteredDetails: {
          ...this.state.enteredDetails,
          accountFrom: parseInt(e.target.value),
        },
      });
    } else {
      this.setState({
        enteredDetails: {
          ...this.state.enteredDetails,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  renderAccountOptions = (allAccounts: BankAccount[]) => {
    let user: User | undefined;
    return (
      <>
        {allAccounts.map((account, index) => {
          if (this.props.context === PaymentContextType.SALARY) {
            user = this.props.allUsers!.find(e => e.id === account.userId);
          }
          return (
            <option key={index} value={account.id}>
              {user
                ? user.first_name + ' ' + user.last_name + ' | ' + account.name
                : account.name}
            </option>
          );
        })}
      </>
    );
  };

  renderPrivateBankAccountOption = (
    salaryAccount: BankAccount,
    index: number,
  ) => {
    let user: User | undefined = this.props.allUsers!.find(
      e => e.id === salaryAccount.userId,
    );
    return (
      <option key={index} value={salaryAccount.id}>
        {user
          ? user.first_name + ' ' + user.last_name + ' | ' + salaryAccount.name
          : salaryAccount.name}
      </option>
    );
  };

  //The current from account is not rendered as an option
  renderToAccountOptions = (allAccounts: BankAccount[]) => {
    const fromAccountRemoved: BankAccount[] = [...allAccounts];
    const removeIndex: number = allAccounts.findIndex(
      elem => elem.id === this.state.enteredDetails.accountFrom,
    );
    if (removeIndex !== -1) {
      fromAccountRemoved.splice(removeIndex, 1);
    }

    return this.renderAccountOptions(fromAccountRemoved);
  };

  renderContent = () => {
    const activeCompanyAccounts: BankAccount[] = this.props.userCompanyBankAccounts.filter(
      elem => elem.isActive,
    );
    const context: PaymentContextType = this.props.context;
    return (
      <>
        <Form>
          <Form.Group className="mb-2">
            <Form.Label>Fra konto</Form.Label>
            {context === PaymentContextType.PRIVAT_BANK ? (
              <Form.Control
                as="select"
                defaultValue={this.props.salaryAccount?.id}
                name={'accountFrom'}
                value={this.state.enteredDetails.accountFrom}
                onChange={this.onChange}
              >
                {this.renderPrivateBankAccountOption(
                  this.props.salaryAccount!,
                  0,
                )}
              </Form.Control>
            ) : (
              <Form.Control
                as="select"
                defaultValue="Vælg.."
                name={'accountFrom'}
                value={this.state.enteredDetails.accountFrom}
                onChange={this.onChange}
              >
                <option value={-1}>Vælg..</option>
                {this.renderAccountOptions(activeCompanyAccounts)}
              </Form.Control>
            )}
          </Form.Group>
          <Form.Group className="mb-2">
            {this.props.type === PaymentType.PAYMENT_CARD ? null : this.props
                .type === PaymentType.TRANSFER_INTERN ? (
              <>
                <Form.Label>
                  Til{' '}
                  {context === PaymentContextType.SALARY ? 'lønkonto' : 'konto'}
                </Form.Label>
                <Form.Control
                  as="select"
                  defaultValue="Vælg.."
                  name={'accountTo'}
                  value={this.state.enteredDetails.accountTo}
                  onChange={this.onChange}
                >
                  <option value={-1}>Vælg..</option>
                  {this.renderToAccountOptions(
                    this.props.context === PaymentContextType.SALARY
                      ? this.props.salaryAccounts!
                      : activeCompanyAccounts,
                  )}
                </Form.Control>
              </>
            ) : (
              <>
                <Form.Label>Modtager konto</Form.Label>
                <Form.Control
                  placeholder="Kontonummer"
                  name={'accountTo'}
                  value={this.state.enteredDetails.accountTo}
                  onChange={this.onChange}
                />
              </>
            )}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Beløb</Form.Label>
            <Form.Control
              placeholder="Beløb"
              name={'amount'}
              value={this.state.enteredDetails.amount}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Besked</Form.Label>
            <Form.Control
              placeholder="Indtast besked .."
              name={'message'}
              value={this.state.enteredDetails.message}
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            {this.props.type === PaymentType.TRANSFER_EXTERN && (
              <>
                <Form.Label className={'font-weight-bold text-muted'}>
                  Betaling af en faktura
                </Form.Label>
                <input
                  className={'ml-4'}
                  type="checkbox"
                  checked={this.state.isPaymentToInvoice}
                  onChange={() => {
                    this.setState({
                      isPaymentToInvoice: !this.state.isPaymentToInvoice,
                    });
                  }}
                />
              </>
            )}
          </Form.Group>

          <Form.Group className="mb-2">
            {this.props.type === PaymentType.TRANSFER_EXTERN &&
            this.state.isPaymentToInvoice ? (
              <>
                <Form.Label>Faktura nr.</Form.Label>
                <Form.Control
                  placeholder="Faktura nr."
                  name={'invoiceId'}
                  value={this.state.enteredDetails.invoiceId}
                  onChange={this.onChange}
                />
              </>
            ) : null}
            {this.props.type === PaymentType.PAYMENT_CARD ? (
              <>
                <Form.Label>Kodelinje:</Form.Label>
                <Form inline>
                  <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Form.Control
                      disabled={true}
                      as="select"
                      defaultValue="71"
                      name={'paymentCardTypeCode'}
                      value={this.state.enteredDetails.paymentCardTypeCode}
                      onChange={this.onChange}
                    >
                      <option>71</option>
                    </Form.Control>
                    {'<'}
                    <Form.Control
                      placeholder=""
                      name={'paymentCardPaymentIdentificationNumber'}
                      value={
                        this.state.enteredDetails
                          .paymentCardPaymentIdentificationNumber
                      }
                      onChange={this.onChange}
                    />
                    {'+'}
                    <Form.Control
                      placeholder=""
                      name={'paymentCardCreditorNumber'}
                      value={
                        this.state.enteredDetails.paymentCardCreditorNumber
                      }
                      onChange={this.onChange}
                    />
                    {'<'}
                  </FormGroup>
                </Form>
              </>
            ) : null}
          </Form.Group>
        </Form>
        <ErrorDiv>
          {this.state.localErrors.accountFrom}
          {this.state.localErrors.accountTo}
          {this.state.localErrors.amount}
          {this.state.localErrors.message}
          {this.state.localErrors.invoiceId}
          {this.state.localErrors.paymentCard.type}
          {this.state.localErrors.paymentCard.paymentIdentificationNumber}
          {this.state.localErrors.paymentCard.creditorNumber}
          {this.state.localErrors.paymentCard.checkCiffer}
        </ErrorDiv>
        <Button variant="primary" className={'mt-3'} onClick={this.onSubmit}>
          Til godkendelse
        </Button>

        {this.state.indicator ? (
          <div className="alert alert-success mt-2" role="alert">
            {this.state.indicator}
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  public render() {
    return (
      <div className={'row'}>
        <div className={'col-sm-12 col-lg-5'}>{this.renderContent()}</div>
      </div>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

export default connect()(PaymentTab);
