import { ErrorMessage } from '../store/instructor/orderInvoice';
import React from 'react';
import {
  ExtendedDetails,
  MistakeContext,
} from '../store/contexts/MistakeProvider';
import { MistakeActionTypes } from '../store/reducers/MistakesReducer';

export function ActionTitleDeterminer(type: MistakeActionTypes) {
  switch (type) {
    case MistakeActionTypes.MESSAGE:
      return 'Send besked';
    case MistakeActionTypes.RETURN_DELIVERY:
      return 'Administrer varer';
    case MistakeActionTypes.UNDEFINED:
      return 'Undefined action';
  }
}

function determineActionTypeBasedOnId(error: ErrorMessage) {
  switch (error.id) {
    case 3:
    case 4:
    case 5:
      return MistakeActionTypes.RETURN_DELIVERY;
    default:
      return MistakeActionTypes.UNDEFINED;
  }
}

export function collectUniqueActionTypes(
  errors: ErrorMessage[],
): MistakeActionTypes[] {
  let allTypes: MistakeActionTypes[] = [];

  errors.forEach(err => {
    allTypes.push(determineActionTypeBasedOnId(err));
  });

  return Array.from(new Set(allTypes));
}

export function RenderAction(
  action: MistakeActionTypes,
  details: ExtendedDetails,
) {
  const { setModalVisibility, setSelectedDetails } = React.useContext(
    MistakeContext,
  );

  switch (action) {
    case MistakeActionTypes.RETURN_DELIVERY:
      return (
        <>
          <p
            className={'link-primary'}
            onClick={() => {
              if (details) {
                setSelectedDetails(details);
              }
              setModalVisibility({
                state: true,
                actionType: MistakeActionTypes.RETURN_DELIVERY,
              });
            }}
          >
            Administrer varer (returnering og modtagelse)
          </p>
        </>
      );
    case MistakeActionTypes.MESSAGE:
      return (
        <p
          className={'link-primary'}
          onClick={() => {
            if (details) {
              setSelectedDetails(details);
            }
            setModalVisibility({
              state: true,
              actionType: MistakeActionTypes.MESSAGE,
            });
          }}
        >
          Send besked til afsender
        </p>
      );
  }
}
