import * as React from 'react';
import { connect } from 'react-redux';
import { InstructorPageLayout } from '../_layout';
import styled from 'styled-components';
import ProductOverview from './productContent';
import { Helmet } from 'react-helmet-async';

const IProductsPage: React.FC = () => {
  return (
    <InstructorPageLayout>
      <Helmet>
        <title>Produkter</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Produkter og typer</h2>
      </PageHeader>

      <ProductOverview />
    </InstructorPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(IProductsPage);
