import React from 'react';
import {
  MistakeContext,
  MistakeHandlingProcedures,
} from '../../../store/contexts/MistakeProvider';
import { Button, Col, Row } from 'react-bootstrap';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import {
  fetchProductById,
  fetchRequisitionById,
  updateMistakeDelivery,
} from '../../../utils/api';
import { Requisition } from '../../../store/instructor/requisitions';
import { Product } from '../../../store/instructor/product';
import LoadingComponent from '../../LoadingComponent';

enum RenderTypes {
  RECEIVED,
  EXPECTED,
}

interface ProductWithQuantity {
  product: Product;
  quantity: number;
}

export const ReturnDelivery: React.FC = () => {
  const {
    selectedDetails,
    setModalVisibility,
    setMistakeError,
  } = React.useContext(MistakeContext);

  let productsReceived: ProductWithQuantity[] = [];
  let productsExpected: ProductWithQuantity[] = [];
  const queryClient = useQueryClient();

  const [productDifLists] = React.useState<{
    differencesList: ProductWithQuantity[];
    excessProductsList: ProductWithQuantity[];
  }>({ differencesList: [], excessProductsList: [] });

  const { data: requisition, isLoading: reqLoading } = useQuery<
    Requisition,
    AxiosError
  >(
    ['requisition', selectedDetails?.details.requisition_id],
    () => fetchRequisitionById(selectedDetails?.details.requisition_id!),
    {
      enabled: !!selectedDetails?.details.requisition_id,
    },
  );

  const receivedProductsQueries = useQueries<Product, AxiosError>(
    selectedDetails?.details.items?.map(product => {
      return {
        queryKey: ['product', product.product_id],
        queryFn: () => fetchProductById(product.product_id),
        enabled: !!selectedDetails?.details.items,
      };
    }) ?? [],
  );

  const expectedProductsQueries = useQueries<Product, AxiosError>(
    requisition?.items.map(product => {
      return {
        queryKey: ['product', product.product_id],
        queryFn: () => fetchProductById(product.product_id),
        enabled: !!requisition.items,
      };
    }) ?? [],
  );

  const { mutate: mutateReturn } = useMutation(updateMistakeDelivery, {
    onSuccess: () => {
      queryClient.invalidateQueries('deliveryMistakes');
    },
    onError: (error: AxiosError) => {
      setMistakeError(error.response?.data.error);
    },
  });

  const handleReturnDeliveryAction = (type: MistakeHandlingProcedures) => {
    setModalVisibility({ state: false, actionType: '' });

    const details = {
      id: selectedDetails?.details.delivery_id,
      handling_procedure: type,
    };

    mutateReturn(details);
  };

  const calculateDifferences = () => {
    productDifLists.excessProductsList = [];
    productDifLists.differencesList = [];

    // Map through queries to properly set the received products and expected

    receivedProductsQueries.forEach(query => {
      const product = query.data;
      const productQuantity = selectedDetails?.details.items?.find(
        pr => pr.product_id === product?.id,
      )?.product_quantity;

      if (productQuantity && product) {
        productsReceived = [
          ...productsReceived,
          { product: product, quantity: productQuantity },
        ];
      }
    });

    expectedProductsQueries.forEach(query => {
      const product = query.data;
      const productQuantity = requisition?.items.find(
        pr => pr.product_id === product?.id,
      )?.product_quantity;

      if (productQuantity && product) {
        productsExpected = [
          ...productsExpected,
          { product: product, quantity: productQuantity },
        ];
      }
    });

    const productListReceivedCopy: ProductWithQuantity[] = [
      ...productsReceived,
    ];

    productsExpected.forEach(elem => {
      const matchingReceivedProduct = productsReceived.find(
        el => el.product.id === elem.product.id,
      );
      //Have we received the current product?
      if (matchingReceivedProduct) {
        //Save the difference between received and expected amount
        productDifLists.differencesList.push({
          product: elem.product,
          quantity: matchingReceivedProduct.quantity - elem.quantity,
        });

        //Remove the element which has been found
        const index = productListReceivedCopy.indexOf(matchingReceivedProduct);
        if (index > -1) {
          productListReceivedCopy.splice(index, 1);
        }
      } else {
        //We have not received the expected product
        productDifLists.differencesList.push({
          product: elem.product,
          quantity: -elem.quantity,
        });
      }
    });

    //Handle received products which were not in the expected list
    productListReceivedCopy.forEach(elem =>
      productDifLists.excessProductsList.push(elem),
    );

    return productDifLists;
  };

  const renderProduct = (
    pr: ProductWithQuantity,
    key: number,
    type: RenderTypes,
    difList,
  ) => {
    let product = difList.differencesList.find(
      pro => pro.product.id === pr.product.id,
    );

    return (
      <div
        key={key}
        className={'basket row align-items-center text-left p-2 bg-light my-1'}
      >
        <div className={'basket-item col-7'}>
          {pr.product.name} <br />
          Varenr: {pr.product.navision_id}
        </div>
        <div className={'basket-item col-4 text-center mx-3'}>
          {type === RenderTypes.EXPECTED ? 'Bestilt' : 'Leveret'}: {pr.quantity}
          <br />
          {product && (
            <span className={'text-danger'}>
              {product?.quantity > 1
                ? 'For mange: ' + Math.abs(product.quantity)
                : 'Mangler: ' + Math.abs(product.quantity)}
            </span>
          )}
          {!product && <span className={'text-info'}>Forkert vare</span>}
        </div>
      </div>
    );
  };

  let isLoading =
    receivedProductsQueries.some(query => query.isLoading) ||
    reqLoading ||
    receivedProductsQueries.some(query => query.isLoading);

  if (isLoading) {
    return <LoadingComponent visible={isLoading} />;
  }

  if (requisition && selectedDetails && !isLoading) {
    const dif = calculateDifferences();

    return (
      <>
        <Row>
          <Col>
            <h5>Forventede varer</h5>
            {productsExpected.map((pr, i) => {
              return renderProduct(pr, i, RenderTypes.EXPECTED, dif);
            })}
          </Col>

          <Col>
            <h5>Modtagne varer</h5>
            {productsReceived.map((pr, i) => {
              return renderProduct(pr, i, RenderTypes.RECEIVED, dif);
            })}
          </Col>
        </Row>
        <Row>
          <Col className={'text-right'}>
            <Button
              variant="primary"
              disabled={
                productsReceived.length === 0 ||
                selectedDetails.details.handlingProcedure?.handlingProcedure ===
                  MistakeHandlingProcedures.DELIVERY_RETURN
              }
              className={'mr-3'}
              onClick={() =>
                handleReturnDeliveryAction(
                  MistakeHandlingProcedures.DELIVERY_RETURN,
                )
              }
            >
              Send alle varer retur
            </Button>
            <Button
              variant="primary"
              disabled={
                productsReceived.length === 0 ||
                selectedDetails.details.handlingProcedure?.handlingProcedure ===
                  MistakeHandlingProcedures.DELIVERY_KEEP
              }
              onClick={() =>
                handleReturnDeliveryAction(
                  MistakeHandlingProcedures.DELIVERY_KEEP,
                )
              }
            >
              Tag imod alle varer
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  return null;
};

export default ReturnDelivery;
