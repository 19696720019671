import * as React from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { ApplicationState } from '../../../../../store';
import { ShippingAreaType, ShippingPrices } from './data/shippingPrices';
import LoadingComponent from '../../../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
  infoTabType: ShippingAreaType;
  thisUserCompanyId: number;
}

interface ThisCompState {
  shippingPrices: ShippingPrices;
}

class ShippingInfoTab extends React.Component<PropsFromParent, ThisCompState> {
  state = {
    shippingPrices: new ShippingPrices(
      this.props.infoTabType,
      this.props.thisUserCompanyId, //TODO-future: currently hardcoded companyId/Name
    ),
  };

  renderTableHead = () => {
    return (
      <thead>
        <tr>
          {this.state.shippingPrices.headerLabels.map((name, index) => {
            return <th key={index}> {name}</th>;
          })}
        </tr>
      </thead>
    );
  };

  renderTableRow = (rowData: string[], index) => {
    return (
      <tr key={index}>
        {rowData.map((elem: string, index) => {
          return <td key={index}>{elem}</td>;
        })}
      </tr>
    );
  };

  renderContent = () => {
    return (
      <Table>
        {this.renderTableHead()}
        <tbody>
          {this.state.shippingPrices.priceRows.map((elem, index) => {
            return this.renderTableRow(elem, index);
          })}
        </tbody>
      </Table>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  loading: userInfo.loading,
});

export default connect(mapStateToProps)(ShippingInfoTab);
