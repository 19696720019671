import React, { Component } from 'react';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { store } from '../../store/store';
import styled from 'styled-components';
import { UserInformation } from '../../store/user/userAuthentication';
import { NavbarDataType } from '../../data/NavbarDataStudent';
import { Link, NavLink } from 'react-router-dom';
import Switch from 'react-switch';
import { Col, Row } from 'react-bootstrap';
import { navbarCollapseToggle } from '../../store/instructor/navbar';

interface PropsFromParent {
  navbarData: NavbarDataType[];
}

interface ThisCompState {
  toggled: boolean;
  collapsed: boolean;
  userInfo: {
    first_name: string;
    last_name: string;
    super_company: number;
    super_company_name: string;
  };
}

class NavigationBar extends Component<PropsFromParent, ThisCompState> {
  constructor(props: Readonly<PropsFromParent>) {
    super(props);
    this.state = {
      toggled: false,
      collapsed: store.getState().navbar.isCollapsed,
      userInfo: {
        first_name: '',
        last_name: '',
        super_company: 0,
        super_company_name: '',
      },
    };
  }

  getSuperCompanyNameFromId(id: number): string {
    if (id === undefined) {
      return 'Instructor';
    } else if (id === 1) {
      return 'Mixit';
    } else if (id === 2) {
      return 'EDP';
    } else {
      console.log(id);
      throw new Error('The user is logged in neither as company id 1 or 2');
    }
  }

  componentDidMount() {
    const userLoginState: UserInformation = store.getState().userInfo;
    this.setState({
      userInfo: {
        first_name: userLoginState.userFirstName,
        last_name: userLoginState.userLastName,
        super_company: userLoginState.userSuperCompanyId,
        //TODO-future: replace with field from store/api when implemented
        super_company_name: this.getSuperCompanyNameFromId(
          userLoginState.userSuperCompanyId,
        ),
      },
    });
  }

  handleToggleSidebar = () => {
    this.setState({ toggled: !this.state.toggled });
  };

  handleToggleCollapse = () => {
    store.dispatch(navbarCollapseToggle(!store.getState().navbar.isCollapsed));
    this.setState({ collapsed: store.getState().navbar.isCollapsed });
  };

  render() {
    return (
      <ProSidebar
        collapsed={this.state.collapsed}
        toggled={this.state.toggled}
        onToggle={this.handleToggleSidebar}
        breakPoint="md"
      >
        <SidebarHeader>
          <div
            style={{
              padding: '24px',
              fontSize: 14,
              letterSpacing: '1px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <div className={'row align-items-center'}>
              {!this.state.collapsed && (
                <Avatar className={'col-4'}>
                  <img
                    alt={'profile_picture'}
                    src={process.env.PUBLIC_URL + '/images/KOPRA-LOGO.png'}
                  />
                </Avatar>
              )}

              <div className={'col-md-8 col-12 p-0 text-wrap'}>
                <p className={'m-0 font-weight-bold'}>
                  {this.state.userInfo.first_name +
                    ' ' +
                    this.state.userInfo.last_name}
                </p>
                <p className={'m-0'}>
                  {this.state.userInfo.super_company_name}
                </p>
              </div>
            </div>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="round">
            {this.props.navbarData.map((elem, index) => {
              return (
                <MenuItem key={index} icon={elem.icon}>
                  <NavLink
                    to={elem.url}
                    activeClassName={'font-weight-bold active-link'}
                  >
                    {elem.title}
                  </NavLink>
                </MenuItem>
              );
            })}
          </Menu>
        </SidebarContent>

        <SidebarFooter className={'text-center'}>
          <Row className="sidebar-btn-wrapper py-3 px-3">
            <Col>
              <Switch
                onChange={this.handleToggleCollapse}
                checked={this.state.collapsed}
                checkedIcon={false}
                uncheckedIcon={false}
                height={10}
                width={40}
                handleDiameter={20}
                offColor={'#fff'}
                onColor={'#8ba0a9'}
              />
            </Col>

            <Col>
              <Link to={'/logout'} className="sidebar-btn">
                <ExitToAppIcon />
                <span> Log ud </span>
              </Link>
            </Col>
          </Row>
        </SidebarFooter>
      </ProSidebar>
    );
  }
}

const Avatar = styled.div`
  img {
    vertical-align: middle;
    width: 4em;
    height: 4em;
    border-radius: 50%;
  }
`;

export default NavigationBar;
