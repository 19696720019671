import { HomemadeDate } from '../../../utils/homemadeDate';
import { Page } from '../../../utils/storeTypes';
import { LocalDateTime } from '../../../utils/convertions';

export interface NewBankTransaction {
  fromBankAccountId: number;
  toBankAccountId?: number;
  amount: number;
  bankTransactionTypeId: number;
  invoiceId?: number;
  message: string;
  slip_paymentTypeCode?: number;
  slip_identificationNumber?: number;
  slip_creditorNumber?: number;
}

export interface BankTransaction {
  id: number;
  createdAt: HomemadeDate;
  created_at?: LocalDateTime;
  fromAfterBalance: number;
  toAfterBalance: number;
  fromBankAccountId: number;
  toBankAccountId: number;
  amount: number;
  message: string;
}

export enum IBankTransactionActionTypes {
  IBANKTRANSACTION_GET_ALL = '@@instructor/BankTransaction/GET_ALL',
  IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT = '@@instructor/BankTransaction/GET_ALL_TOANDFROM_ACCOUNT',
  IBANKTRANSACTION_TRANSACTION_CREATE = '@@instructor/BankTransaction/CREATE',
  IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_PAGE = '@@instructor/BankTransaction/GET_ALL_TOANDFROM_ACCOUNT_PAGE',
  IBANKTRANSACTION_TRANSACTION_SUCCESS = '@@instructor/BankTransaction/SUCCESS',
  IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_SUCCESS = '@@instructor/BankTransaction/GET_ALL_TOANDFROM_ACCOUNT_SUCCESS',
  IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_PAGE_SUCCESS = '@@instructor/BankTransaction/GET_ALL_TOANDFROM_ACCOUNT_PAGE_SUCCESS',
  IBANKTRANSACTION_TRANSACTION_ERROR = '@@instructor/BankTransaction/ERROR',
}

export interface IBankTransactionState {
  readonly loading: boolean;
  readonly loadingToFromAccount: boolean;
  readonly loadingToFromAccountPage: boolean;
  readonly errors?: string;
  readonly bankTransactions: [];
  readonly bankTransactionsToFromAccount: [];
  readonly bankTransactionsToFromAccountPages: Map<number, Page>; //Account id -> currentPage
}
