import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../../../store';
import { store } from '../../../../../store/store';
import {
  ProductType,
  productTypeEdit,
} from '../../../../../store/instructor/productType';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  productType: ProductType;
  visibilityToggleFunc: VisibilityToggleFuncType;
}

interface ThisCompState {
  editedProductTypeDetails: {
    code: string;
  };
}

class EditProductTypeModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      editedProductTypeDetails: {
        code: '',
      },
    };
  }

  onChange = e => {
    this.setState({
      editedProductTypeDetails: {
        ...this.state.editedProductTypeDetails,
        [e.target.name]: e.target.value,
      },
    });
  };

  onEditButtonClick = e => {
    e.preventDefault();

    if (this.state.editedProductTypeDetails.code !== '') {
      store.dispatch(
        productTypeEdit({
          id: this.props.productType.id,
          code: this.state.editedProductTypeDetails.code,
        }),
      );
    }
    this.props.visibilityToggleFunc();
  };

  renderContent = () => {
    return (
      <div className="px-3 pt-0 pb-3">
        <div>
          <Form>
            <Row>
              <Form.Label>Id</Form.Label>
              <Form.Control
                placeholder={this.props.productType.id.toString()}
                name={'name'}
                readOnly
              />
            </Row>
            <Row>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder={this.props.productType.code}
                name={'code'}
                defaultValue={this.props.productType.code}
                value={this.state.editedProductTypeDetails.code}
                onChange={this.onChange}
              />
            </Row>
          </Form>
        </div>
      </div>
    );
  };

  public render() {
    return (
      <>
        <p>Enter the new information and press save to confirm changes</p>
        <>{this.renderContent()}</>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onEditButtonClick}>
            Save
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ iProductType }: ApplicationState) => ({
  loading: iProductType.loading,
  errors: iProductType.errors,
});

export default connect(mapStateToProps)(EditProductTypeModalData);
