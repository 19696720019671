import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { IBankTransactionActionTypes, BankTransaction } from './types';
import {
  BankTransactionError,
  BankTransactionGetAll,
  BankTransactionSuccess,
  BankTransactionSuccessToFromAccount,
} from './actions';
import { BankTransactionSuccessToFromAccountPage } from './actions';
import {
  getEndpoint,
  getEndpointWithFilters,
  getTransactionsFromBankAccountId,
  postEndpoint,
} from '../../../utils/api';
import { convertLocalDateTimeToHomemadeDate } from '../../../utils/convertions';
import { usersError } from '../users';

function* handleGetAll() {
  try {
    const res = yield getEndpoint('bank-transaction');
    if (res.error) {
      yield put(BankTransactionError(res.error));
    } else {
      let bankTransactions: BankTransaction[] = [];
      res.data.forEach(elem => {
        let parsedBankTransaction: BankTransaction = {
          fromBankAccountId: elem.from_bank_account_id,
          toBankAccountId: elem.to_bank_account_id,
          amount: elem.amount,
          createdAt: convertLocalDateTimeToHomemadeDate(elem.created_at),
          id: elem.id,
          fromAfterBalance: elem.bank_account_from_balance,
          toAfterBalance: elem.bank_account_to_balance,
          message: elem.message,
        };
        bankTransactions.push(parsedBankTransaction);
      });
      yield put(BankTransactionSuccess(bankTransactions));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAllToFromAccount(action) {
  try {
    const res = yield getTransactionsFromBankAccountId(
      action.payload.accountId,
    );
    if (res.error) {
      yield put(BankTransactionError(res.error));
    } else {
      let bankTransactions: BankTransaction[] = [];
      res.data.forEach(elem => {
        let parsedBankTransaction: BankTransaction = {
          fromBankAccountId: elem.from_bank_account_id,
          toBankAccountId: elem.to_bank_account_id,
          amount: elem.amount,
          createdAt: convertLocalDateTimeToHomemadeDate(elem.created_at),
          id: elem.id,
          fromAfterBalance: elem.bank_account_from_balance,
          toAfterBalance: elem.bank_account_to_balance,
          message: elem.message,
        };
        bankTransactions.push(parsedBankTransaction);
      });
      yield put(BankTransactionSuccessToFromAccount(bankTransactions));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAllToFromAccountPage(action) {
  try {
    const search = action.payload.searchQuery;
    const after = action.payload.startDate;
    const before = action.payload.endDate;

    const res = yield getEndpointWithFilters('bank-transaction/page', {
      tofrom_bank_account_id: action.payload.accountId,
      size: action.payload.pageSize,
      page: action.payload.page,
      sort_by: action.payload.sort_by,
      descending: action.payload.descending,
      ...(search && { search }),
      ...(after && { after }),
      ...(before && { before }),
    });
    if (res.error) {
      yield put(BankTransactionError(res.error));
    } else {
      let bankTransactions: BankTransaction[] = [];
      res.data.items.forEach(elem => {
        let parsedBankTransaction: BankTransaction = {
          fromBankAccountId: elem.from_bank_account_id,
          toBankAccountId: elem.to_bank_account_id,
          amount: elem.amount,
          createdAt: convertLocalDateTimeToHomemadeDate(elem.created_at),
          id: elem.id,
          fromAfterBalance: elem.bank_account_from_balance,
          toAfterBalance: elem.bank_account_to_balance,
          message: elem.message,
        };
        bankTransactions.push(parsedBankTransaction);
      });
      yield put(
        BankTransactionSuccessToFromAccountPage({
          accountId: action.payload.accountId,
          currentPage: {
            items: bankTransactions,
            total_pages: res.data.total_pages,
            total_items: res.data.total_items,
            page_size: res.data.page_size,
            current_page: res.data.current_page,
          },
        }),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCreateNew(action) {
  try {
    const data = action.payload.newTransaction;
    let convertedNewTransaction;
    if (data.bankTransactionTypeId === 1) {
      convertedNewTransaction = {
        from_bank_account_id: data.fromBankAccountId,
        to_bank_account_id: data.toBankAccountId,
        amount: data.amount,
        bank_transaction_type_id: data.bankTransactionTypeId,
        invoice_id: data.invoiceId,
        message: data.message,
      };
    } else if (data.bankTransactionTypeId === 2) {
      convertedNewTransaction = {
        from_bank_account_id: data.fromBankAccountId,
        to_bank_account_id: data.toBankAccountId,
        amount: data.amount,
        bank_transaction_type_id: data.bankTransactionTypeId,
        message: data.message,
      };
    } else if (data.bankTransactionTypeId === 3) {
      convertedNewTransaction = {
        from_bank_account_id: data.fromBankAccountId,
        amount: data.amount,
        message: data.message,
        bank_transaction_type_id: data.bankTransactionTypeId,
        slip_payment_type: data.slip_paymentTypeCode,
        slip_identification_number: data.slip_identificationNumber,
        slip_creditor_number: data.slip_creditorNumber,
      };
    } else {
      throw Error;
    }

    const res = yield postEndpoint('bank-transaction', convertedNewTransaction);

    if (res.error) {
      yield put(usersError(res.error));
    } else {
      yield put(BankTransactionGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchBankTransaction() {
  yield takeEvery(
    IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL,
    handleGetAll,
  );
  yield takeEvery(
    IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT,
    handleGetAllToFromAccount,
  );
  yield takeEvery(
    IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_PAGE,
    handleGetAllToFromAccountPage,
  );
  yield takeEvery(
    IBankTransactionActionTypes.IBANKTRANSACTION_TRANSACTION_CREATE,
    handleCreateNew,
  );
}

function* iBankTransactionSaga() {
  yield all([fork(watchBankTransaction)]);
}

export { iBankTransactionSaga };
