import * as React from 'react';
import { store } from '../../../../store/store';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Company } from '../../../../store/instructor/company';
import { BankAccount } from '../../../../store/instructor/bank';
import {
  NewTaxDeclaration,
  TaxAdd,
  TaxDeclarationType,
} from '../../../../store/instructor/tax';
import {
  addThousandDots,
  removeDotsFromString,
} from '../../../../utils/convertions';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  thisUserCompanyId: number;
  taxCompany: Company;
  userCompanyBankAccounts: BankAccount[];
  selectedMonth: number;
  selectedYear: number;
}

interface ThisCompState {
  type: TaxDeclarationType;
  enteredData: {
    laborMC: string;
    aTax: string;
  };
  accountFrom: number;
  totalTaxAmountEntered: string;
  localErrors: {
    laborMC: string | null;
    aTax: string | null;
    accountFrom: string | null;
    totalAmount: string | null;
  };
}

//A-skat og AM-bidrag
class DeclareATaxAndLaborMCModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      type: TaxDeclarationType.A_AM_TAX,
      enteredData: {
        laborMC: '0',
        aTax: '0',
      },
      accountFrom: -1,
      totalTaxAmountEntered: '0',
      localErrors: {
        laborMC: null,
        aTax: null,
        accountFrom: null,
        totalAmount: null,
      },
    };
  }

  onChange = e => {
    const re = /^(-)?(\d*(\.\d{2,4})*)$/; //allows: space | integer dotted per thousand | integer
    if (!re.test(e.target.value)) {
      return;
    } else {
      if (e.target.name === 'accountFrom') {
        this.setState({
          accountFrom: parseInt(e.target.value),
        });
      } else {
        this.setState(
          {
            enteredData: {
              ...this.state.enteredData,
              [e.target.name]: addThousandDots(
                removeDotsFromString(e.target.value),
              ),
            },
          },
          () => {
            this.recalculateTotalTaxAmount();
          },
        );
      }
    }
  };

  recalculateTotalTaxAmount() {
    const error = this.validate();
    if (error.aTax === null && error.laborMC === null) {
      //NO ERRORS -> calculate
      this.setState({
        totalTaxAmountEntered: addThousandDots(
          (
            parseInt(removeDotsFromString(this.state.enteredData.aTax)) +
            parseInt(removeDotsFromString(this.state.enteredData.laborMC))
          ).toString(),
        ).toString(),
      });
    } else {
      this.setState({ totalTaxAmountEntered: 'Ej Muligt' });
    }
  }

  validate = () => {
    let errors: {
      aTax: string | null;
      laborMC: string | null;
      accountFrom: string | null;
      totalAmount: string | null;
    } = {
      aTax: null,
      laborMC: null,
      accountFrom: null,
      totalAmount: null,
    };

    if (this.state.enteredData.aTax === '') {
      errors.aTax = 'Arbejdsmarkedsbidrag skal være et tal.';
    }
    if (this.state.enteredData.laborMC === '') {
      errors.laborMC = 'A-skat skal være et tal.';
    }

    if (this.state.accountFrom === -1) {
      errors.accountFrom = 'Vælg venligst en konto.';
    }

    if (isNaN(parseInt(this.state.totalTaxAmountEntered))) {
      errors.totalAmount = 'Totalt beløb er ikke gyldigt.';
    }

    return errors;
  };

  onSubmitButtonClick = e => {
    e.preventDefault();
    //validates the entered data (client-side)
    const errors = this.validate();
    this.setState({
      localErrors: {
        aTax: errors.aTax,
        laborMC: errors.laborMC,
        accountFrom: errors.accountFrom,
        totalAmount: errors.totalAmount,
      },
    });

    if (
      errors.aTax === null &&
      errors.laborMC === null &&
      errors.accountFrom === null &&
      errors.totalAmount === null
    ) {
      //At this point, all values are an int
      if (this.props.taxCompany.bank_accounts.length < 1) {
        throw new Error('The tax company has no bank accounts!');
      } else {
        const declaration: NewTaxDeclaration = {
          company_id: this.props.thisUserCompanyId,
          bank_account_id: this.state.accountFrom,
          target_month: this.props.selectedMonth,
          target_year: this.props.selectedYear,
          type: TaxDeclarationType.A_AM_TAX,
          amount: parseInt(
            removeDotsFromString(this.state.totalTaxAmountEntered),
          ),
          details: {
            labor_mc: parseInt(
              removeDotsFromString(this.state.enteredData.laborMC),
            ),
            a_tax: parseInt(removeDotsFromString(this.state.enteredData.aTax)),
          },
        };

        store.dispatch(TaxAdd(declaration));
        this.props.visibilityToggleFunc();
      }
    }
  };

  //The current from account is not rendered as an option
  renderToAccountOptions = (allAccounts: BankAccount[]) => {
    const fromAccountRemoved: BankAccount[] = [...allAccounts];
    const removeIndex: number = allAccounts.findIndex(
      elem => elem.id === this.state.accountFrom,
    );
    if (removeIndex !== -1) {
      fromAccountRemoved.splice(removeIndex, 1);
    }

    return this.renderAccountOptions(fromAccountRemoved);
  };

  renderAccountOptions = (allAccounts: BankAccount[]) => {
    return (
      <>
        {allAccounts.map((account, index) => {
          return (
            <option key={index} value={account.id}>
              {account.name}
            </option>
          );
        })}
      </>
    );
  };

  /** This function is used when a field is un-focused/blur.
   If the value is empty, it is it to 0. */
  onEnteredDataTaxFieldBlur = e => {
    if (e.target.value === '') {
      //The value of the field should be set to 0
      this.setState(
        {
          enteredData: {
            ...this.state.enteredData,
            [e.target.name]: '0',
          },
        },
        () => {
          this.recalculateTotalTaxAmount();
        },
      );
    }
  };

  public render() {
    return <div>{this.renderContent()}</div>;
  }

  renderContent() {
    const activeCompanyAccounts: BankAccount[] = this.props.userCompanyBankAccounts.filter(
      elem => elem.isActive,
    );

    return (
      <>
        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-7">
              <Form.Label>Arbejdsmarkedsbidrag:</Form.Label>
            </div>
            <div className="col-sm-4">
              <Form.Control
                placeholder=""
                name={'laborMC'}
                value={this.state.enteredData.laborMC}
                onChange={this.onChange}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
          <Row>
            <div className="col-sm-7">
              <Form.Label>A-skat:</Form.Label>
            </div>
            <div className="col-sm-4">
              <Form.Control
                placeholder=""
                name={'aTax'}
                value={this.state.enteredData.aTax}
                onChange={this.onChange}
                onBlur={this.onEnteredDataTaxFieldBlur}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-7">
              <Form.Label className={'font-weight-bold'}>
                <i>Afgiftsbeløb i alt</i>:
              </Form.Label>
            </div>
            <div className="col-sm-4">
              <Form.Control
                placeholder=""
                name={'totalTaxAmountEntered'}
                value={this.state.totalTaxAmountEntered}
                disabled={true}
              />
            </div>
            <div className="col-sm-1">
              <Form.Label>,00</Form.Label>
            </div>
          </Row>
        </div>

        <div className="pt-0 pb-4">
          <Row>
            <div className="col-sm-5">
              <Form.Label>Fra konto</Form.Label>
            </div>
            <div className="col-sm-7">
              <Col sm="auto">
                <Form.Control
                  as="select"
                  defaultValue="Vælg.."
                  name={'accountFrom'}
                  value={this.state.accountFrom}
                  onChange={this.onChange}
                >
                  <option value={-1}>Vælg..</option>
                  {this.renderAccountOptions(activeCompanyAccounts)}
                </Form.Control>
              </Col>
            </div>
          </Row>
        </div>

        <ErrorDiv>
          {this.state.localErrors.laborMC}
          {this.state.localErrors.aTax}
          {this.state.localErrors.accountFrom}
          {this.state.localErrors.totalAmount}
        </ErrorDiv>

        <Button variant="success" onClick={e => this.onSubmitButtonClick(e)}>
          Indberet
        </Button>
      </>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

const mapStateToProps = ({ userInfo }: ApplicationState) => ({
  thisUserCompanyId: userInfo.userSuperCompanyId,
});

export default connect(mapStateToProps)(DeclareATaxAndLaborMCModalData);
