import React from 'react';
import { Add, Close } from '@material-ui/icons';
import {
  createRelationBetweenCompanies,
  removeRelationBetweenCompanies,
} from '../../../utils/api';
import { useMutation, useQueryClient } from 'react-query';
import { CompanyWithRelation } from './addRelationToCompanyModalContent';

interface PropsFromParent {
  company: CompanyWithRelation;
  superCompany: CompanyWithRelation;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyRelationRow: React.FC<PropsFromParent> = props => {
  const queryClient = useQueryClient();
  const { mutate: addRelation } = useMutation(createRelationBetweenCompanies, {
    onMutate: () => {
      props.setLoading(true);
    },
    onSuccess: () => {
      queryClient.refetchQueries('allCompanies').then(() => {
        props.setLoading(false);
      });
    },
  });

  const { mutate: removeRelation } = useMutation(
    () =>
      removeRelationBetweenCompanies(props.superCompany.id, props.company.id),
    {
      onMutate: () => {
        props.setLoading(true);
      },
      onSuccess: data => {
        queryClient.refetchQueries('allCompanies').then(() => {
          props.setLoading(false);
        });
      },
    },
  );

  const handleButtonClick = (type: 'remove' | 'add') => {
    switch (type) {
      case 'add':
        addRelation({
          super_company_id: props.superCompany.id,
          company_id: props.company.id,
        });
        return 0;
      case 'remove':
        removeRelation();
        return 0;
    }
  };

  return (
    <div className={'row my-2 py-2 bg-light align-items-center'}>
      <div className="col-md-8">{props.superCompany.name}</div>

      <div className="col-md-4 text-right">
        {props.company.relations.includes(props.superCompany.id) ? (
          <button
            className="btn btn-danger"
            onClick={() => handleButtonClick('remove')}
          >
            <Close /> Fjern
          </button>
        ) : (
          <button
            className="btn btn-success"
            onClick={() => handleButtonClick('add')}
          >
            <Add /> Tilføj
          </button>
        )}
      </div>
    </div>
  );
};

export default CompanyRelationRow;
