import React from 'react';
import { UserTypes } from '../store/user/userAuthentication';
import { Redirect } from 'react-router';

export const userRedirect = (usertype: UserTypes) => {
  switch (usertype) {
    case UserTypes.INSTRUCTOR:
      return <Redirect to={'/instructor/dashboard'} />;
    case UserTypes.STUDENT:
      return <Redirect to={'/student/dashboard'} />;
    default:
      return;
  }
};
