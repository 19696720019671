export interface Theme {
  title: string;

  colors: {
    primary: string;
    secondary: string;
    highlight: string;
    highlight_secondary: string;
    background: string;
    text: string;
    success: string;
  };
}

export enum ThemeTypes {
  THEME_SWITCH = '@@theme/switch',
}

export interface ThemeState {
  readonly theme: Theme;
}
