import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../../store/store';
import { ApplicationState } from '../../../../store';
import InstructorModal from '../../../../components/modal/instructorModal';
import AddIcon from '@material-ui/icons/Add';
import {
  ProductType,
  productTypeDelete,
} from '../../../../store/instructor/productType';
import AddProductTypeModalData from './modals/addProductTypeModalData';
import EditProductTypeModalData from './modals/editProductTypeModal';
import styled from 'styled-components';
import CustomTable from '../../../../components/table/customTable';
import { confirmAlert } from 'react-confirm-alert';

interface PropsFromParent {
  errors?: string;
  allProductTypes: ProductType[];
}

interface StateProps {
  modalAddVisible: boolean;
  modalEditVisible: boolean;
  selectedProductType: ProductType;
  tableData: ProductType[];
  tableHeader: any[];
}

class ProductTypeContent extends React.Component<PropsFromParent, StateProps> {
  state = {
    modalEditVisible: false,
    modalAddVisible: false,
    selectedProductType: {
      id: -1,
      code: '',
    },
    tableData: [],
    tableHeader: [
      { title: 'ID', field: 'id', editable: 'never' },
      { title: 'Navn', field: 'code', editable: 'never' },
    ],
  };

  componentDidMount() {
    this.setState({ tableData: this.props.allProductTypes });
  }

  componentDidUpdate(prevProps: Readonly<PropsFromParent>) {
    if (prevProps.allProductTypes !== this.props.allProductTypes) {
      this.setState({ tableData: this.props.allProductTypes });
    }
  }

  handleModalAddVisibility = () => {
    this.setState({ modalAddVisible: !this.state.modalAddVisible });
  };

  handleModalEditVisibility = () => {
    this.setState({ modalEditVisible: !this.state.modalEditVisible });
  };

  setSelectedProductType = (productType: ProductType) => {
    this.setState({ selectedProductType: productType });
  };

  onDeleteClick = (productType: ProductType) => {
    store.dispatch(productTypeDelete(productType.id));
  };

  confirmDeleteProductType = (productType: ProductType) => {
    confirmAlert({
      title: 'Confirm deletion of product type: ' + productType.code + '?',
      message: 'Are you sure you want to delete this product type?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.onDeleteClick(productType),
        },
        {
          label: 'No',
          onClick: () => void 0,
        },
      ],
    });
  };

  renderContent = () => {
    return (
      <>
        <CustomTable
          data={this.state.tableData}
          headers={this.state.tableHeader}
          removeBorder={true}
          titleComponent={
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                this.handleModalAddVisibility();
              }}
            >
              <AddIcon /> Tilføj ny produkt type
            </button>
          }
          actions={[
            {
              icon: 'edit',
              tooltip: 'Rediger produkt type',
              onClick: (event, productType) => {
                this.setSelectedProductType(productType);
                this.handleModalEditVisibility();
              },
            },
            {
              icon: 'delete',
              tooltip: 'Slet produkt type',
              onClick: (event, productType) =>
                this.confirmDeleteProductType(productType),
            },
          ]}
        />
        <ErrorDiv>{this.props.errors}</ErrorDiv>
      </>
    );
  };

  public render() {
    return (
      <>
        {this.renderContent()}

        <InstructorModal
          modalState={this.state.modalAddVisible}
          showFunc={this.handleModalAddVisibility}
          titleHeader="Add product"
        >
          <AddProductTypeModalData
            visibilityToggleFunc={this.handleModalAddVisibility}
            allProductTypes={this.props.allProductTypes}
          />
        </InstructorModal>
        <InstructorModal
          modalState={this.state.modalEditVisible}
          showFunc={this.handleModalEditVisibility}
          titleHeader="Edit product"
        >
          <EditProductTypeModalData
            productType={this.state.selectedProductType}
            visibilityToggleFunc={this.handleModalEditVisibility}
          />
        </InstructorModal>
      </>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

const mapStateToProps = ({ iProductType }: ApplicationState) => ({
  errors: iProductType.errors,
});

export default connect(mapStateToProps)(ProductTypeContent);
