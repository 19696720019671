import { Company, CompanyType } from '../store/instructor/company';
import { HomemadeDate } from './homemadeDate';
import {
  BankAccount,
  BankAccountTypeNumber,
  BankAccountTypeString,
} from '../store/instructor/bank';
import { User } from '../store/instructor/users';
import { validateDoubleWithCommaOrInteger } from './validators';
import { AxiosError } from 'axios';

/** Returns string: the name of the company which id matches the given integer.
 * If none is found: returns the given id as a string. */
export function convertCompanyIdToName(
  allCompanies: Company[],
  suppId: number,
) {
  let supp: Company[] = allCompanies.filter(elem => elem.id === suppId);
  return supp[0] ? supp[0].name : suppId.toString();
}

/** Returns string: the name of the user which id matches the given integer.
 * If none is found: returns the given id as a string. */
export function convertUserIdToName(allUsers: User[], userId: number): string {
  const foundUser: User | undefined = allUsers.find(elem => elem.id === userId);
  if (foundUser !== undefined) {
    return foundUser.first_name + ' ' + foundUser.last_name;
  } else {
    return userId.toString();
  }
}

export function getCompanyFromId(allCompanies: Company[], companyId: number) {
  return allCompanies.find(elem => elem.id === companyId);
}

export function getCompanyNameFromId(
  allCompanies: Company[],
  companyId: number,
) {
  let foundComp = allCompanies.find(comp => comp.id === companyId);
  if (foundComp !== undefined) {
    return foundComp.name;
  } else {
    return 'Not available';
  }
}

export function convertLocalDateTimeToHomemadeDate(
  ldt: LocalDateTime,
): HomemadeDate {
  return new HomemadeDate(
    ldt.year,
    ldt.monthValue - 1,
    ldt.dayOfMonth,
    ldt.hour,
    ldt.minute,
    ldt.second,
  );
}

function numberPaddingWithZero(data: number | string) {
  return ('0' + data).slice(-2);
}

export function axiosErrorToString(err: AxiosError) {
  let formattedMessage =
    err.response?.status +
    ': ' +
    err.response?.data.error +
    ' (' +
    err.response?.config.method +
    ' @ ' +
    err.response?.config.url +
    ')';

  return formattedMessage;
}

export function convertLocalDateTimeToString(ldt: LocalDateTime) {
  return `${numberPaddingWithZero(ldt.dayOfMonth)}-${numberPaddingWithZero(
    ldt.monthValue,
  )}-${ldt.year}`;
}

export function convertCompanyTypeIdToEnum(id: number): CompanyType {
  if (id === 1) {
    return CompanyType.SUPER;
  } else if (id === 2) {
    return CompanyType.NORMAL;
  } else {
    throw Error;
  }
}

export interface LocalDateTime {
  nano: number;
  year: number;
  monthValue: number;
  dayOfMonth: number;
  hour: number;
  minute: number;
  second: number;
  dayOfWeek: string;
  dayOfYear: number;
  month: string;
  chronology: {
    id: string;
    calendarType: string;
  };
}

export enum ArchiveType {
  DEFAULT = 1,
  PROCESSED = 2,
}

export function convertBankAccountIdToName(
  allBankAccounts: BankAccount[],
  bankAccountId: number,
) {
  let bankAccount: BankAccount[] = allBankAccounts.filter(
    elem => elem.id === bankAccountId,
  );
  return bankAccount[0] ? bankAccount[0].name : bankAccountId.toString();
}

export function getBankAccountTypeIdFromString(
  input: string,
): BankAccountTypeNumber {
  if (input === BankAccountTypeString.COMPANY) {
    return BankAccountTypeNumber.COMPANY;
  } else if (input === BankAccountTypeString.STUDENT) {
    return BankAccountTypeNumber.STUDENT;
  } else {
    throw new Error('Unknown BankAccountType: string');
  }
}

export function parseDanishDouble(input: string): number {
  if (validateDoubleWithCommaOrInteger(input) === '') {
    const positionOfComma: number = input.indexOf(',');
    if (positionOfComma === -1) {
      return parseInt(input);
    } else {
      const strings: string[] = input.split(',');
      if (strings.length !== 2) {
        throw new Error(
          'Convertions: parseDanishDouble: the given number contained more then one comma!',
        );
      } else {
        return (
          parseInt(strings[0]) +
          parseInt(strings[1]) * Math.pow(0.1, strings[1].length)
        );
      }
    }
  } else {
    throw new Error('Convertions: parseDanishDouble: is not a valid number!');
  }
}

/** Formats and returns the input number with the following format: xx.xxx,xx (two decimals and EU use of comma and dot)*/
export function floatToDKFloatString(input: number): string {
  return input
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

/** Removes non-numeric values from the given string. */
export function removeNonNumeric(num: string): string {
  return num.toString().replace(/[^0-9]/g, '');
}

/** Remove dots from string. */
export function removeDotsFromString(input: string): string {
  return input.toString().replace(/\./g, '');
}

/** Adds thousand dots to given string. 12333 -> 12.333. */
export function addThousandDots(input: string): string {
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
