import { User } from '../../../../store/instructor/users';
import * as React from 'react';
import { store } from '../../../../store/store';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import {
  changePassword,
  PasswordChangeRequest,
} from '../../../../store/user/userAuthentication';
import styled from 'styled-components';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  userToEdit: User;
  userEditorId: number;
}

interface ThisCompState {
  oldPassword: string;
  password1: string;
  password2: string;
  passwordNotMatching: string;
}

class ChangePasswordModalData extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      oldPassword: '',
      password1: '',
      password2: '',
      passwordNotMatching: '',
    };
  }

  onChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  //TODO-future: display error msgs from api
  onSubmitButtonClick = e => {
    e.preventDefault();

    if (this.state.password1 !== this.state.password2) {
      this.setState({
        passwordNotMatching: 'De to indtastede kodeord er ikke ens',
      });
    } else {
      this.setState({ passwordNotMatching: '' });

      let passwordChangeData: PasswordChangeRequest;
      if (this.isInstructorChangingOwnPassword()) {
        passwordChangeData = {
          password_old: this.state.oldPassword,
          password_first: this.state.password1,
          password_second: this.state.password2,
        };
      } else {
        passwordChangeData = {
          password_first: this.state.password1,
          password_second: this.state.password2,
        };
      }

      store.dispatch(
        changePassword(this.props.userToEdit.id, passwordChangeData),
      );

      this.props.visibilityToggleFunc();
    }
  };

  isInstructorChangingOwnPassword = (): boolean => {
    return (
      this.props.userToEdit.user_type_id === 1 &&
      this.props.userToEdit.id === this.props.userEditorId
    );
  };

  public render() {
    return (
      <>
        <p>
          Indtast de ønskede oplysninger nedenfor, og tryk på tilføj for at
          bekræfte
        </p>
        <div className="px-3 pt-0 pb-3">
          <div>
            <Form>
              <Row>
                {this.isInstructorChangingOwnPassword() ? (
                  <>
                    <Form.Label>Nuværende kodeord</Form.Label>
                    <Form.Control
                      placeholder={'Nuværende kodeord'}
                      name={'oldPassword'}
                      value={this.state.oldPassword}
                      type={'password'}
                      onChange={this.onChange}
                    />
                  </>
                ) : null}
                <Form.Label>Kodeord</Form.Label>
                <Form.Control
                  placeholder={'Kodeord'}
                  name={'password1'}
                  value={this.state.password1}
                  type={'password'}
                  onChange={this.onChange}
                />
              </Row>
              <Row>
                <Form.Label>Gentag kodeord</Form.Label>
                <Form.Control
                  placeholder={'kodeord'}
                  name={'password2'}
                  value={this.state.password2}
                  type={'password'}
                  onChange={this.onChange}
                />
              </Row>
            </Form>
          </div>
        </div>
        <ErrorDiv>{this.state.passwordNotMatching}</ErrorDiv>
        <Modal.Footer>
          <Button variant="primary" onClick={this.onSubmitButtonClick}>
            Gem
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const ErrorDiv = styled.div`
  color: red;
`;

const mapStateToProps = ({ iUsers }: ApplicationState) => ({
  loading: iUsers.loading,
  errors: iUsers.errors,
});

export default connect(mapStateToProps)(ChangePasswordModalData);
