import { action } from 'typesafe-actions';
import {
  IMessagesActionTypes,
  Message,
  MessageNew,
  MessageStatusType,
} from './types';

export const messagesGetAll = () =>
  action(IMessagesActionTypes.IMESSAGES_GET_ALL);
export const messageSetStatus = (newStatus: MessageStatusType, msg: Message) =>
  action(IMessagesActionTypes.IMESSAGES_SET_STATUS, { newStatus, msg });
export const messageCreate = (newMsg: MessageNew) =>
  action(IMessagesActionTypes.IMESSAGES_CREATE, { newMsg });
export const messagesSuccess = (data: Message[]) =>
  action(IMessagesActionTypes.IMESSAGES_SUCCESS, data);
export const messagesError = (message: string) =>
  action(IMessagesActionTypes.IMESSAGES_ERROR, message);
