import {
  getEndpointWithFilters,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ArchiveType,
  convertLocalDateTimeToHomemadeDate,
} from '../../../utils/convertions';
import {
  deliveryError,
  deliveryFromSuccess,
  deliveryGetAllToAndFromCompanyId,
  deliverySuccess,
  deliverySuccessFromCompanyPage,
  deliverySuccessToCompanyActivePage,
  deliverySuccessToCompanyArchivePage,
} from './actions';
import { Delivery, IDeliveryActionTypes } from './types';
import { OrderItem } from '../orderInvoice';
import { companyProductRemoveAmount } from '../company-products';
import { AxiosResponse } from 'axios';

function* getAllToAndFromCompanyId(action) {
  try {
    const res = yield getEndpointWithFilters('delivery', {
      tofrom_company_id: action.payload.companyId,
    });

    if (res.error) {
      yield put(deliveryError(res.error));
    } else {
      yield put(deliverySuccess(getParsedApiDataDeliveries(res.data)));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* getAllFromCompanyId(action) {
  try {
    const res = yield getEndpointWithFilters('delivery', {
      from_company_id: action.payload.companyId,
    });

    if (res.error) {
      yield put(deliveryError(res.error));
    } else {
      yield put(deliveryFromSuccess(getParsedApiDataDeliveries(res.data)));
    }
  } catch (err) {
    console.log(err.message);
  }
}

export function getParsedApiDataDeliveries(data): Delivery[] {
  let deliveries: Delivery[] = [];
  data.forEach(elem => {
    let parsedOrderItems: OrderItem[] = [];
    elem.items.forEach(elem => {
      const orderItem: OrderItem = {
        id: elem.id,
        product_id: elem.product_id,
        cost: elem.cost,
        product_quantity: elem.product_quantity,
        navision_id: elem.navision_id,
        is_service: elem.is_service,
        is_vax_free: elem.is_vax_free,
        total_cost: elem.total_cost,
        total_vax_amount: elem.total_vax_amount,
      };
      parsedOrderItems.push(orderItem);
    });

    let parsedDelivery: Delivery = {
      ...elem,
      delivery_date: convertLocalDateTimeToHomemadeDate(elem.delivery_date),
      items: parsedOrderItems,
    };
    deliveries.push(parsedDelivery);
  });

  return deliveries;
}

function* handleGetFromCompanyPage(action) {
  try {
    const search = action.payload.searchQuery;
    const after = action.payload.startDate;
    const before = action.payload.endDate;
    const res = yield getEndpointWithFilters('delivery/page', {
      from_company_id: action.payload.companyId,
      size: action.payload.pageSize,
      page: action.payload.page,
      descending: action.payload.descending,
      ...(search && { search }),
      ...(after && { after }),
      ...(before && { before }),
    });

    if (res.error) {
      yield put(deliveryError(res.error));
    } else {
      yield put(
        deliverySuccessFromCompanyPage({
          items: getParsedApiDataDeliveries(res.data.items),
          total_pages: res.data.total_pages,
          total_items: res.data.total_items,
          page_size: res.data.page_size,
          current_page: res.data.current_page,
        }),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetToCompanyArchiveTypePage(action) {
  try {
    const search = action.payload.searchQuery;
    const after = action.payload.startDate;
    const before = action.payload.endDate;
    const res = yield getEndpointWithFilters('delivery/page', {
      to_company_id: action.payload.companyId,
      archive_type: action.payload.archiveType,
      size: action.payload.pageSize,
      page: action.payload.page,
      descending: action.payload.descending,
      is_delivered: action.payload.isDelivered,
      ...(search && { search }),
      ...(after && { after }),
      ...(before && { before }),
    });

    if (res.error) {
      yield put(deliveryError(res.error));
    } else {
      switch (action.payload.archiveType) {
        case ArchiveType.DEFAULT:
          yield put(
            deliverySuccessToCompanyActivePage({
              items: getParsedApiDataDeliveries(res.data.items),
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
              page_size: res.data.page_size,
              current_page: res.data.current_page,
            }),
          );
          break;
        case ArchiveType.PROCESSED:
          yield put(
            deliverySuccessToCompanyArchivePage({
              items: getParsedApiDataDeliveries(res.data.items),
              total_pages: res.data.total_pages,
              total_items: res.data.total_items,
              page_size: res.data.page_size,
              current_page: res.data.current_page,
            }),
          );
          break;
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCreate(action) {
  try {
    const filteredArray = action.payload.newDelivery.products.filter(
      item => item[2] === false,
    );
    const productsWithoutServices = filteredArray.map(item => item.slice(0, 2));
    const productsWithoutThirdElement = action.payload.newDelivery.products.map(
      item => item.slice(0, 2),
    );
    const formattedDeliveryRequestObject = {
      ...action.payload.newDelivery,
      products: productsWithoutThirdElement,
    };

    const res: AxiosResponse<Delivery> = yield postEndpoint(
      'delivery',
      formattedDeliveryRequestObject,
    );

    if (res.status !== 200) {
      yield put(
        deliveryError('An error occurred during creation of new delivery'),
      );
    } else {
      yield put(deliveryGetAllToAndFromCompanyId(action.payload.fromCompanyId));

      // Successful delivery -> Remove items from our storage
      if (action.payload.newDelivery.products) {
        for (const product of productsWithoutServices) {
          yield put(
            companyProductRemoveAmount(
              action.payload.newDelivery.delivery_company_id,
              action.payload.fromCompanyId,
              res.data.delivery_id,
              product[0], // Product id
              product[1], // Amount
              'remove',
            ),
          );
        }
      }
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleSetArchiveType(action) {
  try {
    const res = yield putEndpoint(
      'delivery',
      {
        archive_type_id: action.payload.newArchiveTypeId,
      },
      action.payload.deliveryId,
    );

    if (res.error) {
      yield put(deliveryError(res.error));
    } else {
      yield put(
        deliveryGetAllToAndFromCompanyId(action.payload.currentUserCompanyId),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleSetAddedToStockStatus(action) {
  try {
    const res = yield putEndpoint(
      'delivery',
      { added_to_stock: action.payload.status },
      action.payload.deliveryId,
    );

    if (res.error) {
      yield put(deliveryError(res.error));
    } else {
      yield put(
        deliveryGetAllToAndFromCompanyId(action.payload.currentUserCompanyId),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchDelivery() {
  yield takeEvery(
    IDeliveryActionTypes.IDELIVERY_GET_ALL,
    getAllToAndFromCompanyId,
  );
  yield takeEvery(IDeliveryActionTypes.IDELIVERY_GET_FROM, getAllFromCompanyId);
  yield takeEvery(
    IDeliveryActionTypes.IDELIVERY_GET_ALL_FROM_COMP_PAGE,
    handleGetFromCompanyPage,
  );
  yield takeEvery(
    IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ACTIVE_PAGE,
    handleGetToCompanyArchiveTypePage,
  );
  yield takeEvery(
    IDeliveryActionTypes.IDELIVERY_GET_ALL_TO_COMP_ARCHIVED_PAGE,
    handleGetToCompanyArchiveTypePage,
  );
  yield takeEvery(IDeliveryActionTypes.IDELIVERY_CREATE, handleCreate);
  yield takeEvery(
    IDeliveryActionTypes.IDELIVERY_SET_ARCHIVE_TYPE,
    handleSetArchiveType,
  );
  yield takeEvery(
    IDeliveryActionTypes.IDELIVERY_SET_ADDEDTOSTOCK_STATUS,
    handleSetAddedToStockStatus,
  );
}

function* iDeliverySaga() {
  yield all([fork(watchDelivery)]);
}

export { iDeliverySaga };
