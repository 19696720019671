import * as React from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { store } from '../../../../store/store';
import { ApplicationState } from '../../../../store';
import { Company } from '../../../../store/instructor/company';
import CompanyTab from './secondaryTabs/companyTab';
import {
  Invoice,
  invoiceGetAll,
} from '../../../../store/instructor/orderInvoice';
import { Product, productGetAll } from '../../../../store/instructor/product';
import LoadingComponent from '../../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
  allCompanies: Company[];
  allInvoices: Invoice[];
  allProducts: Product[];
}

interface ThisCompState {
  selectedCompany: Company;
  currentSelectedCompanyId: number;
}

class CompanyOverview extends React.Component<PropsFromParent, ThisCompState> {
  state = {
    selectedCompany: {
      name: '',
      id: -1,
      payment_terms: -1,
      address: '',
      delivery_time: -1,
      bank_accounts: [],
      company_type_id: -1,
      company_type: '',
    },
    currentSelectedCompanyId: -1,
  };

  componentDidMount() {
    store.dispatch(invoiceGetAll());
    store.dispatch(productGetAll());
  }

  retrieveCompanyFromId = (id: number) => {
    return this.props.allCompanies.find(company => company.id === id);
  };

  onChange = e => {
    const selectedCompany: any = this.retrieveCompanyFromId(
      parseInt(e.target.value),
    );

    if (e.target.value !== -1) {
      this.setState({ selectedCompany: selectedCompany });
    }

    this.setState({
      currentSelectedCompanyId: selectedCompany.id,
    });
  };

  renderTab = (company: Company, index: number) => {
    const invoicesForThisCompany: Invoice[] = this.props.allInvoices.filter(
      elem => elem.company_to === company.id,
    );
    return (
      <Tab key={index} eventKey={company.name} title={company.name}>
        <CompanyTab
          company={company}
          invoices={invoicesForThisCompany}
          allCompanies={this.props.allCompanies}
        />
      </Tab>
    );
  };

  renderContent = () => {
    return (
      <>
        {this.state.currentSelectedCompanyId !== -1 ? (
          <>
            {this.props.loading ? (
              <div className={'d-flex justify-content-center'}>
                <LoadingComponent visible={this.props.loading} />
              </div>
            ) : (
              <Tabs>{this.renderTab(this.state.selectedCompany, 0)}</Tabs>
            )}
          </>
        ) : null}
      </>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          <>
            <Form>
              <Row>
                <Form.Label>Vælg virksomhed:</Form.Label>
                <Col sm="auto">
                  <Form.Control
                    as="select"
                    disabled={true}
                    defaultValue="Vælg.."
                    name={'currentSelectedCompanyId'}
                    value={this.state.currentSelectedCompanyId}
                    onChange={this.onChange}
                  >
                    <option value={-1}>Vælg..</option>
                    {this.props.allCompanies.map((elem, index) => {
                      return (
                        <option key={index} value={elem.id}>
                          {elem.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>
            </Form>
            {this.renderContent()}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iCompany,
  iInvoice,
  iProduct,
}: ApplicationState) => ({
  loading: iCompany.loading || iInvoice.loading || iProduct.loading,
  allCompanies: iCompany.companies,
  allInvoices: iInvoice.invoices,
  allProducts: iProduct.products,
});

export default connect(mapStateToProps)(CompanyOverview);
