import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { ApplicationState } from '../../../../store';
import { Company } from '../../../../store/instructor/company';
import { Delivery } from '../../../../store/instructor/delivery';
import { Product, productGetAll } from '../../../../store/instructor/product';
import { OrderItem } from '../../../../store/instructor/orderInvoice';
import { store } from '../../../../store/store';

type VisibilityToggleFuncType = () => void;

interface PropsFromParent {
  visibilityToggleFunc: VisibilityToggleFuncType;
  delivery: Delivery;
  allCompanies: Company[];
  allProducts: Product[];
}

class ViewDeliveryModalData extends React.Component<PropsFromParent> {
  componentDidMount() {
    store.dispatch(productGetAll());
  }

  renderProduct = (orderItem: OrderItem, index: number) => {
    const product = this.props.allProducts.find(
      elem => elem.id === orderItem.product_id,
    );
    return (
      <div
        key={index}
        className={'basket row align-items-center text-left p-2 bg-light my-1'}
      >
        <div className={'basket-item col-7'}>
          {product ? product.name : 'placeholder'} <br />
          Varenr: {product ? product.navision_id : 'placeholder'}
        </div>
        <div className={'basket-item col-4 text-center mx-3'}>
          bestilt/leveret: {orderItem.product_quantity}
        </div>
      </div>
    );
  };

  renderContent = () => {
    return (
      <Container className="px-2 pt-0 pb-2">
        <Row>
          <Col>Navision nr. fra rekvisition</Col>
          <Col>{this.props.delivery.requisition_navision_id}</Col>
        </Row>
        <Row>
          <Col>Leverings dato</Col>
          <Col>{this.props.delivery.delivery_date.getString()}</Col>
        </Row>
        <Row>
          <Col>Varer</Col>
          <Col>{this.props.delivery.items.map(this.renderProduct)}</Col>
        </Row>
      </Container>
    );
  };

  public render() {
    return (
      <>
        {this.renderContent()}
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.visibilityToggleFunc}>
            Luk
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

const mapStateToProps = ({ iProduct }: ApplicationState) => ({
  loading: iProduct.loading,
  errors: iProduct.errors,
  allProducts: iProduct.products,
});

export default connect(mapStateToProps)(ViewDeliveryModalData);
