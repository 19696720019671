import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import SalaryContent from './SalaryContent';

const SalaryOverviewPage: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Løn</title>
      </Helmet>
      <PageHeader className={'pb-3'}>
        <h2>Løn</h2>
      </PageHeader>

      <SalaryContent />
    </StudentPageLayout>
  );
};

const PageHeader = styled.div``;

export default connect()(SalaryOverviewPage);
