import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  usersError,
  usersGetAll,
  usersSuccess,
  usersSuccessInstructors,
  usersSuccessStudents,
} from './actions';
import { IUsersActionTypes } from './types';
import {
  deleteEndpoint,
  getEndpoint,
  getEndpointId,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';

function* handleEdit(action) {
  try {
    const res = yield putEndpoint(
      'user',
      action.payload.editedUser,
      action.payload.userId,
    );

    if (res.error) {
      yield put(usersError(res.error));
    } else {
      yield put(usersGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleDelete(action) {
  try {
    const res = yield deleteEndpoint('user', action.payload.userId);

    if (res.error) {
      yield put(usersError(res.error));
    } else {
      yield put(usersGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAll() {
  try {
    const res = yield getEndpoint('user');

    if (res.error) {
      yield put(usersError(res.error));
    } else {
      yield put(usersSuccess(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAllInstructors() {
  try {
    const res = yield getEndpointId('user/type', 1);

    if (res.error) {
      yield put(usersError(res.error));
    } else {
      yield put(usersSuccessInstructors(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAllStudents() {
  try {
    const res = yield getEndpointId('user/type', 2);

    if (res.error) {
      yield put(usersError(res.error));
    } else {
      yield put(usersSuccessStudents(res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleAdd(action) {
  try {
    const res = yield postEndpoint('user', action.payload.newUser);

    if (res.error) {
      yield put(usersError(res.error));
    } else {
      yield put(usersGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchUsers() {
  yield takeEvery(IUsersActionTypes.IUSERS_EDIT, handleEdit);
  yield takeEvery(IUsersActionTypes.IUSERS_DELETE, handleDelete);
  yield takeEvery(IUsersActionTypes.IUSERS_GET_ALL, handleGetAll);
  yield takeEvery(
    IUsersActionTypes.IUSERS_GET_ALL_INSTRUCTORS,
    handleGetAllInstructors,
  );
  yield takeEvery(
    IUsersActionTypes.IUSERS_GET_ALL_STUDENTS,
    handleGetAllStudents,
  );
  yield takeEvery(IUsersActionTypes.IUSERS_ADD, handleAdd);
}

function* iUsersSaga() {
  yield all([fork(watchUsers)]);
}

export { iUsersSaga };
