import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import AboutShippingCompanyTab from './tabs/companyInfo/aboutShippingCompanyTab';
import {
  Company,
  companyGetAll,
  companyGetDebtors,
  companyGetProducts,
} from '../../../store/instructor/company';
import DeliveryOverview, {
  ComponentSection,
  DeliveryOverviewType,
} from './tabs/delivery/deliveryOverview';
import { Product } from '../../../store/instructor/product';
import TwoStepDeliveryAndInvoice from './tabs/delivery/twoStepDeliveryAndInvoice';
import {
  CompanyIdProductsPair,
  companyProductsGetAll,
} from '../../../store/instructor/company-products';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  allCompanies: Company[];
  userCompanyId: number;
  companyProducts: Product[];
  debtorCompanies: Company[];
  allCompanyProductsPairs: CompanyIdProductsPair[];
}

class ShippingContent extends React.Component<PropsFromState> {
  componentDidMount() {
    store.dispatch(companyGetAll());
    store.dispatch(companyGetDebtors(this.props.userCompanyId));
    store.dispatch(companyGetProducts(this.props.userCompanyId));
    store.dispatch(companyProductsGetAll(this.props.userCompanyId));
  }

  renderContent = () => {
    return (
      <Tabs defaultActiveKey="newDelivery">
        <Tab eventKey="newDelivery" title="Ny forsendelse">
          <TwoStepDeliveryAndInvoice
            debtorCompanies={this.props.debtorCompanies}
            allCompanies={this.props.allCompanies}
            companyProducts={this.props.companyProducts}
            userCompanyId={this.props.userCompanyId}
            allCompanyProductsPairs={this.props.allCompanyProductsPairs}
          />
        </Tab>
        <Tab eventKey="sendDeliveries" title="Afsendte Leveringer">
          <DeliveryOverview
            componentSection={ComponentSection.SHIPPING}
            type={DeliveryOverviewType.OUTGOING}
            allCompanies={this.props.allCompanies}
            thisUserCompanyId={this.props.userCompanyId}
          />
        </Tab>
        <Tab eventKey="aboutShippingCompany" title="Om fragt virksomhed">
          <AboutShippingCompanyTab userCompanyId={this.props.userCompanyId} />
        </Tab>
      </Tabs>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  iInvoice,
  iCompany,
  userInfo,
  iDelivery,
  iCompanyProducts,
}: ApplicationState) => ({
  loading:
    iInvoice.loading ||
    iCompany.loading ||
    iDelivery.loading ||
    iDelivery.loadingDeliveriesFrom ||
    iCompanyProducts.loading,
  errors: iInvoice.errors,
  allCompanies: iCompany.companies,
  userCompanyId: userInfo.userSuperCompanyId,
  deliveriesFromThisCompany: iDelivery.deliveriesFrom,
  companyProducts: iCompany.companyProducts,
  debtorCompanies: iCompany.companyDebtors,
  allCompanyProductsPairs: iCompanyProducts.companyProducts,
});

export default connect(mapStateToProps)(ShippingContent);
