export enum FreightCategory {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  FRANCO = 'FRANCO',
}

export enum SuperCompany {
  EDP,
  MIXIT,
}

export interface FreightPriceAndCategory {
  category: FreightCategory;
  price: number;
}

export function getSuperCompanyFromCompanyName(companyName: string) {
  if (companyName === 'Ejendomspatruljen') {
    return SuperCompany.EDP;
  } else if (companyName === 'Mixit') {
    return SuperCompany.MIXIT;
  } else {
    throw Error;
  }
}

/** If this method returns 0 as the price, the selected freight type should
 * have been franco, and this method should not have been called! */
export function getFreightPriceAndCategory(
  totalPackageCost: number,
  company: SuperCompany,
): FreightPriceAndCategory {
  if (totalPackageCost < 0) {
    throw Error;
  }

  switch (company) {
    case SuperCompany.EDP:
      return getFreightPriceForEDP(totalPackageCost);
    case SuperCompany.MIXIT:
      return getFreightPriceForMixit(totalPackageCost);
  }
}

function getFreightPriceForEDP(totalPackageCost): FreightPriceAndCategory {
  if (totalPackageCost <= 1000) {
    return { category: FreightCategory.A, price: 50 };
  } else if (totalPackageCost <= 3000) {
    return { category: FreightCategory.B, price: 100 };
  } else if (totalPackageCost <= 5000) {
    return { category: FreightCategory.C, price: 150 };
  } else if (totalPackageCost <= 7000) {
    return { category: FreightCategory.D, price: 200 };
  } else {
    return { category: FreightCategory.FRANCO, price: 0 }; //franco, tax price is 80 ex tax, but not for the sender!
  }
}

function getFreightPriceForMixit(totalPackageCost): FreightPriceAndCategory {
  if (totalPackageCost <= 3000) {
    return { category: FreightCategory.A, price: 50 };
  } else if (totalPackageCost <= 5000) {
    return { category: FreightCategory.B, price: 100 };
  } else if (totalPackageCost <= 7000) {
    return { category: FreightCategory.C, price: 150 };
  } else if (totalPackageCost <= 10000) {
    return { category: FreightCategory.D, price: 200 };
  } else {
    return { category: FreightCategory.FRANCO, price: 0 }; //franco, tax price is 80 ex tax, but not for the sender!
  }
}
