import React, { ReactElement } from 'react';
import { DayOfWeek } from '../../../../types/expenses';
import { Field } from 'formik';
import { DatePickerFormikField } from '../../form/datePickerFormikField';

interface PropsFromParent {
  selectedSendTypeId: number;
}

const ExpenseSendFrequency: React.FC<PropsFromParent> = ({
  selectedSendTypeId,
}) => {
  const renderBasedOnSelectedType = () => {
    switch (selectedSendTypeId) {
      case 1:
        return (
          <p className={'text-muted py-2'}>
            Denne omkostning skal sendes alle hverdage klokken:
            <DatePickerFormikField
              name={'hour_of_the_day'}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={60}
              timeCaption="Tidspunkt"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              autoComplete="off"
            />
          </p>
        );
      case 2:
        return (
          <>
            <p className={'text-muted'}>
              Denne omkostning skal sendes pågældende ugedag:
            </p>
            <div className={'row d-flex justify-content-center'}>
              {Object.keys(DayOfWeek)
                .filter(k => isNaN(Number(k)))
                .map(enumKey => (
                  <label key={DayOfWeek[enumKey]} className={'col-4'}>
                    <Field
                      type="radio"
                      name="day_of_the_week"
                      value={DayOfWeek[enumKey] + ''}
                    />
                    <span className={'ml-2'}>{enumKey}</span>
                  </label>
                ))}
            </div>
          </>
        );
      case 3:
        return (
          <p className={'py-2'}>
            Den
            <Field
              as="select"
              name="day_of_the_month"
              className="form-control form-control-sm w-auto d-inline-block mx-2"
            >
              {createDaySelector()}
            </Field>
            i hver måned
          </p>
        );
      default:
        return null;
    }
  };

  const createDaySelector = () => {
    let options: ReactElement[] = [];
    for (let i = 1; i <= 31; i++) {
      options.push(<option key={i}>{i}</option>);
    }
    return options;
  };

  return <>{renderBasedOnSelectedType()}</>;
};

export default ExpenseSendFrequency;
