import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Container } from 'reactstrap';
import {
  TaxDeclaration,
  TaxDeclarationSet,
  TaxDeclarationType,
  TaxDeclarationYear,
  TaxDelete,
  TaxGetYear,
} from '../../../store/instructor/tax';
import { getMonthFromIndex } from './taxContent';
import { store } from '../../../store/store';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Description from '@material-ui/icons/Description';
import Add from '@material-ui/icons/Add';
import InstructorModal from '../../../components/modal/instructorModal';
import DeclareATaxAndLaborMCModalData from './declarationModals/declareATaxAndLaborMCModalData';
import { Company } from '../../../store/instructor/company';
import { BankAccount } from '../../../store/instructor/bank';
import DeclareSalesTaxModalData from './declarationModals/declareSalesTaxModalData';
import DeclareATPModalData from './declarationModals/declareATPModalData';
import ViewTaxDeclaration from './viewTaxDeclaration';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  companyId: number;
  taxDeclarationYear: TaxDeclarationYear;
  taxCompany: Company;
  companyAccounts: BankAccount[];
}

interface ThisCompState {
  year: number;
  tableData: TaxDeclarationSet[];
  modalDeclareATaxAndLaborMCVisible: boolean;
  modalDeclareATPVisible: boolean;
  modalDeclareSalesTaxVisible: boolean;
  modalViewTaxDecVisible: boolean;
  selectedMonth: number;
  selectedTaxDeclaration: TaxDeclaration;
}

class DeclarationOverview extends React.Component<
  PropsFromState,
  ThisCompState
> {
  constructor(props: Readonly<PropsFromState>) {
    super(props);
    const currentDate = new Date();
    this.state = {
      selectedTaxDeclaration: {
        company_id: -1,
        id: -1,
        bank_account_id: -1,
        date_of_creating: new Date(),
        is_pending: false,
        target_month: -1,
        target_year: -1,
        type: TaxDeclarationType.TAX,
        amount: -1,
        details: {
          salary_receiver_contribution: -1,
          employer_contribution: -1,
        },
      },
      modalViewTaxDecVisible: false,
      selectedMonth: -1,
      modalDeclareATaxAndLaborMCVisible: false,
      modalDeclareATPVisible: false,
      modalDeclareSalesTaxVisible: false,
      year: currentDate.getFullYear(),
      tableData: [],
    };
  }

  handleModalDeclareATaxAndLaborMCVisibility = () => {
    this.setState({
      modalDeclareATaxAndLaborMCVisible: !this.state
        .modalDeclareATaxAndLaborMCVisible,
    });
  };

  handleModalDeclareATPVisibility = () => {
    this.setState({
      modalDeclareATPVisible: !this.state.modalDeclareATPVisible,
    });
  };

  handleModalDeclareSalesTaxVisibility = () => {
    this.setState({
      modalDeclareSalesTaxVisible: !this.state.modalDeclareSalesTaxVisible,
    });
  };

  handleModalViewTaxDecVisibility = () => {
    this.setState({
      modalViewTaxDecVisible: !this.state.modalViewTaxDecVisible,
    });
  };

  onDeleteTaxDeclaration = (declaration: TaxDeclaration) => {
    store.dispatch(TaxDelete(declaration, this.props.companyId));
  };

  onDeclareClick = (declarationType: TaxDeclarationType, month: number) => {
    this.setState({ selectedMonth: month });
    switch (declarationType) {
      case TaxDeclarationType.A_AM_TAX:
        this.handleModalDeclareATaxAndLaborMCVisibility();
        break;
      case TaxDeclarationType.ATP:
        this.handleModalDeclareATPVisibility();
        break;
      case TaxDeclarationType.TAX:
        this.handleModalDeclareSalesTaxVisibility();
        break;
      default:
        throw Error('Invalid declarationType given!');
    }
  };

  onViewClick = (taxDeclaration: TaxDeclaration) => {
    this.setState({ selectedTaxDeclaration: taxDeclaration });
    this.handleModalViewTaxDecVisibility();
  };

  getDeclarationBox = (
    data: TaxDeclaration | null,
    type: TaxDeclarationType,
    month: number,
  ): any => {
    const currentDate = new Date();

    if (data) {
      //It has been declared
      //TODO this button also has two states: pending and completed!
      return (
        <span>
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip id={`tooltip-top`}>Vis indberetning</Tooltip>}
          >
            <Button variant="success" onClick={() => this.onViewClick(data)}>
              <Description />
            </Button>
          </OverlayTrigger>
        </span>
      );
    } else {
      //It has not declared
      return (
        <span>
          <OverlayTrigger
            placement={'top'}
            overlay={<Tooltip id={`tooltip-top`}>Indberet</Tooltip>}
          >
            <Button
              variant={this.getButtonVariant(
                currentDate.getMonth(),
                currentDate.getFullYear(),
                month,
                this.state.year,
              )}
              onClick={() => this.onDeclareClick(type, month)}
              disabled={
                this.getMonthDifference(
                  currentDate,
                  new Date(this.state.year, month),
                ) > 1
              }
            >
              <Add />
            </Button>
          </OverlayTrigger>
        </span>
      );
    }
  };

  getButtonVariant(currentMonth, currentYear, targetMonth, targetYear) {
    const monthDiff = this.getMonthDifference(
      new Date(currentYear, currentMonth),
      new Date(targetYear, targetMonth),
    );
    if (monthDiff < 0) {
      return 'danger';
    } else if (monthDiff === 0) {
      return 'warning';
    } else if (monthDiff === 1) {
      return 'info';
    } else {
      return 'secondary';
    }
  }

  getMonthDifference(dateFrom: Date, dateTo: Date): number {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  /** Should be called each time api data is called and received */
  setTableData = (taxDeclarationYear: TaxDeclarationYear) => {
    let rows: TaxDeclarationSet[] = [];
    for (let index = 0; index < 12; index++) {
      if (taxDeclarationYear.declarations === null) {
        throw Error('This should never happen! taxYear.declarations is null!');
      }
      rows.push(taxDeclarationYear.declarations[index]);
    }

    this.setState({ year: taxDeclarationYear.year, tableData: rows });
  };

  componentDidUpdate(
    prevProps: Readonly<PropsFromState>,
    prevState: Readonly<ThisCompState>,
  ) {
    if (this.state.year !== prevState.year) {
      store.dispatch(TaxGetYear(this.state.year, this.props.companyId));
    }

    if (this.props.taxDeclarationYear !== prevProps.taxDeclarationYear) {
      this.setTableData(this.props.taxDeclarationYear);
    }
  }

  componentDidMount() {
    store.dispatch(TaxGetYear(this.state.year, this.props.companyId));
  }

  onDecrementSelectedYear = () => {
    this.setState({ year: this.state.year - 1 });
  };

  onIncrementSelectedYear = () => {
    this.setState({ year: this.state.year + 1 });
  };

  renderHeader = () => {
    const currentDate = new Date();
    return (
      <div>
        Nuværende år: {this.state.year}
        <div>
          <Button
            variant="primary"
            onClick={this.onDecrementSelectedYear}
            disabled={this.state.year === 1994}
          >
            Forige år
          </Button>
          <Button
            variant="primary"
            onClick={this.onIncrementSelectedYear}
            disabled={this.state.year === currentDate.getFullYear() + 1}
          >
            Næste år
          </Button>
        </div>
      </div>
    );
  };

  renderTableRow(taxDecSet: TaxDeclarationSet, key: number): JSX.Element {
    return (
      <tr key={key}>
        <td>{getMonthFromIndex(taxDecSet.month)}</td>
        <td>
          {this.getDeclarationBox(
            taxDecSet.declaration_a_am_tax,
            TaxDeclarationType.A_AM_TAX,
            taxDecSet.month,
          )}
        </td>
        <td>
          {this.getDeclarationBox(
            taxDecSet.declaration_tax,
            TaxDeclarationType.TAX,
            taxDecSet.month,
          )}
        </td>
        <td>
          {this.getDeclarationBox(
            taxDecSet.declaration_atp,
            TaxDeclarationType.ATP,
            taxDecSet.month,
          )}
        </td>
      </tr>
    );
  }

  renderTable() {
    return (
      <Table>
        <thead>
          <tr>
            <th>Måned</th>
            <th>A-Skat og AM-bidrag</th>
            <th>Moms</th>
            <th>ATP</th>
          </tr>
        </thead>
        <tbody>
          {this.state.tableData.map((elem, key) =>
            this.renderTableRow(elem, key),
          )}
        </tbody>
      </Table>
    );
  }

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          <Container fluid={true}>
            {this.renderHeader()}
            {this.renderTable()}
          </Container>
        )}

        <InstructorModal
          modalState={this.state.modalDeclareATaxAndLaborMCVisible}
          showFunc={this.handleModalDeclareATaxAndLaborMCVisibility}
          titleHeader="Indberetning af A-Skat og AM-bidrag"
        >
          <DeclareATaxAndLaborMCModalData
            visibilityToggleFunc={
              this.handleModalDeclareATaxAndLaborMCVisibility
            }
            taxCompany={this.props.taxCompany}
            userCompanyBankAccounts={this.props.companyAccounts}
            selectedMonth={this.state.selectedMonth}
            selectedYear={this.state.year}
          />
        </InstructorModal>
        <InstructorModal
          modalState={this.state.modalDeclareSalesTaxVisible}
          showFunc={this.handleModalDeclareSalesTaxVisibility}
          titleHeader="Indberetning af moms"
          width={70}
        >
          <DeclareSalesTaxModalData
            visibilityToggleFunc={this.handleModalDeclareSalesTaxVisibility}
            taxCompany={this.props.taxCompany}
            userCompanyBankAccounts={this.props.companyAccounts}
            selectedMonth={this.state.selectedMonth}
            selectedYear={this.state.year}
          />
        </InstructorModal>
        <InstructorModal
          modalState={this.state.modalDeclareATPVisible}
          showFunc={this.handleModalDeclareATPVisibility}
          titleHeader="Indberetning af ATP"
          width={50}
        >
          <DeclareATPModalData
            visibilityToggleFunc={this.handleModalDeclareATPVisibility}
            taxCompany={this.props.taxCompany}
            userCompanyBankAccounts={this.props.companyAccounts}
            selectedMonth={this.state.selectedMonth}
            selectedYear={this.state.year}
          />
        </InstructorModal>

        <InstructorModal
          modalState={this.state.modalViewTaxDecVisible}
          showFunc={this.handleModalViewTaxDecVisibility}
          titleHeader="Detaljeret visning"
          width={50}
        >
          <ViewTaxDeclaration
            visibilityToggleFunc={this.handleModalViewTaxDecVisibility}
            taxDeclaration={this.state.selectedTaxDeclaration}
            deleteTaxDeclarationFunc={this.onDeleteTaxDeclaration}
          />
        </InstructorModal>
      </div>
    );
  }
}

const mapStateToProps = ({ userInfo, iTax }: ApplicationState) => ({
  loading: userInfo.loading || iTax.loading,
  companyId: userInfo.userSuperCompanyId,
  taxDeclarationYear: iTax.declarationYear,
});

export default connect(mapStateToProps)(DeclarationOverview);
