import React from 'react';
import { Company, companyGetAll } from '../../../store/instructor/company';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { Tab, Tabs } from 'react-bootstrap';
import RequisitionTab, { TabArchiveType } from './requisitionTab';
import { PDFTypes } from '../../../components/pdfgenerator/documentPreview';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromStore {
  allCompanies: Company[];
  userCompanyId: number;
  requisitionLoading: boolean;
}

class TabHolder extends React.Component<PropsFromStore> {
  componentDidMount() {
    store.dispatch(companyGetAll());
  }

  public render() {
    return (
      <Tabs defaultActiveKey={'activeTab'}>
        <Tab title="Ubehandlede" eventKey="activeTab">
          {this.props.requisitionLoading ? (
            <div className={'d-flex justify-content-center'}>
              <LoadingComponent visible={this.props.requisitionLoading} />
            </div>
          ) : (
            <RequisitionTab
              allCompanies={this.props.allCompanies}
              type={TabArchiveType.ACTIVE}
              pdfType={PDFTypes.REQUISITION}
            />
          )}
        </Tab>
        <Tab title="Behandlede" eventKey="processedTab">
          {this.props.requisitionLoading ? (
            <div className={'d-flex justify-content-center'}>
              <LoadingComponent visible={this.props.requisitionLoading} />
            </div>
          ) : (
            <RequisitionTab
              allCompanies={this.props.allCompanies}
              type={TabArchiveType.PROCESSED}
              pdfType={PDFTypes.REQUISITION}
            />
          )}
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = ({
  iCompany,
  userInfo,
  Requisitions,
}: ApplicationState) => ({
  allCompanies: iCompany.companies,
  userCompanyId: userInfo.userSuperCompanyId,
  requisitionLoading: Requisitions.loading,
});

export default connect(mapStateToProps)(TabHolder);
