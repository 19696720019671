import * as React from 'react';
import { connect } from 'react-redux';
import { store } from '../../../store/store';
import { ApplicationState } from '../../../store';
import {
  User,
  usersDelete,
  usersGetAll,
} from '../../../store/instructor/users';
import InstructorModal from '../../../components/modal/instructorModal';
import AddUserModalData from './featureModal/addUserModalData';
import AddIcon from '@material-ui/icons/Add';
import ChangePasswordModalData from './featureModal/changePasswordModalData';
import { confirmAlert } from 'react-confirm-alert';
import { Company, companyGetAll } from '../../../store/instructor/company';
import CustomTable from '../../../components/table/customTable';
import { convertCompanyIdToName } from '../../../utils/convertions';
import { filterBySimilarity } from '../../../utils/filteringAlgorithms';
import { sortStringAlphabetically } from '../../../utils/sortingAlgorithms';
import ChangeUserSuperCompanyModalData from './featureModal/changeUserSuperCompanyModalData';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromState {
  loading: boolean;
  currentUserTypeId: number;
  currentUserId: number;
  instructors: User[];
  students: User[];
  allCompanies: Company[];
}

interface ThisCompState {
  usersTableData: User[];
  usersTableHeader: any[];
  modalAddVisible: boolean;
  modalChangePasswordVisible: boolean;
  modalChangeSuperCompanyVisible: boolean;
  selectedUser: User;
  superCompanies: Company[];
}

class UserOverviewContent extends React.Component<
  PropsFromState,
  ThisCompState
> {
  state = {
    modalAddVisible: false,
    modalChangePasswordVisible: false,
    modalChangeSuperCompanyVisible: false,
    selectedUser: {
      id: -1,
      email: '',
      username: '',
      user_type: '',
      user_type_id: -1,
      user_group_code: '',
      user_group_id: -1,
      first_name: '',
      last_name: '',
    },
    superCompanies: [],
    usersTableData: [],
    usersTableHeader: [
      {
        title: 'Email',
        field: 'email',
        editable: 'never',
      },
      {
        title: 'Brugernavn',
        field: 'username',
        editable: 'never',
      },
      {
        title: 'Brugertype',
        field: 'user_type',
        editable: 'never',
      },
      {
        title: 'Virksomhed',
        field: 'super_company_id',
        editable: 'never',
        customFilterAndSearch: (term, rowData) => {
          if (rowData.user_type_id === 2) {
            filterBySimilarity(
              term,
              convertCompanyIdToName(
                this.props.allCompanies,
                rowData.super_company_id,
              ),
            );
          }
        },
        customSort: (a, b) => {
          if (a.user_type_id === 2 && b.user_type_id === 2) {
            sortStringAlphabetically(
              convertCompanyIdToName(
                this.props.allCompanies,
                a.super_company_id,
              ),
              convertCompanyIdToName(
                this.props.allCompanies,
                b.super_company_id,
              ),
            );
          }
        },
        render: rowData => (
          <span>
            {rowData.super_company_id
              ? convertCompanyIdToName(
                  this.props.allCompanies,
                  rowData.super_company_id,
                )
              : null}
          </span>
        ),
      },
    ],
  };

  componentDidMount() {
    store.dispatch(usersGetAll());
    store.dispatch(companyGetAll());
  }

  componentDidUpdate(prevProps: Readonly<PropsFromState>) {
    if (
      prevProps.students !== this.props.students ||
      prevProps.instructors !== this.props.instructors
    ) {
      //TODO-future-optimize: we could avoid this if we combined users in redux, has to be done in saga
      const combinedArray: User[] = [];
      combinedArray.push(...this.props.students);
      combinedArray.push(...this.props.instructors);
      this.setState({ usersTableData: combinedArray });
    }
    if (prevProps.allCompanies !== this.props.allCompanies) {
      this.setState({
        superCompanies: this.props.allCompanies.filter(
          elem => elem.company_type_id === 1,
        ),
      });
    }
  }

  handleModalAddVisibility = () => {
    this.setState({ modalAddVisible: !this.state.modalAddVisible });
  };

  handleModalChangePasswordVisibility = () => {
    this.setState({
      modalChangePasswordVisible: !this.state.modalChangePasswordVisible,
    });
  };
  handleModalChangeSuperCompanyVisibility = () => {
    this.setState({
      modalChangeSuperCompanyVisible: !this.state
        .modalChangeSuperCompanyVisible,
    });
  };

  setSelectedUser = (user: User) => {
    this.setState({ selectedUser: user });
  };

  onClickDeleteUser = (user: User) => {
    store.dispatch(usersDelete(user.id));
  };

  confirmDeleteUser = (thisRowUser: User) => {
    confirmAlert({
      title: 'Bekræft sletning af bruger: ' + thisRowUser.username + '?',
      message: 'Er du sikker?',
      buttons: [
        {
          label: 'Ja',
          onClick: () => this.onClickDeleteUser(thisRowUser),
        },
        {
          label: 'Nej',
          onClick: () => void 0,
        },
      ],
    });
  };

  renderContent = () => {
    return (
      <>
        <CustomTable
          data={this.state.usersTableData}
          headers={this.state.usersTableHeader}
          isLoading={this.props.loading}
          actions={[
            {
              icon: 'edit',
              tooltip: 'Ændre brugerens virksomhed',
              onClick: (event, user) => {
                //TODO-future: this should display a msg or just remove action icon
                //action cannot be performed on instructors
                if (user.user_type_id === 2) {
                  this.setSelectedUser(user);
                  this.handleModalChangeSuperCompanyVisibility();
                }
              },
            },
            {
              icon: 'vpn_key',
              tooltip: 'Ændre brugerens kodeord',
              onClick: (event, user) => {
                //TODO-future: this should display a msg or just remove action icon
                //Action cannot be performed on other instuctors
                if (
                  user.user_type_id === 1 &&
                  user.id !== this.props.currentUserId
                ) {
                  //TODO display not possible
                } else {
                  this.setSelectedUser(user);
                  this.handleModalChangePasswordVisibility();
                }
              },
            },
            {
              icon: 'delete',
              tooltip: 'Slet bruger',
              onClick: (event, user) => this.confirmDeleteUser(user),
            },
          ]}
        />
        <button
          type="button"
          className="btn btn-success rounded-pill"
          onClick={() => {
            this.handleModalAddVisibility();
          }}
        >
          <AddIcon /> Opret ny bruger
        </button>
      </>
    );
  };

  public render() {
    return (
      <div>
        {this.props.loading ? (
          <div className={'d-flex justify-content-center'}>
            <LoadingComponent visible={this.props.loading} />
          </div>
        ) : (
          this.renderContent()
        )}

        <InstructorModal
          modalState={this.state.modalAddVisible}
          showFunc={this.handleModalAddVisibility}
          titleHeader="Tilføj bruger"
        >
          <AddUserModalData
            visibilityToggleFunc={this.handleModalAddVisibility}
            allSuperCompanies={this.state.superCompanies}
          />
        </InstructorModal>
        <InstructorModal
          modalState={this.state.modalChangePasswordVisible}
          showFunc={this.handleModalChangePasswordVisibility}
          titleHeader="Ændre kodeord"
        >
          <ChangePasswordModalData
            visibilityToggleFunc={this.handleModalChangePasswordVisibility}
            userToEdit={this.state.selectedUser}
            userEditorId={this.props.currentUserId}
          />
        </InstructorModal>
        <InstructorModal
          modalState={this.state.modalChangeSuperCompanyVisible}
          showFunc={this.handleModalChangeSuperCompanyVisibility}
          titleHeader="Ændre brugerens virksomhed"
        >
          <ChangeUserSuperCompanyModalData
            visibilityToggleFunc={this.handleModalChangeSuperCompanyVisibility}
            userToEdit={this.state.selectedUser}
            allSuperCompanies={this.props.allCompanies.filter(
              elem => elem.company_type_id === 1,
            )}
            userEditingTypeId={this.props.currentUserTypeId}
            userEditingId={this.props.currentUserId}
          />
        </InstructorModal>
      </div>
    );
  }
}

const mapStateToProps = ({ iUsers, iCompany, userInfo }: ApplicationState) => ({
  loading: iUsers.loading || iCompany.loading,
  errors: iUsers.errors,
  currentUserTypeId: userInfo.userTypeId,
  currentUserId: userInfo.id,
  students: iUsers.students,
  instructors: iUsers.instructors,
  allCompanies: iCompany.companies,
});

export default connect(mapStateToProps)(UserOverviewContent);
