import {
  IBankTransactionActionTypes,
  BankTransaction,
  NewBankTransaction,
} from './types';
import { action } from 'typesafe-actions';
import { Page } from '../../../utils/storeTypes';

export const BankTransactionGetAll = () =>
  action(IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL);
export const BankTransactionGetAllToFromAccount = (accountId: number) =>
  action(
    IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT,
    { accountId },
  );
export const BankTransactionGetAllToFromAccountPage = (
  accountId: number,
  page: number,
  pageSize: number,
  sort_by: string,
  descending: boolean,
  searchQuery: string | undefined,
  startDate: Date | null,
  endDate: Date | null,
) =>
  action(
    IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_PAGE,
    {
      accountId,
      page,
      pageSize,
      sort_by,
      descending,
      searchQuery,
      startDate,
      endDate,
    },
  );
export const BankTransactionCreate = (newTransaction: NewBankTransaction) =>
  action(IBankTransactionActionTypes.IBANKTRANSACTION_TRANSACTION_CREATE, {
    newTransaction,
  });
export const BankTransactionSuccess = (data: BankTransaction[]) =>
  action(
    IBankTransactionActionTypes.IBANKTRANSACTION_TRANSACTION_SUCCESS,
    data,
  );
export const BankTransactionSuccessToFromAccount = (data: BankTransaction[]) =>
  action(
    IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_SUCCESS,
    data,
  );
export const BankTransactionSuccessToFromAccountPage = (data: {
  accountId: number;
  currentPage: Page;
}) =>
  action(
    IBankTransactionActionTypes.IBANKTRANSACTION_GET_ALL_TOANDFROM_ACCOUNT_PAGE_SUCCESS,
    data,
  );
export const BankTransactionError = (message: string) =>
  action(
    IBankTransactionActionTypes.IBANKTRANSACTION_TRANSACTION_ERROR,
    message,
  );
