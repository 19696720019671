import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { messagesError, messagesSuccess } from './actions';
import {
  IMessagesActionTypes,
  Message,
  MessageEdit,
  MessageStatusType,
} from './types';
import { getEndpoint, postEndpoint, putEndpoint } from '../../../utils/api';
import { convertLocalDateTimeToHomemadeDate } from '../../../utils/convertions';

function* handleSetStatus(action) {
  const desiredStatus: MessageStatusType = action.payload.newStatus;
  const msg: Message = action.payload.msg;
  try {
    const msgEdit: MessageEdit = {
      msg_status: desiredStatus,
      msg_to_id: msg.msg_to_id,
      msg_body: msg.msg_body,
      msg_header: msg.msg_header,
      msg_from_id: msg.msg_from_id,
      msg_type: msg.msg_type,
      msg_recipient_id: msg.msg_recipient_id,
    };

    const res = yield putEndpoint('message', msgEdit, msg.msg_recipient_id);

    if (res.error) {
      yield put(messagesError(res.error));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAll() {
  try {
    const res = yield getEndpoint('message');

    if (res.error) {
      yield put(messagesError(res.error));
    } else {
      let msgs: Message[] = [];
      res.data.forEach(elem => {
        let parsedMsg = {
          msg_datesent: convertLocalDateTimeToHomemadeDate(elem.msg_datesent),
          msg_id: elem.msg_id,
          msg_to_id: elem.msg_to_id,
          msg_dateread: convertLocalDateTimeToHomemadeDate(elem.msg_dateread),
          msg_status: elem.msg_status,
          msg_type: elem.msg_type,
          msg_body: elem.msg_body,
          msg_from_id: elem.msg_from_id,
          msg_header: elem.msg_header,
          msg_recipient_id: elem.msg_recipient_id,
        };
        msgs.push(parsedMsg);
      });

      yield put(messagesSuccess(msgs));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleCreate(action) {
  try {
    const res = yield postEndpoint('message', action.payload.newMsg);

    if (res.error) {
      yield put(messagesError(res.error));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchMessages() {
  yield takeEvery(IMessagesActionTypes.IMESSAGES_GET_ALL, handleGetAll);
  yield takeEvery(IMessagesActionTypes.IMESSAGES_SET_STATUS, handleSetStatus);
  yield takeEvery(IMessagesActionTypes.IMESSAGES_CREATE, handleCreate);
}

function* iMessagesSaga() {
  yield all([fork(watchMessages)]);
}

export { iMessagesSaga };
