import React, { Component } from 'react';
import Checkout from './checkout';

interface PropsFromParent {
  products?: any;
  to_company_id: any;
  from_company_id: any;
  setProducts: any;
}

interface ThisCompState {}

const formatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
});

class ShoppingCart extends Component<PropsFromParent, ThisCompState> {
  render() {
    return (
      <div>
        <h2 className={'mt-5'}>Produkter i kurven</h2>

        {this.props.products ? (
          <>
            {this.props.products.map((product, index) => {
              return (
                <div
                  className={
                    'basket row align-items-center text-left p-2 bg-light my-1'
                  }
                >
                  <div className={'basket-item col-7'}>
                    {product.name} <br />
                    Varenr: {product.navision_id}
                  </div>
                  <div className={'basket-item col-1 text-center border mx-3'}>
                    {product.quantity}
                  </div>
                  <div className={'basket-item col-3 flex-fill text-right'}>
                    {formatter.format(product.quantity * product.price_cost)}
                  </div>
                </div>
              );
            })}
            <Checkout
              from_company_id={this.props.from_company_id}
              to_company_id={this.props.to_company_id}
              products={this.props.products}
              setProducts={this.props.setProducts}
            />
          </>
        ) : (
          'Der er på nuværende tidspunkt ingen varer i kurven'
        )}
      </div>
    );
  }
}

export default ShoppingCart;
