import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  BankAccount,
  BankAccountActionTypes,
  BankAccountTypeNumber,
} from './types';
import {
  bankAccountCompanyAccountsSuccess,
  bankAccountError,
  bankAccountGetAll,
  bankAccountGetStudentAccounts,
  bankAccountSpecificAccountSuccess,
  bankAccountStudentAccountsSuccess,
  bankAccountSuccess,
} from './actions';
import {
  deleteEndpoint,
  getEndpoint,
  getEndpointId,
  getEndpointWithFilters,
  postEndpoint,
  putEndpoint,
} from '../../../utils/api';
import { getBankAccountTypeIdFromString } from '../../../utils/convertions';

export interface ApiRequestNewBankAccount {
  name: string;
  currency_code: number;
  balance: number;
  user_id?: number;
  company_id?: number;
  bank_account_type_id: BankAccountTypeNumber;
}

export function parseBankAccount(accountData): BankAccount {
  return {
    id: accountData.id,
    name: accountData.name,
    type_id: getBankAccountTypeIdFromString(accountData.bank_account_type),
    currencyCode: accountData.currency_code,
    balance: accountData.balance,
    isActive: accountData.is_active,
  };
}

function parseBankAccounts(accountsData): BankAccount[] {
  let parsedAccounts: BankAccount[] = [];
  accountsData.forEach(elem => {
    let parsedBankAccount: BankAccount = parseBankAccount(elem);
    if (parsedBankAccount.type_id === BankAccountTypeNumber.COMPANY) {
      parsedBankAccount.companyId = elem.company_id;
    }
    if (parsedBankAccount.type_id === BankAccountTypeNumber.STUDENT) {
      parsedBankAccount.userId = elem.user_id;
    }
    parsedAccounts.push(parsedBankAccount);
  });

  return parsedAccounts;
}

function* handleGetAll() {
  try {
    const res = yield getEndpoint('bank-account');

    if (res.error) {
      yield put(bankAccountError(res.error));
    } else {
      yield put(bankAccountSuccess(parseBankAccounts(res.data)));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetAccount(action) {
  try {
    const res = yield getEndpointId('bank-account', action.payload.id);

    if (res.error) {
      yield put(bankAccountError(res.error));
    } else {
      yield put(
        bankAccountSpecificAccountSuccess(parseBankAccount(res.data[0])),
      );
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetCompanyAccounts(action) {
  try {
    const res = yield getEndpointId(
      'bank-account/company',
      action.payload.companyId,
    );

    if (res.error) {
      yield put(bankAccountError(res.error));
    } else {
      yield put(bankAccountCompanyAccountsSuccess(parseBankAccounts(res.data)));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleGetStudentAccounts() {
  try {
    const res = yield getEndpointWithFilters('bank-account', {
      bank_account_type_id: 2,
    });

    if (res.error) {
      yield put(bankAccountError(res.error));
    } else {
      yield put(bankAccountStudentAccountsSuccess(parseBankAccounts(res.data)));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleAdd(action) {
  try {
    const formattedNewAccount: ApiRequestNewBankAccount = {
      bank_account_type_id: action.payload.newBankAccount.type_id,
      name: action.payload.newBankAccount.name,
      currency_code: action.payload.newBankAccount.currencyCode,
      balance: action.payload.newBankAccount.balance,
    };

    if (
      formattedNewAccount.bank_account_type_id === BankAccountTypeNumber.STUDENT
    ) {
      formattedNewAccount.user_id = action.payload.newBankAccount.userId;
    } else if (
      formattedNewAccount.bank_account_type_id === BankAccountTypeNumber.COMPANY
    ) {
      formattedNewAccount.company_id = action.payload.newBankAccount.companyId;
    }

    const res = yield postEndpoint('bank-account', formattedNewAccount);

    if (res.error) {
      yield put(bankAccountError(res.error));
    } else {
      if (
        formattedNewAccount.bank_account_type_id ===
        BankAccountTypeNumber.STUDENT
      ) {
        yield put(bankAccountGetStudentAccounts());
      }
      yield put(bankAccountGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleDelete(action) {
  try {
    const res = yield deleteEndpoint('bank-account', action.payload.id);

    if (res.error) {
      yield put(bankAccountError(res.error));
    } else {
      yield put(bankAccountGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleEdit(action) {
  try {
    const res = yield putEndpoint(
      'bank-account',
      action.payload.editedBankAccount,
      action.payload.bankAccountId,
    );

    if (res.error) {
      yield put(bankAccountError(res.error));
    } else {
      yield put(bankAccountGetAll());
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchBankAccount() {
  yield takeEvery(BankAccountActionTypes.BANK_ACCOUNT_GET_ALL, handleGetAll);
  yield takeEvery(
    BankAccountActionTypes.BANK_ACCOUNT_GET_SPECIFIC_ACCOUNT,
    handleGetAccount,
  );
  yield takeEvery(
    BankAccountActionTypes.BANK_ACCOUNT_GET_COMPANY_ACCOUNTS,
    handleGetCompanyAccounts,
  );
  yield takeEvery(
    BankAccountActionTypes.BANK_ACCOUNT_GET_STUDENT_ACCOUNTS,
    handleGetStudentAccounts,
  );
  yield takeEvery(BankAccountActionTypes.BANK_ACCOUNT_CREATE, handleAdd);
  yield takeEvery(BankAccountActionTypes.BANK_ACCOUNT_DELETE, handleDelete);
  yield takeEvery(BankAccountActionTypes.BANK_ACCOUNT_EDIT, handleEdit);
}

function* bankAccountSaga() {
  yield all([fork(watchBankAccount)]);
}

export { bankAccountSaga };
