import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { InstructorPageLayout } from '../_layout';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useQuery } from 'react-query';
import { fetchCompanyById, fetchInvoiceById } from '../../../utils/api';
import { AxiosError } from 'axios';
import { Company } from '../../../store/instructor/company';
import InvoiceDetails from './details/InvoiceDetails';
import { Invoice } from '../../../store/instructor/orderInvoice';
import LoadingComponent from '../../../components/LoadingComponent';

export const InvoiceDetailsPage = () => {
  const { id } = useParams();
  const routes = [
    { path: '/instructor/mistakes', breadcrumb: 'Mistakes' },
    { path: '/instructor/mistakes/invoices/:id', breadcrumb: 'Faktura ' + id },
  ];

  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  const { data: invoice, isLoading: deliveryLoading } = useQuery<
    Invoice,
    AxiosError
  >(['invoice', id], () => fetchInvoiceById(id), {
    enabled: !!id,
  });

  const { data: companyFromDetails, isLoading: companyFromLoading } = useQuery<
    Company,
    AxiosError
  >(
    ['company', invoice?.company_from],
    () => fetchCompanyById(invoice!.company_from),
    {
      enabled: !!invoice?.company_from,
    },
  );

  const { data: companyToDetails, isLoading: companyToLoading } = useQuery<
    Company,
    AxiosError
  >(
    ['company', invoice?.company_to],
    () => fetchCompanyById(invoice!.company_to),
    {
      enabled: !!invoice?.company_to,
    },
  );

  const allLoadingFinished =
    !deliveryLoading && !companyFromLoading && !companyToLoading;

  return (
    <>
      <InstructorPageLayout>
        <div className={'pb-3'}>
          <h2>Detaljer</h2>
        </div>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to={'/instructor/dashboard'}>Forside</Link>
          </BreadcrumbItem>
          {breadcrumbs.map(({ match, breadcrumb }) => (
            <BreadcrumbItem key={match.url} active>
              <Link to={match.url}>{breadcrumb}</Link>
            </BreadcrumbItem>
          ))}
        </Breadcrumb>

        {/* Show loading while retrieving data */}
        {!allLoadingFinished && (
          <LoadingComponent visible={!allLoadingFinished} />
        )}

        {allLoadingFinished && (
          <InvoiceDetails
            to_company={companyToDetails}
            from_company={companyFromDetails}
            invoice={invoice!}
          />
        )}
      </InstructorPageLayout>
    </>
  );
};
