import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 
 ul {
  list-style-type: none;
}
 
  .cursor-pointer {cursor: pointer;}
  .link-primary {cursor: pointer;color: #0d6efd}
  
  .text-custom {color: #55a8fd;}
  .bg-custom {background-color: #55a8fd;}
  
  .w-5 {width: 5%;}
  .w-10 {width: 10%;}
  .w-15 {width: 15%;}
  .w-20 {width: 20%;}
  .w-30 {width: 30%;}
  .w-35 {width: 35%;}
  .w-40 {width: 40%;}
  .w-45 {width: 45%;}
  .w-55 {width: 55%;}
  .w-60 {width: 60%;}
  .w-65 {width: 65%;}
  .w-70 {width: 70%;}
  .w-80 {width: 80%;}
  .w-85 {width: 85%;}
  .w-90 {width: 90%;}
  .w-95 {width: 95%;}
  .w-100 {width: 100%;}
 .max-w-5 {max-width: 5%;}
 .max-w-10 {max-width: 10%;}
 .max-w-15 {max-width: 15%;}
 .max-w-20 {max-width: 20%;}
 .max-w-30 {max-width: 30%;}
 .max-w-35 {max-width: 35%;}
 .max-w-40 {max-width: 40%;}
 .max-w-45 {max-width: 45%;}
 .max-w-55 {max-width: 55%;}
 .max-w-60 {max-width: 60%;}
 .max-w-65 {max-width: 65%;}
 .max-w-70 {max-width: 70%;}
 .max-w-80 {max-width: 80%;}
 .max-w-85 {max-width: 85%;}
 .max-w-90 {max-width: 90%;}
 .max-w-95 {max-width: 95%;}
 .max-w-100 {max-width: 100%;}
  .h-vp-100 {height: 100vh;}
  
  .react-confirm-alert-overlay {
  z-index: 1060!important;
  }

  .mx-none {max-width: none!important;}
  
   #wrapper {
    overflow-x: hidden;
 }
.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.sidebar-wrapper {
  height: 100vh;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: #ffffff;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }

  .layout-wrapper {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: 780px) {
    .btn-toggle {
      display: flex;
    }
  }
}


/* Tabs (react-bootstrap override style) */

.nav-link.active {
    color: #55a8fd !important;
    border-top: 3px solid #55a8fd;
    border-color: #55a8fd rgba(40,44,42,0.1) transparent rgba(40,44,42,0.1) !important;
}

.nav-item {
color: rgba(0, 0, 0, 0.87);
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: 3px solid transparent !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 1px solid transparent !important;
    padding-top: 0.5rem!important;
    padding-bottom: 0.5rem!important;
    flex: 1 1 auto!important;
    text-align: center;
    margin-bottom: 0px !important;
}

.nav-item:hover {
    color: #1c92d2;
}

.nav.nav-tabs {
    justify-content: center!important;
    margin-bottom: 1.5rem!important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 2px 5px 0px rgb(0 0 0 / 12%);
}

/* Material Table Style override */
.MuiPaper-elevation2 {
    box-shadow: none!important;
    border: 1px solid rgba(0, 0, 0, 0.10);
}

/* Navigation bar style override */
.pro-sidebar .pro-sidebar-inner {
background: #1b2034 !important; 
color: white !important;
}

.pro-sidebar .pro-icon-wrapper {
    background-color: #55a8fd !important;
}

.pro-sidebar .pro-menu a {
    color: white !important;
}

.pro-sidebar .pro-menu .active-link {
    color: #55a8fd !important;
}

.pro-sidebar {
    color: white;
}

.pro-sidebar .pro-menu-item {

}

.pro-sidebar-content {
  overflow-y: auto;
}

.robot-terminal {
color: white;
  font-family: 'Roboto Mono', monospace;
  max-height: 20vh;
  height: 20vh;
  overflow-y: scroll;
  overflow-anchor: auto;
  font-size: 14px;
}

.react-datepicker-popper {
    z-index: 50;
}

 .MTableToolbar-highlight-6{
   color: #1b2034 !important;
   background-color: #f8f9fa !important;
 }
 
 .MuiCheckbox-colorSecondary.Mui-checked {
   color: #1b2034 !important;
 }

 .MuiCheckbox-colorSecondary.Mui-checked:hover, .MuiIconButton-colorSecondary:hover {
   background-color: rgb(0, 12, 245, 0.04) !important;
 }
`;
