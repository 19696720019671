import * as React from 'react';
import { connect } from 'react-redux';
import { StudentPageLayout } from '../_layout';
import { Helmet } from 'react-helmet-async';
import DashboardContent from './dashboardContent';

const StudentDashboard: React.FC = () => {
  return (
    <StudentPageLayout>
      <Helmet>
        <title>Forside</title>
      </Helmet>
      <DashboardContent />
    </StudentPageLayout>
  );
};

export default connect()(StudentDashboard);
