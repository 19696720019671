import * as React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

interface MyProps {
  titleHeader: string;
  modalState: boolean;
  showFunc?: any;
  children?: any;
  width?: number;
}

const InstructorModal: React.FC<MyProps> = props => {
  return (
    <>
      <Modal
        show={props.modalState}
        onHide={props.showFunc}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={props.width ? 'mx-none w-' + props.width : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.titleHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
};

export default connect()(InstructorModal);
