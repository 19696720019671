import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { ICompanyProductsActionTypes } from './types';
import {
  companyProductsError,
  companyProductsGetAll,
  companyProductsSuccess,
} from './actions';
import {
  addCompanyProduct,
  addCompanyProductAmount,
  deleteCompanyProduct,
  getEndpoint,
  removeCompanyProductAmount,
} from '../../../utils/api';

function* handleGetAll(action) {
  try {
    const res = yield getEndpoint(
      'company/' + action.payload.companyId + '/product',
    );
    if (res.error) {
      yield put(companyProductsError(res.error));
    } else {
      yield put(companyProductsSuccess(action.payload.companyId, res.data));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleAdd(action) {
  try {
    const res = yield addCompanyProduct(
      action.payload.companyId,
      action.payload.productId,
    );

    if (res.error) {
      yield put(companyProductsError(res.error));
    } else {
      yield put(companyProductsGetAll(action.payload.companyId));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleDelete(action) {
  try {
    const res = yield deleteCompanyProduct(
      action.payload.companyId,
      action.payload.productId,
    );

    if (res.error) {
      yield put(companyProductsError(res.error));
    } else {
      yield put(companyProductsGetAll(action.payload.companyId));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleAddAmountProduct(action) {
  try {
    const res = yield addCompanyProductAmount(
      action.payload.receiverCompanyId,
      action.payload.senderCompanyId,
      action.payload.deliveryId,
      action.payload.productId,
      action.payload.amount,
      action.payload.operationType,
    );

    if (res.error) {
      yield put(companyProductsError(res.error));
    } else {
      yield put(companyProductsGetAll(action.payload.receiverCompanyId));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* handleRemoveAmountProduct(action) {
  try {
    const res = yield removeCompanyProductAmount(
      action.payload.receiverCompanyId,
      action.payload.senderCompanyId,
      action.payload.deliveryId,
      action.payload.productId,
      action.payload.amount,
      action.payload.operationType,
    );

    if (res.error) {
      yield put(companyProductsError(res.error));
    } else {
      yield put(companyProductsGetAll(action.payload.senderCompanyId));
    }
  } catch (err) {
    console.log(err.message);
  }
}

function* watchCompanyProducts() {
  yield takeEvery(
    ICompanyProductsActionTypes.ICOMPANYPRODUCTS_GET_ALL,
    handleGetAll,
  );
  yield takeEvery(ICompanyProductsActionTypes.ICOMPANYPRODUCTS_ADD, handleAdd);
  yield takeEvery(
    ICompanyProductsActionTypes.ICOMPANYPRODUCTS_DELETE,
    handleDelete,
  );
  yield takeEvery(
    ICompanyProductsActionTypes.ICOMPANYPRODUCTS_AMOUNT_ADD,
    handleAddAmountProduct,
  );
  yield takeEvery(
    ICompanyProductsActionTypes.ICOMPANYPRODUCTS_AMOUNT_REMOVE,
    handleRemoveAmountProduct,
  );
}

function* companyProductsSaga() {
  yield all([fork(watchCompanyProducts)]);
}

export { companyProductsSaga };
