import React from 'react';
import InstructorModal from '../modal/instructorModal';
import { MistakeContext } from '../../store/contexts/MistakeProvider';
import SendMessage from './mistakeActions/SendMessage';
import { MistakeActionTypes } from '../../store/reducers/MistakesReducer';
import ReturnDelivery from './mistakeActions/ReturnDelivery';
import { ActionTitleDeterminer } from '../../utils/mistakesUtils';

export const MistakeModal: React.FC = () => {
  const { modalVisibility, setModalVisibility } = React.useContext(
    MistakeContext,
  );

  const renderProperModule = () => {
    switch (modalVisibility.actionType) {
      case MistakeActionTypes.MESSAGE:
        return <SendMessage />;
      case MistakeActionTypes.RETURN_DELIVERY:
        return <ReturnDelivery />;
      case MistakeActionTypes.UNDEFINED:
        return <p>Unknown action</p>;
    }
  };

  return (
    <InstructorModal
      width={65}
      modalState={modalVisibility.state}
      showFunc={() =>
        setModalVisibility({ state: !modalVisibility.state, actionType: '' })
      }
      titleHeader={ActionTitleDeterminer(modalVisibility.actionType)}
    >
      {renderProperModule()}
    </InstructorModal>
  );
};

export default MistakeModal;
