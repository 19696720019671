import React, { useEffect, useState } from 'react';
import {
  Company,
  companyGetAll,
  companyGetCreditors,
  companyGetDebtors,
  companyGetExpenseComps,
} from '../../../store/instructor/company';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Tab, Tabs } from 'react-bootstrap';
import InboxTab, { InboxTabType } from './inboxTab';
import { store } from '../../../store/store';
import NewMsgTab from './newMsgTab';
import LoadingComponent from '../../../components/LoadingComponent';

interface PropsFromParent {
  loading: boolean;
  allCompanies: Company[];
  thisUserCompanyId: number;
  companyDebtors: Company[];
  companyCreditors: Company[];
  companyExpenses: Company[];
}

const InboxContent: React.FC<PropsFromParent> = props => {
  const [msgReceivers, setMsgReceivers] = useState<Company[]>([]);
  const [indicator, setIndicator] = useState<string>('');

  useEffect(() => {
    setMsgReceivers([
      ...props.companyDebtors,
      ...props.companyCreditors,
      ...props.companyExpenses,
    ]);
  }, [props.companyDebtors, props.companyCreditors, props.companyExpenses]);

  useEffect(() => {
    store.dispatch(companyGetAll());
    store.dispatch(companyGetDebtors(props.thisUserCompanyId));
    store.dispatch(companyGetCreditors(props.thisUserCompanyId));
    store.dispatch(companyGetExpenseComps(props.thisUserCompanyId));
  }, []);

  const renderTab = (type: InboxTabType) => {
    return (
      <Tab eventKey={type} title={type}>
        <InboxTab tabType={type} allCompanies={props.allCompanies} />
      </Tab>
    );
  };

  const renderContent = () => {
    return (
      <Tabs mountOnEnter={true}>
        <Tab eventKey={'newMsgTab'} title={'Ny Besked'} className={'p-4'}>
          <NewMsgTab setIndicator={setIndicator} msgReceivers={msgReceivers} />
          {indicator ? (
            <div className="alert alert-success mt-2" role="alert">
              {indicator}
            </div>
          ) : (
            ''
          )}
        </Tab>
        {renderTab(InboxTabType.UNREAD)}
        {renderTab(InboxTabType.INBOX)}
        {renderTab(InboxTabType.SENT)}
      </Tabs>
    );
  };

  return (
    <div>
      {props.loading ? (
        <div className={'d-flex justify-content-center'}>
          <LoadingComponent visible={props.loading} />
        </div>
      ) : (
        renderContent()
      )}
    </div>
  );
};

const mapStateToProps = ({
  iMessages,
  iCompany,
  userInfo,
}: ApplicationState) => ({
  loading: iMessages.loading,
  errors: iMessages.errors,
  allCompanies: iCompany.companies,
  thisUserCompanyId: userInfo.userSuperCompanyId,
  companyCreditors: iCompany.companyCreditors,
  companyDebtors: iCompany.companyDebtors,
  companyExpenses: iCompany.companyExpenseComps,
});

export default connect(mapStateToProps)(InboxContent);
