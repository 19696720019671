import React from 'react';
import { connect } from 'react-redux';
import NewShipmentPage from './new';
import { store } from '../../../../../store/store';
import {
  deliveryCreate,
  NewDelivery,
} from '../../../../../store/instructor/delivery';
import {
  Company,
  companyGetProducts,
} from '../../../../../store/instructor/company';
import { Product } from '../../../../../store/instructor/product';
import InvoiceCreation from '../../../invoice/invoiceCreation';
import { invoiceCreate } from '../../../../../store/instructor/orderInvoice';
import { CompanyIdProductsPair } from '../../../../../store/instructor/company-products';
import { ApplicationState } from '../../../../../store';

export enum StepType {
  DELIVERY = 'Forsendelse',
  INVOICE = 'Faktura',
}

interface PropsFromParent {
  allCompanies: Company[];
  userCompanyId: number;
  companyProducts: Product[];
  debtorCompanies: Company[];
  deliveryError?: string;
  allCompanyProductsPairs: CompanyIdProductsPair[];
  errorsFirst?: string;
  errorsLast?: string;
}

interface ThisCompState {
  currentStep: StepType;
  newDelivery?: NewDelivery; //Data from step-one, new delivery
  newDeliveryTotalCost?: {
    total_amount_without_vax: number;
    total_vax: number;
  }; //Data from step-one, delivery amount
}

class TwoStepDeliveryAndInvoice extends React.Component<
  PropsFromParent,
  ThisCompState
> {
  constructor(props: PropsFromParent) {
    super(props);
    this.state = {
      currentStep: StepType.DELIVERY,
    };
  }

  onNewDeliveryCreated = (newDelivery: NewDelivery) => {
    this.setState({ newDelivery: newDelivery, currentStep: StepType.INVOICE });
  };

  setNewDeliveryTotalCost = data => {
    this.setState({ newDeliveryTotalCost: data });
  };

  onLastStepCompleted = (
    payment_receiving_bank_account_id: number,
    navision_id: number,
  ) => {
    if (this.state.newDelivery === undefined) {
      throw new Error(
        'TwoStepDeliveryAndInvoice. On last step completed, first step was not executed right.',
      );
    } else {
      const handleDispatches = async () => {
        //Check if products are available
        const products = //TODO-futrue.. it is currently [number[2]] //make typesafe!
          this.state.newDelivery?.products === undefined
            ? []
            : this.state.newDelivery?.products;

        let productAmountErrors: string[] = [];
        products.forEach(elemDelivery => {
          const matchingProd = this.props.companyProducts.find(
            elem => elem.id === elemDelivery[0],
          );
          if (matchingProd === undefined) {
            //THIS CANNOT HAPPEN
            throw new Error(
              'TwoStepDeliveryAndInvoice: the product in new delivery has no relation to the company anymore. Happened since adding to basket.',
            );
          } else if (elemDelivery[1] > matchingProd.amount) {
            productAmountErrors.push(
              'There is not sufficient amount of ' +
                elemDelivery[0] +
                ' in stock.',
            );
          }
        });
        if (productAmountErrors.length > 0) {
          console.log(productAmountErrors);
        }

        await store.dispatch(
          deliveryCreate(this.state.newDelivery!, this.props.userCompanyId),
        );

        await store.dispatch(
          invoiceCreate({
            total_amount_without_vax: this.state.newDeliveryTotalCost
              ?.total_amount_without_vax,
            total_vax: this.state.newDeliveryTotalCost?.total_vax,
            requisition_id: this.state.newDelivery?.requisition_id,
            payment_receiving_bank_account_id: payment_receiving_bank_account_id,
            navision_id: navision_id,
          }),
        );

        this.setState({ currentStep: StepType.DELIVERY });

        //Reload data in product table
        store.dispatch(companyGetProducts(this.props.userCompanyId));
      };

      handleDispatches();
    }
  };

  render() {
    return (
      <>
        {this.state.currentStep === StepType.DELIVERY ? (
          <>
            Nuværende trin: <b>{StepType.DELIVERY}</b> - {StepType.INVOICE}
          </>
        ) : (
          <>
            Nuværende trin: {StepType.DELIVERY} - <b>{StepType.INVOICE}</b>
          </>
        )}
        {this.state.currentStep === StepType.DELIVERY ? (
          <NewShipmentPage
            debtorCompanies={this.props.debtorCompanies}
            allCompanies={this.props.allCompanies}
            companyProducts={this.props.companyProducts}
            userCompanyId={this.props.userCompanyId}
            setRequisitionInStepParent={this.onNewDeliveryCreated}
            setDeliveryTotalCostInStepParent={this.setNewDeliveryTotalCost}
            allCompanyProductsPairs={this.props.allCompanyProductsPairs}
          />
        ) : null}
        {this.state.currentStep === StepType.INVOICE ? (
          <InvoiceCreation setInvoiceInStepParent={this.onLastStepCompleted} />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ iInvoice, iDelivery }: ApplicationState) => ({
  errorsFirst: iInvoice.errors,
  errorsLast: iDelivery.errors,
});

export default connect(mapStateToProps)(TwoStepDeliveryAndInvoice);
