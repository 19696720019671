import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { NotFoundPage } from './components/notFoundPage/loadable';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import InstructorRoute from './routes/instructor';
import AdminDashboard from './pages/instructor-pages/dashboard';
import AdminCompanies from './pages/instructor-pages/companies';
import StudentRoute from './routes/student';
import AdminProducts from './pages/instructor-pages/products';
import AdminOrderGen from './pages/instructor-pages/orderGenerator';
import AdminOrderInvoices from './pages/instructor-pages/orderInvoices';
import AdminUsersOverview from './pages/instructor-pages/userOverview';
import AdminInbox from './pages/instructor-pages/inbox';
import StudentDashboard from './pages/student-pages/dashboard';
import StudentShipping from './pages/student-pages/shipping';
import StudentInbox from './pages/student-pages/inbox';
import StudentProducts from './pages/student-pages/products';
import StudentCompany from './pages/student-pages/companies';
import StudentBank from './pages/student-pages/bank';
import AdminBank from './pages/instructor-pages/bank';
import Frontpage from './pages/public-pages/frontpage';
import TempDevPage from './components/temporary/TempDevPage';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoginPage from './pages/public-pages/login/loginPage';
import { GuestRoute } from './routes/guest';
import Logout from './pages/public-pages/login/logout';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store/store';
import RequisitionPage from './pages/student-pages/requisition';
import AdminRequisition from './pages/instructor-pages/requisition';
import RequisitionOverview from './pages/student-pages/rekvisitioner';
import InvoiceOverviewPage from './pages/student-pages/invoice-overview';
import InventoryPage from './pages/student-pages/inventory';
import TaxPage from './pages/student-pages/tax';
import SalaryOverviewPage from './pages/student-pages/salary';
import MistakePage from './pages/instructor-pages/mistakes/MistakePage';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-datepicker/dist/react-datepicker.css';
import MistakeProvider from './store/contexts/MistakeProvider';
import { InvoiceDetailsPage } from './pages/instructor-pages/overview/InvoiceDetailsPage';
import { RequisitionDetailsPage } from './pages/instructor-pages/overview/RequisitionDetailsPage';
import { DeliveryDetailsPage } from './pages/instructor-pages/overview/DeliveryDetailsPage';
import 'moment/locale/da';
import { RobotProvider } from './contexts/RobotContexts';
import { ModalProvider } from './contexts/modalContexts';
import * as Yup from 'yup';
import YupLocaleDa from '../locales/dk/yup_locale_da';
import ExpensesPage from './pages/instructor-pages/expenses/expensesPage';
import { ReactQueryDevtools } from 'react-query/devtools';
import BankStatement from './pages/student-pages/bank-statement';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import { ToastProvider } from './contexts/ToastContext';

Yup.setLocale(YupLocaleDa);
registerLocale('da', da);
setDefaultLocale('da');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HashRouter>
            <GlobalStyle />
            <Helmet
              titleTemplate="KOPRA - %s"
              defaultTitle="KOPRA - Kontorelevernes praktiksystem"
            >
              <meta
                name="description"
                content="A React Boilerplate application"
              />
              <meta httpEquiv="cache-control" content="no-cache" />
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
              />
            </Helmet>
            <ToastProvider>
              <RobotProvider>
                <MistakeProvider>
                  <ModalProvider>
                    <Switch>
                      <Route exact path="/" component={Frontpage} />
                      <Route exact path="/dev" component={TempDevPage} />
                      <GuestRoute exact path="/login" component={LoginPage} />
                      <Route exact path="/logout" component={Logout} />
                      <Route
                        path="/instructor/mistakes/invoices/:id"
                        component={InvoiceDetailsPage}
                      />
                      <Route
                        path="/instructor/mistakes/deliveries/:id"
                        component={DeliveryDetailsPage}
                      />
                      <Route
                        path="/instructor/mistakes/requisitions/:id"
                        component={RequisitionDetailsPage}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/dashboard"
                        component={AdminDashboard}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/mistakes"
                        component={MistakePage}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/requisition"
                        component={AdminRequisition}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/company"
                        component={AdminCompanies}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/product"
                        component={AdminProducts}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/orderGenerator"
                        component={AdminOrderGen}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/orderInvoices"
                        component={AdminOrderInvoices}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/users"
                        component={AdminUsersOverview}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/inbox"
                        component={AdminInbox}
                      />
                      <StudentRoute
                        exact
                        path="/student/dashboard"
                        component={StudentDashboard}
                      />
                      <StudentRoute
                        exact
                        path="/student/company"
                        component={StudentCompany}
                      />
                      <StudentRoute
                        exact
                        path="/student/shipping"
                        component={StudentShipping}
                      />
                      <StudentRoute
                        exact
                        path="/student/requisition"
                        component={RequisitionPage}
                      />
                      <StudentRoute
                        exact
                        path="/student/requisitions"
                        component={RequisitionOverview}
                      />
                      <StudentRoute
                        exact
                        path="/student/products"
                        component={StudentProducts}
                      />
                      <StudentRoute
                        exact
                        path="/student/inventory"
                        component={InventoryPage}
                      />
                      <StudentRoute
                        exact
                        path="/student/inbox"
                        component={StudentInbox}
                      />
                      <StudentRoute
                        exact
                        path="/student/tax"
                        component={TaxPage}
                      />
                      <StudentRoute
                        exact
                        path="/student/bank"
                        component={StudentBank}
                      />
                      <StudentRoute
                        exact
                        path="/student/statement"
                        component={BankStatement}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/bank"
                        component={AdminBank}
                      />
                      <InstructorRoute
                        exact
                        path="/instructor/expenses"
                        component={ExpensesPage}
                      />
                      <StudentRoute
                        exact
                        path="/student/invoice"
                        component={InvoiceOverviewPage}
                      />
                      <StudentRoute
                        exact
                        path="/student/salary"
                        component={SalaryOverviewPage}
                      />
                      <Route component={NotFoundPage} />
                    </Switch>
                  </ModalProvider>
                </MistakeProvider>
              </RobotProvider>
            </ToastProvider>
          </HashRouter>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
