import {
  OrderGen,
  orderGenSetIsActive,
} from '../../../../store/instructor/orderGen';
import { ProductType } from '../../../../store/instructor/productType';
import { Company } from '../../../../store/instructor/company';
import * as React from 'react';
import { store } from '../../../../store/store';
import { Label } from 'reactstrap';
import WeekTab from './companyTab';
import { connect } from 'react-redux';
import RobotConsole from '../../../../components/robotConsole/robotConsole';

interface PropsFromState {
  orderGen: OrderGen;
}

interface ThisCompState {
  allProductTypes: ProductType[];
  allCompanies: Company[];
  indicator: string;
}

class OrderGeneratorTab extends React.Component<PropsFromState, ThisCompState> {
  state = {
    allProductTypes: [],
    allCompanies: [],
    indicator: '',
  };

  setIndicator = (msg: string) => {
    this.setState({
      indicator: msg,
    });
  };

  renderActiveButton = () => {
    return (
      <>
        <Label>
          Ordregenerator status:
          {this.props.orderGen.active ? (
            <span className="ml-1 badge badge-success">Aktiv</span>
          ) : (
            <span className="ml-1 badge badge-danger">Ikke aktiv</span>
          )}
        </Label>
        <div>
          {this.props.orderGen.active ? (
            <button
              className={'mb-2 d-block btn btn-sm btn-outline-danger'}
              onClick={() =>
                store.dispatch(orderGenSetIsActive(false, this.props.orderGen))
              }
            >
              Deaktiver ordregenerator
            </button>
          ) : (
            <button
              className={'mb-2 d-block btn btn-sm btn-outline-success'}
              onClick={() =>
                store.dispatch(orderGenSetIsActive(true, this.props.orderGen))
              }
            >
              Aktiver ordregenerator
            </button>
          )}
        </div>
      </>
    );
  };

  renderTabs = () => {
    return (
      <>
        {this.renderActiveButton()}
        <WeekTab
          setIndicator={this.setIndicator}
          orderGen={this.props.orderGen}
        />
        {this.state.indicator ? (
          <div className="alert alert-success mt-2" role="alert">
            {this.state.indicator}
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  public render() {
    return (
      <div>
        {this.renderTabs()}

        <RobotConsole orderGen={this.props.orderGen} />
      </div>
    );
  }
}

export default connect()(OrderGeneratorTab);
