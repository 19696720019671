import { HomemadeDate } from '../../../utils/homemadeDate';

export interface Message {
  msg_datesent: HomemadeDate;
  msg_id: number;
  msg_to_id: number;
  msg_dateread: HomemadeDate;
  msg_status: MessageStatusType;
  msg_type: MessageType;
  msg_body: string;
  msg_from_id: number;
  msg_header: string;
  msg_recipient_id: number;
}

export interface MessagePaginated {
  items: Message[];
  current_page: number;
  page_size: number;
  total_items: number;
  total_pages: number;
}

export enum MessageStatusType {
  READ = 'READ',
  UNREAD = 'UNREAD',
  DELETED = 'DELETED',
}

export enum MessageType {
  MAIL = 'MAIL',
  INVOICE = 'INVOICE',
  SOMETHING = 'SOMETHING',
}

export interface MessageEdit {
  msg_status: MessageStatusType;
  msg_to_id: number;
  msg_body: string;
  msg_header: string;
  msg_from_id: number;
  msg_type: MessageType;
  msg_recipient_id: number;
}

export interface MessageNew {
  msg_to_id: number;
  msg_body: string;
  msg_header: string;
  msg_from_id: number;
  msg_type: MessageType;
}

export enum IMessagesActionTypes {
  IMESSAGES_GET_ALL = '@@instructor/messages/GET_ALL',
  IMESSAGES_SET_STATUS = '@@instructor/messages/SET_STATUS',
  IMESSAGES_CREATE = '@@instructor/messages/CREATE',
  IMESSAGES_SUCCESS = '@@instructor/messages/SUCCESS',
  IMESSAGES_ERROR = '@@instructor/messages/ERROR',
}

export interface IMessageState {
  readonly messages: Message[];
  readonly loading: boolean;
  readonly errors?: string;
}
