import { SuperCompany } from '../../../../../../utils/deliveryUtils';

export enum ShippingAreaType {
  DOMESTIC,
  FOREIGN,
}

export class ShippingPrices {
  type: ShippingAreaType;
  headerLabels: string[];
  priceRows: string[][];
  superCompany: number;

  constructor(type: ShippingAreaType, superCompany: SuperCompany) {
    this.type = type;
    this.superCompany = superCompany;
    this.headerLabels = [
      'Interval (total pris levering)',
      'Kategori',
      'Fragt pris',
    ];
    this.priceRows = [[]];
    if (type === ShippingAreaType.DOMESTIC) {
      this.setShippingDetailsDomestic();
    } else if (type === ShippingAreaType.FOREIGN) {
      this.priceRows = [[]];
    } else {
      throw new Error(
        'Shipping price info: a new ShippingAreaType has been added but not handled!',
      );
    }
  }

  setShippingDetailsDomestic() {
    this.priceRows = [];
    if (this.superCompany === 1) {
      this.priceRows.push(['0-3000kr', 'A', '50kr']);
      this.priceRows.push(['3.001-5.000kr', 'B', '100kr']);
      this.priceRows.push(['5.001-7.000kr', 'C', '150kr']);
      this.priceRows.push(['7.001-10.000kr', 'D', '200kr']);
      this.priceRows.push(['+10.000kr', '', 'franko']);
    } else if (this.superCompany === 2) {
      this.priceRows.push(['0-1.000kr', 'A', '50kr']);
      this.priceRows.push(['1.001-3.000kr', 'B', '100kr']);
      this.priceRows.push(['3.001-5.000kr', 'C', '150kr']);
      this.priceRows.push(['5.001-7.000kr', 'D', '200kr']);
      this.priceRows.push(['+7.000kr', '', 'franko']);
    } else {
      throw new Error(
        'Shipping price info: a new super company has been added! But is not handled!',
      );
    }
  }
}
